import React from "react"
import TwitterPost from "../Previews/Twitter"
import FacebookPost from "../Previews/Facebook"
import InstagramPost from "../Previews/Instagram"
import LinkedIn from "../Previews/LinkedIn/LinkedIn"
import Pinterest from "../Previews/Pinterest/Pinterest"
import { ReactComponent as FaceBookIcon } from "../../icons/socialIcons/facebook.svg"
import { ReactComponent as FaceBookGroupIcon } from "../../icons/socialIcons/facebook-group.svg"
import { ReactComponent as InstagramIcon } from "../../icons/socialIcons/instagram.svg"
import { ReactComponent as TwitterIcon } from "../../icons/socialIcons/twitter.svg"
import { ReactComponent as LinkedinIcon } from "../../icons/socialIcons/linkedin.svg"
import { ReactComponent as LinkedinCompanyIcon } from "../../icons/socialIcons/linkedin-company-page.svg"
import { ReactComponent as PinterestIcon } from "../../icons/socialIcons/pinterest.svg"


export const socialObj = {
    instagram: {
        view: InstagramPost,
        Icon: (
            <div className="social-icon-background">
                <InstagramIcon />
            </div>
        ),
    },
    instagrampage: {
        view: InstagramPost,
        Icon: (
            <div className="social-icon-background">
                <InstagramIcon />
            </div>
        ),
    },
    facebook: {
        view: FacebookPost,
        Icon: (
            <div className="social-icon-background">
                <FaceBookIcon />
            </div>
        ),
    },
    facebookpage: {
        view: FacebookPost,
        Icon: (
            <div className="social-icon-background">
                <FaceBookIcon />
            </div>
        ),
    },
    facebookgroup: {
        view: FacebookPost,
        Icon: (
            <div className="social-icon-background">
                <FaceBookGroupIcon />
            </div>
        ),
    },
    twitter: {
        view: TwitterPost,
        Icon: (
            <div className="social-icon-background">
                <TwitterIcon />
            </div>
        ),
    },
    twitteruser: {
        view: TwitterPost,
        Icon: (
            <div className="social-icon-background">
                <TwitterIcon />
            </div>
        ),
    },
    linkedin: {
        view: LinkedIn,
        Icon: (
            <div className="social-icon-background">
                <LinkedinIcon />
            </div>
        ),
    },
    linkedinuser: {
        view: LinkedIn,
        Icon: (
            <div className="social-icon-background">
                <LinkedinIcon />
            </div>
        ),
    },
    linkedingroup: {
        view: LinkedIn,
        Icon: (
            <div className="social-icon-background">
                <LinkedinCompanyIcon />
            </div>
        ),
    },
    linkedinpage: {
        view: LinkedIn,
        Icon: (
            <div className="social-icon-background">
                <LinkedinCompanyIcon />
            </div>
        ),
    },
    pinterest: {
        view: Pinterest,
        Icon: (
            <div className="social-icon-background">
                <PinterestIcon />
            </div>
        ),
    },
    pinterestuser: {
        view: Pinterest,
        Icon: (
            <div className="social-icon-background">
                <PinterestIcon />
            </div>
        ),
    },
}
