import React from 'react';
import "./CircleCheckBox.css";
import {ReactComponent as Check} from "./check.svg";

const CircleCheckBox = ({select, selectChange, ...props}) => {
    return (
        <div className={`aui-checkbox-select ${select && "aui-checkbox-selected"}`} onClick={() => {selectChange && selectChange(prev => !prev)}}> 
            {select && <Check />}
        </div>
    )
}

export default CircleCheckBox;