import React, { useEffect, useRef, useState } from "react"
import Loader from "../../../commonComps/Loaders"
import "rc-time-picker/assets/index.css"
import "./PostBottomBarNew.scss"
import { useTracking } from "react-tracking"
import { CLICK, SOCIAL_POST } from "../../../../constants"
import dropdownIcon from "../../../icons/social/dropdown.svg"
import { connect } from "react-redux"
import { changePost, updateTimezone } from "../../../../Actions/postAction"
import { updatedTimeZones } from "../../../../Data/timeZones"
import SearchableMenu from "../../SearchableMenu"
import { createMessage } from "../../../../Actions/messageAction"
import { updatePublishTimezone } from "../../../../Actions/publishAction"
import { getAssociatedWorkflowWithPost } from "../../../apis"
import Axios from "axios"
import { DatePicker, TimePicker } from "@awesomesuite-frontend/awesome-nebula"
import { getHeaders } from "../../../../Actions/userAction"
import Guard from "Awesome-ui/Guard"
import TooltipWrapper from "components/commonComps/Tooltip/TooltipWrapper"
import { createUseStyles } from "react-jss"
import { ReactComponent as AddQueue } from "./../../../icons/queuelogo.svg"

const useStyles = createUseStyles({
    bulkPublishCustomMenu: {
        minWidth: "150px",
    },
})

async function getWorkflow(brandId, postId) {
    const headers = getHeaders().headers
    let levelRejected = false
    try {
        const res = await Axios.get(getAssociatedWorkflowWithPost, {
            headers: headers,
            params: {
                brand_id: brandId,
                post_id: postId,
            },
        })
        if (res.data.model && res.data.model?.length > 0) {
            const levels = res.data.model[0].levels
            levels &&
                levels.sort((first, second) => {
                    if (first.step < second.step) return -1
                    if (first.step > second.step) return 1
                    return 0
                })
            for (let i = 0; i < levels?.length; i++) {
                const { teams, isRequiredAll } = levels[i]
                let approvedCnt = 0
                let rejectedCnt = 0
                let pendingCnt = 0
                for (let j = 0; j < teams?.length; j++) {
                    const { isApproved } = teams[j]
                    if (isApproved === 1) approvedCnt++
                    else if (isApproved === -1) rejectedCnt++
                    else pendingCnt++
                }
                if (isRequiredAll) {
                    if (rejectedCnt > 0) levelRejected = true
                } else if (rejectedCnt === teams?.length) levelRejected = true
            }
        }
    } catch (error) {
        console.log(error) //  to convert to nebula log
    }
    return levelRejected
}

export const getTime = (time) => {
    if (time.endsWith("AM") || time.endsWith("PM")) return time
    let hour = Number(time.substring(0, 2))
    const mins = Number(time.substring(3, 5))
    let meridian
    if (hour >= 12) {
        if (hour !== 12) hour = hour - 12
        meridian = "PM"
    } else {
        if (hour === 12) hour = 0
        meridian = "AM"
    }
    const newTime = `${hour < 10 ? "0" : ""}${hour}:${mins < 10 ? "0" : ""
        }${mins}:${meridian}`
    return newTime
}

/**
 * @param {*} state
 * @param {*} date
 * @param {*} time
 * @param {*} createPost
 * @param {*} updateScheduledDate
 * @param {*} updateScheduledTime
 */
const PostBottomBarNew = ({
    state,
    date,
    time,
    createPost,
    updateScheduledDate,
    updateScheduledTime,
    timezoneValue,
    bottomBarOptions,
    brandWorkflow,
    user,
    isApprovalRequired,
    flag,
    setPublishError,
    post,
    brandId,
    errors,
    blockSubmit,
    fromBulkPublish,
    setShowQueue,
    showQueue,
    resetQueue
}) => {
    const { trackEvent: track } = useTracking()
    const _C = "aui-new-post-manager-bottom-bar"
    const [loading, setLoading] = useState(false)
    const [isDraft, setIsDraft] = useState(false)
    const [dateValue, setDateValue] = useState("")
    const [timeValue, setTimeValue] = useState(time ? getTime(time) : "00:00:AM")
    const [rejectedPost, setRejectedPost] = useState(false)
    const classes = useStyles()

    useEffect(() => {
        if (date) setDateValue(date)
    }, [date])
    useEffect(() => {
        if (time) setTimeValue(getTime(time))
    }, [time])
    const [workflowHandler, setWorkflowHandler] = useState(
        bottomBarOptions?.NoWorkflow ?? []
    )

    useEffect(() => {
        if (post?.id && brandId) {
            async function getRejectionStatus() {
                const levelRejected = await getWorkflow(brandId, post.id)
                if (levelRejected) setRejectedPost(true)
            }
            getRejectionStatus()
        }
    }, [])

    //used for creating workflow based buttons
    const dropdown = useRef(null)
    const format = "h:mm a"

    useEffect(() => {
        updateScheduledDate(dateValue)
    }, [dateValue])

    useEffect(() => {
        if (timeValue === "00:00:AM") return
        let hour = Number(timeValue.substring(0, 2))
        const mins = Number(timeValue.substring(3, 5))
        const meridian = timeValue.substring(6)
        if (meridian === "PM") {
            if (hour !== 12) hour = hour + 12
        } else {
            if (hour === 12) hour = 0
        }
        const newTime = `${hour < 10 ? "0" : ""}${hour}:${mins < 10 ? "0" : ""
            }${mins}:00`
        updateScheduledTime(newTime)
    }, [timeValue])

    function getPostButtonTitle() {
        if (rejectedPost) return "Rejected"
        if (!date && !time) {
            return workflowHandler?.[0]?.title
        }
        return workflowHandler?.[0]?.SecondaryTitle
            ? workflowHandler?.[0]?.SecondaryTitle
            : workflowHandler?.[0]?.title
    }

    const makeDecision = () => {
        if (rejectedPost) return
        if (state === "edit") {
            if (isApprovalRequired === 0) {
                if (!date && !time) publishHandler()
                else scheduleHandler()
            } else {
                sendForApprovalHandler()
            }
        } else if (state === "create") {
            if (isApprovalRequired === 1) {
                sendForApprovalHandler()
            } else {
                if (!date && !time) publishHandler()
                else scheduleHandler()
            }
        } else if (state === "publish") {
            if (!date && !time) {
                publishHandler()
            } else {
                scheduleHandler()
            }
        }
    }

    function stringXor(a, b) {
        let aLen = a?.trim()?.length,
            bLen = b?.trim()?.length
        return (aLen > 0) ^ (bLen > 0)
    }

    const publishHandler = async () => {
        if (flag) {
            createMessage(
                "danger",
                "Some Posts Require Approval before they can be published, Please create a Seperate Post for These"
            )
            return setPublishError(true)
        }
        track({
            type: CLICK,
            title: `Post Publish`,
            key: "Publish",
            category: SOCIAL_POST,
        })
        setLoading(true)
        if (state !== "publish") changePost("isApprovalRequired", 0)
        // date && time ? "schedule"
        await createPost("publish", () => {
            setIsDraft(false)
            setLoading(false)
        })
    }

    const draftHandler = async () => {
        if (loading || stringXor(date, time) > 0) return
        setLoading(true)
        track({
            type: SOCIAL_POST,
            title: "Post as draft",
            category: SOCIAL_POST,
        })
        if (state !== "publish")
            changePost("isApprovalRequired", brandWorkflow ? 1 : 0)
        await createPost("draft", () => {
            setIsDraft(false)
            setLoading(false)
            resetQueue()
        })
    }

    const scheduleHandler = async () => {
        if (loading || stringXor(date, time) > 0) {
            return
        }
        track({
            type: CLICK,
            title: `Post Schedule Post`,
            key: "Schedule Post",
            category: SOCIAL_POST,
        })
        setLoading(true)
        if (state !== "publish")
            changePost("isApprovalRequired", isApprovalRequired ? 1 : 0)
        await createPost("schedule", () => {
            setIsDraft(false)
            setLoading(false)
            resetQueue()
        })
    }

    const sendForApprovalHandler = async () => {
        if (loading || stringXor(date, time) > 0 || (!date && !time)) {
            if (!date) createMessage("warn", "Please Enter a Valid Date")
            else createMessage("warn", "Please Enter a Valid Time")
            return
        }
        track({
            type: CLICK,
            title: `Post Request For Approval`,
            key: "Request For Approval",
            category: SOCIAL_POST,
        })
        setLoading(true)
        if (state !== "publish") changePost("isApprovalRequired", 1)
        await createPost("schedule", () => {
            setIsDraft(false)
            setLoading(false)
            resetQueue()
        })
    }

    const postSubmitHandler = {
        "Send for Approval": sendForApprovalHandler,
        "Schedule Post": scheduleHandler,
        Draft: draftHandler,
        Publish: publishHandler,
    }

    useEffect(() => {
        if (!dropdown.current) return
        if (isDraft) dropdown.current.style.transform = "rotate(180deg)"
        else dropdown.current.style.transform = "rotate(0deg)"
    }, [isDraft])

    useEffect(() => {
        setLoading(true)
        if (brandWorkflow !== undefined) setLoading(false)
        if (brandWorkflow) {
            const workflowTypeDecider = brandWorkflow?.levels?.sort((a, b) =>
                a.step > b.step ? -1 : a.step < b.step ? 1 : 0
            )
            if (workflowTypeDecider[0].teams.indexOf(user.user_name) !== -1)
                setWorkflowHandler(bottomBarOptions?.Workflow_TopUser)
            else setWorkflowHandler(bottomBarOptions?.Workflow_NonTopUser)
        }
        setLoading(false)
    }, [brandWorkflow])

    function isButtonBlocked() {
        return (
            stringXor(date, time) > 0 ||
            rejectedPost ||
            errors?.length > 0 ||
            blockSubmit
        )
    }

    return (
        <div className={_C}>

            <div className={`${_C + "-button " + _C + "-queue"} ${fromBulkPublish && classes.bulkPublishCustomMenu
                } `}>
                <div className="queue"
                    onClick={() => {
                        setShowQueue(!showQueue)
                    }}
                >
                    <AddQueue />
                    <span>Add to queue</span>
                </div>
            </div>
            <div className={_C + "-button " + _C + "-date"}>

                <div className="date">

                    <div className="date-picker-component-wrapper">
                        {/* <TooltipWrapper text={"Select date"} direction={"top"}> */}
                        <DatePicker
                            direction="top"
                            date={dateValue}
                            setDate={(val) => {
                                setDateValue(val)
                            }}
                            theme={"default"}
                            mode={
                                fromBulkPublish
                                    ? (window.innerWidth <= 1510 &&
                                        window.innerWidth >= 1100) ||
                                        (window.innerWidth <= 992 &&
                                            window.innerWidth >= 550)
                                        ? "icon"
                                        : "full"
                                    : window.innerWidth >= 1250
                                        ? "full"
                                        : "icon"
                            }
                        />
                        {/* </TooltipWrapper> */}
                    </div>

                    {/* <TooltipWrapper text={"Select Time"} direction={"top"}> */}
                    <div className={"time-picker"}>
                        <TimePicker
                            direction="top"
                            currentTime={timeValue}
                            setCurrentTime={setTimeValue}
                            theme={"darkOne"}
                            mode={
                                fromBulkPublish
                                    ? (window.innerWidth <= 1510 &&
                                        window.innerWidth >= 1100) ||
                                        (window.innerWidth <= 992 &&
                                            window.innerWidth >= 550)
                                        ? "icon"
                                        : "full"
                                    : window.innerWidth >= 1250
                                        ? "full"
                                        : "icon"
                            }
                        />
                    </div>
                    {/* </TooltipWrapper> */}
                </div>
            </div>
            <div className={_C + "-button " + _C + "-container "}>
                {isDraft && (
                    <div className={_C + "-button " + _C + "-draft"}>
                        {!loading ? (
                            workflowHandler
                                ?.filter((item) => item.title !== getPostButtonTitle())
                                ?.map((btn) => {
                                    return (
                                        <Guard
                                            scope={
                                                state === "edit"
                                                    ? "post_update"
                                                    : "post_create"
                                            }
                                            show
                                        >
                                            <div
                                                className="btns"
                                                onClick={() => {
                                                    const buttonAction =
                                                        postSubmitHandler[btn.title]
                                                    !blockSubmit && buttonAction()
                                                }}
                                            >
                                                {rejectedPost
                                                    ? "Rejected"
                                                    : btn.title}
                                            </div>
                                        </Guard>
                                    )
                                })
                        ) : (
                            <Loader
                                h="1rem"
                                w="1rem"
                                b="2px"
                                clr="white"
                                bk="#eaf0f6"
                                t="1s"
                            />
                        )}
                    </div>
                )}
                <Guard scope={state === "edit" ? "post_update" : "post_create"} show>
                    <div
                        className={
                            _C +
                            "-button " +
                            _C +
                            "-post " +
                            (isButtonBlocked() ? _C + "-button-inactive" : "")
                        }
                        onClick={() => {
                            !isButtonBlocked() && makeDecision()
                        }}
                    >
                        {!loading ? (
                            getPostButtonTitle()
                        ) : (
                            <Loader
                                h="1rem"
                                w="1rem"
                                b="2px"
                                clr="white"
                                bk="#eaf0f6"
                                t="1s"
                            />
                        )}
                    </div>
                </Guard>
                {!loading && !rejectedPost && !(errors?.length > 0) && (
                    <div
                        className={`dropdown-icon ${blockSubmit && _C + "-button-inactive"
                            }`}
                        onClick={() => {
                            !blockSubmit && setIsDraft(!isDraft)
                        }}
                    >
                        <img src={dropdownIcon} alt="" ref={dropdown} />
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = ({ postReducer, userReducer }) => {
    return {
        bottomBarOptions: postReducer.bottomBarOptions,
        user: userReducer?.user,
        post: postReducer?.post,
    }
}

export default connect(mapStateToProps)(PostBottomBarNew)
