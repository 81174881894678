import {
    UPDATE_COMPANY_SETTING_ID,
    UPDATE_COMPANY_SETTING,
    UPDATE_SETTING,
    CLEAR_EVERYTHING,
    ADD_BRAND_SETTING_ID,
    CLEAR_SETTINGS,
    REMOVE_BRAND_SETTING_ID,
} from "../ActionTypes/settingActionType"

const initialState = {
    companySettingId: null,
    companySetting: null,
    setting: null,
    brandSettingId: null,
    brandSettingCompanyId: null,
}

const settingReducer = (state = initialState, action) => {
    try {
        switch (action.type) {
            case CLEAR_EVERYTHING:
                return {
                    companySetting: null,
                    setting: null,
                    companySettingId: null,
                    brandSettingId: null,
                    brandSettingCompanyId: null,
                }
            case CLEAR_SETTINGS:
                return {
                    companySetting: null,
                    setting: null,
                    companySettingId: null,
                    brandSettingId: null,
                    brandSettingCompanyId: null,
                }
            case UPDATE_COMPANY_SETTING_ID:
                return {
                    ...state,
                    companySettingId: action.payload.companyId,
                }
            case UPDATE_COMPANY_SETTING:
                return {
                    ...state,
                    companySetting: action.payload.companySetting,
                }
            case UPDATE_SETTING:
                return {
                    ...state,
                    setting: action.payLoad.setting,
                }
            case ADD_BRAND_SETTING_ID:
                return {
                    ...state,
                    brandSettingId: action.payload.brandId,
                    brandSettingCompanyId: action.payload.companyId,
                }
            case REMOVE_BRAND_SETTING_ID:
                return {
                    ...state,
                    brandSettingId: null,
                    brandSettingCompanyId: null,
                }
            default:
                return state
        }
    } catch (err) {
    }
}

export default settingReducer
