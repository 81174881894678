import React, { memo, useRef } from "react"
import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react"
import useToggle from "../../../hooks/ToggleHook"
import { ReactComponent as Emoji } from "../../icons/emoji.svg"
import "./emojiPicker.scss"
import TooltipWrapper from "components/commonComps/Tooltip/TooltipWrapper"

/**
 * Component for handling Adding EMoji to an input
 * @param {String} value : value/content of input/textarea
 * @param {Function} setValue : update Value Function
 * @param {Reference} textareaRef : Ref to the component where the emoji has to be entered
 *
 */

const Pick = memo(({ addEmoji }) => (
    <Picker
        onEmojiClick={addEmoji}
        disableAutoFocus={true}
        skinTone={SKIN_TONE_MEDIUM_DARK}
        groupNames={{ smileys_people: "PEOPLE" }}
        native
    />
))

const EmojiPicker = ({ value, setValue, textareaRef }) => {
    const _C = "aui-emoji-picker"
    const emojiRef = useRef()
    const [visible, toggle] = useToggle(emojiRef)

    function addEmoji(e, o) {
        var curPos = textareaRef.current.selectionStart
        setValue(value.slice(0, curPos) + o.emoji + value.slice(curPos))
    }

    return (
        <div className={_C} ref={emojiRef}>
            <button className={_C + "-smiley"}>
                <TooltipWrapper text={"Add an Emoji"} direction={"bottom"} style={{maxWidth: "4rem", padding: "0.4rem 0.4rem"}} >
                    <span
                        className="emojiLogo"
                        role="img"
                        aria-label="abc"
                        onClick={toggle}
                    >
                        <Emoji className="emojiIcon" />
                    </span>
                </TooltipWrapper>
            </button>
            {visible && <Pick addEmoji={addEmoji} />}
        </div>
    )
}

export default EmojiPicker
