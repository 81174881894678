import { createMessage } from "Actions/messageAction"
import React from "react"
import { connect } from "react-redux"
import Caption from "./Caption/Caption"
import CreateCaptionForm from "./CreateCaptionForm/CreateCaptionForm"
import captionsStyles from "./CaptionsStyle"
import { useState } from "react"
import {
    createCaption,
    getCaptions,
    updateCaption,
    deleteCaption,
} from "Actions/socialAction"
import { changePostMultiSocial, changePost } from "Actions/postAction"
import { useEffect } from "react"

const Captions = ({
    brandId,
    captions,
    activeDuplication,
    selectedChannelToView,
    setShowCaptions,
    post,
}) => {
    const [captionForm, setCaptionForm] = useState(false)
    const [captionName, setCaptionName] = useState("")
    const [captionText, setCaptionText] = useState("")
    const [editCaptionId, setEditCaptionId] = useState(null)
    const [selected, setSelected] = useState(null)
    const classes = captionsStyles()

    useEffect(() => {
        async function getCaption() {
            await getCaptions(brandId)
        }
        if (!captions) getCaption()
    }, [])

    function checkCaption() {
        if (!captionName) {
            createMessage("warn", "Please Add a Caption Name")
            return false
        }
        if (!captionText) {
            createMessage("warn", "Please Add Caption Text")
            return false
        }
        return true
    }

    const CaptionsButton = ({ text, type, buttonAction }) => {
        return (
            <div
                className={
                    type === "blue"
                        ? classes.captionsButtonBlue
                        : classes.captionsButton
                }
                onClick={(e) => {
                    buttonAction(e)
                }}
            >
                {text}
            </div>
        )
    }

    async function buttonAction() {
        if (captionForm) {
            if (!checkCaption()) return
            let success = null
            if (editCaptionId) {
                success = await updateCaption(
                    editCaptionId,
                    brandId,
                    captionName,
                    captionText
                )
            } else {
                success = await createCaption(brandId, captionName, captionText)
            }
            setEditCaptionId(null)
            setCaptionForm(false)
            if (success) {
                setCaptionName("")
                setCaptionText("")
            }
            return
        }
        if (!selected) {
            createMessage("warn", "Please Select a Caption")
            return
        }
        if (activeDuplication === "Similar") {
            changePost("text", {
                twitter_text: post.text.twitter_text
                    ? post.text.twitter_text + "\n" + selected.captionDescription
                    : selected.captionDescription,
                instagram_text: post.text.instagram_text
                    ? post.text.instagram_text + "\n" + selected.captionDescription
                    : selected.captionDescription,
                facebook_text: post.text.facebook_text
                    ? post.text.facebook_text + "\n" + selected.captionDescription
                    : selected.captionDescription,
                linkedin_text: post.text.linkedin_text
                    ? post.text.linkedin_text + "\n" + selected.captionDescription
                    : selected.captionDescription,
            })
        } else {
            changePostMultiSocial(
                `text.${selectedChannelToView}_text`,
                post.text[`${selectedChannelToView}_text`]
                    ? post.text[`${selectedChannelToView}_text`] +
                    "\n" + selected.captionDescription
                    : selected.captionDescription
            )
        }
        setShowCaptions(false)
    }

    function cancelAction() {
        if (captionForm) {
            setCaptionName("")
            setCaptionText("")
            setCaptionForm(false)
        } else {
            setShowCaptions(false)
        }
    }

    return (
        <div className={classes.captionsContainer}>
            <div className={classes.captionsHeader}>💾 Saved Captions</div>
            <div className={`${classes.captionsWrapper} scroll-modern`}>
                {!captionForm ? (
                    <>
                        <div
                            className={classes.createCaption}
                            onClick={() => {
                                setCaptionForm(true)
                            }}
                        >
                            + Create Caption
                        </div>
                        <div className={classes.captions}>
                            {captions?.map((item) => (
                                <Caption
                                    heading={item.captionName}
                                    text={item.captionDescription}
                                    active={selected?.id === item.id}
                                    id={item.id}
                                    setCaptionName={setCaptionName}
                                    setCaptionText={setCaptionText}
                                    setCaptionForm={setCaptionForm}
                                    setEditCaptionId={setEditCaptionId}
                                    deleteCaption={deleteCaption}
                                    brandId={brandId}
                                    clickFunction={() => {
                                        setSelected(item)
                                    }}
                                />
                            ))}
                        </div>
                    </>
                ) : (
                    <CreateCaptionForm
                        captionName={captionName}
                        setCaptionName={setCaptionName}
                        captionText={captionText}
                        setCaptionText={setCaptionText}
                    />
                )}
            </div>
            <div className={classes.captionsFooter}>
                <CaptionsButton
                    text={
                        captionForm
                            ? editCaptionId
                                ? "Save"
                                : "Create"
                            : "Use Caption"
                    }
                    type={"blue"}
                    buttonAction={async () => {
                        await buttonAction()
                    }}
                />
                <CaptionsButton
                    text={captionForm ? "Cancel" : "Close"}
                    buttonAction={() => {
                        cancelAction()
                    }}
                />
            </div>
        </div>
    )
}

const mapStateToProps = ({ socialReducer, postReducer }, { brandId }) => {
    return {
        captions: socialReducer.captions[brandId],
        post: postReducer.post,
    }
}

export default connect(mapStateToProps)(Captions)
