import React, { useEffect, useState } from 'react'
import "./style.scss"
import {ReactComponent as QueueLogo} from "../../icons/queuelogo.svg"
import { connect } from 'react-redux'
import { DataLoader } from '@awesomesuite-frontend/awesome-nebula'
import IntersectionVisible from "react-intersection-visible"


const getTime = (time) => {
  if (time.endsWith("AM") || time.endsWith("PM")) return time
  let hour = Number(time.substring(0, 2))
  const mins = Number(time.substring(3, 5))
  let meridian
  if (hour >= 12) {
      if (hour !== 12) hour = hour - 12
      meridian = "PM"
  } else {
      if (hour === 12) hour = 0
      meridian = "AM"
  }
  const newTime = `${hour < 10 ? "0" : ""}${hour}:${
      mins < 10 ? "0" : ""
  }${mins}${meridian}`
  return newTime
}

function formatDateAndCheckToday(dateString) {
  const inputDate = new Date(dateString);
  const today = new Date();
  if (
    inputDate.getDate() === today.getDate() &&
    inputDate.getMonth() === today.getMonth() &&
    inputDate.getFullYear() === today.getFullYear()
  ) {
    return "Today";
  }
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const month = monthNames[inputDate.getMonth()];
  const day = inputDate.getDate();
  const formattedDate = `${month} ${day}`;
  return formattedDate;
}
const QueueSlot = ({ 
  prefilledTime, 
  setShowQueue,
  activeTime,
  activeDate,
  QueueData,
  date,
  setDate,
  }) => {

  function getMoreQueue() {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() +  7)
    setDate(newDate.toISOString().slice(0, 10));
  }
  useEffect(()=>{
    function dataLength(data){
      let count = 0
      for (let entry of data) {
          if (entry.timeslot && entry.enable) {
              for (let timeslot of entry.timeslot) {
                  if (timeslot) {
                      count += 1;
              }
          }
      }
      if (count <= 5 ) return getMoreQueue() 
      }
    }
    dataLength(QueueData)
  },[QueueData])
  
    
  return (
    <div className='queueSlot'>
      <div className="queueHeader">
          <QueueLogo/> Add to queue
      </div>
      <div className='dayTime'> 
        {QueueData?.map((item, key)=>{
            const { day, date , timeslot, enable} = item
            if (enable && timeslot?.length > 0)
            return (
              <div className='dayofweek'>
                <div className='dayheader'><h4>{day}</h4><span>{formatDateAndCheckToday(date)}</span></div>
                <ul className='timeslot'>
                  {timeslot?.map((ts)=>{
                      const time = ts?.time
                      return (
                        <li 
                          className= {
                            activeTime && 
                            activeDate && 
                            getTime(activeTime) == time &&
                            activeDate==date ?
                            `activeTime`:
                            ""
                          }>
                          {time}
                          {!(activeTime && 
                            activeDate &&
                            getTime(activeTime) == time && 
                            activeDate==date) && 
                            <button className='selectBtn' onClick={()=>prefilledTime(date,time)}>
                              Select Time
                            </button>}
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            )
          })}
          <IntersectionVisible onShow={getMoreQueue}>
            <DataLoader/>
          </IntersectionVisible>
      </div>
      
      <button className='closeBtn' onClick={()=>setShowQueue(false)}>
          Close
      </button>
    </div>
  )
}

const mapStateToProps = ({postReducer}) => {
  return {
      activeTime : postReducer.scheduleTime,
      activeDate: postReducer.scheduleDate
  }
}

export default connect(mapStateToProps)(QueueSlot)
