import store from "../store"
import axios from "axios"
import {
    LOAD_CUSTOMER_DETAILS,
    LOAD_CUSTOMER_DETAILS_SUCCESS,
    LOAD_TRANSACTION_SUCCESS,
    LOAD_TRANSACTION_DETAILS,
    LOAD_SUBSCRIPTION_DETAILS,
    LOAD_SUBSCRIPTION_DETAILS_SUCCESS,
    LOAD_CUSTOMER_DETAILS_FAILED,
    LOAD_TRANSACTION_FAILED,
    LOAD_SUBSCRIPTION_DETAILS_FAILED,
    LOAD_PLAN_DETAILS_LOADING,
    LOAD_PLAN_DETAILS_SUCCESS,
    LOAD_PLAN_DETAILS_FAILED,
    SET_SUBSCRIPTION_PLANS,
    CONSUME_PLAN,
    CONSUME_USER,
    CONSUME_ACCOUNT,
    CONSUME_EVENT,
    UNCONSUME_PLAN,
} from "../ActionTypes/transactionActionTypes"
import { createMessage } from "./messageAction"
import { getHeaders, logout } from "./userAction"
import { accountsLink, plansApi, subscriptionApi } from "components/apis"
import Axios from "axios"
import {
    NO_SUBSCRIPTION,
    SUBSCRIPTION_END_DATE,
    SUBSCRIPTION_STATUS,
} from "ActionTypes/userActionTypes"
import { ADD_POPUP, DELETE_POPUP } from "ActionTypes/messageActionTypes"
import { SUCCESS } from "../constants"
import calendar from "../components/icons/calendar.png"
import team from "../components/icons/team-icon.png"
import surprise from "../components/icons/open-surprise.webp"
import moment from "moment"
import { useHistory } from "react-router-dom"

const { dispatch, getState } = store

const api = axios.create({
    baseURL: subscriptionApi,
})

const subscriptionApiAxios = axios.create({
    baseURL: subscriptionApi,
})

/**
 * @deprecated
 */
export const getCustomerDetails = async () => {
    dispatch({ type: LOAD_CUSTOMER_DETAILS })

    try {
        const result = await api.get("/chargebee", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
            },
        })

        if (result.data.code === 200) {
            dispatch({
                type: LOAD_CUSTOMER_DETAILS_SUCCESS,
                payload: {
                    ...result.data.model,
                },
            })
        } else {
            dispatch({ type: LOAD_CUSTOMER_DETAILS_FAILED })
        }
    } catch (error) {
        createMessage(
            "danger",
            error.response ? error.response.data.message : "Something went wrong."
        )
    }
}

/**
 * @deprecated
 */
export const getUserTransactions = async () => {
    if (getState().userReducer.user) {
        dispatch({ type: LOAD_TRANSACTION_DETAILS })
        const userId = getState().userReducer.user.id
        try {
            const transaction = await api.get(`/transaction?userId=${userId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
                },
            })

            if (transaction.data.code === 200) {
                dispatch({
                    type: LOAD_TRANSACTION_SUCCESS,
                    payload: {
                        transaction: transaction.data.model,
                    },
                })
            } else {
                dispatch({ type: LOAD_TRANSACTION_FAILED })
            }
        } catch (error) {
            dispatch({ type: LOAD_TRANSACTION_FAILED })
            createMessage(
                "danger",
                error.response
                    ? error.response.data.message
                    : "Something went wrong."
            )
        }
    }
}

export const getSubscribedPlan = async () => {
    dispatch({ type: LOAD_SUBSCRIPTION_DETAILS })

    try {
        const subscription = await subscriptionApiAxios.get(`/subscription`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
            },
        })

        if (subscription.data.code === 200) {
            dispatch({
                type: LOAD_SUBSCRIPTION_DETAILS_SUCCESS,
                payload: {
                    subscription: subscription.data.model,
                },
            })
        } else {
            dispatch({ type: LOAD_SUBSCRIPTION_DETAILS_FAILED })
        }
    } catch (error) {
        createMessage(
            "danger",
            error.response ? error.response.data.message : "Something went wrong."
        )
    }
}

export const getPlans = async () => {
    dispatch({ type: LOAD_PLAN_DETAILS_LOADING })

    try {
        const plans = await subscriptionApiAxios.get(`/plan?product_id=1`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
            },
        })

        if (plans.data.code === 200) {
            dispatch({
                type: LOAD_PLAN_DETAILS_SUCCESS,
                payload: {
                    plans: plans.data.model,
                },
            })
        } else {
            dispatch({ type: LOAD_PLAN_DETAILS_FAILED })
        }
    } catch (error) {
        createMessage(
            "danger",
            error.response ? error.response.data.message : "Something went wrong."
        )
    }
}

export async function getSubscriptionStatus() {
    let isActive
    try {
        let options = getHeaders()
        options.params = { product_id: 1 }

        const res = await Axios.get(subscriptionApi + "/subscription", options)
        if (res.data.code === 400) {
            dispatch({ type: NO_SUBSCRIPTION, payload: true })
            return -1
        }
        if (!res.data.model) {
            createMessage("danger", "Cant load subscription status")
            logout()
            return true
        }
        const activePlan = res.data.model.find((plan) => plan.productId === 1)
        isActive = activePlan?.status === "active"
        const endDate = new Date(moment(activePlan?.endDate));
        dispatch({
            type: SUBSCRIPTION_STATUS,
            payload: { status: isActive },
        })
        dispatch({
            type: SUBSCRIPTION_END_DATE,
            payload: {
                endDate,
            },
        })
        dispatch({
            type: LOAD_SUBSCRIPTION_DETAILS_SUCCESS,
            payload: activePlan,
        })
    } catch (err) {
        window.location.replace("/getting-started")
        return -1
    }
    return isActive
}

export async function getSubscriptionPlans() {
    try {
        let options = getHeaders()
        options.params = { product_id: 1 }

        const res = await Axios.get(plansApi, options)

        if (!res.data.model) return
        const newPlans = res.data.model

        let planValidityMapper = {
            "1 Month": [],
            "12 Months": [],
            "24 Months": [],
        }
        if (newPlans) {
            newPlans.forEach((item) => {
                if (!planValidityMapper[item.planValidity])
                    planValidityMapper[item.planValidity] = []
                planValidityMapper[item.planValidity].push(item)
            })

            console.log(planValidityMapper)
            dispatch({
                type: SET_SUBSCRIPTION_PLANS,
                payload: planValidityMapper,
            })
        }

        return res.data.model
    } catch {
        return null
    }
}

export function consumePlan(name, points, checkStatusOnly, options = {}) {
    const { subscription } = getState().transactionReducer
    let { currentActiveWorkspace } = getState().workplaceReducer
    let subscriptionData = { ...subscription }
    let popup = {
        title: "",
        text: "",
        type: name,
        action: "Upgrade Now",
        subAction: "Maybe Later",
        loading: false,
        icon: null,
        category: SUCCESS,
        proceed: () => {
            window.open(accountsLink + "/billing?service=social")
        },
        cancel: () => {
            dispatch({ type: DELETE_POPUP })
        },
    }

    if (!currentActiveWorkspace) return

    if (name === CONSUME_USER) {
        if (
            currentActiveWorkspace.users.find(
                (item) => item.email_id === options.email_id?.trim()
            )
        ) {
            return true
        }

        subscriptionData.remainingNoOfUser -= points
        if (subscriptionData.remainingNoOfUser < 0) {
            popup.title = "Invite more team members"
            popup.text =
                "You have reached the limit of your current plan. Please upgrade your plan to invite more team members."
            popup.icon = team
            dispatch({ type: ADD_POPUP, payload: { popup } })
            return false
        }
    }
    if (name === CONSUME_ACCOUNT) {
        subscriptionData.remainingNoOfAccounts -= points
        if (subscriptionData.remainingNoOfAccounts < 0) {
            popup.title = "Unlock more brands"
            popup.text =
                "You have reached the limit of your current plan. Please upgrade your plan to add more brands."
            popup.icon = surprise
            dispatch({ type: ADD_POPUP, payload: { popup } })
            return false
        }
    }
    if (name === CONSUME_EVENT) {
        subscriptionData.remainingNoOfEvent -= points
        if (subscriptionData.remainingNoOfEvent < 0) {
            popup.title = "Unlock more posts"
            popup.text =
                "You have reached the limit of your current plan. Please upgrade your plan to create more posts."
            popup.icon = calendar
            dispatch({ type: ADD_POPUP, payload: { popup } })
            return false
        }
    }
    if (!checkStatusOnly) {
        dispatch({
            type: CONSUME_PLAN,
            payload: subscriptionData,
        })
    }

    return true
}

export function unConsumePlan(name, points) {
    const { subscription } = getState().transactionReducer
    let subscriptionData = { ...subscription }

    if (name === CONSUME_ACCOUNT) {
        subscriptionData = {
            ...subscriptionData,
            remainingNoOfAccounts: subscriptionData.remainingNoOfAccounts + points,
        }
    }
    if (name === CONSUME_USER) {
        subscriptionData = {
            ...subscriptionData,
            remainingNoOfUser: subscriptionData.remainingNoOfUser + points,
        }
    }

    dispatch({
        type: UNCONSUME_PLAN,
        payload: subscriptionData,
    })
    return true
}

export const subscribeFreePlan = async (orgId, userId) => {
    try {
        let data = {
            productId: 1,
            orgId: orgId,
            userId: userId,
            planId: 1,
            purchaseId: "",
        }

        const headers = getHeaders()

        const subscription = await axios.post(
            `${subscriptionApi}/subscription`,
            data,
            headers
        )

        if (subscription.data.code === 200) {
            await getSubscriptionStatus()
            return true
        } else {
            // createMessage(DANGER, subscription.data.msg);
        }
    } catch (error) {
        // createMessage(DANGER, "Something went wrong while selecting the plan !");
    }
}
