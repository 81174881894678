import Axios from "axios"
import { getHeaders } from "../../../Actions/userAction"
import {
    instagramApiUrl,
    socialPostsApi,
    getAllPlatformPosts,
    baseURL,
} from "../../apis"
import { associateWorkflowWithPost } from "../../../Actions/workflowAction"
import { createMessage } from "../../../Actions/messageAction"
import { INSTAGRAM } from "../../../Data/SocialMedialTypes"
import { INSTAGRAM_PAGE } from "../../../constants"
import { DRAFT, PUBLISH, SCHEDULE } from "../Types/postStatusTypes"
import {
    deleteFirstComment,
    postFirstComment,
    updateFirstComment,
} from "Actions/postAction"

const instaApi = Axios.create({
    baseURL: socialPostsApi("instagram"),
})

/**
 * Atomic or generic api to post as a company or user to the Instagram account.
 *
 * @param {object} data { text, path, brandId, publish, status.... } it contains the
 *      post related data
 * @param {*} authToken auth token for the current user.
 * @param {*} configuration configuration to define the type of posting social company or user.
 *                          - media for the file object
 *
 * @returns Error | reposes data of api.
 */
export const createInstagramPost = async (
    data,
    configuration,
    instaFirstComment
) => {
    if (!data) return

    //TODO: check if we need on response or two....
    let response

    try {
        response = await postToUserFeed(data, configuration, instaFirstComment)

        if (response.code)
            return {
                status: "ERROR",
                failureReason: response.msg,
                platform: INSTAGRAM_PAGE,
                data,
            }

        if (
            (response.status === SCHEDULE || response.status === DRAFT) &&
            response.isApprovalRequired === 1
        )
            await associateWorkflowWithPost(
                data.brandId,
                response.id,
                INSTAGRAM_PAGE
            )
        return response
    } catch (error) {
        return {
            status: "ERROR",
            failureReason: "Couldn't create instagram post.",
            platform: INSTAGRAM_PAGE,
            data,
        }
    }
}

/**
 * make an atomic api call with lots of filters and get post array or
 * single posts of instagram in return
 *
 * @param {*} config { brandId , postId, status }
 *
 * TODO: add get post by user id later
 *
 * @return if postId is not present then it will return the
 * Array of the posts.
 * or if the postId is present then Single post will be return.
 */
export const getInstagramPost = async (config) => {
    if (!config.brandId) return

    let headers = getHeaders()

    if (!headers) return

    let params = {
        brandId: config.brandId,
        platform: config.key ? `instagram_${config.key}` : INSTAGRAM,
    }

    // Add filters
    if (config.status) params.status = config.status
    if (config.page) params.page = config.page
    if (config.limit) params.limit = config.limit
    if (config.postId) params.postId = config.postId
    if (config.sortBy) params.sortBy = config.sortBy
    if (config.id) params.dbId = config.id
    if (config.fromDate) params.fromDate = config.fromDate
    if (config.toDate) params.toDate = config.toDate
    if (config.orderBy) params.orderBy = config.orderBy
    params.key = "posts"

    headers.params = params

    try {
        const result = await Axios.get(getAllPlatformPosts, headers)

        if (result.data.code === 200) {
            //normalize post data based on respective social platform
            let posts = result.data.model.map((post) => ({
                ...post,
                path: post.mediaUrl,
            }))
            return posts
        } else return null
    } catch (error) {
        return null
    }
}

export const getInstagramPostById = async (config) => {
    try {
        if (!config.brandId) return

        let headers = getHeaders()
        if (!headers) return

        headers.params = {
            brandId: config.brandId,
            dbId: config.id,
        }

        let response = await Axios.get(instagramApiUrl, headers)

        return response.data.model
    } catch (err) {
        console.log(err)
        return null
    }
}

/**
 * Post metric starts here.
 * get likes, comments and other metric related stuff.
 */

/**
 * generic api to get the post metric - likes, comments and summery.
 * @param {*} data -  data contains {postId, brandId}
 * @param {*} config - "likes" to enable likes, "comments" to enable comments
 */
async function getPostMetric(data, config) {
    let headersData = getHeaders()

    if (!headersData) {
        headersData = getHeaders(config.token)
    }

    let key = null

    if (config.likes) key = "likes"

    if (config.comments) key = "comments"

    headersData = {
        ...headersData,
        params: {
            brandId: data.brandId,
            postId: data.postId,
        },
    }

    if (key) {
        headersData.params.key = key
    }

    let response = await Axios.get(`${instagramApiUrl}/socialactions`, headersData)

    return response.data
}

/**
 * single api to get the like on individual post.
 *
 * @param {*} data - {postId, brandId}.
 *
 * @returns Error | model response.
 */
export const getInstagramPostLikes = async (data) => {
    let headers = getHeaders()

    let response
    try {
        response = await getPostMetric(data, { headers, likes: true })

        if (response.model) {
            return response.model
        } else {
            return new Error(response.msg)
        }
    } catch (error) {
        return new Error(
            "could not get the likes for the post",
            data.brandId,
            data.postId
        )
    }
}

/**
 * single api to get the comments on individual post.
 *
 * @param {*} data - {postId, brandId}.
 * @returns Error | model response.
 */
export const getInstagramPostComments = async (data) => {
    let headers = getHeaders()

    let response
    try {
        response = await getPostMetric(data, { headers, comments: true })

        if (response.model) {
            return response.model
        } else {
            return new Error(response.msg)
        }
    } catch (error) {
        return new Error(
            "could not get the likes for the post",
            data.brandId,
            data.postId
        )
    }
}

/**
 * single api to get the summary of individual post.
 *
 * @param {*} data - {postId, brandId}
 * @returns Error | model response.
 */
export const getInstagramPostSummery = async (data) => {
    let headers = getHeaders()

    let response
    try {
        response = await getPostMetric(data, { headers })

        if (response.model) return response.model
        else return new Error(response.msg)
    } catch (error) {
        return new Error(
            "could not get the likes for the post",
            data.brandId,
            data.postId
        )
    }
}

// POST create API'S STARTS HERE

// request body data Object
let mockRequestObj = {
    brandId: 1, // brand id
    text: "", // text of the post
    postType: "", // post type image
    mediaContent: [], // media url
    tag: "", // add this feature later
    label: "",
    publishDate: "",
    status: "",
    version: "",
    isApprovalRequired: 1,
    timeZone: "",
    firstComment: "",
    key: "page",
    platform: INSTAGRAM_PAGE,
    created_on: null,
}

/**
 * singleton api to post to user feed only this is for internal use only not for export.
 *
 * @param {object} data { text, path, brandId, publish, status.... } it contains the
 *      post related data
 * @param {*} config contains { headers }
 */
async function postToUserFeed(data, config, firstComment) {
    let headersData = getHeaders()
    let request = { ...mockRequestObj }

    Object.keys(data).forEach((item) => {
        if (request.hasOwnProperty(item)) {
            request[item] = data[item]
        }
    })
    if (request.publishDate?.length === 0) delete request.publishDate

    try {
        let postResult = await instaApi.post("", request, headersData)

        if (postResult.data.code === 200) {
            const { brandId, id } = postResult.data.model

            if (firstComment) {
                postFirstComment(
                    INSTAGRAM,
                    brandId,
                    id,
                    firstComment,
                    null,
                    postResult.data.model
                )
            }
            return postResult.data.model
        } else {
            return { code: 400, msg: postResult.data.msg }
        }
    } catch (error) {
        return { code: 400, msg: "Couldn't create instagram post." }
    }
}

export const updateInstagramPost = async (post) => {
    const headers = getHeaders()
    try {
        // let params = {
        //     brandId: post.brandId,
        // }
        const updateResult = await instaApi.put("", post, headers)

        if (updateResult.data.code === 200) {
            if (post.firstComment && post.firstComment[INSTAGRAM]) {
                let commentFunction = post.commentId
                    ? updateFirstComment
                    : postFirstComment

                await commentFunction(
                    INSTAGRAM,
                    updateResult.data.model.brandId,
                    updateResult.data.model.id,
                    post.firstComment[INSTAGRAM],
                    null,
                    updateResult.data.model
                )
            } else if (post.commentId) {
                await deleteFirstComment(
                    INSTAGRAM,
                    updateResult.data.model.brandId,
                    updateResult.data.model.id
                )
            }
            return updateResult.data.model
        } else {
            return new Error(updateResult.data.msg)
        }
    } catch (error) {
        return new Error("Instagram post updation failed.")
    }
}

export const editInstagramPostSchedule = async (post) => {
    const headers = getHeaders()
    headers.params = {
        key: INSTAGRAM,
    }
    const newData = {
        ...post,
    }
    try {
        const res = await instaApi.put("", newData, headers)

        if (res.data.model) {
            // associateWorkflow(res.data.model.id) // Dont know what this is for
            createMessage("success", res.data.msg)
            return res.data.model
        }
        return null
    } catch (err) {
        createMessage("danger", err.response.data)
        return null
    }
}

export const getInstagramPostByDateNew = async ({
    brandId,
    fromDate,
    toDate,
    platform,
}) => {
    let headers = getHeaders()
    try {
        let url = `${baseURL}/v2/instagram/publish/posts?brand_id=${brandId}&start_date=${fromDate}&end_date=${toDate}`
        if (platform) url += `&platform=${platform}`
        const result = await Axios.get(url, headers)
        if (result?.data?.code !== 200) return null
        return result.data
    } catch (error) {
        console.log(error)
        createMessage("danger", "Error while getting posts")
        return null
    }
}

export const getInstagramPostNew = async (brandId, limit, social) => {
    let headers = getHeaders()
    try {
        let url = `${baseURL}/v2/instagram/publish/posts?brand_id=${brandId}`
        if (limit) url += `&limit=${limit}`
        if (social) url += `&platform=${social}`
        const result = await Axios.get(url, headers)
        if (result?.data?.code !== 200) return null
        return result.data
    } catch (error) {
        return null
    }
}

export const getInstagramPostNext = async (brandId, next, limit, platform) => {
    let headers = getHeaders()
    try {
        let url = `${baseURL}/v2/instagram/publish/posts?brand_id=${brandId}&after=${next}`
        if (limit) url += `&limit=${limit}`
        if (platform) url += `&platform=${platform}`
        const result = await Axios.get(url, headers)
        if (result?.data?.code !== 200) return null
        return result.data
    } catch (error) {
        return null
    }
}
