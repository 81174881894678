import React, { memo } from "react"
import { useLinkMeta } from "../../../hooks/useLinkMeta"
import "./linkThumbnail.scss"

/**
 * @description Component to get a THUMBNAIL for an url
 * @param {Link} url
 * @param {Boolean} [column] : If the thumbnail needs to be vertical manner
 * @param {Boolean} [isSocialMedia] : This is for social Media
 * @returns
 */
const LinkThumbnail = ({ url, column, isSocialMedia }) => {
    const meta = useLinkMeta(url)
    const _C = "aui-link-thumb-nail"

    return !meta ? (
        <></>
    ) : (
        <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className={_C + " " + (column ? _C + "-column" : _C + "-row")}
        >
            <span className={_C + "-icon"}>
                <img
                    src={meta["og:image"] ?? meta.icon}
                    alt={meta["og:title"] ?? meta.title}
                />
            </span>
            <span className={_C + "-data"}>
                {(meta["og:title"] || meta.title) && (
                    <span className={_C + "-title"}>
                        {meta["og:title"] ?? meta.title}
                    </span>
                )}
                {!isSocialMedia && (meta["og:description"] ?? meta.description) && (
                    <span className={_C + "-description"}>
                        {(meta["og:description"] ?? meta.description).substr(0, 100)}
                        ...
                    </span>
                )}
                <span className={_C + "-link"}>
                    {meta["og:url"] ?? new URL(url).hostname}
                </span>
            </span>
        </a>
    )
}

export default LinkThumbnail
