import React, { useState, useEffect } from "react"
import {
    FACEBOOK,
    INSTAGRAM,
    LINKED_IN,
    TWITTER,
} from "../../../../Data/SocialMedialTypes"
import FilesDragShuffler from "../../FIlesDragShuffler"
import { socialObj } from "../SocialIconPreviewMapping"
import "./previewSection.scss"
import { ReactComponent as Link } from "../../../icons/open-post.svg"
import {
    SocialSubTypeChecker,
    isSocialSubtype,
} from "components/Helper/Normalizers/SocialSubTypeChecker"
import { Dropdown } from "@awesomesuite-frontend/awesome-nebula"
import SocialIconsMapping from "components/Utilities/SocialIcons/SocialIconsMapping"

/**
 *
 * @param {*} post
 * @param {*} socialProfile
 * @param {*} selectedChannelToView
 * @param {*} files
 * @param {*} cornerIcon Icon to be shown on the top right of the preview
 * @param {Function} onFilesShuffle
 * @returns
 */
const PreviewSection = ({
    post,
    socialProfile,
    socialProfiles,
    selectedChannelToView,
    selectedKeyToView,
    files,
    onFilesShuffle,
    activeDuplication = "Similar",
    fromBulkPublish,
    multipleAccounts,
    selectProfileToView,
    selectedAccountToView,
}) => {
    const _C = "aui-preview-section"
    const [dropdownValue, setDropdownValue] = useState("all")
    const [dropdownContent, setDropdownContent] = useState({})

    useEffect(() => {
        if (multipleAccounts) {
            const dropdownContent = [
                {
                    name: "All",
                    value: "all",
                },
                ...multipleAccounts
                    .filter((account) => account.added)
                    .map((account) => ({
                        name: account.type[0].toUpperCase() + account.type.slice(1).toLowerCase(),
                        value: account.type.toUpperCase(),
                        icon: (
                            <SocialIconsMapping
                                active={true}
                                name={account.type?.toLowerCase()}
                                subType={isSocialSubtype(account.type, account.key) ? 1 : 0}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    maxWidth: "1rem",
                                }}
                            />
                        ),
                    })),
            ];

            setDropdownValue(
                dropdownValue === selectedAccountToView.type.toUpperCase()
                    ? dropdownValue
                    : selectedAccountToView.type.toUpperCase()
            );

            setDropdownContent({ dropdownContent });
        }
    }, [multipleAccounts, selectedAccountToView, selectedKeyToView]);

    if (SocialSubTypeChecker(selectedChannelToView)) {
        socialProfile = socialProfile?.[selectedKeyToView]
    }
    function getPdf() {
        const pdfFile = files[selectedChannelToView]?.find(
            (file) => file.mediaType === "application/pdf"
        )
        return pdfFile ? true : false
    }
    const isPdf = files && getPdf()

    if (multipleAccounts && multipleAccounts?.length > 0) {
        if (!selectedAccountToView) return <></>

        return (
            <div className={_C + " scroll-modern"}>
                <div className={_C + "-post-preview"}>
                    <div className="preview-header">
                        <div className="header-label">Post Preview</div>
                        <span className="dropdown-wrapper">
                            <Dropdown
                                value={dropdownValue}
                                onChange={(e) => {
                                    setDropdownValue(e)
                                }}
                                dropdown={dropdownContent}
                                width={"15rem"}
                                className={"radius"}
                            />
                        </span>
                    </div>
                    {multipleAccounts
                        .filter((item) =>
                            dropdownValue === "all"
                                ? true
                                : dropdownValue.toLowerCase() ===
                                item.type.toLowerCase()
                        )
                        .map((account, i) => {
                            let accountDetails =
                                socialProfiles?.[account?.brandId]?.[
                                account?.type?.toUpperCase()
                                ]
                            if (SocialSubTypeChecker(account.type)) {
                                accountDetails =
                                    accountDetails?.[account.key.toLowerCase()]
                            }

                            return (
                                socialObj[account.type.toLowerCase()] && (
                                    <div
                                        key={i}
                                        onClick={() => {
                                            selectProfileToView(account)
                                        }}
                                        ref={
                                            selectedAccountToView.type ===
                                                account.type &&
                                                selectedAccountToView.key ===
                                                account.key &&
                                                selectedAccountToView.brandId ===
                                                account.brandId
                                                ? selectedAccountToView?.ref
                                                : null
                                        }
                                        className="preview-container"
                                    >
                                        {React.createElement(
                                            socialObj[account.type.toLowerCase()]
                                                .view,
                                            {
                                                createdBy: accountDetails?.title,
                                                handleName: accountDetails?.subText,
                                                time: post.publishDate,
                                                text:
                                                    account.type.toLowerCase() ===
                                                        TWITTER
                                                        ? post?.text?.twitter_text
                                                        : account.social ===
                                                            INSTAGRAM
                                                            ? post?.text?.instagram_text
                                                            : account.social === FACEBOOK
                                                                ? post?.text?.facebook_text
                                                                : account.social ===
                                                                    LINKED_IN
                                                                    ? post?.text?.linkedin_text
                                                                    : post.text.twitter_text,
                                                files: files[
                                                    account.type.toLowerCase()
                                                ],
                                                profilePic: accountDetails?.image,
                                                icon: (
                                                    <div id="preview-icon">
                                                        {
                                                            socialObj[
                                                                account.type.toLowerCase() +
                                                                account.key.toLowerCase()
                                                            ]?.Icon
                                                        }
                                                    </div>
                                                ),
                                                showStats: true,
                                                social: account.type.toLowerCase(),
                                                firstComment:
                                                    post?.firstComment?.[
                                                    account.type
                                                    ],
                                                title: post?.title,
                                                post: post,
                                            }
                                        )}
                                        {fromBulkPublish && files && (
                                            <FilesDragShuffler
                                                files={
                                                    fromBulkPublish
                                                        ? files[
                                                        account.type.toLowerCase()
                                                        ]
                                                        : files[
                                                        account.type.toLowerCase()
                                                        ]
                                                }
                                                onFilesShuffle={onFilesShuffle}
                                                height={6}
                                                activeDuplication={activeDuplication}
                                                previewChannel={account.type.toLowerCase()}
                                            />
                                        )}
                                        {!fromBulkPublish &&
                                            files &&
                                            post.status !== "publish" && (
                                                <FilesDragShuffler
                                                    files={
                                                        fromBulkPublish
                                                            ? files[
                                                            account.type.toLowerCase()
                                                            ]
                                                            : files[
                                                            account.type.toLowerCase()
                                                            ]
                                                    }
                                                    onFilesShuffle={onFilesShuffle}
                                                    height={6}
                                                    activeDuplication={
                                                        activeDuplication
                                                    }
                                                    previewChannel={account.type.toLowerCase()}
                                                />
                                            )}
                                        {!fromBulkPublish &&
                                            post?.postLink &&
                                            post?.status === "publish" && (
                                                <div className="open-post-link">
                                                    <a
                                                        href={post.postLink}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        View on{" "}
                                                        {account.type.toLowerCase()}
                                                        <span>
                                                            <Link />
                                                        </span>
                                                    </a>
                                                </div>
                                            )}
                                    </div>
                                )
                            )
                        })}
                </div>
            </div>
        )
    }

    return (
        <div className={_C + " scroll-modern"}>
            <div className={_C + "-post-preview"}>
                {selectedChannelToView &&
                    socialObj[selectedChannelToView.toLowerCase()] && (
                        <>
                            <p>Post Preview</p>
                            {React.createElement(
                                socialObj[selectedChannelToView.toLowerCase()]?.view,
                                {
                                    createdBy: socialProfile?.title || socialProfiles?.[selectedChannelToView?.toUpperCase()]["page"]?.title,
                                    handleName: socialProfile?.subText || socialProfiles?.[selectedChannelToView?.toUpperCase()]["page"]?.subText,
                                    time: post.publishDate,
                                    text:
                                        (selectedChannelToView === TWITTER
                                            ? post?.text?.twitter_text
                                            : selectedChannelToView === INSTAGRAM
                                                ? post?.text?.instagram_text
                                                : selectedChannelToView === FACEBOOK
                                                    ? post?.text?.facebook_text
                                                    : selectedChannelToView === LINKED_IN
                                                        ? post?.text?.linkedin_text
                                                        : post?.text?.twitter_text || "") || post?.title,
                                    files: files[selectedChannelToView]?.length ? files[selectedChannelToView] : post?.mediaUrl,
                                    profilePic: socialProfile?.image || socialProfiles?.[selectedChannelToView?.toUpperCase()]["page"]?.image,
                                    icon: (
                                        <div id="preview-icon">
                                            {
                                                socialObj[
                                                    selectedChannelToView.toLowerCase() +
                                                    selectedKeyToView.toLowerCase()
                                                ]?.Icon
                                            }
                                        </div>
                                    ),
                                    showStats: true,
                                    social: selectedChannelToView,
                                    firstComment:
                                        post?.firstComment?.[selectedChannelToView],
                                    title: post?.title,
                                    post: post,
                                    status: post?.status || "publish",
                                }
                            )}
                            {fromBulkPublish && files && (
                                <FilesDragShuffler
                                    files={
                                        fromBulkPublish
                                            ? files[selectedChannelToView]
                                            : files[selectedChannelToView]
                                    }
                                    onFilesShuffle={onFilesShuffle}
                                    height={6}
                                    activeDuplication={activeDuplication}
                                    previewChannel={selectedChannelToView}
                                />
                            )}
                            {!fromBulkPublish &&
                                selectedChannelToView !== "linkedin" &&
                                !isPdf &&
                                files &&
                                post?.status && post?.status !== "publish"
                                && (
                                    <FilesDragShuffler
                                        files={
                                            fromBulkPublish
                                                ? files[selectedChannelToView]
                                                : files[selectedChannelToView]
                                        }
                                        onFilesShuffle={onFilesShuffle}
                                        height={6}
                                        activeDuplication={activeDuplication}
                                        previewChannel={selectedChannelToView}
                                    />
                                )}
                            {
                                (post?.postLink || post?.permalink) &&
                                <div className="open-post-link">
                                    <a
                                        href={post.postLink || post.permalink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        View on {selectedChannelToView}
                                        <span>
                                            <Link />
                                        </span>
                                    </a>
                                </div>
                            }
                        </>
                    )}
            </div>
        </div>
    )
}

export default PreviewSection
