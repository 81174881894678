import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(() => ({
    popupAlert: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 100000000000005,
        
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    popupContent: {
        backgroundColor: "white",
        maxWidth: "30rem",
        width: "90%",
        height: "250px",
    },
    popupTitle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "1rem",
        fontWeight: 500,
        height: "50%",
        borderBottom: "1px solid lightgray",
        position: "relative",
        background: "linear-gradient(90deg, #33475B, #516F90)",
    },
    popupTitle_success: {
        background: "linear-gradient(90deg, #1DBAD2, #A692E9)",
    },
    popupTitle_critical: {
        background: "linear-gradient(90deg, #F2547D, #FF8F59)",
    },
    popupTitleText:{
        fontFamily: "unset", 
    },
    popupIcon: {
        position: "absolute",
        left: "50%",
        top: 0,
        transform: "translate(-50%, -50%)",
    },
    cross: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "2.5rem",
        width: "2.5rem",
        position: "absolute",
        top: "1rem",
        right: "1rem",
        fontSize: "2.5rem",
        transform: "rotateZ(45deg)",
        fontWeight: 200,
        color: "white",
        cursor: "pointer",
        borderRadius: "50%",
        transition: "all 0.1s ease",

        "&:hover": {
            backgroundColor: "#9ba0a5",
        },
    },
    bottomAlert: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#fff",
        height: "4rem",
        paddingTop: "1rem",
    },
    btn: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #dde5ec",
        height: "2.25rem",
        width: "10rem",
        marginRight: "1rem",
        borderRadius: "4px",
        cursor: "pointer",

        "&:hover": {
            opacity: 0.8,
        },
    },
    proceed: {
        backgroundColor: "#354A5F",
        marginRight: "3rem",
        "& span": {
            color: "white",
        },
    },
    proceed_success: {
        backgroundColor: "#00B2FF",
    },
    proceed_critical: {
        backgroundColor: "#F2545B",
    },
    cancel: {
        backgroundColor: "#fff",
        color: "#f2545b",
        borderColor: "#D6DFE8",
        "& span": {
            color: "#35495E",
        },
    },
    cancel_success: {
        "& span": {
            color: "#99ACC2",
        },
    },
    cancel_critical: {
        "& span": {
            color: "#F2545B",
        },
    },
}))

export default useStyles;
