import store from "../store"
import axios from "axios"
import {
    CLEAR_MESSAGE_CHANNEL,
    PUSH_MESSAGE_TO_CHANNEL,
    START_MESSAGE_CHANNEL,
    UPDATE_PAGE,
    UPDATE_HASMORE,
    ADD_NEW_COMMENT,
    SET_LOADING,
    SET_INTERRUPT,
} from "../ActionTypes/userMessagingActionType"
import {
    getCollaborationSubCommentsInThread,
    getCollaborationCommentThreads,
} from "../components/apis"
import { getHeaders } from "./userAction"

const { getState, dispatch } = store
const getChatApi = {
    Post: getCollaborationSubCommentsInThread,
    Collaboration: getCollaborationCommentThreads,
}

export const setInterrupt = (payload) => {
    dispatch({ type: SET_INTERRUPT, payload: payload })
}

export const startMessageChannel = async (channelType, brandId) => {
    try {
        const { pollerRef } = getState().userMessageingReducer
        if (pollerRef) clearInterval(pollerRef)

        const response = await updatePage(0, channelType, brandId)
        let tickPoller = setInterval(chatPoller, 10000)

        dispatch({
            type: START_MESSAGE_CHANNEL,
            payload: {
                channelType: channelType,
                brandId: brandId,
                tickPoller: tickPoller,
                message: response,
            },
        })
    } catch (error) {
        console.log(error) // to convert to nebula 
    }
}

export const closeMessage = () => {
    dispatch({
        type: CLEAR_MESSAGE_CHANNEL,
    })
}

export const getMessages = async (channelType, brandId, page) => {
    const url = getChatApi[channelType]
    const { pollerRef } = getState().userMessageingReducer
    const headers = getHeaders()?.headers
    try {
        const res = await axios.get(url, {
            params: {
                related_id: brandId,
                page: page,
                limit: 15,
            },
            headers,
        })
        if (res.data.model) {
            let allThread = []
            const comments = res.data.model.comments
            for (let i = 0; i < comments.length; i++) {
                let newThread = comments[i]
                if (parser) {
                    newThread = parser(comments[i])
                    allThread.push(newThread)
                }
            }
            return allThread
        } else {
            clearInterval(pollerRef)
        }
        return null
    } catch (error) {
        clearInterval(pollerRef)
    }
}

const chatPoller = async () => {
    const { currentChannel, interrupt } = getState().userMessageingReducer

    if (interrupt) {
        closeMessage()
        return
    }

    try {
        if (!currentChannel.channelType || !currentChannel.brandId) return null
        const response = await getMessages(
            currentChannel.channelType,
            currentChannel.brandId,
            0
        )

        if (response) {
            dispatch({
                type: PUSH_MESSAGE_TO_CHANNEL,
                payload: {
                    message: response,
                },
            })
        }
    } catch (error) {
        console.log("This is the error : ", error) // to convert to nebula log
    }
}

export const updatePage = async (pageNumber, channelType, brandId) => {
    const { currentChannel, page, hasMore } = getState().userMessageingReducer
    if (pageNumber >= page && hasMore) {
        dispatch({
            type: SET_LOADING,
            payload: true,
        })
        const response = await getMessages(
            channelType ?? currentChannel.channelType,
            brandId ?? currentChannel.brandId,
            pageNumber
        )

        if (response) {
            dispatch({
                type: PUSH_MESSAGE_TO_CHANNEL,
                payload: {
                    message: response,
                },
            })
            dispatch({
                type: UPDATE_HASMORE,
                payload: response.length === 15 ? true : false,
            })
        }

        dispatch({
            type: UPDATE_PAGE,
            payload: pageNumber,
        })

        dispatch({
            type: SET_LOADING,
            payload: false,
        })

        return response ? response : null
    }

    dispatch({
        type: UPDATE_PAGE,
        payload: pageNumber,
    })

    return null
}

export const addNewComment = (comment) => {
    dispatch({
        type: ADD_NEW_COMMENT,
        payload: comment,
    })
}

function parser({ createdOn, createdBy, deception, ...rest }) {
    const { workplaceUsers } = getState().userReducer
    const { companyId } = getState().collaborationReducer
    const userImageMapping = workplaceUsers?.[companyId]

    const name =
        userImageMapping?.[createdBy]?.first_name &&
        userImageMapping?.[createdBy]?.last_name
            ? `${userImageMapping?.[createdBy]?.first_name} ${userImageMapping?.[createdBy]?.last_name}`
            : createdBy.split("@")[0]

    return {
        ...rest,
        name: name,
        comment: deception,
        email_id: createdBy,
        time: createdOn,
        image: userImageMapping?.[createdBy]?.picture,
    }
}
