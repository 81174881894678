import React from "react"
import Loader from "../../commonComps/Loaders"
import Avatar from "../Avatar/Avatar"
import Tooltip from '../AvatarGroup/Tooltip'
import "./createWorkflowComponent.scss"
import UserProfileToolTip from "../UserProfileToolTip"

const statusIconMapping = {
    approved: <img src={require('../../icons/approved.svg')} alt="" />,
    rejected: <img src={require('../../icons/reject.svg')} alt="" />,
    pending: <img src={require('../../icons/pending.svg')} alt="" />,
    awaited: <img src={require('../../icons/awaited.svg')} alt="" />,
}

const CreatedWorkflowComponent = ({ workflow, levelData, lastApprovedLevel,users, roles }) => {
    const _C = "aui-created-workflow-component"

    function ApproverAvatar({ approver, level, levelApprovalData }) {

        let approverStatus
        if(levelApprovalData.isRequiredAll === false){
            if(levelApprovalData.approvedCnt > 0){
                approverStatus = _C + "-approver" + "-approved";
            }
            else if(levelApprovalData.rejectedCnt === levelApprovalData.levelSize){
                approverStatus = _C + "-approver" + "-rejected";
            }
            else if(level === lastApprovedLevel+1){
                approverStatus = _C + "-approver" + "-pending";
            }
            else{
                approverStatus = _C + "-approver" + "-awaited";
            }
        }
        else{
            if(levelApprovalData.approvedCnt === levelApprovalData.levelSize){
                approverStatus = _C + "-approver" + "-approved";
            }
            else if(levelApprovalData.rejectedCnt > 0){
                approverStatus = _C + "-approver" + "-rejected";
            }
            else if(level === lastApprovedLevel+1){
                approverStatus = _C + "-approver" + "-pending";
            }
            else{
                approverStatus = _C + "-approver" + "-awaited";
            }
        }
        
        let statusIcon;
        if(approver.isApproved === 1){
            statusIcon = "approved";
        }
        else if(approver.isApproved === -1){
            statusIcon = "rejected";
        }
        else if(level <= lastApprovedLevel + 1){
            statusIcon = "pending"
        }
        else statusIcon = "awaited"

        return (
            <div className={_C + "-approver "}>
                <div className={_C + "-approver " + approverStatus}>
                    <div className="showCollaborateTooltip">
                        <Avatar
                            firstName={approver.userName}
                            lastName=""
                            icon={approver.image}
                            height="44px"
                            width="44px"
                            wrapperStyle={{padding: "3px"}}
                        />
                        <UserProfileToolTip
                          firstName={approver?.workflowUser?.first_name}
                          lastName={approver?.workflowUser?.last_name}
                          image={approver.workflowUser?.picture}
                          email={approver?.workflowUser?.email_id}
                          user={approver?.workflowUser}
                          roles={roles}
                          styleObj={{zIndex: 1000,}}
                        />
                    </div>
                </div>
                <div className="approverStatusIcon">
                    {
                        (statusIconMapping[statusIcon])
                    }
                </div>
            </div>
        )
    }
    
    return (
        <div className={_C}>
            {(!workflow || !levelData) ? (
                <Loader
                    h="1.5rem"
                    w="1.5rem"
                    b="3px"
                    bk="#eaf0f6"
                    clr="#cbd6e2"
                    t="0.7s"
                />
            ) : (!workflow.length && !levelData.length) ? (
                // <div className={"head"}>No Associated Workflow</div>
                <></>
            ) : (
                <>
                    <div className={"head"}>Approvers</div>
                    <div className={_C + "-wrapper"}>
                        {(workflow && levelData) && workflow.map((level, i) => (
                            <div className={_C + "-level"} key={i}>
                                {level.map((member, j) => (
                                    <ApproverAvatar
                                        approver={member}
                                        levelApprovalData={levelData[i]}
                                        level={i}
                                        key={j}
                                    />
                                ))}
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    )
}

export default CreatedWorkflowComponent
