export const themeObject = {
  breakpoints: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
  color: {
    primary: "red",
    default: "#33475B",
  },
  spacing: 2,
  grid: {
    justify: "flex-start",
    align: "flex-start",
  },
  font: {
    h1: {
      fontWeight: 700,
      fontFamily: "Urbanist",
      fontSize: {
        xs: 28,
        sm: 38,
        md: 48,
        lg: 56,
        xl: 56,
      },
    },
    h2: {
      fontWeight: 600,
      fontFamily: "Urbanist",
      fontSize: {
        xs: 26,
        sm: 26,
        md: 36,
        lg: 46,
        xl: 48,
      },
    },
    h3: {
      fontWeight: 600,
      fontFamily: "Urbanist",
      fontSize: {
        xs: 24,
        sm: 24,
        md: 30,
        lg: 38,
        xl: 38,
      },
    },
    h4: {
      fontWeight: 500,
      fontFamily: "Urbanist",
      fontSize: {
        xs: 22,
        sm: 22,
        md: 26,
        lg: 28,
        xl: 28,
      },
    },
    h5: {
      fontWeight: 500,
      fontFamily: "Urbanist",
      fontSize: {
        xs: 20,
        sm: 22,
        md: 24,
        lg: 24,
        xl: 24,
      },
    },
    h6: {
      fontWeight: 500,
      fontFamily: "Urbanist",
      fontSize: {
        xs: 18,
        sm: 14,
        md: 16,
        lg: 18,
        xl: 18,
      },
    },
    body: {
      fontWeight: 500,
      fontFamily: "Poppins",
      fontSize: {
        xs: 16,
        sm: 16,
        md: 16.5,
        lg: 16.5,
        xl: 18,
      },
    },
    subText: {
      fontWeight: 500,
      fontFamily: "Poppins",
      fontSize: {
        xs: 14,
        sm: 14,
        md: 14.4,
        lg: 14.4,
        xl: 14.4,
      },
    },
    "body-blog": {
      fontWeight: 500,
      fontFamily: "Poppins",
      fontSize: {
        xs: 15.8,
        sm: 15.8,
        md: 16,
        lg: 16.5,
        xl: 16.5,
      },
    },
    colors: {
      primary: "#33475B",
      default: "#33475B",
      secondary: "#425b76",
      light: "#7C98B6",
      hard: "#253342",
      danger: "#f2545b",
      link: "#15b5cd",
    },
  },
  colors: {
    green: "#25c16f",
    greenDark: "#00BDA5",
    sky: "#00aeef",
    red: "#F2545B",
    blue: "#00aeef",
    purple: "#6A78D1",
    "gray-1": "#33475B",
    "gray-2": "#7C98B6",
    "gray-3": "#cbd6e2",
    "gray-4": "#EAF0F6",
  },
};