import React, { useEffect } from "react"
import { useState } from "react"
import { hidePlanPopup } from "../../../Actions/messageAction"
// import { ReactComponent as PlanPopupIcon } from "../../icons/PlanPopupIcon.svg"
import { ReactComponent as GreenTickIcon } from "../../icons/GreenTickCircle.svg"
import { getSubscriptionPlans } from "../../../Actions/userAction"
import untangleImage from "../../icons/PlanPopupIcon.png"
import { connect } from "react-redux"
import { billingsPage, PlansPage } from "Data/RedirectionLinks"
import "./style.scss"

const PlanPopup = ({ active, subscriptionPlans, isPlan, onClose, isClosable = true }) => {
    const _C = "aui-plan"
    const [activePlan, _] = useState(1)
    const [selectedPlans, setSelectedPlans] = useState([])

    useEffect(() => {
        if (!subscriptionPlans && active) getSubscriptionPlans()
    }, [subscriptionPlans, active])

    useEffect(() => {
        if (!subscriptionPlans) return

        const tempPlans = []
        if (
            subscriptionPlans["1 Month"] &&
            subscriptionPlans["1 Month"].length > 0
        ) {
            const plan = subscriptionPlans["1 Month"].find(
                (plan) => plan.title == "Professional"
            )
            if (plan) {
                tempPlans.push({
                    title: plan.title,
                    price: `$${plan.amount}/month`,
                })
            }
        }
        if (
            subscriptionPlans["12 Months"] &&
            subscriptionPlans["12 Months"].length > 0
        ) {
            const plan = subscriptionPlans["12 Months"].find(
                (plan) => plan.title == "Professional"
            )
            if (plan) {
                tempPlans.push({
                    tag: "Recommended",
                    title: plan.title,
                    price: `$${plan.amount}/month`,
                })
            }
        }

        setSelectedPlans(tempPlans)
    }, [subscriptionPlans])

    const features = [
        "Unlimited Posts",
        "Publishing calendar",
        "Calendar, queue, table, feed & Instagram grid view",
        "Social Inbox",
        "Analytics",
        "Team Collaboration",
        "Approval Workflow",
    ]

    if (!active) return <></>
    return (
        <div className={_C}>
            <div className={_C + "-popup"}>
                <div className={_C + "-left"}>
                    <div className={_C + "-title"}>
                        Do more with Awesome Social's Professionals Plan
                    </div>
                    <p>
                        Guarantee a high-performing social media marketers team that
                        boosts your brand's publishing game with Awesome Social's
                        Professional Plan. Unlock the full potential with more posts
                        to publish, more media storage, Hey.bio customization,
                        Instagram first comment and Grid view.
                    </p>
                    <div className={_C + "-title"}>
                        Why upgrade to Professionals Plan?
                    </div>
                    <div className={_C + "-features"}>
                        {features.map((feature, i) => (
                            <div key={i}>
                                <GreenTickIcon /> <span>{feature}</span>
                            </div>
                        ))}
                    </div>
                    <div className={_C + "-container"}>
                        {selectedPlans &&
                            selectedPlans.map(({ tag, title, price }, i) => (
                                <div
                                    key={i}
                                    className={
                                        _C +
                                        "-card " +
                                        (i === activePlan ? _C + "-card-active" : "")
                                    }
                                >
                                    {tag && (
                                        <div className={_C + "-card-tag"}>{tag}</div>
                                    )}
                                    <div className={_C + "-card-price"}>
                                        {title} <br />
                                        {price}
                                    </div>
                                    <div className={_C + "-card-sub"}>
                                        billed {i === 0 ? "Monthly" : "Yearly"}
                                    </div>
                                </div>
                            ))}
                    </div>
                    <a href={billingsPage} className={_C + "-upgrade"}>
                        {`Upgrade to ${selectedPlans[0]?.title ?? "Essentials"}`}
                    </a>
                    <a href={PlansPage} className={_C + "-all-plans"}>
                        View All Plans
                    </a>
                </div>
                <div className={_C + "-right"}>
                    <div className={_C + "-title"}>
                        Untangle Your Social Media Management
                    </div>
                    <img src={untangleImage} alt="plan popup icon" />
                </div>
                {isClosable && (
                    <div className={_C + "-close"} onClick={isPlan ? onClose : hidePlanPopup}>
                        +
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = ({ transactionReducer }) => {
    return {
        subscriptionPlans: transactionReducer.subscriptionPlans,
    }
}

export default connect(mapStateToProps)(PlanPopup)
