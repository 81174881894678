const joinedStatusColors = {
    joined: {
        color: "#35D088",
    },
    pending: {
        color: "#FFA72F",
    },
}

const roleColors = {
    manager: {
        backgroundColor: "#b4ffdb",
        color: "#19db7e",
    },
    admin: {
        backgroundColor: "#FFF1EE",
        color: "#FF7A59",
    },
    "team member": {
        backgroundColor: "#EBF0F9",
        color: "#446FCB",
    },
    guest: {
        backgroundColor: "#a9fffa",
        color: "#00d4c8",
    },
    user: {
        backgroundColor: "#FFF4E5",
        color: "#FF970A",
    },
    "non member": {
        backgroundColor: "#eaf0f6",
        color: "black",
    },
    agent:{
        backgroundColor: "#ecd3ff",
        color: "#9f46e4"
    }
}

/**
 * @description Get the color of status for a user
 * @param {String} status
 * @returns color of status
 */

export function getJoinedStatusColor(status) {
    return joinedStatusColors[status.toLowerCase()]
}

/**
 * @description Get the color of role for a user
 * @param {String} role
 * @returns background and color of the role
 */

export function getRoleColor(role) {
    return roleColors[role.toLowerCase()]
}
