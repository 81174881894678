/**
 * @description Format a message by returning is substring of given length for short descriptions
 * @param {String} msg Message to be formatted
 * @param {Number} len Length for which the message has to be shown
 * @returns formatted message
 */

export function formatMsg(msg, len) {
    let l = len ?? 50
    return msg.substr(0, l) + (msg.length > l ? "..." : "")
}

/**
 * @description Format and Extract Type of Status, Reason & Msg from the collaboration msg
 * @example
 * msg:
 * 1) |||Status|||Reason|||Msg
 * 2) |||Status|||Msg
 * 3) Msg
 * @param {String} msg
 *
 * @return {Array} [status, reason, msg]
 */

export function parseCollaborationMsg(msg) {
    if (!msg || msg.length === 0) return null

    let splittedMsg = msg.split("|||")

    if (splittedMsg.length === 1) return [null, null, splittedMsg[0]]
    if (splittedMsg.length === 3) return [splittedMsg[1], null, splittedMsg[2]]
    if (splittedMsg.length === 4)
        return [splittedMsg[1], splittedMsg[2], splittedMsg[3]]
}
