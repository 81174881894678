import React from "react"
import { formatDateIntoWord } from "../../../Helper/HandleDateAndTime"
import IconImage from "../../IconImage"

const LogStrip = ({ image, emailId: name, createdOn, type }) => {
    const _C = "aui-activity-log-strip"
    return (
        <div className={_C}>
            <IconImage
                width="32px"
                height="32px"
                round
                bgClr="#CB3333"
                src={image}
                image={image}
                border="1px solid lightgray"
            />
            <div className={_C + "-content"}>
                <div className={_C + "-top"}>
                    <div className={_C + "-name"}>{name?.split("@")[0]}</div>
                    <div className={_C + "-comment"}>{type}</div>
                </div>
                <div className={_C + "-bottom"}>
                    {formatDateIntoWord(createdOn, true, true)}
                </div>
            </div>
        </div>
    )
}

export default LogStrip
