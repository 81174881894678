import React from "react"
import ReactDom from "react-dom"

const ExternalLibConnector = ({ open, children }) => {
    if (!open) return <></>
    ReactDom.unmountComponentAtNode(document.getElementById("external-libs"))
    return ReactDom.createPortal(children, document.getElementById("external-libs"))
}

export default ExternalLibConnector
