import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
    createCaption: {
        width: "100%",
        padding: "1rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
        flexDirection: "column",
        overflowY: "auto"
    },
    captionContent: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginBottom: "1rem",
    },
    inputLabel: {
        width: "100%",
        textAlign: "left",
    },
    captionInput: {
        width: "100%",
        border: "1px solid #D6DFE8",
        outline: "none",
        borderRadius: "4px",
        height: "2rem",
        padding: "0.3rem",
    },
    txt: {
        width: "100%",
        resize: "vertical",
        border: "1px solid #D3D3D3",
        outline: "none",
        borderRadius: "4px",
        padding: "0.3rem",
    }
})

function CreateCaptionForm({captionName, setCaptionName, captionText, setCaptionText}) {
    const classes = useStyles()
  return (
    <div className={classes.createCaption}>
        <div className={classes.captionContent}>
            <div className={classes.inputLabel}>
                Caption Name
            </div>
            <input 
                type="text" 
                className={classes.captionInput} 
                value={captionName}
                onChange={(e)=>{setCaptionName(e.target.value)}}
            />
        </div>
        <div className={classes.captionContent}>
            <div className={classes.inputLabel}>
                Caption Text
            </div>
            <textarea 
                className={`${classes.txt}`} 
                cols="30" 
                rows="10"
                value={captionText}
                onChange={(e)=>setCaptionText(e.target.value)}
                spellCheck={true}
            >
            </textarea>
        </div>
    </div>
  )
}

export default CreateCaptionForm
