import Axios from "axios"
import store from "../store"
import {
    getAnalyticsApi,
    linkedinPageAnalyticsApi,
    linkedinShareanalyticsApi,
} from "components/apis"
import {
    SET_PERIOD_ANALYTICS,
    SET_ACTIVE_NAV,
} from "ActionTypes/AnalyticsActionTypes"
import { createMessage } from "./messageAction"
import { getHeaders } from "./userAction"
import { LINKED_IN } from "Data/SocialMedialTypes"

const { dispatch, getState } = store

export const setActiveNav = (value) => {
    dispatch({ type: SET_ACTIVE_NAV, payload: value })
}

export const delay = (ms) => new Promise((res) => setTimeout(res, ms))

const transformerPostsAnalytics = (data, social) => {
    let formattedPostsAnalytics = null
    switch (social) {
        case "facebook":
        case "instagram":
            if (!Array.isArray(data)) return null
            formattedPostsAnalytics = data?.map((item) => {
                return {
                    id: item?.data[0]?.id?.split("/")[0],
                    data: item?.data?.map((_item) => {
                        return { ..._item, values: [_item.values[0].value] }
                    }),
                }
            })
            break
        case "twitter":
            break
        case "linkedin":
            break
        case "pinterest":
            break
        default:
            break
    }
    return formattedPostsAnalytics
}

export const getPostsMetrics = ({ data, social }) => {
    const { supportedPostMetrics } = getState().analyticsReducer
    const metrics = supportedPostMetrics[social]
    const formattedMetrics = metrics?.map((item) => {
        let _item = item
        if (social === "facebook") {
            _item = item.replace(/_/g, " ")
        }
        if (Array.isArray(data)) {
            const _data = data.find((_item) => _item.name === item)
            return {
                label: _item,
                value: (_data && _data.values[0]) || "0",
            }
        }
        return {
            label: _item,
            value: (data && data[item]) || "0",
        }
    })
    return formattedMetrics
}

export const getPostAnalytics = async (
    social,
    brandId,
    postId,
    type,
    iteration = 1
) => {
    const { supportedPostMetrics } = getState().analyticsReducer
    try {
        const metrics = supportedPostMetrics[social]?.join(",")
        const headers = getHeaders()
        headers.params = {
            postId,
            brandId,
            metrics,
            platform: social + "_" + (type || "user"),
        }
        const res = await Axios.get(getAnalyticsApi(social), headers)
        if (res.data.code === 200) {
            return {
                id: postId,
                data:
                    social === "linkedin"
                        ? res?.data?.model?.[0]?.elements?.[0]?.totalShareStatistics
                        : res?.data?.model?.data,
            }
        }
        return null
    } catch (error) {
        if (iteration >= 2) {
            console.log(`Error while getting ${social} post's analytics:`, error)
            createMessage("danger", "Failed to fetch analytics!")
            return null
        }
        await delay(300)
        return await getPostAnalytics(social, brandId, postId, type, iteration + 1)
    }
}

export const getPostsAnalytics = async (social, brandId, postIds, iteration = 1) => {
    const { supportedPostMetrics } = getState().analyticsReducer
    try {
        const metrics = supportedPostMetrics[social]?.join(",")
        const headers = getHeaders()
        headers.params = {
            postId: postIds?.join(",") || "",
            brandId,
            metrics,
        }
        const res = await Axios.get(getAnalyticsApi(social), headers)
        if (res?.data?.code === 200) {
            const formattedPostsAnalytics = transformerPostsAnalytics(
                res.data.model,
                social
            )
            return formattedPostsAnalytics
        }
        return null
    } catch (error) {
        if (iteration >= 2) {
            console.log(`Error while getting ${social} posts' analytics:`, error)
            createMessage("danger", "Failed to fetch analytics!")
            return null
        }
        await delay(300)
        return await getPostsAnalytics(social, brandId, postIds, iteration + 1)
    }
}

export const getPageAnalyticsByTimestamp = async (
    social,
    brandId,
    metrics,
    since,
    until,
    type,
    iteration = 1
) => {
    dispatch({
        type: SET_PERIOD_ANALYTICS,
        payload: [],
    })
    if (!brandId || !metrics) return
    try {
        const headers = getHeaders()

        headers.params = {
            brandId,
            since,
            until,
            platform: social + "_" + (type || "user"),
        }
        if (social !== LINKED_IN) {
            headers.params = {
                ...headers.params,
                period: "day",
                metrics,
            }
        }
        if (social === LINKED_IN) {
            const [res1, res2] = await Promise.all([
                await Axios.get(linkedinPageAnalyticsApi, headers),
                await Axios.get(linkedinShareanalyticsApi, headers),
            ])
            if (res1.data.code === 200 && res2.data.code === 200) {
                dispatch({
                    type: SET_PERIOD_ANALYTICS,
                    payload: processLinkedInAnalytics([
                        ...res1.data.model.data,
                        ...res2.data.model.data,
                    ]),
                })
            }
            return [...res1.data.model.data, ...res2.data.model.data]
        }
        let res = await Axios.get(getAnalyticsApi(social), headers)

        if (res.data.code === 200) {
            dispatch({
                type: SET_PERIOD_ANALYTICS,
                payload: processPeriodAnalytics(res.data.model.data, social),
            })
            return res.data.model.data
        } else {
            return null
        }
    } catch (error) {
        if (iteration >= 2) {
            console.log(`Error while getting ${social} page's analytics:`, error)
            createMessage("danger", "Failed to fetch analytics!")
            return null
        }
        await delay(300)
        return await getPageAnalyticsByTimestamp(
            social,
            brandId,
            metrics,
            since,
            until,
            iteration + 1
        )
    }
}

const processLinkedInAnalytics = (data) => {
    const { supportedMetrics } = getState().analyticsReducer
    const baseDataObject = {
        borderWidth: 1,
        cubicInterpolationMode: "monotone",
        fill: "origin",
    }

    const dataItems = []
    const newDataSet = []

    data.forEach((item) => {
        // if (!item.values) return

        let counter = 0
        let summedValues = []
        const values =
            item.values?.map((valueObject) => {
                counter += valueObject.value
                summedValues.push(counter)
                return valueObject.value
            }) ?? []

        dataItems.push({
            id: item.name,
            desc: item.name,
            name: item.name,
            color: supportedMetrics["linkedin"][item.name]?.color,
            percent:
                summedValues[0] === 0
                    ? summedValues[summedValues.length - 1] * 100
                    : ((summedValues[summedValues.length - 1] - summedValues[0]) /
                          summedValues[0]) *
                      100,
            count: counter,
        })
        newDataSet.push({
            ...baseDataObject,
            backgroundColor: supportedMetrics?.["linkedin"][item.name]?.color,
            borderColor: supportedMetrics?.["linkedin"]?.[item.name]?.border,
            data: values,
            label: item.name,
            id: item.name,
        })
    })

    return [newDataSet, dataItems]
}

export const processPeriodAnalytics = (data, social) => {
    const { supportedMetrics } = getState().analyticsReducer

    const baseDataObject = {
        borderWidth: 1,
        cubicInterpolationMode: "monotone",
        fill: "origin",
    }

    const dataItems = []
    const newDataSet = data?.map((item) => {
        let counter = 0
        let summedValues = []
        const values = item.values.map((valueObject) => {
            counter += valueObject.value
            summedValues.push(counter)
            return valueObject.value
        })

        dataItems.push({
            id: item.name,
            desc: item.description,
            name: item.title,
            color: supportedMetrics[social][item.name].color,
            percent:
                summedValues[0] === 0
                    ? summedValues[summedValues.length - 1] * 100
                    : ((summedValues[summedValues.length - 1] - summedValues[0]) /
                          summedValues[0]) *
                      100,
            count: counter,
        })
        return {
            ...baseDataObject,
            backgroundColor: supportedMetrics[social][item.name].color,
            borderColor: supportedMetrics[social][item.name].border,
            data: values,
            label: item.title,
            id: item.name,
        }
    })

    return [newDataSet, dataItems]
}
