import React, { memo, useEffect } from "react"
import { useState } from "react"
import IntersectionVisible from "react-intersection-visible"

const PdfCanvas = memo(
    ({ pdfRefs, pdf, pageNumber, index, setPageSize }) => {
        const [renderTask, setrenderTask] = useState(null)
        
        async function getPages(e) {
            if (!pdfRefs.current[index]) return
            
            const canvasDiv = pdfRefs.current[index]

            await new Promise((resolve, reject) => {
                pdf && pdf.getPage(pageNumber)
                    .then(async (page) => {
                        if (renderTask) {
                            await renderTask.cancel()
                        }

                        const viewPort = page.getViewport({ scale: 1.7 })

                        const ctx = canvasDiv.getContext("2d")

                        canvasDiv.height = viewPort.height
                        canvasDiv.width = viewPort.width
                        canvasDiv.style.width = 450+ "px"
                        canvasDiv.style.height = 550+ "px"
                        ctx.clearRect(0, 0, canvasDiv.width, canvasDiv.height)

                        const renderContext = {
                            canvasContext: ctx,
                            viewport: viewPort,
                        }

                        let task = page.render(renderContext)
                        setrenderTask(task)
                        task.promise
                            .then(() => {
                                resolve()
                            })
                            .catch(async (error) => {
                                console.log(error)
                                if (renderTask) {
                                    await renderTask.cancel()
                                }
                                reject()
                            })
                    })
                    .catch((error) => {
                        console.log(error)
                        reject()
                    })
            })
        }

        useEffect(() => {
            return () => {
                setrenderTask(null)
                const canvasDiv = pdfRefs.current[index]
                if (canvasDiv) {
                    const ctx = canvasDiv.getContext("2d")
                    ctx.clearRect(0, 0, canvasDiv.width, canvasDiv.height)
                }
            }
        }, [])

        useEffect(() => {
            const init = async () => {
                await cancleTask()
                if (pdf)
                    await getPages()
                
            }

            init()
        }, [pdf])

        /**
         * cancle all the render tasks and clear canvas
         * @param {*} e
         */
        async function cancleTask(e) {
            if (renderTask) {
                await renderTask.cancel()
            }
            setrenderTask(null)
            const canvasDiv = pdfRefs.current[index]
            if (canvasDiv) {
                const ctx = canvasDiv.getContext("2d")
                ctx.clearRect(0, 0, canvasDiv.width, canvasDiv.height)
            }
        }

        return (
            <IntersectionVisible onHide={cancleTask} onShow={getPages}>
                <canvas ref={(el) => (pdfRefs.current[index] = el)}></canvas>
            </IntersectionVisible>
            
        )
    }
)

export default PdfCanvas