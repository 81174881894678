import React, { useState } from "react"
import { connect } from "react-redux"
import SideDrawer from "../SideDrawer"
import { closeSocialPostManager, shuffleFile } from "../../../Actions/postAction"
import { selectChannelToView, selectKeyToView } from "../../../Actions/postAction"
import PreviewSection from "../PostRelatedComponents/PreviewSection"
import ManagementSection from "./ManagementSection"
import Captions from "../Captions/Captions"
import "./PostManagementSideDrawer.scss"
import {getQueueForCreation} from "Actions/brandsAction"
import QueueSlot from "../QueueSlot/QueueSlot"
import { useEffect } from "react"
import { updatePostScheduledTime, updatePostScheduledDate } from "../../../Actions/postAction"


const PostManagementSideDrawer = ({
    post,
    socialProfile,
    socialProfiles,
    selectedChannelToView,
    selectedKeyToView,
    files,
    brandId,
    brand, platform,
    socialSubtype,
    channelsToPostOn,

}) => {
    const _C = "aui-post-manager"
    const [activeDuplication, setActiveDuplication] = useState("Similar")
    const [showCaptions, setShowCaptions] = useState(false)
    const [showQueue, setShowQueue]= useState(false)
    const [time, setTime]=useState("")
    const [date, setDate]= useState()
    const [dateForAPI, setDateForAPI] = useState(new Date().toISOString().slice(0, 10))
    const [timeslot, setTimeslot] = useState([])
    
    useEffect(()=>{
        async function queueForCreation() {
            try {
               const result = await getQueueForCreation(brandId, selectedChannelToView+"_"+socialSubtype,dateForAPI);
               setTimeslot((prev)=>[...prev, ...result])
            } catch (err) {
               console.error(err);
            }
        }
        if (socialSubtype && brandId && selectChannelToView) queueForCreation()
    },[socialSubtype, brandId, selectedChannelToView, dateForAPI])

    const prefilledTime=(param1, param2)=>{
        function addColonToTime(timeString) {
            if (timeString.endsWith("AM") || timeString.endsWith("PM")) {
              return timeString.replace(/([APap][Mm])$/, ":$1");
            }
            return timeString;
        }
        setDate(param1)
        setTime(addColonToTime(param2))
    }
    useEffect(() => {
        updatePostScheduledDate(date)
    }, [date])
    useEffect(() => {
        if (time === "00:00:AM") return
        let hour = Number(time.substring(0, 2))
        const mins = Number(time.substring(3, 5))
        const meridian = time.substring(6)
        if (meridian === "PM") {
            if (hour !== 12) hour = hour + 12
        } else {
            if (hour === 12) hour = 0
        }
        const newTime = `${hour < 10 ? "0" : ""}${hour}:${
            mins < 10 ? "0" : ""
        }${mins}:00`
        updatePostScheduledTime(newTime)
    }, [time])

    useEffect(()=>{
        showCaptions && setShowQueue(false)
    },[showCaptions])

    useEffect(()=>{
        showQueue && setShowCaptions(false)
    },[showQueue])

    const close=()=>{
        setShowQueue(false)
        setShowCaptions(false)
        closeSocialPostManager()
        setTimeslot([])
        setDateForAPI(new Date().toISOString().slice(0, 10))
        setTime("")
        setDate("")
    }
    const resetQueue =()=>{
        setShowQueue(false)
        setShowCaptions(false)
        setTimeslot([])
        setDateForAPI(new Date().toISOString().slice(0, 10))
        setTime("")
        setDate("")
    }
    
    if(!post) return <></> // So that drawer is not shown when not active

    return (
        <SideDrawer
            minWidth="70%"
            maxWidth="93%"
            toggle={close}
            vis={post}
            position="right"
            toggleBtn={<div className={_C + "-cross"}>+</div>}
            className={_C}
        >
                <div className="preview-section-container">
                    <PreviewSection
                        post={post}
                        socialProfile={socialProfile}
                        socialProfiles={socialProfiles}
                        selectedChannelToView={selectedChannelToView}
                        selectedKeyToView={selectedKeyToView}
                        files={files}
                        activeDuplication={activeDuplication}
                        onFilesShuffle={shuffleFile}
                        selectedAccountToView={
                            selectedChannelToView &&
                            selectedKeyToView && {
                                added: true,
                                brandId: brandId,
                                choose: true,
                                key: selectedKeyToView.toUpperCase(),
                                name: "Preview",
                                type: selectedChannelToView.toUpperCase(),
                            }
                        }
                        selectProfileToView={(account) => {
                            selectChannelToView(account.type.toLowerCase())
                            selectKeyToView(account.key.toLowerCase())
                        }}
                        multipleAccounts={
                            channelsToPostOn?.map((item) => {
                                const [social, key] = item.split("_")

                                return {
                                    added: true,
                                    brandId: brandId,
                                    choose: true,
                                    key: key.toUpperCase(),
                                    name: "Preview",
                                    type: social.toUpperCase(),
                                }
                            })
                        }
                    />
                </div>
            <ManagementSection
                social={post?.platform?.split("_")[0]}
                brandId={brandId}
                activeDuplication={activeDuplication}
                setActiveDuplication={setActiveDuplication}
                showCaptions={showCaptions}
                setShowCaptions={setShowCaptions}
                showQueue={showQueue}
                setShowQueue={setShowQueue}
                time={time}
                date={date}
                resetQueue={resetQueue}
                
            />
            {showCaptions ? 
                <Captions
                    brandId={brandId}
                    activeDuplication={activeDuplication}
                    selectedChannelToView={selectedChannelToView}
                    setShowCaptions={setShowCaptions}
                />
                :
                showQueue && 
                <QueueSlot 
                    prefilledTime={prefilledTime} 
                    setShowQueue={setShowQueue} 
                    selectedKeyToView={selectedKeyToView} 
                    QueueData={timeslot}
                    date={dateForAPI}
                    setDate={setDateForAPI}
                />
            }

        </SideDrawer>
    )
}

const mapStateToProps = ({ postReducer, socialReducer }) => {
    const {
        post,
        selectedChannelToView,
        selectedKeyToView,
        files,
        brandId,
        channelsToPostOn,
    } = postReducer
    const { social, socialProfiles } = socialReducer

    let chosenSocial = selectedChannelToView ?? social
    let currentProfile = socialProfiles[brandId]?.[chosenSocial?.toUpperCase()]

    return {
        post,
        files,
        selectedChannelToView: selectedChannelToView ?? social,
        selectedKeyToView: selectedKeyToView,
        brandId: brandId,
        socialProfile: currentProfile,
        socialProfiles: socialProfiles[brandId],
        brand:postReducer.brandId,
        platform:postReducer.selectedChannelToView,
        socialSubtype:socialReducer.socialSubtype,
        channelsToPostOn,

    }
}

export default connect(mapStateToProps)(PostManagementSideDrawer)
