import store from "../store"
import {
    OPEN_SOCIAL_POST_MANAGER,
    CLOSE_SOCIAL_POST_MANAGER,
    CHANGE_POST_MANAGER_OPTION,
    UPDATE_SOCIAL_POST,
    CHANGE_POST,
    SELECT_CHANNEL_TO_VIEW,
    SELECT_KEY_TO_VIEW,
    ADD_FILES,
    DELETE_FILES,
    TOGGLE_MINIFY_LINK,
    SELECT_CHANNEL_TO_POST_ON,
    UPDATE_POST_SCHEDULED_DATE,
    UPDATE_POST_SCHEDULED_TIME,
    CLEAR_POSTS,
    UPDATE_ALL_POSTS,
    ADD_POST,
    RESET_PAGE,
    PREVIOUS_PAGE,
    UPDATE_APPROVALS_PAGE,
    POST_LOADING,
    POST_LOADING_END,
    UPDATE_CREATED_POST,
    UPDATE_ALL_SOCIAL_POSTS,
    CLEAR_ALL_SOCIAL_POSTS,
    SHUFFLE_FILES,
    REORDER_POSTS,
    UPDATE_TIMEZONE,
    RESET_HAS_MORE,
    CHANGE_POST_MULTISOCIAL,
    DUPLICATE_FILES,
    SET_LABEL_FILTER_STATUS,
    SET_SORT_FILTER_STATUS,
    SET_USER_FILTER_STATUS,
    SET_SEARCH_FILTER_STATUS,
    SET_DATE_FILTER_STATUS,
    SET_FILTER,
    MEDIA_CHANGE,
    OPEN_SCHEDULE_DATE_UPDATE_MODAL,
    CLOSE_SCHEDULE_DATE_UPDATE_MODAL,
    SET_ROW_LIMIT,
    POST_ERROR,
    POST_APPEND_TEXT,
    SET_NOTIFICATION_POST,
    SET_ORDER,
    TOKEN_SAVING,
    REFETCHQUEUE,
} from "../ActionTypes/postActionType"
import {
    createPostHandler,
    getAllPostsByDateNew,
    getPostById,
    handlePostUpdate,
} from "../components/Helper/SocialPostHandler/postHandler"
import {
    getAllPosts,
    getAllPostsByDate,
} from "../components/Helper/SocialPostHandler/postHandler"

import { testLinkCompressor } from "../components/Helper/textLinkConverter"
import { createMessage } from "./messageAction"
import { SocialSubTypeChecker } from "../components/Helper/Normalizers/SocialSubTypeChecker"
import { getHeaders } from "./userAction"
import { getCommentsApi, socialPostsApi } from "../components/apis"
import Axios from "axios"
import { checkPost } from "../components/Helper/SocialPostHandler/processPostBeforeRequest"
import { UPDATE_CREATED_PUBLISH_POST } from "../ActionTypes/publishActionType"
import { getAllPublishPosts } from "../components/Helper/SocialPostHandler/bulkPublish"
import { consumePlan } from "./transactionAction"
import { CONSUME_EVENT } from "ActionTypes/transactionActionTypes"
import { changePublishFilterStatus } from "./publishAction"
import { changePostFilterStatus } from "./socialAction"
import { SCHEDULE } from "components/Helper/Types/postStatusTypes"
import { PINTEREST_USER } from "../constants"

const { dispatch, getState } = store

/**
 * @description Reducer to open post manager and update post
 * @param {["edit","view","create"]} state
 * @param {{ create: [{ title: "Create", active: 1 },{ title: "Collaborate", active: 0 },{ title: "Activity", active: 0 },],view: [    { title: "Edit", active: 1 },    { title: "Collaborate", active: 1 },    { title: "Activity", active: 1 },],published: [    { title: "Collaborate", active: 1 },    { title: "Engagement", active: 1 },    { title: "Activity", active: 1 },] }} option Option index(int) Based on the state
 * @param {*} brandId
 * @param {*} companyId
 * @param {*} post
 * @param {["TWITTER","LINKEDIN","FACEBOOK","INSTAGRAM"]} socialChannel Social channel of the current post
 * @param {Boolean?} [deselectRestSocials=false] Keep the current social as selected
 */
function openSocialPostManager(
    state,
    option,
    companyId,
    brandId,
    inputPost,
    socialChannel,
    creator,
    fromBulkPublish,
    deselectRestSocials = false
) {
    let brand = getState().brandsReducer.brandDetails[brandId]
    const socialPlatform = socialChannel?.toLowerCase()
    const newKey = `${socialPlatform}_text`
    const postText = inputPost?.text || ""

    const post = {
        ...inputPost,
        text: {
            [newKey]: postText,
        },
    }

    let channels = [socialChannel]
    let socialProfiles = getState().socialReducer.socialProfiles[brandId]
    if (!deselectRestSocials) {
        channels = Object.values(
            getState().socialReducer.brandSocialChannels[brandId] ?? {}
        )
            .filter((chnl) => {
                let hasProfile = socialProfiles?.[chnl.name?.toLowerCase()]
                if (SocialSubTypeChecker(chnl.name)) hasProfile = hasProfile?.page
                return !!hasProfile
            })
            .map(({ name }) => name.toLowerCase())
    }
    let payload = {
        state,
        option,
        brandId,
        companyId,
        post,
        socialChannel,
        selectedChannelToView: socialChannel,
        creator,
        allChannels: channels,
        fromBulkPublish,
        version: post?.version || "",
        timeZone: state === "create" ? brand?.timezone : post?.timeZone || "",
    }

    if (post.publishDate && post.status !== "draft") {
        payload.scheduleDate = post?.publishDate?.split("T")[0]
        payload.scheduleTime = post?.publishDate?.split("T")[1]?.slice(0, -1)
    }

    dispatch({
        type: OPEN_SOCIAL_POST_MANAGER,
        payload,
    })
}

/**
 * @description Add a file to the files in the post
 * @param {*} file
 */
function addFiles(file, previewChannel, activeDuplication, metaData) {
    dispatch({ type: ADD_FILES, payload: { file, previewChannel } })
    if (metaData) {
        let creditText = `\n\n 📷 Photo by ${metaData.creatorName} on ${metaData.platform}`
        appendPostText(null, creditText)
    }
    if (activeDuplication === "Similar") duplicateFiles(previewChannel)
}

function duplicateFiles(previewChannel) {
    dispatch({ type: DUPLICATE_FILES, payload: { previewChannel } })
}

function shuffleFile(files, previewChannel, activeDuplication) {
    dispatch({ type: SHUFFLE_FILES, payload: { files, previewChannel } })
    if (activeDuplication === "Similar") duplicateFiles(previewChannel)
}

/**
 * @description Delete a file with given name or at position from the files in the post
 * @param {String} name
 * @param {Number} position
 */
function deleteFile(position, name, previewChannel, activeDuplication) {
    dispatch({ type: DELETE_FILES, payload: { position, name, previewChannel } })
    if (activeDuplication === "Similar") duplicateFiles(previewChannel)
}

/**
 * @description Toggle Minify Link option
 */
function toggleMinifyLink() {
    dispatch({ type: TOGGLE_MINIFY_LINK })
}

/**
 * @description Select the channel for which the post preview is to be seen in the post preview section
 * @param {String} channel
 */
function selectChannelToView(channel) {
    dispatch({
        type: SELECT_CHANNEL_TO_VIEW,
        payload: { channel: channel.toLowerCase() },
    })
}

function selectKeyToView(key) {
    dispatch({
        type: SELECT_KEY_TO_VIEW,
        payload: { key: key.toLowerCase() },
    })
}

/**
 * @description Function to clear the posts.
 */
function clearPosts() {
    dispatch({ type: CLEAR_POSTS })
}

function postLoadingEnd() {
    dispatch({ type: POST_LOADING_END })
}

/**
 * @description Get All Posts
 * @param {page?} [page] Page number
 */
async function getPosts(page, status, fromDate, toDate, rows) {
    const {
        social,
        activeSocialBrand,
        currentPostFilterStatus,
        socialSubtype,
        socialView,
    } = getState().socialReducer

    const { rowLimit, sortBy, orderBy } = getState().postReducer
    if (!page) page = getState().postReducer.page
    const dataLimit = rows ?? (socialView === 3 ? rowLimit : 15)
    const currentSocialDetail = activeSocialBrand?.socialMedia.socials.filter(
        ({ name }) => name.toLowerCase() === social?.toLowerCase()
    )

    if (!currentSocialDetail || !currentSocialDetail?.find((item) => item?.enable))
        return
    const socialKey = `${social?.toLowerCase()}_${socialSubtype.toLowerCase()}`

    dispatch({ type: POST_LOADING })
    let posts
    if (toDate && fromDate) {
        posts = await getAllPostsByDate(
            socialKey,
            activeSocialBrand.id,
            status ?? currentPostFilterStatus?.statusKey,
            fromDate,
            toDate,
            page - 1,
            30
        )
    } else {
        // if (socialView === 1) return // as we use Posts fetch by dateRange for Calendar View
        posts = await getAllPosts(
            socialKey,
            activeSocialBrand.id,
            page - 1,
            dataLimit,
            status ?? currentPostFilterStatus?.statusKey,
            socialView === 3 && sortBy,
            socialView === 3 && orderBy
        )
    }
    if (
        posts &&
        getState().socialReducer.currentPostFilterStatus.statusKey ===
            currentPostFilterStatus?.statusKey // To prevent overlap when status is switched before request is over
    )
        dispatch({
            type: UPDATE_ALL_POSTS,
            payload: {
                posts,
            },
        })
    return posts
}

async function getPostsByDateNew(social, brandId, startDate, endDate) {
    if (!startDate || !endDate) return
    try {
        dispatch({ type: POST_LOADING })
        const posts = await getAllPostsByDateNew(social, brandId, startDate, endDate)
        dispatch({ type: POST_LOADING_END })
        return posts
    } catch (error) {
        console.log(error)
        createMessage("error", "Something went wrong")
        return null
    }
}

export const getSinglePost = async (postId, platform, brandId, key) => {
    if (!postId) return

    const config = {
        id: postId,
        brandId: brandId,
        key: key,
    }

    const post = await getPostById(platform, config)

    if (post) {
        dispatch({ type: SET_NOTIFICATION_POST, payload: post })
        return post
    }

    return null
}

async function getAllSocialsPosts(page) {
    const brandsList = Object.keys(getState().brandsReducer.brandDetails)
    let allPosts = []
    dispatch({ type: POST_LOADING })
    try {
        const res = await getAllPublishPosts(
            brandsList,
            null,
            page,
            15,
            "schedule,draft"
        )
        if (res.data.model) {
            allPosts = res.data.model
        }
        if (allPosts) {
            dispatch({
                type: UPDATE_ALL_SOCIAL_POSTS,
                payload: { allPosts },
            })
        }
        dispatch({ type: POST_LOADING_END })
    } catch (err) {
        dispatch({ type: POST_LOADING_END })
        dispatch({ type: RESET_HAS_MORE })
    }
}

function clearAllSocialPosts() {
    dispatch({ type: CLEAR_ALL_SOCIAL_POSTS })
}

/**
 * @description Add a post
 * @param {{twitter:Post, linkedin: Post, instagram:Post, Facebook: Post}} posts
 */
async function addPost(postHavingAllSocial) {
    let post = postHavingAllSocial[getState().socialReducer?.social?.toLowerCase()]
    if (!post) return
    dispatch({ type: ADD_POST, payload: { post: { ...post, path: post.mediaUrl } } })
}

/**
 * @description delete a post
 * @param {String} social
 * @param {String} postId
 * @param {String} brandId
 * @param {[Function]} callback
 */
async function deletePost(social, postId, brandId, callback = () => {}) {
    try {
        let requestHeaders = { ...getHeaders() }
        if (social === "pinterest") {
            requestHeaders.params = {
                postId: postId,
                brandId,
            }
        } else {
            requestHeaders.params = {
                id: postId,
                brandId,
            }
        }

        const res = await Axios.delete(socialPostsApi(social), requestHeaders)
        if (res.data.code === 200) {
            createMessage("success", "Successfully deleted the post")
            // dispatch({ type: DELETE_POST, payload: { id: postId } })
            // deletePublishPost(postId)
            dispatch({ type: REFETCHQUEUE, payload: true })
            setTimeout(() => dispatch({ type: REFETCHQUEUE, payload: false }), 2000)
            return true
        } else {
            createMessage("danger", "Can't delete the post")
            return false
        }
    } catch (err) {
        createMessage("danger", err?.response?.data)
        return false
    } finally {
        callback()
    }
}

/**
 * @description TO change the post data in the redux
 * @param {*} key
 * @param {*} value
 */
function changePost(key, value, fromEvent = false) {
    if (fromEvent) value = value.target.value
    dispatch({ type: CHANGE_POST, payload: { key, value } })
}

function changePostMultiSocial(key, value, fromEvent = false) {
    if (fromEvent) value = value.target.value
    dispatch({ type: CHANGE_POST_MULTISOCIAL, payload: { key, value } })
}

export function checkTimeDateZone(scheduleDate, scheduleTime, timeZone) {
    let currentdate = new Date().toLocaleString("en-US", {
        timeZone: timeZone,
    })

    let selectedDate = new Date(scheduleDate + "T" + scheduleTime).toLocaleString(
        "en-US"
    )

    return new Date(currentdate) > new Date(selectedDate)
}

/**
 * Post create handler to send new post to the post handler for the creation
 *
 *  @description Create the post in the state of reducer
 */
async function createPost(postStatus, callback = () => {}, isApprovalRequired) {
    const {
        brandId,
        companyId,
        post,
        channelsToPostOn,
        files,
        scheduleDate,
        scheduleTime,
        minifyLink,
        version,
        timeZone,
    } = getState().postReducer
    const { brandDetails } = getState().brandsReducer
    const { user } = getState().userReducer
    const { postStatusArray } = getState().socialReducer
    if (!user || !brandId || !companyId) {
        // TODO: control basic details from this place.
        callback()
        return
    }

    if (channelsToPostOn.length === 0) {
        createMessage("warn", "Please select channels to post on")
        callback()
        return
    }

    let err = checkPost(
        post,
        scheduleDate,
        scheduleTime,
        channelsToPostOn,
        timeZone,
        postStatus,
        files
    )

    if (err) {
        createMessage("warn", err)
        callback()
        return
    }

    if (minifyLink) {
        await Promise.all(
            post.text &&
                Object.keys(post.text).map(async (channel) => {
                    if (
                        post.text.twitter_text &&
                        channel === "twitter_text" &&
                        channelsToPostOn.includes("twitter_user")
                    ) {
                        post.text.twitter_text = await testLinkCompressor(
                            post.text.twitter_text,
                            brandDetails[brandId]?.isBitly,
                            brandId
                        )
                    }
                    if (
                        post.text.instagram_text &&
                        channel === "instagram_text" &&
                        channelsToPostOn.includes("instagram_page")
                    ) {
                        post.text.instagram_text = await testLinkCompressor(
                            post.text.instagram_text,
                            brandDetails[brandId]?.isBitly,
                            brandId
                        )
                    }
                    if (
                        post.text.facebook_text &&
                        channel === "facebook_text" &&
                        (channelsToPostOn.includes("facebook_page") ||
                            channelsToPostOn.includes("facebook_group"))
                    ) {
                        post.text.facebook_text = await testLinkCompressor(
                            post.text.facebook_text,
                            brandDetails[brandId]?.isBitly,
                            brandId
                        )
                    }
                    if (
                        post.text.linkedin_text &&
                        channel === "linkedin_text" &&
                        (channelsToPostOn.includes("linkedin_user") ||
                            channelsToPostOn.includes("linkedin_page"))
                    ) {
                        post.text.linkedin_text = await testLinkCompressor(
                            post.text.linkedin_text,
                            brandDetails[brandId]?.isBitly,
                            brandId
                        )
                    }
                })
        )
    }
    if (scheduleDate && scheduleTime && postStatus === SCHEDULE) {
        if (checkTimeDateZone(scheduleDate, scheduleTime, timeZone)) {
            createMessage(
                "warn",
                "Please Enter a Valid Schedule Date and Schedule Time"
            )
            callback()
            return
        }
    }

    let isAllowed = consumePlan(
        CONSUME_EVENT,
        channelsToPostOn.filter((a) => a).length
    )

    if (!isAllowed) {
        return callback()
    }

    let errorArray = await createPostHandler(
        brandId,
        timeZone,
        user,
        post.text,
        scheduleDate,
        scheduleTime,
        postStatus,
        files,
        channelsToPostOn,
        { ...post },
        version,
        postStatusArray
    )

    if (errorArray) {
        dispatch({ type: POST_ERROR, payload: errorArray })
    }
    dispatch({ type: REFETCHQUEUE, payload: true })
    setTimeout(() => dispatch({ type: REFETCHQUEUE, payload: false }), 2000)
    callback()
}

/**
 * @description edit the post in the state of reducer
 */
async function editPost(postStatus, callback) {
    const { brandDetails } = getState().brandsReducer
    const { postStatusArray } = getState().socialReducer
    let {
        post,
        files,
        scheduleDate,
        scheduleTime,
        fromBulkPublish,
        version,
        timeZone,
        brandId,
        mediaChange,
        minifyLink,
    } = getState().postReducer
    let err = checkPost(post, scheduleDate, scheduleTime, null, timeZone)

    if (err) {
        createMessage("warn", err)
        callback()
        return
    }

    if (scheduleDate && scheduleTime && postStatus === SCHEDULE) {
        if (checkTimeDateZone(scheduleDate, scheduleTime, timeZone)) {
            createMessage(
                "warn",
                "Please Enter a Valid Schedule Date and Schedule Time"
            )
            callback()
            return
        }
    }

    if (minifyLink && post.platform !== PINTEREST_USER) {
        post.text[`${post.platform.split("_")[0]}_text`] = await testLinkCompressor(
            post.text.twitter_text,
            brandDetails[brandId]?.isBitly,
            brandId
        )
    }

    try {
        let res = await handlePostUpdate(
            post.Platform ?? post.platform,
            post,
            files,
            scheduleDate,
            scheduleTime,
            postStatus,
            timeZone,
            version,
            mediaChange
        )
        if (res instanceof Error) {
            createMessage("danger", res.message)
        } else {
            createMessage("success", "Post successfully updated")
            if (!fromBulkPublish)
                dispatch({ type: UPDATE_CREATED_POST, payload: { post: res } })
            else
                dispatch({
                    type: UPDATE_CREATED_PUBLISH_POST,
                    payload: { post: res },
                })
            dispatch({ type: CLEAR_ALL_SOCIAL_POSTS })
            changePostFilterStatus(postStatusArray?.[postStatus])
            changePublishFilterStatus(postStatusArray?.[postStatus])
            dispatch({ type: REFETCHQUEUE, payload: true })
            setTimeout(() => dispatch({ type: REFETCHQUEUE, payload: false }), 2000)
            clearAllSocialPosts()
            closeSocialPostManager()
        }
    } catch (err) {
        createMessage("danger", "post cant be updated")
    } finally {
        callback()
    }
}

async function updatePostData(post) {
    dispatch({ type: UPDATE_CREATED_POST, payload: { post } })
}

/**
 * @description Reducer to close post manager
 */
function closeSocialPostManager() {
    dispatch({
        type: CLOSE_SOCIAL_POST_MANAGER,
    })
}

/**
 * @description Change option of current state in redux
 * @param {Number} option
 */
function changePostManagerOption(option) {
    dispatch({
        type: CHANGE_POST_MANAGER_OPTION,
        payload: { option },
    })
}

/**
 * @description Update the post in redux
 * @param {Object} post
 */
function updatePostInManager(post) {
    dispatch({
        type: UPDATE_SOCIAL_POST,
        payload: { post },
    })
}

/**
 * @description Select or deselect the channel to post on
 * @param {*} channel
 */
function selectChannelToPostOn(channel) {
    dispatch({
        type: SELECT_CHANNEL_TO_POST_ON,
        payload: { channel: channel.toLowerCase() },
    })
}

/**
 * @description Update Scheduled date of the post
 * @param {*} date
 */
function updatePostScheduledDate(date) {
    dispatch({
        type: UPDATE_POST_SCHEDULED_DATE,
        payload: { date },
    })
}

/**
 * @description Update Scheduled time of the post
 * @param {*} time
 */
function updatePostScheduledTime(time) {
    dispatch({
        type: UPDATE_POST_SCHEDULED_TIME,
        payload: { time },
    })
}

/** POST PAGINATION */
function previousPage() {
    dispatch({ type: PREVIOUS_PAGE })
}

// post reset will call the getPost for the first time
function resetPage(status, apiCall = true) {
    dispatch({ type: RESET_PAGE })
    apiCall && getPosts(1, status)
}

function resetPageNumber() {
    dispatch({ type: RESET_PAGE })
}

function updateApprovalsPage() {
    dispatch({ type: UPDATE_APPROVALS_PAGE })
}

function reorderPosts(posts) {
    dispatch({ type: REORDER_POSTS, payload: { posts } })
}

function updateTimezone(value) {
    dispatch({ type: UPDATE_TIMEZONE, payload: { timeZone: value } })
}

function setLabelFilterStatus(name) {
    dispatch({ type: SET_LABEL_FILTER_STATUS, payload: { name: name } })
}

function openPostDateUpdate() {
    dispatch({ type: OPEN_SCHEDULE_DATE_UPDATE_MODAL })
}

function closePostDateUpdate() {
    dispatch({ type: CLOSE_SCHEDULE_DATE_UPDATE_MODAL })
}

function setUserFilterStatus(num) {
    dispatch({ type: SET_USER_FILTER_STATUS, payload: { name: num } })
}

function setSortFilterStatus(num) {
    dispatch({ type: SET_SORT_FILTER_STATUS, payload: { name: num } })
}

function setSearchFilterStatus(name) {
    dispatch({ type: SET_SEARCH_FILTER_STATUS, payload: { name: name } })
}

function setdateFilterStatus(date) {
    dispatch({ type: SET_DATE_FILTER_STATUS, payload: { date: date } })
}

function setOpenFilter(stat) {
    dispatch({ type: SET_FILTER, payload: { stat: stat } })
}

function mediaChanged() {
    dispatch({ type: MEDIA_CHANGE })
}

function setRowLimit(limit) {
    dispatch({ type: SET_ROW_LIMIT, payload: { limit: limit } })
}

function setOrder(sortBy, orderBy) {
    dispatch({ type: SET_ORDER, payload: { sortBy, orderBy } })
}

/**
 * append new text to existing text.
 * if social is provided then it will update text for only one social.
 * if social is not provided it will update for each social available.
 *
 * @param {*} social social media.
 * @param {*} text text to append.
 */
export const appendPostText = function (social, text) {
    dispatch({
        type: POST_APPEND_TEXT,
        payload: { social, text },
    })
}

export const postFirstComment = async (
    social,
    brandId,
    postId,
    firstComment,
    subType,
    post
) => {
    try {
        let headersData = getHeaders()
        headersData.params = {
            ...headersData.params,
        }
        let payload = {
            brandId: brandId,
            replyId: postId,
            message: firstComment,
            platform: social + "_" + (subType || "user"),
        }
        if (social === "instagram" || social === "facebook") {
            payload = {
                ...payload,
                replyIdType: "POST",
            }
        }
        if (post && post.status !== "published") {
            payload = {
                ...payload,
                replyId: post.postId,
                postId: postId,
            }
        }
        let res = await Axios.post(getCommentsApi(social), payload, headersData)

        if (res.data.code === 200) {
            return res.data.model
        }
        return null
    } catch (err) {
        console.log(err)
        return null
    }
}

export const updateFirstComment = async (social, brandId, postId, firstComment) => {
    try {
        let headersData = getHeaders()
        let { post } = getState().postReducer

        headersData.params = {
            ...headersData.params,
        }
        let res = await Axios.put(
            getCommentsApi(social),
            {
                brandId: brandId,
                postId: postId,
                message: firstComment,
                commentId: Number(post?.commentId),
            },
            headersData
        )

        if (res.data.code === 200) {
            return res.data.model
        }
        return null
    } catch (err) {
        console.log(err)
        return null
    }
}

export const getFirstComment = async (social, brandId, postId) => {
    try {
        let headersData = getHeaders()

        if (!postId) return null

        headersData.params = {
            brandId: brandId,
            postId: postId,
        }
        let res = await Axios.get(getCommentsApi(social), headersData)

        if (res.data.code === 200 && res.data.model.data?.length > 0) {
            changePost("firstComment", {
                [social]: res.data.model.data[0].message,
            })
            changePost("commentId", res.data.model.data[0].id)
            return res.data.model
        }
        return null
    } catch (err) {
        console.log(err)
        return null
    }
}

export const deleteFirstComment = async (social, brandId, postId) => {
    try {
        let headersData = getHeaders()
        let { post } = getState().postReducer

        headersData.params = {
            brandId: brandId,
            postId: postId,
            commentId: post.commentId,
        }
        let res = await Axios.delete(getCommentsApi(social), headersData)

        if (res.data.code === 200) {
            return res.data.model
        }
        return null
    } catch (err) {
        console.log(err)
        return null
    }
}
export const assignValue = async (value) => {
    dispatch({ type: TOKEN_SAVING, payload: value })
}

export {
    openSocialPostManager,
    createPost,
    clearPosts,
    getPosts,
    getPostsByDateNew,
    getAllSocialsPosts,
    clearAllSocialPosts,
    addPost,
    deletePost,
    editPost,
    closeSocialPostManager,
    changePostManagerOption,
    updatePostInManager,
    addFiles,
    shuffleFile,
    deleteFile,
    duplicateFiles,
    selectChannelToView,
    selectKeyToView,
    changePost,
    toggleMinifyLink,
    selectChannelToPostOn,
    updatePostScheduledDate,
    updatePostScheduledTime,
    previousPage,
    updateApprovalsPage,
    resetPage,
    postLoadingEnd,
    reorderPosts,
    updateTimezone,
    changePostMultiSocial,
    setLabelFilterStatus,
    setUserFilterStatus,
    setSortFilterStatus,
    setSearchFilterStatus,
    setdateFilterStatus,
    setOpenFilter,
    mediaChanged,
    openPostDateUpdate,
    closePostDateUpdate,
    updatePostData,
    setRowLimit,
    setOrder,
    resetPageNumber,
}
