import Axios from "axios"
import { createMessage } from "../../../Actions/messageAction"
import { getHeaders } from "../../../Actions/userAction"
import { PINTEREST } from "../../../Data/SocialMedialTypes"
import {
    socialPostsApi,
    socialProfileInfo,
    createBoardsApi,
    baseURL,
} from "../../apis"
import { associateWorkflowWithPost } from "../../../Actions/workflowAction"
import { PINTEREST_USER } from "../../../constants"
import { DRAFT, SCHEDULE } from "../Types/postStatusTypes"
import {
    deleteFirstComment,
    postFirstComment,
    updateFirstComment,
} from "Actions/postAction"

const printStrApi = Axios.create({
    baseURL: socialPostsApi("pinterest"),
})

const accountsApi = Axios.create({
    baseURL: socialProfileInfo("pinterest"),
})

/**
 * request data Object for the printerest
 */
let mockRequestObj = {
    brandId: null,
    text: "",
    label: "",
    enable: 1,
    status: "",
    title: "",
    altText: "",
    boardId: "",
    destinationLink: "",
    version: "",
    mediaContent: [],
    isApprovalRequired: 1,
    timeZone: "",
    created_on: null,
    publishDate: "",
    key: "user",
    platform: "pinterest_user",
}

/**
 * cerate the pin in the pinterest
 * @param {*} data
 * @param {*} config
 * @returns
 */
export const createPrintSter = async (data, config, firstComment) => {
    if (!data) throw new Error("Can not create the printestr pin.")
    const headers = getHeaders()
    const request = { ...mockRequestObj }

    Object.keys(data).forEach((item) => {
        if (request.hasOwnProperty(item)) {
            request[item] = data[item]
        }
    })
    request.platform = PINTEREST_USER

    try {
        const postResult = await printStrApi.post("", request, headers)
        if (postResult.data.code !== 200) {
            return {
                status: "ERROR",
                failureReason: postResult.data.msg,
                platform: PINTEREST_USER,
                data,
            }
        }

        if (postResult.data.code === 200) {
            if (
                (postResult.data.model.status === SCHEDULE ||
                    postResult.data.model.status === DRAFT) &&
                postResult.data.model.isApprovalRequired === 1
            )
                await associateWorkflowWithPost(
                    data.brandId,
                    postResult.data.model.id,
                    PINTEREST_USER
                )

            if (firstComment) {
                postFirstComment(
                    PINTEREST,
                    postResult.data.model.brandId,
                    postResult.data.model.id,
                    firstComment,
                    null,
                    postResult.data.model
                )
            }
        }

        return postResult.data.model
    } catch (error) {
        return {
            status: "ERROR",
            failureReason: "Couldn't create pinterest post.",
            platform: PINTEREST_USER,
            data,
        }
    }
}

/**
 * make an atomic api call with lots of filters and get post array or
 * single posts of  in return.
 *
 * TODO: Do the error handling.
 *
 * @param {*} config { brandId , postId, status, userId, userName }
 *
 * @return if postId is not present then it will return the
 * Array of the posts.
 * or if the postId is present then Single  post will be return.
 */
export const getPintersetPost = async (config) => {
    if (!config.brandId) return

    let headers = getHeaders()

    if (!headers) return

    headers.params = {}

    headers.params.key = "posts"
    headers.params.brandId = config.brandId

    // Add filters
    if (config.status) headers.params.status = config.status
    if (config.page) headers.params.page = config.page
    if (config.limit) headers.params.limit = config.limit
    if (config.postId) headers.params.postId = config.postId
    if (config.userName) headers.params.userName = config.userName
    if (config.sortBy) headers.params.sortBy = config.sortBy
    if (config.id) headers.params.dbId = config.id
    if (config.fromDate) headers.params.fromDate = config.fromDate
    if (config.toDate) headers.params.toDate = config.toDate
    if (config.orderBy) headers.params.orderBy = config.orderBy

    try {
        const result = await printStrApi.get("", headers)
        const data = result.data
        if (data && data.code === 200) {
            let posts = data.model?.map((post) => ({
                ...post,
                path: post.mediaUrl,
            }))
            return posts
        } else return null
    } catch (error) {
        return null
    }
}

export const getPintersetPostById = async (config) => {
    try {
        if (!config.brandId) return

        let headers = getHeaders()
        if (!headers) return

        headers.params = {
            brandId: config.brandId,
            dbId: config.id,
        }

        let response = await printStrApi.get("", headers)

        return response.data.model
    } catch (err) {
        console.log(err)
        return null
    }
}

/**
 * delete the printester post with the brand id and the post id
 * @param {*} brandId brand id:
 * @param {*} id id of the post
 */
export const deletePinterest = async (brandId, id) => {
    const headers = getHeaders()

    headers.body = {
        brandId: brandId,
        id: id,
    }

    try {
        const res = await printStrApi.delete("", headers)
        if (res.data.model) {
            return res.data.model
        }
        return null
    } catch (error) {
        // TODO: Add the globel error hadling event serviec to handle these cases
        return null
    }
}

/**
 * Update the pinterest post with the following object.
 * 
 * 
 * @param {*} data 
 *  * ```
     {
        link: "https://picsum.photos/200/300/?blur",
        title: "pin",
        description: "to save in draft 2nd",
        alt_text: "text",
        status: "publish",
        board_id: "711076297355141301",
        media_source: {
          source_type:"image_url",
          url : "https://picsum.photos/200/300/?blur"
        }
      }
    ```
 * @param {*} config configuration
 */
export const updatePinterest = async (data) => {
    if (!data) throw new Error("Can not update the empty object.")
    const headers = getHeaders()
    const request = { ...data }

    try {
        const response = await printStrApi.put("", request, headers)

        if (response.data.code === 200) {
            if (data.firstComment && data.firstComment[PINTEREST]) {
                let commentFunction = data.commentId
                    ? updateFirstComment
                    : postFirstComment

                await commentFunction(
                    PINTEREST,
                    response.data.model.brandId,
                    response.data.model.id,
                    data.firstComment[PINTEREST],
                    null,
                    response.data.model
                )
            } else if (data.commentId) {
                await deleteFirstComment(
                    PINTEREST,
                    response.data.model.brandId,
                    response.data.model.id
                )
            }
            return response.data.model
        } else {
            return new Error(response.data.msg)
        }
    } catch (error) {
        return new Error("Pinterest post updation Failed.")
    }
}

// Account related apis

/**
 * 
 * get the boards of a prticuar pinterest account.
 * and then returns the list of array
 * 
 * @param {*} brandId brand Id
 * 
 * return   
 * ```      
 * "items": [
            {
                "description": "",
                "id": "711076297355141301",
                "name": "My Saves",
                "owner": {
                    "username": "abhiisharma18335"
                },
                "privacy": "PUBLIC"
            },
            {
                "description": "",
                "id": "711076297355446626",
                "name": "new board",
                "owner": {
                    "username": "abhiisharma18335"
                },
                "privacy": "PUBLIC"
            }
        ]
    ```
 */
export const getPinterestBoards = async (brandId) => {
    const headers = getHeaders()
    headers.params = {
        brandId: brandId,
        key: "boards",
    }

    try {
        const response = await accountsApi.get("", headers)
        const data = response.data

        if (data.code === 200) return data.model?.items
        else return new Error(response.data.msg)
    } catch (error) {
        return new Error("Could not get the boards for the logged in pinterest.")
    }
}

export const editPinterestPostSchedule = async (post) => {
    const headers = getHeaders()
    headers.params = {
        key: PINTEREST,
    }
    const newData = {
        ...post,
    }
    try {
        const res = await printStrApi.put("", newData, headers)

        if (res.data.model) {
            return res.data.model
        }
        createMessage("success", res.data.msg)
        return null
    } catch (err) {
        createMessage("danger", err.response.data)
        return null
    }
}

export const createPinterestBoard = async (brandId, name, description, privacy) => {
    const headers = getHeaders()
    headers.params = {
        brandId: brandId,
    }

    try {
        const response = await Axios.post(
            createBoardsApi,
            {
                name: name,
                description: description,
                privacy: privacy,
            },
            headers
        )
        const data = response.data

        if (data.code === 200) return data.model
        else return new Error(response.data.msg)
    } catch (error) {
        return new Error("Could not create the board for the logged in pinterest.")
    }
}

export const getPinterestPostNew = async (brandId, limit, social) => {
    let headers = getHeaders()
    try {
        let url = `${baseURL}/v2/pinterest/publish/posts?brand_id=${brandId}`
        if (limit) url += `&limit=${limit}`
        if (social) url += `&platform=${social}`
        const result = await Axios.get(url, headers)
        if (result?.data?.code !== 200) return null
        return result.data
    } catch (error) {
        return null
    }
}

export const getPinterestPostNext = async (brandId, next, limit, platform) => {
    let headers = getHeaders()
    try {
        let url = `${baseURL}/v2/pinterest/publish/posts?brand_id=${brandId}&after=${next}`
        if (limit) url += `&limit=${limit}`
        if (platform) url += `&platform=${platform}`
        const result = await Axios.get(url, headers)
        if (result?.data?.code !== 200) return null
        return result.data
    } catch (error) {
        return null
    }
}

export const getPinterestPostByDateNew = async ({
    brandId,
    fromDate,
    toDate,
    platform,
}) => {
    let headers = getHeaders()
    try {
        let url = `${baseURL}/v2/pinterest/publish/posts?brand_id=${brandId}&start_date=${fromDate}&end_date=${toDate}`
        if (platform) url += `&platform=${platform}`
        const result = await Axios.get(url, headers)
        if (result?.data?.code !== 200) return null
        return result.data
    } catch (error) {
        console.log(error)
        createMessage("danger", "Error while getting posts")
        return null
    }
}
