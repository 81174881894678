import { useState, useEffect } from "react"

/**
 * @description Function to make link clickable in a text/commment by converting them to HTML and returning array of links
 * @param {String} text
 * @returns {[HTMLAllCollection, Links[]]}
 */
const parseTextForLinks = (text) => {
    let exp = /(https?:\/\/[^\s]+)/g

    if (!text) return ["", []]

    let links = []
    let htmlText = text.replace(exp, function (url) {
        links.push(url)
        return `<a href="${url}" target="_blank"> ${url}</a>`
    })

    return [htmlText, links]
}

export default parseTextForLinks

export const hashtagParser = (text)=>{
    let exp = /(\#\w+)/g

    if(!text) return ""

    let parsedText = text.replace(exp , `<span class="changeColor">$1</span> `)

    return parsedText
}

/**
 * @description Hook to make link clickable in a text/commment by converting them to HTML and returning array of links
 * @param {String} text
 * @returns {[HTMLAllCollection, Links[]]}
 */
export const useParseTextForLinks = (text) => {
    const [parsedMsg, setParsedMsg] = useState(["", []])

    useEffect(() => {
        setParsedMsg(parseTextForLinks(text))
    }, [text])

    return parsedMsg
}

/**
 * @description Hook to get the msg, files and link structure
 * @example
 * Returns
 * msg: []
 * 1) Msg
 * 2) Msg|||JSON.stringify([{name, preview, mediaType}])
 * 3) Link[]
 *
 * @param {String} msg
 *
 * @return {[HTMLmsg, {NAME,link,type}[], Link[]]}
 */
export const useParseFileMsg = (msg) => {
    const [splittedMsg, setSplittedMsg] = useState(["", [], []])

    useEffect(() => {
        let splittedMsgString = msg.split("|||")
        let [comment, links] = parseTextForLinks(splittedMsgString[0])
        let files = []

        if (splittedMsg[1]) {
            try {
                files = JSON.parse(splittedMsgString[1])
            } catch (err) {}
        }
        setSplittedMsg([comment, files, links])
    }, [msg])

    return splittedMsg
}
