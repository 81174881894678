import Axios from "axios"
import store from "../store"
import {
    signIn,
    getUserDetails,
    organization,
    signUp,
    updateCompany as editCompany,
    updateUser,
    notificationSettingApi,
    getAccessToken,
    logoutApi,
    accountsLink,
} from "../components/apis"
import { createMessage, ViewTrialEnded } from "./messageAction"
import {
    USER_GET_DETAILS,
    USER_LOADING,
    USER_LOGIN,
    USER_LOGOUT,
    USER_STOP_LOADING,
    UPDATE_USER,
    TOGGLE_NOTIFICATION,
    UPDATE_COMPANY,
    ADD_ONBOARDING_WORKSPACE,
    ADD_ONBOARDING_BRAND,
    ADD_ONBOARDING_TEAM,
    GET_ORGANIZATION_DETAILS,
    GET_ORGANIZATION_LOADING,
    GET_ORGANIZATION_FAILED,
    GET_ALL_USERS_IN_ORG_LOADING,
    GET_ALL_USERS_IN_ORGANIZATION,
    GET_ALL_USERS_IN_ORG_FAILED,
    GLOBAL_LOADING_OPEN,
    GLOBAL_LOADING_CLOSE,
    ENABLE_ON_BOARDING,
    DISABLE_ON_BOARDING,
    UPDATE_USER_VISIT,
    SET_NOTIFICATION_SETTINGS,
    SAVE_NOTIFICATION_SETTINGS,
    DETAILS_LOADING,
    USER_ROLE
} from "../ActionTypes/userActionTypes"
import { CLEAR_EVERYTHING as CB } from "../ActionTypes/brandsActionTypes"
import { CLEAR_EVERYTHING as CP } from "../ActionTypes/permissionActionType"
import { CLEAR_EVERYTHING as CR } from "../ActionTypes/roleTypes"
import {
    CLEAR_EVERYTHING as CS,
    SET_ENABLED_FEATURES,
} from "../ActionTypes/socialActionType"
import { CLEAR_EVERYTHING as CW } from "../ActionTypes/workplaceType"
import { ReactComponent as Comment } from "../components/icons/brand-approve.svg"
import { getAllWorkspaces } from "./workplaceAction"
import { getPermissions } from "./permissionAction"
import { getSubscriptionPlans, getSubscriptionStatus } from "./transactionAction"
import { setEnabledFeatures } from "./socialAction"

const { dispatch, getState } = store

function updateUserVisit() {
    dispatch({ type: UPDATE_USER_VISIT })
}

async function loadBasicDetails() {
    const { detailsLoading, token } = getState().userReducer
    if (detailsLoading || !token) return

    dispatch({ type: DETAILS_LOADING, payload: true })

    if (!(window.location.pathname === "/oauthcallback")) {
        globalLoading()
        const data = await Promise.all([
            getSubscriptionStatus(),
            getSignedUserDetails(),
        ])
        let isActive = data[0]
        if (!isActive) {
            globalLoadingClose()
            ViewTrialEnded()
            dispatch({ type: USER_STOP_LOADING })
            return
        }
        if (isActive === -1) {
            globalLoadingClose()
            dispatch({ type: USER_STOP_LOADING })
            return
        }
        await getPermissions()
        getOrganization()
        const companyDetails = await getAllWorkspaces()
        globalLoadingClose()
        dispatch({ type: USER_STOP_LOADING, payload: true })
        return companyDetails
    }
}

export async function reloadCompanyDetails() {
    await getAllWorkspaces([])
}

async function userLogin(data) {
    dispatch({ type: USER_LOADING })
    try {
        const res = await Axios.get(signIn, {
            params: data,
            headers: {
                "Content-Type": "application/json",
            },
        })
        if (res.data.code === 200) {
            localStorage.setItem("auth-token", res.data.model.auth_token)
            dispatch({
                type: USER_LOGIN,
                payload: {
                    user: res.data.model.user,
                    auth_token: res.data.model.auth_token,
                    user_role:res.data.model.organisations[0].role
                },
            })
            globalLoading()
            await loadBasicDetails()
        } else {
            dispatch({ type: USER_STOP_LOADING })
            createMessage("danger", res.data.msg)
        }
    } catch (err) {
        dispatch({ type: USER_STOP_LOADING })
        createMessage("danger", "Failed to Login")
    }
}

async function userLoginWithJustToken(token, accessToken) {
    globalLoading()
    dispatch({ type: USER_LOADING })

    try {
        if (token) {
            dispatch({
                type: USER_LOGIN,
                payload: {
                    auth_token: accessToken,
                    redirectToken: token,
                },
            })
            await loadBasicDetails()
        } else {
            dispatch({ type: USER_STOP_LOADING })
            createMessage("danger", "Failed to login.")
        }
    } catch (err) {
        dispatch({ type: USER_STOP_LOADING })
        createMessage("danger", err.response.data)
    }
}

/**
 *
 * @param {User} user
 * @example```
 * {
 *      id
 *      [first_name], [last_name], [email_id], [picture]
 * }
 * ```
 */
async function updateUserProfile(user) {
    try {
        const res = await Axios.put(updateUser, user, getHeaders())
        if (res.data.model) {
            dispatch({ type: UPDATE_USER, payload: { data: res.data.model } })
            createMessage("success", "User profile updated successfully!")
        } else {
            createMessage("danger", res.data.msg)
        }
    } catch (err) {
        createMessage("danger", err.response)
    }
}

/**
 * this is the simple login handler and logs user without the
 * global loading which allows the
 * @param {*} token
 * @param {*} accessToken
 */
export const loginWithTokenOnly = async (token, accessToken, role) => {
    try {
        if (!token) {
            dispatch({ type: USER_STOP_LOADING })
            createMessage("danger", "Failed to login.")
            return
        }
        localStorage.setItem("auth-token", token)
        dispatch({
            type: USER_LOGIN,
            payload: {
                auth_token: accessToken,
                redirectToken: token,
                role:role
            },
        })
        await setEnabledFeatures()
        await loadBasicDetails()
        dispatch({ type: USER_STOP_LOADING })
    } catch (err) {
        dispatch({ type: USER_STOP_LOADING })
        createMessage("danger", err?.response?.data)
        console.log(err)
    }
}

async function getSignedUserDetails() {
    dispatch({ type: USER_LOADING })
    try {
        const res = await Axios.get(getUserDetails, getHeaders())
        if (res.data.model) {
            dispatch({
                type: USER_GET_DETAILS,
                payload: { user: res.data.model },
            })
            createMessage("success", `Welcome ${res.data.model.first_name}!`)
        } else {
            dispatch({ type: USER_LOGOUT })
            logout()
            // createMessage("danger", res.data.msg)
        }
    } catch (err) {
        dispatch({ type: USER_LOGOUT })
        logout()
    }
}

async function userSignup(body) {
    dispatch({ type: USER_LOADING })
    try {
        const header = {
            "Content-Type": "application/json",
        }

        const res = await Axios.put(signUp, body, header)
        if (res.data.model) {
            localStorage.setItem("auth-token", res.data.model.auth_token)
            dispatch({
                type: USER_LOGIN,
                payload: {
                    user: res.data.model,
                    auth_token: res.data.model.auth_token,
                    user_role: res.data.model.organisations[0].role
                },
            })
            createMessage(
                "success",
                `Hello ${res.data.model.user_name}, Welcome to our World!`
            )
            await loadBasicDetails()
        } else {
            dispatch({ type: USER_STOP_LOADING })
            createMessage("danger", `${res.data.msg}`)
        }
        this.props.history.push("/")
    } catch (err) {
        if (err.response) {
            dispatch({ type: USER_STOP_LOADING })
            this.updateMessage("danger", err.response.data.message_error[0])
        }
    }
}

export async function logoutUser() {
    try {
        const { token } = getState().userReducer
        if(!token) return;
        const resp = await Axios.post(logoutApi, null, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
        if (resp.data.code !== 200) return null

        return resp.data.model
    } catch (err) {
        return null
    }
}

async function logout(noRedirect) {
    logoutUser()
    localStorage.removeItem("auth-token")
    dispatch({ type: USER_LOGOUT })
    dispatch({ type: CP })
    dispatch({ type: CR })
    dispatch({ type: CW })
    dispatch({ type: CS })
    dispatch({ type: CB })
    //todo to redirect change done!
    if (!noRedirect) window.location.replace("/login?logout=true")
}

function toggleNotification(isOpen) {
    dispatch({ type: TOGGLE_NOTIFICATION, payload: { isOpen } })
}

async function updateCompany(user) {
    try {
        const res = await Axios.put(
            editCompany,
            {
                id: user.id,
                name: user.name,
                logo: user.logo,
                largeLogo: user.largeLogo,
            },
            {
                ...getHeaders(),
            }
        )
        if (res.data.model) {
            dispatch({
                type: UPDATE_COMPANY,
                payload: { user },
            })
            createMessage("success", `Company successfully Updated!`)
            return true
        } else {
            createMessage("danger", res.data.msg)
        }
        return false
    } catch (err) {
        if (err.response) {
            createMessage("danger", err.response.data)
        }
        return false
    }
}

export const saveWorkspaceForOnBoarding = (data) => {
    dispatch({ type: ADD_ONBOARDING_WORKSPACE, payload: data })
}

export const saveBrandForOnBoarding = (data) => {
    dispatch({ type: ADD_ONBOARDING_BRAND, payload: data })
}

/**
 * Api to get one organization 
 * in which user is currently active
 * 
 * @example
 * {
    "user_name":"MKUMAR",
    "first_name":"MANISH",
    "last_name":"KUMAR",
    "email_id":"manish.kumwat@gmail.com",
    "phone_number":"9001298001",
    "picture":"",
    "workspace_id":1,
    "brand_id": 1
  }
 */
export const addTeamToBrand = async (name, email, workspace_id, brand_id) => {
    const data = {
        user_name: name,
        first_name: name,
        password: "12345",
        last_name: name,
        email_id: email,
        phone_number: "Not-Available",
        workspace_id: workspace_id,
        brand_id: brand_id,
    }

    const token = localStorage.getItem("auth-token")
    const result = await getUserDetails.post("/user", data, getHeaders())

    if (result.data.code === 200) {
        dispatch({ type: ADD_ONBOARDING_TEAM, payload: result.data.model })
    }
}

/**
 * Api to get one organization 
 * in which user is currently active
 * 
 * @example
 * {
      "id": 1,
      "name": "FCUBE",
      "contactEmail": "email@fcure.com",
      "contactNumber": "9001708601",
      "logo": "",
      "enable": 1
    }
 */
export const getOrganization = async () => {
    try {
        const { token } = getState().userReducer
        if (token) {
            dispatch({ type: GET_ORGANIZATION_LOADING })
            const res = await Axios.get(organization, {
                ...getHeaders(),
            })
            if (res.data.model) {
                dispatch({
                    type: GET_ORGANIZATION_DETAILS,
                    payload: { data: res.data.model },
                })
                return res.data.model
            } else createMessage("danger", res.data.msg)
        }
    } catch (err) {
        if (err.response) {
            dispatch({ type: GET_ORGANIZATION_FAILED })
            createMessage("danger", err.response.data)
        }
    }
}

/**
 * Api to all the user's connected with the organization
 * 
 * @example 
 * {
    "organisationName": "FCUBE",
    "contactEmail": "manishkp220@fcure.com",
    "contactNumber": "9001708601",
    "logo": "",
    "users": [
        {
            "id": 12,
            "user_name": "pkpanery",
            "first_name": "manish",
            "last_name": "panery",
            "email_id": "manishk22120@gmail.com",
            "phone_number": "9001708601",
            "picture": "https://learnerimagesbucket.s3.amazonaws.com/hacker.png",
            "verified_email": 0,
            "enable": 1
        }]
  } 
 */
export const getAllUsersInOrganization = async () => {
    try {
        const { token, organization: org } = getState().userReducer
        const hearders = getHeaders()
        if (hearders) {
            dispatch({ type: GET_ALL_USERS_IN_ORG_LOADING })
            const res = await Axios.get(`${organization}?org_id=${org.id}`, hearders)
            if (res.data.model) {
                dispatch({
                    type: GET_ALL_USERS_IN_ORGANIZATION,
                    payload: { data: res.data.model.users },
                })
            } else createMessage("danger", res.data.msg)
        }
    } catch (err) {
        if (err.response) {
            dispatch({ type: GET_ALL_USERS_IN_ORG_FAILED })
            createMessage("danger", err.response.data)
        }
    }
}

function getOrg() {
    const { organization: org } = getState().userReducer
    return org
}

/**
 * open the global loading with  this method call
 */
export const globalLoading = () => {
    dispatch({ type: GLOBAL_LOADING_OPEN })
}

/**
 * Close the global loading with  this method call
 */
export const globalLoadingClose = () => {
    dispatch({ type: GLOBAL_LOADING_CLOSE })
}

/**
 * gives the headers
 */
export const getHeaders = () => {
    const { token } = getState().userReducer
    let authLocal = localStorage.getItem("auth-token")
    if (!token && !authLocal) return null
    return {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token ? token : authLocal}`,
        },
    }
}

export const getUser = () => {
    const { user } = getState().userReducer
    return user
}

/**
 * we need to handle the dashboard redirection
 * on login and let user to see the onboarding page.
 */
export const startOnboarding = () => {
    dispatch({ type: ENABLE_ON_BOARDING })
}

/**
 * Close the onboarding flag so that user can redirect to the dashboard or brand
 */
export const completeOnBoarding = () => {
    dispatch({ type: DISABLE_ON_BOARDING })
}

async function getNotificationSettings(workspace_id, callback = () => {}) {
    try {
        const res = await Axios.get(notificationSettingApi, {
            params: { workspace_id },
            ...getHeaders(),
        })
        if (res.data.code === 200) {
            const notifications = res.data.model.map(
                ({ id, config_id, email, dashboard, workspaceId }) => ({
                    id,
                    email,
                    dashboard,
                    name: config_id.name,
                    description: config_id.description,
                    Icon: Comment,
                    workspaceId,
                })
            )

            dispatch({ type: SET_NOTIFICATION_SETTINGS, payload: notifications })
        } else {
            createMessage("danger", res.data.msg)
        }
    } catch (err) {
        createMessage("danger", "Can't fetch notification settings!")
    } finally {
        callback()
    }
}

async function saveNotificationSettings(settings, successCallback, failureCallback) {
    try {
        const res = await Axios.put(notificationSettingApi, settings, {
            // params: { workspaceId:1, action: "type" },
            ...getHeaders(),
        })
        if (res.data.code === 200) {
            dispatch({ type: SAVE_NOTIFICATION_SETTINGS, payload: res.data.model })
            successCallback()
        } else {
            createMessage("danger", res.data.msg)
            failureCallback()
        }
    } catch (err) {
        createMessage("danger", "Can't update notification settings!")
        failureCallback()
    }
}

export async function refreshToken() {
    try {
        globalLoading()
        const res = await Axios.get(getAccessToken, getHeaders())
        if (res.data.code === 200) {
            let token = res.data.model.auth_token
            await loginWithTokenOnly(token, token)
            globalLoadingClose()
        } else {
            globalLoadingClose()
            logout()
            window.location.replace(
                `${accountsLink}/login?service=social&rd=${window.location.origin}&d=101`
            )
        }
        let role =res.data.model.organisations[0].role
        dispatch({type:USER_ROLE , payload:role})
    } catch (err) {
        globalLoadingClose()
        return null
    }
}

export {
    updateUserVisit,
    userLogin,
    updateUserProfile,
    getSignedUserDetails,
    userSignup,
    loadBasicDetails,
    logout,
    toggleNotification,
    userLoginWithJustToken,
    updateCompany,
    getNotificationSettings,
    saveNotificationSettings,
    getSubscriptionPlans,
    getOrg,
}
