import React, { useState, useRef } from "react"
// import { ReactComponent as UploadArrowIcon } from "Assets/Dashboard/file.svg"
import { ReactComponent as ComputerIcon } from "../../../icons/Computer.svg"
// import mediaHandler from "../../Library/mediaHandler/mediaHandler"
import { DrivePicker, DropBoxPicker } from "../../../../Data/MediaLiberary"
// import SignLoader from "Awesome-ui/SignLoader"
import FileUploader from "../../FileUploader/FileUploader"
import { mediaApi } from "../../../apis"
import { LoaderWithText } from "@awesomesuite-frontend/awesome-nebula"
import "./FileUploader.scss"
import { connect } from "react-redux"

const FileUploaderTab = ({
    setUploads,
    setDrive,
    setDropbox,
    companyId,
    invokeByLinkedin,
    postType,
    globalToken
}) => {
    const inputFile = useRef(null)
    const [loading, setLoading] = useState(false)

    return (
        <div className={`upload-files-wrapper`}>
            <div className="file-uploader">
                <FileUploader
                    mediaApi={mediaApi}
                    addFiles={setUploads}
                    triggerRef={inputFile}
                    currentCompanyId={companyId}
                    setLoading={setLoading}
                    invokeByLinkedin={invokeByLinkedin}
                    postType={postType}
                />
                <div className="other-file-picking-methods">
                    <div className="media-icon-wrapper">
                        <div
                            className="media-icon"
                            onClick={() => {
                                if (inputFile.current) inputFile.current.click()
                            }}
                        >
                            <span>
                                <ComputerIcon />
                            </span>
                            <span>Computer</span>
                        </div>
                        Max Size: 20mb
                    </div>

                    <div className="media-icon">
                        <DrivePicker
                            getFileHandler={setDrive}
                            companyId={companyId}
                            invokeByLinkedin={invokeByLinkedin}
                            postType={postType}
                            globalToken={globalToken}
                            setLoading={setLoading}
                        />
                    </div>
                    <div className="media-icon">
                        <DropBoxPicker
                            getFileHandler={setDropbox}
                            invokeByLinkedin={invokeByLinkedin}
                            postType={postType}
                            setLoading={setLoading}
                        />
                    </div>
                </div>
                {loading && (
                    <div className="loaderWrapper">
                        <LoaderWithText
                            size={"1.5rem"}
                            borderWidth={"4px"}
                            interval={"1s"}
                            text={"Uploading Media..."}
                            color={"white"}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}
const mapStateToProps = ({ postReducer }) => ({
    globalToken: postReducer.token
})

export default connect(mapStateToProps)(FileUploaderTab)
