import React, { useEffect } from "react"
import axios from "axios"
import { loginWithTokenOnly, logout } from "../../../Actions/userAction"
import { accountsLink, getAccessToken } from "../../apis"
import { connect } from "react-redux"
import SocialAnimation from "components/Templates/SocialAnimation/SocialAnimation"

const RedirectSignIn = React.memo(
    ({ params, redirectToken, history, noSubscription }) => {
        localStorage.setItem("firstVisit", "true")

        useEffect(() => {
            async function handleRedirectLogin() {
                let isLogout = params.get("logout") === "true"
                const token = params.get("p")
                let accLink = `${accountsLink}/login?service=social&rd=${window.location.origin}`

                if (isLogout) accLink = accLink.replace("login", "logout")

                if (token && redirectToken && token === redirectToken) {
                    return
                }

                if (!token) {
                    logout()
                    window.location.replace(accLink)
                    return
                }

                const res = await axios.get(getAccessToken, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                })

                if (res.data.code !== 200) {
                    if (!isLogout) accLink = accLink.replace("login", "logout")
                    setTimeout(() => {
                        window.location.replace(accLink)
                    }, 2000)
                    return
                }

                logout(true)
                localStorage.removeItem("auth-token")
                localStorage.setItem("auth-token", res.data.model.auth_token)
                let org = res.data.model.organisations?.length ? res.data.model.organisations[0] : {}
                await loginWithTokenOnly(token, res.data.model.auth_token , org.role)
                if (noSubscription) {
                    return history.push("/getting-started")
                }
                if (
                    params.get("onboard") &&
                    window.location.pathname.includes("/login")
                ) {
                    return history.push("/onboarding/createWorkspace")
                }
                if (
                    !window.location.pathname.includes("/getting-started") &&
                    !window.location.pathname.includes("/onboarding/createWorkspace")
                ) {
                    return history.push("/")
                }
            }
            handleRedirectLogin()
        }, [])

        return <SocialAnimation />
    },
    areEqual
)

function areEqual(prevprops, nextProps) {
    return false
}

let mapStateToProps = ({ userReducer }) => ({
    redirectToken: userReducer.redirectToken,
    noSubscription: userReducer.noSubscription,
})

export default connect(mapStateToProps)(RedirectSignIn)
