import {
    SET_COMMENTS,
    SET_CURRENT_COMMENT_ID,
    COMMENT_TO,
    DELETE_COMMENT_TO,
    ALTER_STACK,
    SET_SELECTED_ITEM,
    SET_ACTIVE_OPTION,
    COMMENTS_LOADING,
    REPLY_LOADING,
    COMMENTS_POSTING,
    CLEAR_COMMENT,
    SET_DMS,
    SET_DMS_MESSAGES,
    START_DM_MESSAGE_CHANNEL,
    CLEAR_DM_MESSAGE_CHANNEL,
    SET_CHATS_LOADING,
    SET_DM_INTERRUPT,
    SET_DMS_LOADING,
    SET_SELECTED_ITEM_ID,
} from "ActionTypes/engageActionTypes"
import { POP, PUSH, CLEAR } from "../constants"

const initialState = {
    currentCommentId: null,
    commentStack: [],
    comments: {},
    dmsList: [],
    messages: [],
    activeOption: "Messages",
    selectedItem: null,
    loading: false,
    commentPosting: false,
    dmsLoading: false,

    pollerRef: null,
    interrupt: [],
    chatsLoading: false,
}

const engageReducer = (state = initialState, action) => {
    try {
        switch (action.type) {
            case SET_COMMENTS:
                return setComments(state, action.payload)
            case SET_CURRENT_COMMENT_ID:
                return setCurrentCommentId(state, action.payload)
            case COMMENT_TO:
                return setCurrentCommentTo(state, action.payload)
            case DELETE_COMMENT_TO:
                return deleteCurrentCommentTo(state, action.payload)
            case ALTER_STACK:
                return alterStack(state, action.payload)
            case SET_SELECTED_ITEM:
                return setSelectedItem(state, action.payload)
            case SET_SELECTED_ITEM_ID:
                return setSelectedItemId(state, action.payload)
            case SET_ACTIVE_OPTION:
                return setActiveOption(state, action.payload)
            case COMMENTS_LOADING:
                return setCommentsLoading(state, action.payload)
            case REPLY_LOADING:
                return setReplyLoading(state, action.payload)
            case COMMENTS_POSTING:
                return setCommentsPosting(state, action.payload)
            case CLEAR_COMMENT:
                return clearComment(state, action.payload)
            case SET_DMS:
                return setDms(state, action.payload)
            case SET_DMS_MESSAGES:
                return setDmsMessages(state, action.payload)
            case START_DM_MESSAGE_CHANNEL:
                return startDmMessageChannel(state, action.payload)
            case CLEAR_DM_MESSAGE_CHANNEL:
                return clearDmMessageChannel(state, action.payload)
            case SET_CHATS_LOADING:
                return setChatsLoading(state, action.payload)
            case SET_DM_INTERRUPT:
                return setDmInterrupt(state, action.payload)
            case SET_DMS_LOADING:
                return setDmsLoading(state, action.payload)
            default:
                return state
        }
    } catch (err) {
        console.log(err)
    }
}

const setDmsLoading = (state, payload) => {
    return {
        ...state,
        dmsLoading: payload,
    }
}

const setDmInterrupt = (state, payload) => {
    const newInterrupts = state.interrupt.filter(
        (item) => !payload.items.includes(item.conversationId)
    )
    return {
        ...state,
        interrupt: newInterrupts,
    }
}

const setChatsLoading = (state, payload) => {
    return {
        ...state,
        chatsLoading: payload,
    }
}

const clearDmMessageChannel = (state, payload) => {
    clearInterval(state.pollerRef)
    return {
        ...state,
        messages: [],
        pollerRef: null,
        dmsList: state.dmsList,
    }
}

const startDmMessageChannel = (state, payload) => {
    return {
        ...state,
        pollerRef: payload.poller,
        interrupt: [
            ...state.interrupt,
            {
                conversationId: payload.conversationId,
                intervalRef: payload.poller,
            },
        ],
    }
}

const setDms = (state, payload) => {
    const newState = {
        ...state,
        dmsList: payload,
    }
    return newState
}

const setDmsMessages = (state, payload) => {
    const newState = {
        ...state,
        messages: payload,
    }
    return newState
}

const setComments = (state, payload) => {
    const newState = {
        ...state,
        comments: {
            ...state.comments,
            [payload.replyId]: payload.comments,
        },
    }
    return newState
}

const setCurrentCommentId = (state, payload) => {
    const newState = {
        ...state,
        currentCommentId: payload,
    }

    return newState
}

const setCurrentCommentTo = (state, payload) => {
    const newState = {
        ...state,
        currentCommentTo: payload,
    }
    return newState
}

const deleteCurrentCommentTo = (state, payload) => {
    const newState = {
        ...state,
        currentCommentTo: null,
        currentCommentId: payload?.id
    }
    return newState
}

const setSelectedItem = (state, payload) => {
    const newState = {
        ...state,
        selectedItem: payload,
    }

    return newState
}

const setSelectedItemId = (state, payload) => {
    const newState = {
        ...state,
        selectedItemId: payload,
    }

    return newState
}

const setActiveOption = (state, payload) => {
    const newState = {
        ...state,
        activeOption: payload,
    }

    return newState
}

const setCommentsLoading = (state, payload) => {
    const newState = {
        ...state,
        loading: payload,
    }

    return newState
}

const setReplyLoading = (state, payload) => {
    const newState = {
        ...state,
        replyLoading: payload,
    }

    return newState
}

const setCommentsPosting = (state, payload) => {
    const newState = {
        ...state,
        commentPosting: payload,
    }

    return newState
}

const clearComment = (state, { commentId, level }) => {
    let comments = { ...state.comments }
    if (!level) {
        let x = comments[state.commentStack[0]]
        x = x.filter((comment) => comment.id !== commentId)
        comments[state.commentStack[0]] = x;
    }
    else {
        let y = comments[state.commentStack[state.commentStack.length - 1]]
        y = y.filter((comment) => comment.id !== commentId)
        comments[state.commentStack[state.commentStack.length - 1]] = y;
    }

    const newState = {
        ...state,
        comments: comments,
    }

    return newState
}

const clearCommentsComment = (state, payload) => {
    const newState = {
        ...state,
        comments: {
            ...state.comments,
        },
    }
    newState.comments[newState.currentCommentId].find(item => !!item.replies.find(reply => reply.id === payload)).replies = state.comments[state.currentCommentId]
        .map(item => (item.replies.filter(reply => reply.id !== payload)))[0]
    return newState
}

const alterStack = (state, payload) => {
    let newState = state
    let oldStack = state.commentStack
    let comments = { ...state.comments }
    if (payload.operation === POP && oldStack.length > 0) {
        oldStack.pop()
        delete comments[oldStack[oldStack.length - 1]]
        newState = {
            ...state,
            currentCommentId:
                oldStack.length > 0 ? oldStack[oldStack.length - 1] : null,
            commentStack: oldStack,
        }
    } else if (payload.operation === PUSH) {
        oldStack.push(payload.commentId)
        newState = {
            ...state,
            currentCommentId: payload.commentId,
            commentStack: oldStack,
        }
    } else if (payload.operation === CLEAR) {
        oldStack = []
        newState = {
            ...state,
            currentCommentId: null,
            commentStack: oldStack,
        }
    }
    return newState
}

export default engageReducer
