import { createStore, applyMiddleware, compose } from "redux"
import reducer from "./Reducers"
// import logger from "redux-logger"
import { persistStore } from "redux-persist"

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const middlewares = []
// const middlewares = [logger]

const store = createStore(reducer, composeEnhancers(applyMiddleware(...middlewares)))

export const persister = persistStore(store)

export default store
