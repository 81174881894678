export const timezone = [
    "(GMT-12:00) International Date Line West",
    "(GMT-11:00) Midway Island, Samoa",
    "(GMT-10:00) Hawaii",
    "(GMT-09:00) Alaska",
    "(GMT-08:00) Pacific Time (US & Canada)",
    "(GMT-08:00) Tijuana, Baja California",
    "(GMT-07:00) Arizona",
    "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
    "(GMT-07:00) Mountain Time (US & Canada)",
    "(GMT-06:00) Central America",
    "(GMT-06:00) Central Time (US & Canada)",
    "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
    "(GMT-06:00) Saskatchewan",
    "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
    "(GMT-05:00) Eastern Time (US & Canada)",
    "(GMT-05:00) Indiana (East)",
    "(GMT-04:00) Atlantic Time (Canada)",
    "(GMT-04:00) Caracas, La Paz",
    "(GMT-04:00) Manaus",
    "(GMT-04:00) Santiago",
    "(GMT-03:30) Newfoundland",
    "(GMT-03:00) Brasilia",
    "(GMT-03:00) Buenos Aires, Georgetown",
    "(GMT-03:00) Greenland",
    "(GMT-03:00) Montevideo",
    "(GMT-02:00) Mid-Atlantic",
    "(GMT-01:00) Cape Verde Is.",
    "(GMT-01:00) Azores",
    "(GMT+00:00) Casablanca, Monrovia, Reykjavik",
    "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
    "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
    "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    "(GMT+01:00) West Central Africa",
    "(GMT+02:00) Amman",
    "(GMT+02:00) Athens, Bucharest, Istanbul",
    "(GMT+02:00) Beirut",
    "(GMT+02:00) Cairo",
    "(GMT+02:00) Harare, Pretoria",
    "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    "(GMT+02:00) Jerusalem",
    "(GMT+02:00) Minsk",
    "(GMT+02:00) Windhoek",
    "(GMT+03:00) Kuwait, Riyadh, Baghdad",
    "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
    "(GMT+03:00) Nairobi",
    "(GMT+03:00) Tbilisi",
    "(GMT+03:30) Tehran",
    "(GMT+04:00) Abu Dhabi, Muscat",
    "(GMT+04:00) Baku",
    "(GMT+04:00) Yerevan",
    "(GMT+04:30) Kabul",
    "(GMT+05:00) Yekaterinburg",
    "(GMT+05:00) Islamabad, Karachi, Tashkent",
    "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    "(GMT+05:30) Sri Jayawardenapura",
    "(GMT+05:45) Kathmandu",
    "(GMT+06:00) Almaty, Novosibirsk",
    "(GMT+06:00) Astana, Dhaka",
    "(GMT+06:30) Yangon (Rangoon)",
    "(GMT+07:00) Bangkok, Hanoi, Jakarta",
    "(GMT+07:00) Krasnoyarsk",
    "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    "(GMT+08:00) Kuala Lumpur, Singapore",
    "(GMT+08:00) Irkutsk, Ulaan Bataar",
    "(GMT+08:00) Perth",
    "(GMT+08:00) Taipei",
    "(GMT+09:00) Osaka, Sapporo, Tokyo",
    "(GMT+09:00) Seoul",
    "(GMT+09:00) Yakutsk",
    "(GMT+09:30) Adelaide",
    "(GMT+09:30) Darwin",
    "(GMT+10:00) Brisbane",
    "(GMT+10:00) Canberra, Melbourne, Sydney",
    "(GMT+10:00) Hobart",
    "(GMT+10:00) Guam, Port Moresby",
    "(GMT+10:00) Vladivostok",
    "(GMT+11:00) Magadan, Solomon Is., New Caledonia",
    "(GMT+12:00) Auckland, Wellington",
    "(GMT+12:00) Fiji, Kamchatka, Marshall Is.",
    "(GMT+13:00) Nuku'alofa",
]
export const oldTimezone = [
    "Anchorage - Alaska Time  (GMT-08:00)",
    "Atikokan - Eastern Standard Time(GMT-05:00)",
    "Araguaina - Brasilia Standard Time (GMT-03:00)",
    "Abidjan - Greenwich Mean Time (GMT+00:00)",
    "Accra - Greenwich Mean Time (GMT+00:00)",
    "Amsterdam - Central European Time (GMT+02:00)",
    "Andorra - Central European Time (GMT+02:00)",
    "Amman - Eastern European Time (GMT+03:00)",
    "Athens - Eastern European Time (GMT+03:00)",
    "Aqtau - West Kazakhstan Time (GMT+05:00)",
    "Aqtobe - West Kazakhstan Time (GMT+05:00)",
    "Atyrau - West Kazakhstan Time (GMT+05:00)",
    "Almaty - East Kazakhstan Time (GMT+06:00)",
    "Australian Brisbane (GMT+10:00)",
    "Australian Lindeman (GMT+10:00)",
    "Adelaide - Central Australia Time (GMT+10:30)",
    "Belize - Central Standard Time (GMT-06:00)",
    "Boise - Mountain Time(GMT-06:00)",
    "Barbados - Atlantic Standard Time(GMT-04:00)",
    "Blanc-Sablon - Atlantic Standard Time(GMT-04:00)",
    "Bermuda - Atlantic Time (GMT-03:00)",
    "Bahia - Brasilia Standard Time (GMT-03:00)",
    "Belem - Brasilia Standard Time (GMT-03:00)",
    "Bissau - Greenwich Mean Time (GMT+00:00)",
    "Belgrade - Central European Time (GMT+02:00)",
    "Berlin - Central European Time (GMT+02:00)",
    "Brussels - Central European Time (GMT+02:00)",
    "Budapest - Central European Time (GMT+02:00)",
    "Baghdad - Arabian Standard Time (GMT+03:00)",
    "Beirut - Eastern European Time (GMT+03:00)",
    "Bucharest - Eastern European Time (GMT+03:00)",
    "Bangkok - Indochina Time (GMT+07:00)",
    "Chihuahua - Mexican Pacific Time (GMT-07:00)",
    "Creston - Mountain Standard Time (GMT-07:00)",
    "Chicago - Central Time(GMT-05:00)",
    "Cancun - Eastern Standard Time(GMT-05:00)",
    "Cuiaba - Amazon Standard Time (GMT-04:00)",
    "Catamarca - Argentina Standard Time (GMT-03:00)",
    "Cordoba - Argentina Standard Time (GMT-03:00)",
    "Central European Standard Time - Algiers (GMT+01:00)",
    "Central European Standard Time - Tunis (GMT+01:00)",
    "Canary - Western European Time  (GMT+01:00)",
    "Ceuta - Central European Time (GMT+02:00)",
    "Copenhagen - Central European Time (GMT+02:00)",
    "Cairo - Eastern European Standard Time (GMT+02:00)",
    "Chisinau - Eastern European Time (GMT+03:00)",
    "Colombo - India Standard Time (GMT+05:30)",
    "Choibalsan - Ulaanbaatar Standard Time(GMT+08:00)",
    "Chita - Yakutsk Standard Time (GMT+09:00)",
    "Denver - Mountain Time(GMT-06:00)",
    "Detroit - Eastern Time(GMT-04:00)",
    "Danmarkshavn - Greenwich Mean Time (GMT+00:00)",
    "Damascus - Eastern European Time (GMT+03:00)",
    "Edmonton - Mountain Time(GMT-06:00)",
    "Eirunepe - Acre Standard Time (GMT-05:00)",
    "Fortaleza - Brasilia Standard Time (GMT-03:00)",
    "Faroe  - Western European Time (GMT+01:00)",
    "Guatemala - Central Standard Time (GMT-06:00)",
    "Gibraltar - Central European Time (GMT+02:00)",
    "Gaza - Eastern European Time (GMT+03:00)",
    "Hawaii-Aleutian Standard Time (GMT-10:00)",
    "Hawaii (Adak) -Aleutian Time  (GMT-09:00)",
    "Halifax - Atlantic Time (GMT-03:00)",
    "Hebron - Eastern European Time (GMT+03:00)",
    "Helsinki - Eastern European Time (GMT+03:00)",
    "Hobart - Eastern Australia Time (GMT+11:00)",
    "Inuvik - Mountain Time(GMT-06:00)",
    "Indianapolis - Eastern Time(GMT-04:00)",
    "Iqaluit - Eastern Time(GMT-04:00)",
    "Juneau - Alaska Time  (GMT-08:00)",
    "Jamaica - Eastern Standard Time(GMT-05:00)",
    "Jujuy - Argentina Standard Time (GMT-03:00)",
    "Juba - Central Africa Time (GMT+02:00)",
    "Jakarta - Western Indonesia Time (GMT+07:00)",
    "Khartoum - Central Africa Time (GMT+02:00)",
    "Kaliningrad - Eastern European Standard Time (GMT+02:00)",
    "Kiev - Eastern European Time (GMT+03:00)",
    "Kolkata - India Standard Time (GMT+05:30)",
    "Krasnoyarsk - Krasnoyarsk Standard Time (GMT+07:00)",
    "Kuching - Malaysia Time (GMT+08:00)",
    "Khandyga - Yakutsk Standard Time (GMT+09:00)",
    "Kwajalein - Marshall Islands Time (GMT+12:00)",
    "Louisville - Eastern Time(GMT-04:00)",
    "Lagos - West Africa Standard Time (GMT+01:00)",
    "Lisbon - Western European Time  (GMT+01:00)",
    "Luxembourg - Central European Time (GMT+02:00)",
    "Metlakatla - Alaska Time  (GMT-08:00)",
    "Mazatlan  - Mexican Pacific Time(GMT-07:00)",
    "Managua - Central Standard Time (GMT-06:00)",
    "Matamoros - Central Time(GMT-05:00)",
    "Menominee - Central Time(GMT-05:00)",
    "Manaus - Amazon Standard Time (GMT-04:00)",
    "Martinique - Atlantic Standard Time(GMT-04:00)",
    "Mendoza - Argentina Standard Time (GMT-03:00)",
    "Moncton - Atlantic Time (GMT-03:00)",
    "Maceio - Brasilia Standard Time (GMT-03:00)",
    "Monrovia - Greenwich Mean Time (GMT+00:00)",
    "Madeira - Western European Time (GMT+01:00)",
    "Maputo - Central Africa Time (GMT+02:00)",
    "Madrid - Central European Time (GMT+02:00)",
    "Malta - Central European Time (GMT+02:00)",
    "Monaco - Central European Time (GMT+02:00)",
    "Minsk - Moscow Standard Time (GMT+03:00)",
    "Moscow - Moscow Standard Time (GMT+03:00)",
    "Macao - China Standard Time (GMT+08:00)",
    "Macquarie - Eastern Australia Time (GMT+11:00)",
    "Melbourne - Eastern Australia Time (GMT+11:00)",
    "Majuro - Marshall Islands Time (GMT+12:00)",
    "Nome - Alaska Time  (GMT-08:00)",
    "Nassau - Eastern Time(GMT-04:00)",
    "Nipigon - Eastern Time(GMT-04:00)",
    "Ndjamena - West Africa Standard Time (GMT+01:00)",
    "Nicosia - Eastern European Time (GMT+03:00)",
    "Novokuznetsk - Krasnoyarsk Standard Time (GMT+07:00)",
    "Ojinaga - Mountain Time(GMT-06:00)",
    "Oslo - Central European Time (GMT+02:00)",
    "Oral - West Kazakhstan Time (GMT+05:00)",
    "Phoenix - Mountain Standard Time (GMT-07:00)",
    "Panama - Eastern Standard Time(GMT-05:00)",
    "Pangnirtung - Eastern Time(GMT-04:00)",
    "Port-au-Prince - Eastern Time(GMT-04:00)",
    "Paris - Central European Time (GMT+02:00)",
    "Prague - Central European Time (GMT+02:00)",
    "Pontianak - Western Indonesia Time (GMT+07:00)",
    "Pyongyang - Korean Standard Time (GMT+09:00)",
    "Qatar - Arabian Standard Time (GMT+03:00)",
    "Qyzylorda - West Kazakhstan Time (GMT+05:00)",
    "Regina - Central Standard Time (GMT-06:00)",
    "Resolute - Central Time(GMT-05:00)",
    "Recife - Brasilia Standard Time (GMT-03:00)",
    "Reykjavik - Greenwich Mean Time (GMT+00:00)",
    "Rome - Central European Time (GMT+02:00)",
    "Riyadh - Arabian Standard Time (GMT+03:00)",
    "Riga - Eastern European Time (GMT+03:00)",
    "Sitka - Alaska Time  (GMT-08:00)",
    "Salta - Argentina Standard Time (GMT-03:00)",
    "Santarem - Brasilia Standard Time (GMT-03:00)",
    "Stockholm - Central European Time(GMT+02:00)",
    "Sofia - Eastern European Time (GMT+03:00)",
    "Simferopol - Moscow Standard Time (GMT+03:00)",
    "Samarkand - Uzbekistan Standard Time (GMT+05:00)",
    "Shanghai - China Standard Time (GMT+08:00)",
    "Seoul - Korean Standard Time (GMT+09:00)",
    "Sydney - Eastern Australia Time (GMT+11:00)",
    "Tijuana - Pacific Time (GMT-07:00)",
    "Tegucigalpa - Central Standard Time (GMT-06:00)",
    "Toronto - Eastern Time(GMT-04:00)",
    "Tucuman - Argentina Standard Time (GMT-03:00)",
    "Thule - Atlantic Time (GMT-03:00)",
    "Tirane - Central European Time (GMT+02:00)",
    "Tripoli - Eastern European Standard Time (GMT+02:00)",
    "Tallinn - Eastern European Time (GMT+03:00)",
    "Tashkent - Uzbekistan Standard Time (GMT+05:00)",
    "Ushuaia - Argentina Standard Time  (GMT-03:00)",
    "Ulaanbaatar - Ulaanbaatar Standard Time (GMT+08:00)",
    "Ust-Nera - Vladivostok Standard Time (GMT+10:00)",
    "Vancouver - Pacific Time (GMT-07:00)",
    "Vienna - Central European Time (GMT+02:00)",
    "Vilnius - Eastern European Time (GMT+03:00)",
    "Vladivostok - Vladivostok Standard Time (GMT+10:00)",
    "Winnipeg - Central Time(GMT-05:00)",
    "Windhoek - Central Africa Time (GMT+02:00)",
    "Warsaw - Central European Time (GMT+02:00)",
    "Yakutat - Alaska Time  (GMT-08:00)",
    "Yellowknife - Mountain Time(GMT-06:00)",
    "Yakutsk - Yakutsk Standard Time (GMT+09:00)",
    "Zurich - Central European Time (GMT+02:00)",
    "Zaporozhye - Eastern European Time (GMT+03:00)",
]

export const updatedTimeZones = [
    { name: "GMT+02:00 Africa/Cairo", value: "Africa/Cairo" },
    { name: "GMT+01:00 Africa/Casablanca", value: "Africa/Casablanca" },
    { name: "GMT+02:00 Africa/Johannesburg", value: "Africa/Johannesburg" },
    { name: "GMT+02:00 Africa/Khartoum", value: "Africa/Khartoum" },
    { name: "GMT+01:00 Africa/Lagos", value: "Africa/Lagos" },
    { name: "GMT+03:00 Africa/Nairobi", value: "Africa/Nairobi" },
    { name: "GMT+00:00 Africa/Sao_Tome", value: "Africa/Sao_Tome" },
    { name: "GMT+02:00 Africa/Tripoli", value: "Africa/Tripoli" },
    { name: "GMT+02:00 Africa/Windhoek", value: "Africa/Windhoek" },
    { name: "GMT-09:00 America/Adak", value: "America/Adak" },
    { name: "GMT-08:00 America/Anchorage", value: "America/Anchorage" },
    { name: "GMT-03:00 America/Araguaina", value: "America/Araguaina" },
    { name: "GMT-04:00 America/Asuncion", value: "America/Asuncion" },
    { name: "GMT-03:00 America/Bahia", value: "America/Bahia" },
    { name: "GMT-05:00 America/Bogota", value: "America/Bogota" },
    { name: "GMT-03:00 America/Buenos_Aires", value: "America/Buenos_Aires" },
    { name: "GMT-05:00 America/Cancun", value: "America/Cancun" },
    { name: "GMT-04:00 America/Caracas", value: "America/Caracas" },
    { name: "GMT-03:00 America/Cayenne", value: "America/Cayenne" },
    { name: "GMT-05:00 America/Chicago", value: "America/Chicago" },
    { name: "GMT-06:00 America/Chihuahua", value: "America/Chihuahua" },
    { name: "GMT-04:00 America/Cuiaba", value: "America/Cuiaba" },
    { name: "GMT-06:00 America/Denver", value: "America/Denver" },
    { name: "GMT-02:00 America/Godthab", value: "America/Godthab" },
    { name: "GMT-04:00 America/Grand_Turk", value: "America/Grand_Turk" },
    { name: "GMT-06:00 America/Guatemala", value: "America/Guatemala" },
    { name: "GMT-03:00 America/Halifax", value: "America/Halifax" },
    { name: "GMT-04:00 America/Havana", value: "America/Havana" },
    { name: "GMT-04:00 America/Indianapolis", value: "America/Indianapolis" },
    { name: "GMT-04:00 America/La_Paz", value: "America/La_Paz" },
    { name: "GMT-07:00 America/Los_Angeles", value: "America/Los_Angeles" },
    { name: "GMT-05:00 America/Mexico_City", value: "America/Mexico_City" },
    { name: "GMT-02:00 America/Miquelon", value: "America/Miquelon" },
    { name: "GMT-03:00 America/Montevideo", value: "America/Montevideo" },
    { name: "GMT-04:00 America/New_York", value: "America/New_York" },
    { name: "GMT-07:00 America/Phoenix", value: "America/Phoenix" },
    { name: "GMT-04:00 America/Port-au-Prince", value: "America/Port-au-Prince" },
    { name: "GMT-03:00 America/Punta_Arenas", value: "America/Punta_Arenas" },
    { name: "GMT-06:00 America/Regina", value: "America/Regina" },
    { name: "GMT-04:00 America/Santiago", value: "America/Santiago" },
    { name: "GMT-03:00 America/Sao_Paulo", value: "America/Sao_Paulo" },
    { name: "GMT-02:30 America/St_Johns", value: "America/St_Johns" },
    { name: "GMT-07:00 America/Tijuana", value: "America/Tijuana" },
    { name: "GMT+06:00 Asia/Almaty", value: "Asia/Almaty" },
    { name: "GMT+03:00 Asia/Amman", value: "Asia/Amman" },
    { name: "GMT+03:00 Asia/Baghdad", value: "Asia/Baghdad" },
    { name: "GMT+04:00 Asia/Baku", value: "Asia/Baku" },
    { name: "GMT+07:00 Asia/Bangkok", value: "Asia/Bangkok" },
    { name: "GMT+07:00 Asia/Barnaul", value: "Asia/Barnaul" },
    { name: "GMT+03:00 Asia/Beirut", value: "Asia/Beirut" },
    { name: "GMT+05:30 Asia/Calcutta", value: "Asia/Calcutta" },
    { name: "GMT+09:00 Asia/Chita", value: "Asia/Chita" },
    { name: "GMT+05:30 Asia/Colombo", value: "Asia/Colombo" },
    { name: "GMT+03:00 Asia/Damascus", value: "Asia/Damascus" },
    { name: "GMT+06:00 Asia/Dhaka", value: "Asia/Dhaka" },
    { name: "GMT+04:00 Asia/Dubai", value: "Asia/Dubai" },
    { name: "GMT+03:00 Asia/Hebron", value: "Asia/Hebron" },
    { name: "GMT+07:00 Asia/Hovd", value: "Asia/Hovd" },
    { name: "GMT+08:00 Asia/Irkutsk", value: "Asia/Irkutsk" },
    { name: "GMT+03:00 Asia/Jerusalem", value: "Asia/Jerusalem" },
    { name: "GMT+04:30 Asia/Kabul", value: "Asia/Kabul" },
    { name: "GMT+12:00 Asia/Kamchatka", value: "Asia/Kamchatka" },
    { name: "GMT+05:00 Asia/Karachi", value: "Asia/Karachi" },
    { name: "GMT+05:45 Asia/Katmandu", value: "Asia/Katmandu" },
    { name: "GMT+07:00 Asia/Krasnoyarsk", value: "Asia/Krasnoyarsk" },
    { name: "GMT+11:00 Asia/Magadan", value: "Asia/Magadan" },
    { name: "GMT+07:00 Asia/Novosibirsk", value: "Asia/Novosibirsk" },
    { name: "GMT+06:00 Asia/Omsk", value: "Asia/Omsk" },
    { name: "GMT+09:00 Asia/Pyongyang", value: "Asia/Pyongyang" },
    { name: "GMT+05:00 Asia/Qyzylorda", value: "Asia/Qyzylorda" },
    { name: "GMT+06:30 Asia/Rangoon", value: "Asia/Rangoon" },
    { name: "GMT+03:00 Asia/Riyadh", value: "Asia/Riyadh" },
    { name: "GMT+11:00 Asia/Sakhalin", value: "Asia/Sakhalin" },
    { name: "GMT+09:00 Asia/Seoul", value: "Asia/Seoul" },
    { name: "GMT+08:00 Asia/Shanghai", value: "Asia/Shanghai" },
    { name: "GMT+08:00 Asia/Singapore", value: "Asia/Singapore" },
    { name: "GMT+11:00 Asia/Srednekolymsk", value: "Asia/Srednekolymsk" },
    { name: "GMT+08:00 Asia/Taipei", value: "Asia/Taipei" },
    { name: "GMT+05:00 Asia/Tashkent", value: "Asia/Tashkent" },
    { name: "GMT+04:00 Asia/Tbilisi", value: "Asia/Tbilisi" },
    { name: "GMT+04:30 Asia/Tehran", value: "Asia/Tehran" },
    { name: "GMT+09:00 Asia/Tokyo", value: "Asia/Tokyo" },
    { name: "GMT+07:00 Asia/Tomsk", value: "Asia/Tomsk" },
    { name: "GMT+08:00 Asia/Ulaanbaatar", value: "Asia/Ulaanbaatar" },
    { name: "GMT+10:00 Asia/Vladivostok", value: "Asia/Vladivostok" },
    { name: "GMT+09:00 Asia/Yakutsk", value: "Asia/Yakutsk" },
    { name: "GMT+05:00 Asia/Yekaterinburg", value: "Asia/Yekaterinburg" },
    { name: "GMT+04:00 Asia/Yerevan", value: "Asia/Yerevan" },
    { name: "GMT+00:00 Atlantic/Azores", value: "Atlantic/Azores" },
    { name: "GMT-01:00 Atlantic/Cape_Verde", value: "Atlantic/Cape_Verde" },
    { name: "GMT+00:00 Atlantic/Reykjavik", value: "Atlantic/Reykjavik" },
    { name: "GMT+09:30 Australia/Adelaide", value: "Australia/Adelaide" },
    { name: "GMT+10:00 Australia/Brisbane", value: "Australia/Brisbane" },
    { name: "GMT+09:30 Australia/Darwin", value: "Australia/Darwin" },
    { name: "GMT+08:45 Australia/Eucla", value: "Australia/Eucla" },
    { name: "GMT+10:00 Australia/Hobart", value: "Australia/Hobart" },
    { name: "GMT+10:30 Australia/Lord_Howe", value: "Australia/Lord_Howe" },
    { name: "GMT+08:00 Australia/Perth", value: "Australia/Perth" },
    { name: "GMT+10:00 Australia/Sydney", value: "Australia/Sydney" },
    { name: "GMT+00:00 Etc/GMT", value: "Etc/GMT" },
    { name: "GMT-11:00 Etc/GMT+11", value: "Etc/GMT+11" },
    { name: "GMT-12:00 Etc/GMT+12", value: "Etc/GMT+12" },
    { name: "GMT-02:00 Etc/GMT+2", value: "Etc/GMT+2" },
    { name: "GMT-08:00 Etc/GMT+8", value: "Etc/GMT+8" },
    { name: "GMT-09:00 Etc/GMT+9", value: "Etc/GMT+9" },
    { name: "GMT+12:00 Etc/GMT-12", value: "Etc/GMT-12" },
    { name: "GMT+13:00 Etc/GMT-13", value: "Etc/GMT-13" },
    { name: "GMT+04:00 Europe/Astrakhan", value: "Europe/Astrakhan" },
    { name: "GMT+02:00 Europe/Berlin", value: "Europe/Berlin" },
    { name: "GMT+03:00 Europe/Bucharest", value: "Europe/Bucharest" },
    { name: "GMT+02:00 Europe/Budapest", value: "Europe/Budapest" },
    { name: "GMT+03:00 Europe/Chisinau", value: "Europe/Chisinau" },
    { name: "GMT+03:00 Europe/Istanbul", value: "Europe/Istanbul" },
    { name: "GMT+02:00 Europe/Kaliningrad", value: "Europe/Kaliningrad" },
    { name: "GMT+03:00 Europe/Kiev", value: "Europe/Kiev" },
    { name: "GMT+01:00 Europe/London", value: "Europe/London" },
    { name: "GMT+03:00 Europe/Minsk", value: "Europe/Minsk" },
    { name: "GMT+03:00 Europe/Moscow", value: "Europe/Moscow" },
    { name: "GMT+02:00 Europe/Paris", value: "Europe/Paris" },
    { name: "GMT+04:00 Europe/Samara", value: "Europe/Samara" },
    { name: "GMT+04:00 Europe/Saratov", value: "Europe/Saratov" },
    { name: "GMT+03:00 Europe/Volgograd", value: "Europe/Volgograd" },
    { name: "GMT+02:00 Europe/Warsaw", value: "Europe/Warsaw" },
    { name: "GMT+04:00 Indian/Mauritius", value: "Indian/Mauritius" },
    { name: "GMT+13:00 Pacific/Apia", value: "Pacific/Apia" },
    { name: "GMT+12:00 Pacific/Auckland", value: "Pacific/Auckland" },
    { name: "GMT+11:00 Pacific/Bougainville", value: "Pacific/Bougainville" },
    { name: "GMT+12:45 Pacific/Chatham", value: "Pacific/Chatham" },
    { name: "GMT-06:00 Pacific/Easter", value: "Pacific/Easter" },
    { name: "GMT+12:00 Pacific/Fiji", value: "Pacific/Fiji" },
    { name: "GMT+11:00 Pacific/Guadalcanal", value: "Pacific/Guadalcanal" },
    { name: "GMT-10:00 Pacific/Honolulu", value: "Pacific/Honolulu" },
    { name: "GMT+14:00 Pacific/Kiritimati", value: "Pacific/Kiritimati" },
    { name: "GMT-09:30 Pacific/Marquesas", value: "Pacific/Marquesas" },
    { name: "GMT+11:00 Pacific/Norfolk", value: "Pacific/Norfolk" },
    { name: "GMT+10:00 Pacific/Port_Moresby", value: "Pacific/Port_Moresby" },
    { name: "GMT+13:00 Pacific/Tongatapu", value: "Pacific/Tongatapu" },
].sort((a, b) => (a.name < b.name ? 1 : a.name > b.name ? -1 : 0))

export const TimeMapping = {
    "Africa/Cairo": 2,
    "Africa/Casablanca": 1,
    "Africa/Johannesburg": 2,
    "Africa/Khartoum": 2,
    "Africa/Lagos": 1,
    "Africa/Nairobi": 3,
    "Africa/Sao_Tome": 0,
    "Africa/Tripoli": 2,
    "Africa/Windhoek": 2,
    "America/Adak": -9,
    "America/Anchorage": -8,
    "America/Araguaina": -3,
    "America/Asuncion": -4,
    "America/Bahia": -3,
    "America/Bogota": -5,
    "America/Buenos_Aires": -3,
    "America/Cancun": -5,
    "America/Caracas": -4,
    "America/Cayenne": -3,
    "America/Chicago": -5,
    "America/Chihuahua": -6,
    "America/Cuiaba": -4,
    "America/Denver": -6,
    "America/Godthab": -2,
    "America/Grand_Turk": -4,
    "America/Guatemala": -6,
    "America/Halifax": -3,
    "America/Havana": -4,
    "America/Indianapolis": -4,
    "America/La_Paz": -4,
    "America/Los_Angeles": -7,
    "America/Mexico_City": -5,
    "America/Miquelon": -2,
    "America/Montevideo": -3,
    "America/New_York": -4,
    "America/Phoenix": -7,

    "America/Port-au-Prince": -4,
    "America/Punta_Arenas": -3,
    "America/Regina": -6,
    "America/Santiago": -4,
    "America/Sao_Paulo": -3,
    "America/St_Johns": -2.5,
    "America/Tijuana": -7,
    "Asia/Almaty": 6,
    "Asia/Amman": 3,
    "Asia/Baghdad": 3,
    "Asia/Baku": 4,
    "Asia/Bangkok": 7,
    "Asia/Barnaul": 7,
    "Asia/Beirut": 3,
    "Asia/Calcutta": 5.5,
    "Asia/Chita": 9,
    "Asia/Colombo": 5.5,
    "Asia/Damascus": 3,
    "Asia/Dhaka": 6,
    "Asia/Dubai": 4,
    "Asia/Hebron": 3,
    "Asia/Hovd": 7,
    "Asia/Irkutsk": 8,
    "Asia/Jerusalem": 3,
    "Asia/Kabul": 4.5,
    "Asia/Kamchatka": 12,
    "Asia/Karachi": 5,
    "Asia/Katmandu": 5.75,
    "Asia/Krasnoyarsk": 7,
    "Asia/Magadan": 11,
    "Asia/Novosibirsk": 7,
    "Asia/Omsk": 6,
    "Asia/Pyongyang": 9,
    "Asia/Qyzylorda": 5,
    "Asia/Rangoon": 6.5,
    "Asia/Riyadh": 3,
    "Asia/Sakhalin": 11,
    "Asia/Seoul": 9,
    "Asia/Shanghai": 8,
    "Asia/Singapore": 8,
    "Asia/Srednekolymsk": 11,
    "Asia/Taipei": 8,
    "Asia/Tashkent": 5,
    "Asia/Tbilisi": 4,
    "Asia/Tehran": 4.5,
    "Asia/Tokyo": 9,
    "Asia/Tomsk": 7,
    "Asia/Ulaanbaatar": 8,
    "Asia/Vladivostok": 10,
    "Asia/Yakutsk": 9,
    "Asia/Yekaterinburg": 5,
    "Asia/Yerevan": 4,
    "Atlantic/Azores": 0,
    "Atlantic/Cape_Verde": -1,
    "Atlantic/Reykjavik": 0,
    "Australia/Adelaide": 9.5,
    "Australia/Brisbane": 10,
    "Australia/Darwin": 9.5,
    "Australia/Eucla": 8.75,
    "Australia/Hobart": 10,
    "Australia/Lord_Howe": 10.5,
    "Australia/Perth": 8,
    "Australia/Sydney": 10,
    "Etc/GMT": 0,
    "Etc/GMT+11": -11,
    "Etc/GMT+12": -12,
    "Etc/GMT+2": -2,
    "Etc/GMT+8": -8,
    "Etc/GMT+9": -9,
    "Etc/GMT-12": 12,
    "Etc/GMT-13": 13,
    "Europe/Astrakhan": 4,
    "Europe/Berlin": 2,
    "Europe/Bucharest": 3,
    "Europe/Budapest": 2,
    "Europe/Chisinau": 3,
    "Europe/Istanbul": 3,
    "Europe/Kaliningrad": 2,
    "Europe/Kiev": 3,
    "Europe/London": 1,
    "Europe/Minsk": 3,
    "Europe/Moscow": 3,
    "Europe/Paris": 2,
    "Europe/Samara": 4,
    "Europe/Saratov": 4,
    "Europe/Volgograd": 3,
    "Europe/Warsaw": 2,
    "Indian/Mauritius": 4,
    "Pacific/Apia": 13,
    "Pacific/Auckland": 12,
    "Pacific/Bougainville": 11,
    "Pacific/Chatham": 12.75,
    "Pacific/Easter": -6,
    "Pacific/Fiji": 12,
    "Pacific/Guadalcanal": 11,
    "Pacific/Honolulu": -10,
    "Pacific/Kiritimati": +14,
    "Pacific/Marquesas": -9.5,
    "Pacific/Norfolk": 11,
    "Pacific/Port_Moresby": 10,
    "Pacific/Tongatapu": 13,
}

export const newTimezones = [
    ...updatedTimeZones.slice(0, 43),
    ...updatedTimeZones
        .slice(43)
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)),
]
