import moment from "moment"
import momentTimeZone from "moment-timezone"

/**
 * @description Check if date is valid, i.e. it is >= current date
 * @param {Date} date
 */
export function handleDate(date) {
    if (
        new Date(`${date}`).getTime() <
        new Date(new Date().toISOString().split("T")[0]).getTime()
    )
        return null
    return moment(new Date(`${date}`)).format("DD MMM, YYYY")
}

/**
 * @description Check if time is valid, i.e. it is >= current time
 * @param {Time} time
 */
export function handleTime(time) {
    let t1 = time.split(":")[0] * 60 + time.split(":")[1]
    let t2 = new Date().getHours() * 60 + new Date().getMinutes()

    if (t1 < t2) return null
    return time
}

/**
 * @description Handle Date and TimeZone at one place
 * @param {Date} date
 * @param {Time} time
 */
export function handleDateAndTime(date, time) {
    if (!time) {
        if (new Date(date).getTime() < new Date().getTime()) return null
        return moment(date).format("DD MMM, YYYY : hh:mm a")
    }
    if (new Date(`${date} ${time}`).getTime() < new Date().getTime()) return null
    return moment(new Date(`${date} ${time}`)).format("DD MMM, YYYY : hh:mm a")
}

/**
 * @description Make date in proper format with timezone, if isValid, then return NULL
 * @param {Date} date
 * @param {Time} time
 * @param {Timezone} tz Time Zone
 */
export function handleTimeZone(date, time, tz) {
    let selectedDateAndTime = handleDateAndTime(date, time)
    if (!selectedDateAndTime) return null
    selectedDateAndTime = new Date(date)

    let oldTimezone = moment.tz.guess()
    if (oldTimezone == null) oldTimezone = "Asia/Kolkata"
    selectedDateAndTime.setHours(time.split(":")[0])
    selectedDateAndTime.setMinutes(time.split(":")[1])
    const timeZoneManager = momentTimeZone.tz(selectedDateAndTime, oldTimezone)
    return timeZoneManager.clone().tz(tz).format()
}

/**
 * @description Helper Function which formats date as today, yesterday, tomorrow or  rest in the format DD MMM YYYY
 * @param {Date} date
 * @param {Boolean} includeTime Want to include time in formatted date
 * @param {Boolean} alwaysIncludeTime Always include time in formatted date
 */
export function formatDateIntoWord(
    date,
    includeTime = false,
    alwaysIncludeTime = false,
    removeUtc = false,
) {
    if (!moment(date).isValid()) return null
    let formatWithoutTime = {
        sameDay: "[Today]",
        nextDay: "[Tomorrow]",
        nextWeek: "DD MMM YYYY",
        lastDay: "[Yesterday]",
        lastWeek: "DD MMM YYYY",
        sameElse: "DD MMM YYYY",
    }
    let formatWithTime = {
        sameDay: "[Today] LT",
        nextDay: "[Tomorrow] LT",
        nextWeek: "DD MMM YYYY",
        lastDay: "[Yesterday] LT",
        lastWeek: "DD MMM YYYY",
        sameElse: "DD MMM YYYY",
    }
    let formatWithWordAndTime = {
        sameElse: "DD MMM YYYY LT",
    }

    let format = alwaysIncludeTime
        ? formatWithWordAndTime
        : includeTime
        ? formatWithTime
        : formatWithoutTime
    if (removeUtc) {
        return moment(date).calendar({
            ...format,
        })
    }
    return moment(date)
        .utc()
        .calendar({
            ...format,
        })
}

/**
 * Convert Individual Date and Time to Date + Time
 * @param {*} date
 * @param {*} time
 * @returns
 */
export function addDateAndTime(date, time) {
    return moment(date + " " + time).format()
}

/**
 * @description Function to check if the time is past or in present or future
 * @param {*} time
 * @returns {Boolean} true if date is in present or future, else false
 */
export function checkTimeValidity(time) {
    return moment(time).diff(moment().format(), "seconds") >= 0
}
