import React, { useEffect } from "react"
import { connect } from "react-redux"
import HelpContent from "components/Utilities/HelpContent"
import PostManagementSideDrawer from "components/Utilities/PostManagementSideDrawer"
import PopupAlert from "components/Utilities/PopupAlert/PopupAlert"
import PreviewFile from "components/Utilities/PreviewFile"
import Error from "components/commonComps/Error"
import DownGradePlan from "components/Utilities/DownGradePlan/DownGradePlan"
import TrialEndedPopup from "components/Utilities/TrialEndedPopup"
import { Helmet } from "react-helmet"
import SocialAnimation from "components/Templates/SocialAnimation/SocialAnimation"
import { useHistory } from "react-router-dom"

const MainPage = ({ msg, metaTitle, globalLoading, noSubscription }) => {
    const history = useHistory()

    useEffect(() => {
        if (noSubscription) {
            history.push("/getting-started")
        }
    }, [noSubscription])

    return (
        <div>
            {globalLoading && <SocialAnimation />}
            <HelpContent />
            <PostManagementSideDrawer />
            <PopupAlert />
            <PreviewFile />
            <DownGradePlan />
            <TrialEndedPopup />
            <Helmet>
                <title>{metaTitle}</title>
            </Helmet>
            {msg && <Error />}
        </div>
    )
}

const mapStatesToProps = ({ messageReducer, userReducer }) => {
    return {
        msg: messageReducer.msg,
        metaTitle: messageReducer.metaTitle,
        globalLoading: userReducer.globalLoading,
        noSubscription: userReducer.noSubscription,
    }
}

export default connect(mapStatesToProps)(MainPage)
