import React from "react"
import { createUseStyles } from "react-jss"

import classes from "./sideDrawer.module.scss"

const positionCss = {
    left: {
        top: 0,
        width: "22rem",
        height: "100%",
        left: 0,
    },
    left_cross: {
        right: 0,
        transform: "translate(115%,15%)",
        top: "0rem",
    },
    right: {
        top: 0,
        minWidth: "22rem",
        height: "100%",
        right: 0,
    },
    right_cross: {
        right: 0,
        top: "0rem",
        transform: "translate(-15%, 15%)",
    },
    top: {
        top: 0,
        width: "100%",
        height: "22rem",
        left: 0,
    },
    top_cross: {
        right: "1.5rem",
        top: "0rem",
    },
    bottom: {
        bottom: 0,
        width: "100%",
        height: "22rem",
        left: 0,
    },
    bottom_cross: {
        left: "1.5rem",
        top: "0rem",
    },
    custom_cross: {
        top: 0,
        left: "0",
        transform: "translate(-100% , 0)"
    }
}

const useStyles = createUseStyles({
    side_drawer_wrapper: (props) => ({
        visibility: props.vis ? "visible" : "hidden",
        position: props.fullPage ? "fixed" : "absolute",
        width: props.fullPage ? "100vw" : "100%",
        height: props.fullPage ? "100vh" : "100%",
    }),
    side_drawer: (props) => ({
        ...positionCss[props.position ?? "top"],
        width: props.width ?? positionCss[props.position ?? "top"].width,
        height: props.height ?? positionCss[props.position ?? "top"].height,
        visibility: props.vis ? "visible" : "hidden",
        maxWidth: props.maxWidth ?? "unset",
        animation: `$slide${props.position ?? "top"} 0.25s ease 1`,
    }),
    cross: (props) => ({
        ...positionCss[`${props.crossPosition ?? props.position}_cross`],
    }),
    "@keyframes slideleft": {
        from: {
            transform: "translateX(-100%)",
        },
        to: {
            transform: "translateX(0)",
        },
    },
    "@keyframes slideright": {
        from: {
            transform: "translateX(100%)",
        },
        to: {
            transform: "translateX(0)",
        },
    },
    "@keyframes slidetop": {
        from: {
            transform: "translateY(-100%)",
        },
        to: {
            transform: "translateY(0)",
        },
    },
    "@keyframes slidebottom": {
        from: {
            transform: "translateY(100%)",
        },
        to: {
            transform: "translateY(0)",
        },
    },
})

/**
 * @description Side Drawer Component
 * @param {Boolean} fullPage FullPage Side Drawer
 * @param {String} className Custom Class name
 * @param {Function} toggle Function TO Toggle
 * @param {ReactElement} toggleBtn Toggle Button Element
 * @param {String} width With of Side drawer
 * @param {String} maxWidth max With of Side drawer
 * @param {Boolean} vis Visibility
 * @param {"left"|"right"|"top"|"bottom"} position Position of side drawer
 * @param {"left"|"right"|"top"|"bottom"} crossPosition Position of cross in side drawer
 */

const SideDrawer = (props) => {
    const { toggle, toggleBtn, children, className, blockOusideClick } = props

    const dynamicClasses = useStyles(props)

    return (
        <div
            className={`${classes.side_drawer_wrapper} ${dynamicClasses.side_drawer_wrapper}`}
            onClick={(e)=>{
                if(!blockOusideClick){
                    toggle(e)
                }
            }}
            style={{...props.style}}
        >
            <div
                className={`${classes.side_drawer} ${dynamicClasses.side_drawer} ${className}`}
                onClick={(e) => e.stopPropagation()}
            >
                {children}
                <div
                    className={`${classes.cross} ${dynamicClasses.cross}`}
                    onClick={toggle}
                >
                    {toggleBtn}
                </div>
            </div>
        </div>
    )
}

export default SideDrawer
