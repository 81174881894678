import axios from "axios"
import { bitly, minifyUrl } from "../apis"
var linksRegex = /(https?:\/\/[^\s]+)/g
var awesmlyLinksRegex = /(https:\/\/www.awesm.ly?[^\s]+)/g

/**
 * @description Compress all the links in the given text
 * @param {String} str : String having the links to be compressed
 * @returns String with Compressed URLs
 */

function compressorUtility(isBitly, str, brandId) {
    if (!isBitly)
        return axios.post(minifyUrl, {
            LongUrl: str,
        })
    else
        return axios.post(bitly, {
            LongUrl: str,
            brandId,
        })
}

export function testLinkCompressor(str, isBitly, brandId) {
    return new Promise(async (resolve, reject) => {
        let allLinks = str.match(linksRegex)
        const awesmlyLinks = str.match(awesmlyLinksRegex)

        
        let linksToBeShortened = []
        const links = {}
        allLinks &&
        allLinks.forEach((link) => {
            if (!awesmlyLinks?.includes(link)) linksToBeShortened.push(link) // So that we dont re-short the links
            else links[link] = link
        })
        
        if (!linksToBeShortened || linksToBeShortened.length === 0)
            return resolve(str)
        await new Promise(async (res, rej) => {
            for (let i = 0; i < linksToBeShortened.length; ) {
                const currentCompressedLink = await compressorUtility(
                    isBitly,
                    linksToBeShortened[i],
                    parseInt(brandId)
                )
                links[linksToBeShortened[i]] =
                    currentCompressedLink.data.code === 200
                        ? currentCompressedLink.data.model.link ??
                          currentCompressedLink.data.model.shortUrl
                        : linksToBeShortened[i]
                i++
            }
            return res()
        })
        let newS = str.replaceAll(linksRegex, (exp) => {
            return links[exp]
        })
        return resolve(newS)
    })
}

/**
 * @description Compress all the plain links in the given text with the hyperlinks for HTML
 * @param {String} str : String in <which></which> link has to be replaced with a tags
 * @returns new updated String with tags
 */
export function convertLinksToHref(str) {
    let res = str.replaceAll(linksRegex, (exp, i) => {
        return `<a href="${exp}" target='_blank'>${exp}</a>`
    })
    return res
}
