import {
    CLEAR_MESSAGE,
    CREATE_MESSAGE,
    MODIFY_ALERT,
    MODIFY_OFFER,
    ADD_POPUP,
    DELETE_POPUP,
    ADD_NOTIFICATION,
    DELETE_NOTIFICATION,
    TOGGLE_HELP,
    OPEN_FILE_PREVIEW,
    CHANGE_PREVIEW_INDEX,
    CLOSE_FILE_PREVIEW,
    RESET_META_TITLE,
    UPDATE_META_TITLE,
    VIEW_TRIAL_ENDED,
    HIDE_TRIAL_ENDED,
    VIEW_PLAN_POPUP,
    HIDE_PLAN_POPUP,
} from "../ActionTypes/messageActionTypes"
import GettingStarted from "../components/icons/Dashboard/getting-started.webp"
import Roadmap from "../components/icons/Dashboard/Roadmap.webp"
import FAQ from "../components/icons/Dashboard/faq.webp"
import ReportBug from "../components/icons/Dashboard/report-bug.webp"

const initialState = {
    alert: null,
    msg: null,
    offer: "Offer Added",
    status: "success",
    popup: null,
    notifications: null,
    helpOpen: false,
    filePreviewIndex: null,
    previewFiles: [],
    page: 0,
    viewTrialEnded: false,
    viewPlanPopup: false,

    resources: [
        {
            name: "Getting Started",
            description: "Read our guide on how to get started using Awesome Social",
            image: GettingStarted,
            background: "rgba(209, 130, 153, 0.57)",
            link: "https://help.awesomesuite.com/portal/en/kb/articles/getting-started-with-awesome-social"
        },
        {
            name: "Public Roadmap",
            description: "Have a look at all the cool things we are working on",
            image: Roadmap,
            background: "rgb(149,232,222)",
            link: "https://trello.com/b/xWeL1eE6/%F0%9F%9A%80awesome-social-public-roadmap",
        },
    ],
    supportContent: [
        {
            title: "FAQ",
            icon: FAQ,
            background: "rgba(218, 217, 217, 0.5)",
            link: "https://help.awesomesuite.com/portal/en/kb/awesome-social"
        },
        {
            title: "Report a bug",
            icon: ReportBug,
            background: "rgba(81, 77, 117, 0.5)",
            link: "https://help.awesomesuite.com/portal/en/newticket"
        },
    ],
    metaTitle: "Social",
}

const messageReducer = (state = initialState, action) => {
    try {
        switch (action.type) {
            case CREATE_MESSAGE:
                return {
                    ...state,
                    msg: action.payload.msg,
                    status: action.payload.status,
                }
            case CLEAR_MESSAGE:
                return {
                    ...state,
                    msg: null,
                    status: "",
                }
            case MODIFY_ALERT:
                return {
                    ...state,
                    alert: action.payload.alert,
                }
            case MODIFY_OFFER:
                return {
                    ...state,
                    offer: action.payload.offer,
                }
            case ADD_POPUP:
                return {
                    ...state,
                    popup: action.payload.popup,
                }
            case DELETE_POPUP:
                return {
                    ...state,
                    popup: null,
                }
            case ADD_NOTIFICATION:
                let notifications = Array.isArray(action.payload.notification)
                    ? [...action.payload.notification]
                    : [action.payload.notification]
                return { ...state, notifications }
            case DELETE_NOTIFICATION:
                let filteredNotifications = state.notifications.filter(
                    ({ id }) => id !== action.payload.notificationId
                )
                return { ...state, notifications: filteredNotifications }
            case TOGGLE_HELP:
                return { ...state, helpOpen: !state.helpOpen }
            case OPEN_FILE_PREVIEW:
                return {
                    ...state,
                    filePreviewIndex: action.payload.index,
                    previewFiles: action.payload.files,
                }
            case CHANGE_PREVIEW_INDEX:
                return {
                    ...state,
                    filePreviewIndex: action.payload.index,
                }
            case CLOSE_FILE_PREVIEW:
                return {
                    ...state,
                    filePreviewIndex: null,
                    previewFiles: [],
                }
            case UPDATE_META_TITLE:
                return { ...state, metaTitle: action.payload.title }
            case RESET_META_TITLE:
                return { ...state, metaTitle: "Social" }
            case VIEW_TRIAL_ENDED:
                return { ...state, viewTrialEnded: true }
            case HIDE_TRIAL_ENDED:
                return { ...state, viewTrialEnded: false }
            case VIEW_PLAN_POPUP:
                return { ...state, viewPlanPopup: true }
            case HIDE_PLAN_POPUP:
                return { ...state, viewPlanPopup: false }
            default:
                return state
        }
    } catch (err) {
    }
}

export default messageReducer
