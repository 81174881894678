import React from "react"
import { openFilePreview } from "../../../../Actions/messageAction"
import { GetFile } from "../../GetFileIcon/index.js"
import "./facebookGridView.scss"

function getPostClass(length) {
    if (length === 1) return "normal"
    if (length > 1 && length <= 5) return `facebook-grid-${length}`
    else return `facebook-grid-5`
}

const FacebookGridView = ({ files, allowFilePreview = true }) => {
    function openPreview(index) {
        if (allowFilePreview) openFilePreview(index, files)
    }

    return files && files.length > 0 ? (
        <div className={`aui-facebook-grid ${getPostClass(files.length)}`}>
            {files.slice(0, 5).map((file, index) => (
                <div className={`photo-${index + 1}`}>
                    {files.length > 5 && index + 1 === 5 && (
                        <span
                            className="overlay"
                            onClick={(e) => {
                                e.stopPropagation()
                                openPreview(index)
                            }}
                        >
                            <p>+{files.length - 5}</p>
                        </span>
                    )}
                    <GetFile
                        file={file}
                        style={{}}
                        openPreview={(e) => {
                            e.stopPropagation()
                            openPreview(index)
                        }}
                    />
                </div>
            ))}
        </div>
    ) : (
        <></>
    )
}

export default FacebookGridView
