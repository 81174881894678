import React, { useEffect, useRef, useState } from "react"
import { IoMdPause, IoMdPlay, IoMdVolumeHigh, IoMdVolumeOff } from "react-icons/io"
import "./styles.scss"

const VideoPlayer = ({
    video: { id = "", src = "", thumbnail = "" },
    mute: muteProp = true,
    play: playProp = false,
    asChild = false
}) => {
    /* eslint-disable no-unused-expressions */
    const videoRef = useRef(null)
    const [play, setPlay] = useState(playProp)
    const [mute, setMute] = useState(muteProp)

    useEffect(() => {
        if (!muteProp && sessionStorage.getItem("mute") === "true") {
            setMute(true)
        }
    }, [muteProp])

    useEffect(() => {
        const currentVideoRef = videoRef.current
        if (play) {
            currentVideoRef?.play()
        } else {
            currentVideoRef?.pause()
        }
    }, [play])

    useEffect(() => {
        sessionStorage.setItem("mute", mute)
    }, [mute])

    return (
        <div className="video-container">
            <div className="video">
                <video
                    preload="metadata"
                    ref={videoRef}
                    src={src}
                    poster={thumbnail}
                    id={id}
                    autoFocus
                    autoPlay={play}
                    loop={true}
                    onClick={(event) => {
                        if (asChild) return
                        event.stopPropagation()
                        setPlay((prev) => !prev)
                    }}
                    muted={mute}
                    style={{
                        objectFit: asChild ? "cover" : "contain"
                    }}
                />
                {!asChild && <div className="video-actions">
                    <div className="play-pause">
                        {play ? (
                            <button
                                onClick={(event) => {
                                    if (asChild) return
                                    event.stopPropagation()
                                    setPlay(false)
                                }}
                            >
                                <IoMdPause />
                            </button>
                        ) : (
                            <button
                                onClick={(event) => {
                                    if (asChild) return
                                    event.stopPropagation()
                                    setPlay(true)
                                }}
                            >
                                <IoMdPlay />
                            </button>
                        )}
                    </div>
                    <div className="volume">
                        {mute ? (
                            <button
                                onClick={(event) => {
                                    if (asChild) return
                                    event.stopPropagation()
                                    setMute(false)
                                }}
                            >
                                <IoMdVolumeOff />
                            </button>
                        ) : (
                            <button
                                onClick={(event) => {
                                    if (asChild) return
                                    event.stopPropagation()
                                    setMute(true)
                                }}
                            >
                                <IoMdVolumeHigh />
                            </button>
                        )}
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default VideoPlayer
