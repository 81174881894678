import React from "react"

import "./tooltip.scss"

const ToolTip = ({ dir, name, clr, bg, style }) => {
    style = style ?? {}
    return (
        <div
            className={`tooltip ${dir}`}
            style={{ color: clr, background: bg, ...style }}
        >
            {name}
        </div>
    )
}

export default ToolTip
