import React from "react";

// comics theme icons
import { ReactComponent as ComicBash1 } from "../../assets/heyTheme/Comic Bash/comic-bash-1.svg";
import { ReactComponent as ComicBash2 } from "../../assets/heyTheme/Comic Bash/comic-bash-2.svg";
import { ReactComponent as ComicBash3 } from "../../assets/heyTheme/Comic Bash/comic-bash-3.svg";
import { ReactComponent as ComicBash4 } from "../../assets/heyTheme/Comic Bash/comic-bash-4.svg";
import { ReactComponent as ComicBash5 } from "../../assets/heyTheme/Comic Bash/comic-bash-5.svg";
import { ReactComponent as ComicBash6 } from "../../assets/heyTheme/Comic Bash/comic-bash-6.svg";
import { ReactComponent as ComicBash7 } from "../../assets/heyTheme/Comic Bash/comic-bash-7.svg";
import { ReactComponent as ComicBash8 } from "../../assets/heyTheme/Comic Bash/comic-bash-8.svg";

// flat theme icons
import { ReactComponent as Flat1 } from "../../assets/heyTheme/Flat Theme/flat-theme-1.svg";
import { ReactComponent as Flat2 } from "../../assets/heyTheme/Flat Theme/flat-theme-2.svg";
import { ReactComponent as Flat3 } from "../../assets/heyTheme/Flat Theme/flat-theme-3.svg";
import { ReactComponent as Flat4 } from "../../assets/heyTheme/Flat Theme/flat-theme-4.svg";
import { ReactComponent as Flat5 } from "../../assets/heyTheme/Flat Theme/flat-theme-5.svg";
import { ReactComponent as Flat6 } from "../../assets/heyTheme/Flat Theme/flat-theme-6.svg";
import { ReactComponent as Flat7 } from "../../assets/heyTheme/Flat Theme/flat-theme-7.svg";
import { ReactComponent as Flat8 } from "../../assets/heyTheme/Flat Theme/flat-theme-8.svg";

import { ReactComponent as Gradient1 } from "../../assets/heyTheme/Gradient Style/gradient-style-1.svg";
import { ReactComponent as Gradient2 } from "../../assets/heyTheme/Gradient Style/gradient-style-2.svg";
import { ReactComponent as Gradient3 } from "../../assets/heyTheme/Gradient Style/gradient-style-3.svg";
import { ReactComponent as Gradient4 } from "../../assets/heyTheme/Gradient Style/gradient-style-4.svg";
import { ReactComponent as Gradient5 } from "../../assets/heyTheme/Gradient Style/gradient-style-5.svg";
import { ReactComponent as Gradient6 } from "../../assets/heyTheme/Gradient Style/gradient-style-6.svg";
import { ReactComponent as Gradient7 } from "../../assets/heyTheme/Gradient Style/gradient-style-7.svg";
import { ReactComponent as Gradient8 } from "../../assets/heyTheme/Gradient Style/gradient-style-8.svg";

import { ReactComponent as Retro1 } from "../../assets/heyTheme/Retro Style/retro-style-1.svg";
import { ReactComponent as Retro2 } from "../../assets/heyTheme/Retro Style/retro-style-2.svg";
import { ReactComponent as Retro3 } from "../../assets/heyTheme/Retro Style/retro-style-3.svg";
import { ReactComponent as Retro4 } from "../../assets/heyTheme/Retro Style/retro-style-4.svg";
import { ReactComponent as Retro5 } from "../../assets/heyTheme/Retro Style/retro-style-5.svg";
import { ReactComponent as Retro6 } from "../../assets/heyTheme/Retro Style/retro-style-6.svg";
import { ReactComponent as Retro7 } from "../../assets/heyTheme/Retro Style/retro-style-7.svg";
import { ReactComponent as Retro8 } from "../../assets/heyTheme/Retro Style/retro-style-8.svg";

import Modern1 from "../../assets/heyTheme/Modern Style/modern_1.webp";
import Modern2 from "../../assets/heyTheme/Modern Style/modern_2.webp";
import Modern3 from "../../assets/heyTheme/Modern Style/modern_3.webp";
import Modern4 from "../../assets/heyTheme/Modern Style/modern_4.webp";
import Modern5 from "../../assets/heyTheme/Modern Style/modern_5.webp";
import Modern6 from "../../assets/heyTheme/Modern Style/modern_6.webp";

/*
Format for themes

primaryColor: "Main Background",
secondaryColor: "Banner Background",
accentColor1: "Link Background",
accentColor2: "Banner Button Background",
borderColor1: "Link Border Color",
borderColor2: "Banner Border Color",
borderColor3: "Banner Button Border Color",
textColor1: "Link Text Color",
textColor2: "Banner Text Color",
textColor3: "Banner Button Text Color",
*/

export const heyThemeData = {
  MODERN_1: {
    img: <img src={Modern1} />,
    type: "theme_5",
    bannerType: "CURVED",
    linkType: "ROUNDED_1",
    properties: {},

    theme: {
      primaryColor: "#FFFFFF",
      secondaryColor: "rgb(255, 255, 255)",
      accentColor1: "#171717",
      accentColor2: "#171717",
      borderColor1: "#171717",
      borderColor2: "#FFFFFF",
      borderColor3: "#171717",
      textColor1: "#FFFFFF",
      textColor2: "#171717",
      textColor3: "#FFFFFF",

      background: "#FFFFFF",
      fontFamily: "Roboto",
      fontColor: "#2f3e46",
    },
  },
  MODERN_2: {
    img: <img src={Modern2} />,
    type: "theme_5",
    bannerType: "CURVED",
    linkType: "ROUNDED_1",
    properties: {},

    theme: {
      primaryColor: "#FFCBCC",
      secondaryColor: "rgb(255, 255, 255)",
      accentColor1: "#FFFFFF",
      accentColor2: "#FF6063",
      borderColor1: "#FFFFFF",
      borderColor2: "#FFFFFF",
      borderColor3: "#FF6063",
      textColor1: "#FF6063",
      textColor2: "#171717",
      textColor3: "#FFFFFF",

      background: "#FFCBCC",
      fontFamily: "Roboto",
      fontColor: "#2f3e46",
    },
  },
  MODERN_3: {
    img: <img src={Modern3} />,
    type: "theme_5",
    bannerType: "CURVED",
    linkType: "ROUNDED_1",
    properties: {},

    theme: {
      primaryColor: "#FFFC9A",
      secondaryColor: "rgb(255, 255, 255)",
      accentColor1: "#171717",
      accentColor2: "#171717",
      borderColor1: "#171717",
      borderColor2: "#FFFFFF",
      borderColor3: "#171717",
      textColor1: "#FFFFFF",
      textColor2: "#171717",
      textColor3: "#FFFFFF",

      background: "#FFFC9A",
      fontFamily: "Roboto",
      fontColor: "#2f3e46",
    },
  },
  MODERN_4: {
    img: <img src={Modern4} />,
    type: "theme_5",
    bannerType: "CURVED",
    linkType: "ROUNDED_1",
    properties: {},

    theme: {
      primaryColor: "#C1E5FF",
      secondaryColor: "rgb(255, 255, 255)",
      accentColor1: "#179CFF",
      accentColor2: "#179CFF",
      borderColor1: "#179CFF",
      borderColor2: "#FFFFFF",
      borderColor3: "#179CFF",
      textColor1: "#FFFFFF",
      textColor2: "#171717",
      textColor3: "#FFFFFF",

      background: "#C1E5FF",
      fontFamily: "Roboto",
      fontColor: "#2f3e46",
    },
  },
  MODERN_5: {
    img: <img src={Modern5} />,
    type: "theme_5",
    bannerType: "CURVED",
    linkType: "ROUNDED_1",
    properties: {},

    theme: {
      primaryColor: "#CED6FF",
      secondaryColor: "rgb(255, 255, 255)",
      accentColor1: "#607CD2",
      accentColor2: "#607CD2",
      borderColor1: "#607CD2",
      borderColor2: "#FFFFFF",
      borderColor3: "#607CD2",
      textColor1: "#FFFFFF",
      textColor2: "#171717",
      textColor3: "#FFFFFF",

      background: "#CED6FF",
      fontFamily: "Roboto",
      fontColor: "#2f3e46",
    },
  },
  MODERN_6: {
    img: <img src={Modern6} />,
    type: "theme_5",
    bannerType: "CURVED",
    linkType: "ROUNDED_1",
    properties: {},

    theme: {
      primaryColor: "#FDF3EA",
      secondaryColor: "rgb(255, 255, 255)",
      accentColor1: "#FF7A59",
      accentColor2: "#FF7A59",
      borderColor1: "#FF7A59",
      borderColor2: "#FFFFFF",
      borderColor3: "#FF7A59",
      textColor1: "#FFFFFF",
      textColor2: "#171717",
      textColor3: "#FFFFFF",

      background: "#FDF3EA",
      fontFamily: "Roboto",
      fontColor: "#2f3e46",
    },
  },
  COMIC_THEME_1: {
    img: <ComicBash1 />,
    type: "theme_1",
    bannerType: "CLASSIC",
    linkType: "SHARP_1",
    properties: {},

    theme: {
      primaryColor: "#FFFFFF",
      secondaryColor: "#FFFFFF",
      accentColor1: "#FFC72C",
      accentColor2: "#FDC0D2",
      borderColor1: "#170C05",
      borderColor2: "#170C05",
      borderColor3: "#170C05",
      textColor1: "#170C05",
      textColor2: "#170C05",
      textColor3: "#170C05",

      background: "#FFFFFF",
      subBg: "#84a98c",
      fontFamily: "Roboto",
      fontColor: "#2f3e46",
    },
  },
  COMIC_THEME_2: {
    img: <ComicBash2 />,
    type: "theme_1",
    bannerType: "CLASSIC",
    linkType: "SHARP_1",
    properties: {},

    theme: {
      primaryColor: "#D59FFF",
      secondaryColor: "#C06AFE",
      accentColor1: "#FFC72C",
      accentColor2: "#FFCA47",
      borderColor1: "#170C05",
      borderColor2: "#170C05",
      borderColor3: "#170C05",
      textColor1: "#170C05",
      textColor2: "#170C05",
      textColor3: "#170C05",

      background: "#D59FFF",
      fontFamily: "Roboto",
      fontColor: "#2f3e46",
    },
  },
  COMIC_THEME_3: {
    img: <ComicBash3 />,
    type: "theme_1",
    bannerType: "CLASSIC",
    linkType: "SHARP_1",
    properties: {},

    theme: {
      primaryColor: "#F2F4F6",
      secondaryColor: "#FFFFFF",
      accentColor1: "#00EBC7",
      accentColor2: "#FF5470",
      borderColor1: "#170C05",
      borderColor2: "#170C05",
      borderColor3: "#170C05",
      textColor1: "#00214D",
      textColor2: "#170C05",
      textColor3: "#FFFFFF",

      background: "#F2F4F6",
      fontFamily: "Roboto",
      fontColor: "#2f3e46",
    },
  },
  COMIC_THEME_4: {
    img: <ComicBash4 />,
    type: "theme_1",
    bannerType: "CLASSIC",
    linkType: "SHARP_1",
    properties: {},

    theme: {
      primaryColor: "#D4D8F0",
      secondaryColor: "#232946",
      accentColor1: "#232946",
      accentColor2: "#EEBBC3",
      borderColor1: "#170C05",
      borderColor2: "#170C05",
      borderColor3: "#170C05",
      textColor1: "#FFFFFF",
      textColor2: "#FFFFFF",
      textColor3: "#170C05",

      background: "#D4D8F0",
      fontFamily: "Roboto",
      fontColor: "#2f3e46",
    },
  },
  COMIC_THEME_5: {
    img: <ComicBash5 />,
    type: "theme_1",
    bannerType: "CLASSIC",
    linkType: "SHARP_1",
    properties: {},

    theme: {
      primaryColor: "#242629",
      secondaryColor: "#16161A",
      accentColor1: "#7F5AF0",
      accentColor2: "#2CB67D",
      borderColor1: "#170C05",
      borderColor2: "#170C05",
      borderColor3: "#170C05",
      textColor1: "#FFFFFF",
      textColor2: "#FFFFFF",
      textColor3: "#FFFFFF",

      background: "#242629",
      fontFamily: "Roboto",
      fontColor: "#fff",
    },
  },
  COMIC_THEME_6: {
    img: <ComicBash6 />,
    type: "theme_1",
    bannerType: "CLASSIC",
    linkType: "SHARP_1",
    properties: {},

    theme: {
      primaryColor: "#F2F7F5",
      secondaryColor: "#00473E",
      accentColor1: "#FAAE2B",
      accentColor2: "#FAAE2B",
      borderColor1: "#170C05",
      borderColor2: "#170C05",
      borderColor3: "#170C05",
      textColor1: "#00473E",
      textColor2: "#FFFFFF",
      textColor3: "#00473E",

      background: "#F2F7F5",
      fontFamily: "Roboto",
      fontColor: "#2f3e46",
    },
  },
  COMIC_THEME_7: {
    img: <ComicBash7 />,
    type: "theme_1",
    bannerType: "CLASSIC",
    linkType: "SHARP_1",
    properties: {},

    theme: {
      primaryColor: "#FDF3E6",
      secondaryColor: "#E5E5E5",
      accentColor1: "#FDBFD2",
      accentColor2: "#FCBFD2",
      borderColor1: "#170C05",
      borderColor2: "#170C05",
      borderColor3: "#170C05",
      textColor1: "#170C05",
      textColor2: "#170C05",
      textColor3: "#170C05",

      background: "#FDF3E6",
      fontFamily: "Roboto",
      fontColor: "#2f3e46",
    },
  },
  COMIC_THEME_8: {
    img: <ComicBash8 />,
    type: "theme_1",
    bannerType: "CLASSIC",
    linkType: "SHARP_1",
    properties: {},

    theme: {
      primaryColor: "#F6A69B",
      secondaryColor: "#E97C7A",
      accentColor1: "#89A5E4",
      accentColor2: "#89A5E4",
      borderColor1: "#170C05",
      borderColor2: "#170C05",
      borderColor3: "#170C05",
      textColor1: "#170C05",
      textColor2: "#170C05",
      textColor3: "#170C05",

      background: "#F6A69B",
      fontFamily: "Roboto",
      fontColor: "#2f3e46",
    },
  },
  FLAT_1: {
    img: <Flat1 />,
    type: "theme_2",
    bannerType: "CURVED",
    linkType: "SHARP_2",
    properties: {},

    theme: {
      primaryColor: "#EAF0F6",
      secondaryColor: "#FFFFFF",
      accentColor1: "#FFFFFF",
      accentColor2: "#33475B",
      borderColor1: "#FFFFFF",
      borderColor2: "#33475B",
      borderColor3: "#33475B",
      textColor1: "#33475B",
      textColor2: "#170C05",
      textColor3: "#FFFFFF",

      background: "#EAF0F6",
      fontFamily: "Roboto",
      fontColor: "#2f3e46",
    },
  },
  FLAT_2: {
    img: <Flat2 />,
    type: "theme_2",
    bannerType: "CURVED",
    linkType: "SHARP_2",
    properties: {},

    theme: {
      primaryColor: "#5AA8F6",
      secondaryColor: "rgba(0, 0, 0, 0.33)",
      accentColor1: "#218AF3",
      accentColor2: "rgba(90, 168, 246, 1)",
      borderColor1: "#218AF3",
      borderColor2: "white", // to test , is none
      borderColor3: "rgba(255, 255, 255, 0.27)",
      textColor1: "#FFFFFF",
      textColor2: "#FFFFFF",
      textColor3: "rgba(255, 255, 255, 1)",

      background: "#5AA8F6",
      fontFamily: "Roboto",
      fontColor: "#fff",
    },
  },
  FLAT_3: {
    img: <Flat3 />,
    type: "theme_2",
    bannerType: "CURVED",
    linkType: "SHARP_2",
    properties: {},

    theme: {
      primaryColor: "#697BAD",
      secondaryColor: "rgba(0, 0, 0, 0.33)",
      accentColor1: "#435A98",
      accentColor2: "rgba(255, 255, 255, 0.27)",
      borderColor1: "#435A98",
      borderColor2: "white", // to test , is none
      borderColor3: "#697BAD",
      textColor1: "#FFFFFF",
      textColor2: "#FFFFFF",
      textColor3: "#FFFFFF",

      background: "#697BAD",
      fontFamily: "Roboto",
      fontColor: "#fff",
    },
  },
  FLAT_4: {
    img: <Flat4 />,
    type: "theme_2",
    bannerType: "CURVED",
    linkType: "SHARP_2",
    properties: {},

    theme: {
      primaryColor: "#748CA6",
      secondaryColor: "rgba(0, 0, 0, 0.33)",
      accentColor1: "#516F90",
      accentColor2: "rgba(255, 255, 255, 0.27)",
      borderColor1: "#516F90",
      borderColor2: "white", // to test , is none
      borderColor3: "#748CA6",
      textColor1: "#FFFFFF",
      textColor2: "#FFFFFF",
      textColor3: "#FFFFFF",

      background: "#748CA6",
      fontFamily: "Roboto",
      fontColor: "#fff",
    },
  },
  FLAT_5: {
    img: <Flat5 />,
    type: "theme_2",
    bannerType: "CURVED",
    linkType: "SHARP_2",
    properties: {},

    theme: {
      primaryColor: "#754BE5",
      secondaryColor: "rgba(0, 0, 0, 0.33)",
      accentColor1: "rgba(0, 0, 0, 0.32)",
      accentColor2: "rgba(255, 255, 255, 0.27)",
      borderColor1: "white", // to test, is none
      borderColor2: "white", // to test , is none
      borderColor3: "white", // to test , is none
      textColor1: "#FFFFFF",
      textColor2: "#FFFFFF",
      textColor3: "#FFFFFF",

      background: "#754BE5",
      fontFamily: "Roboto",
      fontColor: "#fff",
    },
  },
  FLAT_6: {
    img: <Flat6 />,
    type: "theme_2",
    bannerType: "CURVED",
    linkType: "SHARP_2",
    properties: {},

    theme: {
      primaryColor: "#FF2358",
      secondaryColor: "rgba(0, 0, 0, 0.33)",
      accentColor1: "rgba(1, 1, 1, 0.32)",
      accentColor2: "rgba(255, 255, 255, 0.27)",
      borderColor1: "white", // to test, is none
      borderColor2: "white", // to test , is none
      borderColor3: "white", // to test , is none
      textColor1: "rgba(255, 255, 255, 1)",
      textColor2: "#FFFFFF",
      textColor3: "rgba(255, 255, 255, 1)",

      background: "#FF2358",
      fontFamily: "Roboto",
      fontColor: "#fff",
    },
  },
  FLAT_7: {
    img: <Flat7 />,
    type: "theme_2",
    bannerType: "CURVED",
    linkType: "SHARP_2",
    properties: {},

    theme: {
      primaryColor: "#169283",
      secondaryColor: "rgba(0, 0, 0, 0.33)",
      accentColor1: "rgba(1, 1, 1, 0.32)",
      accentColor2: "rgba(255, 255, 255, 0.25)",
      borderColor1: "white", // to test, is none
      borderColor2: "white", // to test , is none
      borderColor3: "white", // to test , is none
      textColor1: "rgba(255, 255, 255, 1)",
      textColor2: "#FFFFFF",
      textColor3: "#FFFFFF",

      background: "#169283",
      fontFamily: "Roboto",
      fontColor: "#fff",
    },
  },
  FLAT_8: {
    img: <Flat8 />,
    type: "theme_2",
    bannerType: "CURVED",
    linkType: "SHARP_2",
    properties: {},

    theme: {
      primaryColor: "rgba(36, 38, 41, 1)",
      secondaryColor: "rgba(0, 0, 0, 0.33)",
      accentColor1: "rgba(1, 1, 1, 0.32)",
      accentColor2: "rgba(255, 255, 255, 0.27)",
      borderColor1: "white", // to test, is none
      borderColor2: "white", // to test , is none
      borderColor3: "white", // to test , is none
      textColor1: "rgba(255, 255, 255, 1)",
      textColor2: "rgba(255, 255, 255, 1)",
      textColor3: "rgba(255, 255, 255, 1)",

      background: "rgba(36, 38, 41, 1)",
      fontFamily: "Roboto",
      fontColor: "#fff",
    },
  },
  GRADIENT_1: {
    img: <Gradient1 />,
    type: "theme_3",
    bannerType: "TRANSLUCENT",
    linkType: "ROUNDED_1",
    properties: {},

    theme: {
      primaryColor:
        "linear-gradient(151deg, rgba(166, 192, 254, 1) 0%, rgba(246, 128, 132, 1) 100%)",
      secondaryColor: "rgba(39, 39, 39, 0.5) 0% 0% no-repeat padding-box",
      accentColor1: "transparent",
      accentColor2: "rgba(255, 255, 255, 1)",
      borderColor1: "rgba(255, 255, 255, 1)",
      borderColor2: "rgba(255, 255, 255, 1)",
      borderColor3: "white", // to test , is none
      textColor1: "rgba(255, 255, 255, 1)",
      textColor2: "rgba(255, 255, 255, 1)",
      textColor3: "rgba(168, 191, 251, 1)",

      background:
        "linear-gradient(151deg, rgba(166, 192, 254, 1) 0%, rgba(246, 128, 132, 1) 100%)",
      fontFamily: "Roboto",
      fontColor: "#fff",
    },
  },
  GRADIENT_2: {
    img: <Gradient2 />,
    type: "theme_3",
    bannerType: "TRANSLUCENT",
    linkType: "ROUNDED_1",
    properties: {},
    theme: {
      primaryColor:
        "linear-gradient(151deg, rgba(79, 172, 254, 1) 0%, rgba(0, 242, 254, 1) 100%)",
      secondaryColor: "rgba(48, 48, 48, 0.5)",
      accentColor1: "transparent",
      accentColor2: "rgba(255, 255, 255, 1)",
      borderColor1: "rgba(255, 255, 255, 1)",
      borderColor2: "white", // to test , is none
      borderColor3: "white", // to test , is none
      textColor1: "rgba(255, 255, 255, 1)",
      textColor2: "rgba(255, 255, 255, 1)",
      textColor3: "rgba(78, 173, 254, 1)",

      background:
        "linear-gradient(151deg, rgba(79, 172, 254, 1) 0%, rgba(0, 242, 254, 1) 100%)",
      fontFamily: "Roboto",
      fontColor: "#fff",
    },
  },
  GRADIENT_3: {
    img: <Gradient3 />,
    type: "theme_3",
    bannerType: "TRANSLUCENT",
    linkType: "SHARP_1",
    properties: {},
    theme: {
      primaryColor:
        "linear-gradient(150deg, rgba(42, 245, 152, 1) 0%, rgba(0, 158, 253, 1) 100%) 0% 0% no-repeat padding-box",
      secondaryColor: "rgba(43, 43, 43, 0.5)",
      accentColor1: "transparent",
      accentColor2: "rgba(255, 255, 255, 1)",
      borderColor1: "rgba(255, 255, 255, 1)",
      borderColor2: "white", // to test , is none
      borderColor3: "white", // to test , is none
      textColor1: "rgba(255, 255, 255, 1)",
      textColor2: "rgba(255, 255, 255, 1)",
      textColor3: "rgba(1, 161, 250, 1)",

      background:
        "linear-gradient(150deg, rgba(42, 245, 152, 1) 0%, rgba(0, 158, 253, 1) 100%) 0% 0% no-repeat padding-box",
      fontFamily: "Roboto",
      fontColor: "#fff",
    },
  },
  GRADIENT_4: {
    img: <Gradient4 />,
    type: "theme_3",
    bannerType: "TRANSLUCENT",
    linkType: "ROUNDED_1",
    properties: {},

    theme: {
      primaryColor:
        "transparent linear-gradient(150deg, rgba(213, 159, 255, 1) 0%, rgba(166, 193, 238, 1) 100%)",
      secondaryColor: "rgba(45, 45, 45, 0.5) 0% 0% no-repeat padding-box",
      accentColor1: "transparent",
      accentColor2: "rgba(255, 255, 255, 1)",
      borderColor1: "rgba(255, 255, 255, 1)",
      borderColor2: "white", // to test , is none
      borderColor3: "white", // to test , is none
      textColor1: "rgba(255, 255, 255, 1)",
      textColor2: "rgba(255, 255, 255, 1)",
      textColor3: "rgba(211, 160, 254, 1)",

      background:
        "transparent linear-gradient(150deg, rgba(213, 159, 255, 1) 0%, rgba(166, 193, 238, 1) 100%)",
      fontFamily: "Roboto",
      fontColor: "#fff",
    },
  },
  GRADIENT_5: {
    img: <Gradient5 />,
    type: "theme_3",
    bannerType: "TRANSLUCENT",
    linkType: "SHARP_1",
    properties: {},

    theme: {
      primaryColor:
        "transparent linear-gradient(150deg, rgba(245, 247, 250, 1) 0%, rgba(195, 207, 226, 1) 100%) 0% 0% no-repeat padding-box",
      secondaryColor: "rgba(196, 208, 226, 0.5) 0% 0% no-repeat padding-box",
      accentColor1: "transparent",
      accentColor2: "#242629",
      borderColor1: "rgba(36, 38, 41, 1)",
      borderColor2: "white", // to test , is none
      borderColor3: "white", // to test , is none
      textColor1: "rgba(27, 28, 31, 1)",
      textColor2: "rgba(36, 38, 41, 1)",
      textColor3: "rgba(255, 255, 255, 1)",

      background:
        "transparent linear-gradient(150deg, rgba(245, 247, 250, 1) 0%, rgba(195, 207, 226, 1) 100%) 0% 0% no-repeat padding-box",
      fontFamily: "Roboto",
      fontColor: "#2f3e46",
    },
  },
  GRADIENT_6: {
    img: <Gradient6 />,
    type: "theme_3",
    bannerType: "TRANSLUCENT",
    linkType: "ROUNDED_1",
    properties: {},

    theme: {
      primaryColor:
        "transparent linear-gradient(151deg, rgba(63, 94, 140, 1) 0%, rgba(18, 235, 131, 1) 100%) 0% 0% no-repeat padding-box",
      secondaryColor: "rgba(45, 45, 45, 0.5) 0% 0% no-repeat padding-box",
      accentColor1: "transparent",
      accentColor2: "rgba(255, 255, 255, 1)",
      borderColor1: "rgba(255, 255, 255, 1)",
      borderColor2: "white", // to test , is none
      borderColor3: "white", // to test , is none
      textColor1: "rgba(255, 255, 255, 1)",
      textColor2: "rgba(255, 255, 255, 1)",
      textColor3: "rgba(62, 98, 140, 1)",

      background:
        "transparent linear-gradient(151deg, rgba(63, 94, 140, 1) 0%, rgba(18, 235, 131, 1) 100%) 0% 0% no-repeat padding-box",
      fontFamily: "Roboto",
      fontColor: "#fff",
    },
  },
  GRADIENT_7: {
    img: <Gradient7 />,
    type: "theme_3",
    bannerType: "TRANSLUCENT",
    linkType: "SHARP_1",
    properties: {},

    theme: {
      primaryColor:
        "transparent linear-gradient(150deg, rgba(236, 122, 60, 1) 0%, rgba(255, 123, 176, 1) 100%) 0% 0% no-repeat padding-box",
      secondaryColor: "rgba(43, 43, 43, 0.5) 0% 0% no-repeat padding-box",
      accentColor1: "transparent",
      accentColor2: "rgba(255, 255, 255, 1)",
      borderColor1: "rgba(255, 255, 255, 1)",
      borderColor2: "white", // to test , is none
      borderColor3: "white", // to test , is none
      textColor1: "rgba(255, 255, 255, 1)",
      textColor2: "rgba(255, 255, 255, 1)",
      textColor3: "rgba(236, 122, 61, 1)",

      background:
        "transparent linear-gradient(150deg, rgba(236, 122, 60, 1) 0%, rgba(255, 123, 176, 1) 100%) 0% 0% no-repeat padding-box",
      fontFamily: "Roboto",
      fontColor: "#fff",
    },
  },
  GRADIENT_8: {
    img: <Gradient8 />,
    type: "theme_3",
    bannerType: "TRANSLUCENT",
    linkType: "ROUNDED_1",
    properties: {},

    theme: {
      primaryColor:
        "transparent linear-gradient(151deg, rgba(97, 248, 241, 1) 0%, rgba(255, 123, 152, 1) 100%) 0% 0% no-repeat padding-box",
      secondaryColor: "rgba(45, 45, 45, 0.5) 0% 0% no-repeat padding-box",
      accentColor1: "transparent",
      accentColor2: "rgba(255, 255, 255, 1)",
      borderColor1: "rgba(255, 255, 255, 1)",
      borderColor2: "white", // to test , is none
      borderColor3: "white", // to test , is none
      textColor1: "rgba(255, 255, 255, 1)",
      textColor2: "rgba(255, 255, 255, 1)",
      textColor3: "rgba(254, 123, 152, 1)",

      background:
        "transparent linear-gradient(151deg, rgba(97, 248, 241, 1) 0%, rgba(255, 123, 152, 1) 100%) 0% 0% no-repeat padding-box",
      fontFamily: "Roboto",
      fontColor: "#fff",
    },
  },
  RETRO_1: {
    img: <Retro1 />,
    type: "theme_4",
    bannerType: "CLASSIC",
    linkType: "SHARP_3",
    properties: {},

    theme: {
      primaryColor: "rgba(255, 255, 255, 1)",
      secondaryColor: "rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box",
      accentColor1: "rgba(255, 255, 255, 1)",
      accentColor2: "rgba(23, 12, 5, 1)",
      borderColor1: "rgba(23, 12, 5, 1)",
      borderColor2: "rgba(23, 12, 5, 1)",
      borderColor3: "rgba(23, 12, 5, 1)",
      textColor1: "rgba(23, 12, 5, 1)",
      textColor2: "rgba(23, 12, 5, 1)",
      textColor3: "rgba(255, 255, 255, 1)",

      background: "rgba(255, 255, 255, 1)",
      fontFamily: "Roboto",
      fontColor: "#2f3e46",
    },
  },
  RETRO_2: {
    img: <Retro2 />,
    type: "theme_4",
    bannerType: "CLASSIC",
    linkType: "SHARP_3",
    properties: {},

    theme: {
      primaryColor: "rgba(216, 239, 85, 1)",
      secondaryColor: "rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box",
      accentColor1: "rgba(112, 151, 255, 1)",
      accentColor2: "rgba(112, 151, 255, 1)",
      borderColor1: "rgba(23, 12, 5, 1)",
      borderColor2: "rgba(23, 12, 5, 1)",
      borderColor3: "rgba(23, 12, 5, 1)",
      textColor1: "rgba(23, 12, 5, 1)",
      textColor2: "rgba(23, 12, 5, 1)",
      textColor3: "rgba(0, 0, 0, 1)",

      background: "rgba(216, 239, 85, 1)",
      fontFamily: "Roboto",
      fontColor: "#2f3e46",
    },
  },
  RETRO_3: {
    img: <Retro3 />,
    type: "theme_4",
    bannerType: "CLASSIC",
    linkType: "SHARP_3",
    properties: {},

    theme: {
      primaryColor: "rgba(252, 149, 213, 1)",
      secondaryColor: "rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box",
      accentColor1: "rgba(74, 211, 196, 1)",
      accentColor2: "rgba(74, 211, 196, 1)",
      borderColor1: "rgba(23, 12, 5, 1)",
      borderColor2: "rgba(23, 12, 5, 1)",
      borderColor3: "rgba(23, 12, 5, 1)",
      textColor1: "rgba(23, 12, 5, 1)",
      textColor2: "rgba(23, 12, 5, 1)",
      textColor3: "rgba(1, 0, 0, 1)",

      background: "rgba(252, 149, 213, 1)",
      fontFamily: "Roboto",
      fontColor: "#2f3e46",
    },
  },
  RETRO_4: {
    img: <Retro4 />,
    type: "theme_4",
    bannerType: "CLASSIC",
    linkType: "SHARP_3",
    properties: {},

    theme: {
      primaryColor: "rgba(199, 168, 247, 1)",
      secondaryColor: "rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box",
      accentColor1: "rgba(255, 204, 30, 1)",
      accentColor2: "rgba(255, 204, 30, 1)",
      borderColor1: "rgba(23, 12, 5, 1)",
      borderColor2: "rgba(23, 12, 5, 1)",
      borderColor3: "rgba(23, 12, 5, 1)",
      textColor1: "rgba(23, 12, 5, 1)",
      textColor2: "rgba(23, 12, 5, 1)",
      textColor3: "rgba(0, 0, 0, 1)",

      background: "rgba(199, 168, 247, 1)",
      fontFamily: "Roboto",
      fontColor: "#2f3e46",
    },
  },
  RETRO_5: {
    img: <Retro5 />,
    type: "theme_4",
    bannerType: "CLASSIC",
    linkType: "SHARP_3",
    properties: {},

    theme: {
      primaryColor: "rgba(56, 179, 210, 1)",
      secondaryColor: "rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box",
      accentColor1: "rgba(107, 100, 206, 1)",
      accentColor2: "rgba(107, 100, 206, 1)",
      borderColor1: "rgba(23, 12, 5, 1)",
      borderColor2: "rgba(23, 12, 5, 1)",
      borderColor3: "rgba(23, 12, 5, 1)",
      textColor1: "rgba(23, 12, 5, 1)",
      textColor2: "rgba(23, 12, 5, 1)",
      textColor3: "rgba(23, 12, 5, 1)",

      background: "rgba(56, 179, 210, 1)",
      fontFamily: "Roboto",
      fontColor: "#2f3e46",
    },
  },
  RETRO_6: {
    img: <Retro6 />,
    type: "theme_4",
    bannerType: "CLASSIC",
    linkType: "SHARP_3",
    properties: {},

    theme: {
      primaryColor: "rgba(250, 229, 232, 1)",
      secondaryColor: "rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box",
      accentColor1: "rgba(234, 146, 146, 1)",
      accentColor2: "rgba(234, 146, 146, 1)",
      borderColor1: "rgba(23, 12, 5, 1)",
      borderColor2: "rgba(23, 12, 5, 1)",
      borderColor3: "rgba(23, 12, 5, 1)",
      textColor1: "rgba(23, 12, 5, 1)",
      textColor2: "rgba(23, 12, 5, 1)",
      textColor3: "rgba(255, 255, 255, 1)",

      background: "rgba(250, 229, 232, 1)",
      fontFamily: "Roboto",
      fontColor: "#2f3e46",
    },
  },
  RETRO_7: {
    img: <Retro7 />,
    type: "theme_4",
    bannerType: "CLASSIC",
    linkType: "SHARP_3",
    properties: {},

    theme: {
      primaryColor: "rgba(248, 243, 231, 1)",
      secondaryColor: "rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box",
      accentColor1: "rgba(83, 210, 255, 1)",
      accentColor2: "rgba(83, 210, 255, 1)",
      borderColor1: "rgba(23, 12, 5, 1)",
      borderColor2: "rgba(23, 12, 5, 1)",
      borderColor3: "rgba(23, 12, 5, 1)",
      textColor1: "rgba(23, 12, 5, 1)",
      textColor2: "rgba(23, 12, 5, 1)",
      textColor3: "rgba(255, 255, 255, 1)",

      background: "rgba(248, 243, 231, 1)",
      fontFamily: "Roboto",
      fontColor: "#2f3e46",
    },
  },
  RETRO_8: {
    img: <Retro8 />,
    type: "theme_4",
    bannerType: "CLASSIC",
    linkType: "SHARP_3",
    properties: {},

    theme: {
      primaryColor: "rgba(157, 188, 184, 1)",
      secondaryColor: "rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box",
      accentColor1: "rgba(190, 170, 140, 1)",
      accentColor2: "rgba(190, 170, 140, 1)",
      borderColor1: "rgba(23, 12, 5, 1)",
      borderColor2: "rgba(23, 12, 5, 1)",
      borderColor3: "rgba(23, 12, 5, 1)",
      textColor1: "rgba(23, 12, 5, 1)",
      textColor2: "rgba(23, 12, 5, 1)",
      textColor3: "rgba(23, 12, 5, 1)",

      background: "rgba(157, 188, 184, 1)",
      fontFamily: "Roboto",
      fontColor: "#2f3e46",
    },
  },
  RETRO_8: {
    img: <Retro8 />,
    type: "theme_4",
    bannerType: "CLASSIC",
    linkType: "SHARP_3",
    properties: {},

    theme: {
      primaryColor: "rgba(157, 188, 184, 1)",
      secondaryColor: "rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box",
      accentColor1: "rgba(190, 170, 140, 1)",
      accentColor2: "rgba(190, 170, 140, 1)",
      borderColor1: "rgba(23, 12, 5, 1)",
      borderColor2: "rgba(23, 12, 5, 1)",
      borderColor3: "rgba(23, 12, 5, 1)",
      textColor1: "rgba(23, 12, 5, 1)",
      textColor2: "rgba(23, 12, 5, 1)",
      textColor3: "rgba(23, 12, 5, 1)",

      background: "rgba(157, 188, 184, 1)",
      fontFamily: "Roboto",
      fontColor: "#2f3e46",
    },
  },
};
