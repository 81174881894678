import Axios from "axios"
import moment from "moment"
import { getHeaders } from "../../../Actions/userAction"
import { metricsUrl } from "../../apis"
import { delay } from "Actions/AnalyticsAction"

const MetricsHandler = async (post, social, brandId, iteration = 1) => {
    if (!post) return
    const platform = social?.toUpperCase()
    const type = post?.platform?.split("_")[1]

    try {
        let startDate = post.publishDate.substring(0, 10)
        let endDate = moment().format("YYYY-MM-DD")
        let params = {
            brandId,
            key: "page",
        }

        if (platform === "PINTEREST") {
            params.startDate = startDate
            params.endDate = endDate
            params.pinId = post?.id
            params.metricsType = "IMPRESSION"
        } else {
            params.postId = post.id || post.postId
            if (type) params.key = type
        }
        const res = await Axios.get(metricsUrl(platform), {
            ...getHeaders(),
            params,
        })
        if (res.data.code !== 200) {
            throw new Error(res.data.msg)
        }

        switch (platform) {
            case "INSTAGRAM":
                return InstagramMetrics(res.data.model)
            case "FACEBOOK":
                return FacebookMetrics(res.data.model)
            case "TWITTER":
                return TwitterMetrics(res.data.model)
            case "LINKEDIN":
                return LinkedinMetrics(res.data.model)
            case "PINTEREST":
                return PinterestMetrics(res.data.model)
            default:
                return FacebookMetrics(res.data.model)
        }
    } catch (error) {
        if (iteration >= 2) {
            console.log(`Error while getting ${platform} post's metrics:`, error)
            return null
        }
        await delay(300)
        return await MetricsHandler(post, social, brandId, iteration + 1)
    }
}

function FacebookMetrics(metrics) {
    return [
        {
            name: "Reactions",
            count: metrics.total_reaction,
            bgClr: "#FDEDF2",
            clr: "#F2547D",
        },
        {
            name: "Shares",
            count: metrics.share_count,
            bgClr: "#F0F1FA",
            clr: "#6A78D1",
        },
        {
            name: "Comment",
            count: metrics.comment_count,
            bgClr: "#E5F8F6",
            clr: "#00A4BD",
        },
    ]
}

function InstagramMetrics(metrics) {
    return [
        {
            name: "Reactions",
            count: metrics.total_reaction,
            bgClr: "#FDEDF2",
            clr: "#F2547D",
        },
        {
            name: "Shares",
            count: metrics.share_count,
            bgClr: "#F0F1FA",
            clr: "#6A78D1",
        },
        {
            name: "Comment",
            count: metrics.comment_count,
            bgClr: "#E5F8F6",
            clr: "#00A4BD",
        },
    ]
}

function TwitterMetrics(metrics) {
    return [
        {
            name: "Reactions",
            count: metrics.total_reaction,
            bgClr: "#FDEDF2",
            clr: "#F2547D",
        },
        {
            name: "Shares",
            count: metrics.share_count,
            bgClr: "#F0F1FA",
            clr: "#6A78D1",
        },
        {
            name: "Comment",
            count: metrics.comment_count,
            bgClr: "#E5F8F6",
            clr: "#00A4BD",
        },
    ]
}

function LinkedinMetrics(metrics) {
    return [
        {
            name: "Reactions",
            count: metrics.total_reaction,
            bgClr: "#FDEDF2",
            clr: "#F2547D",
        },
        {
            name: "Shares",
            count: metrics.share_count,
            bgClr: "#F0F1FA",
            clr: "#6A78D1",
        },
        {
            name: "Comment",
            count: metrics.comment_count,
            bgClr: "#E5F8F6",
            clr: "#00A4BD",
        },
    ]
}

function PinterestMetrics(metrics) {
    return [
        {
            name: "Engagement",
            count: metrics.all.summary_metrics.ENGAGEMENT,
            bgClr: "#FDEDF2",
            clr: "#F2547D",
        },
        {
            name: "Click through",
            count: metrics.all.summary_metrics.CLICKTHROUGH,
            bgClr: "#F0F1FA",
            clr: "#6A78D1",
        },
        {
            name: "Save",
            count: metrics.all.summary_metrics.SAVE,
            bgClr: "#E5F8F6",
            clr: "#00A4BD",
        },
        {
            name: "Closeup",
            count: metrics.all.summary_metrics.CLOSEUP,
            bgClr: "#FDEDF2",
            clr: "#F2547D",
        },
        {
            name: "Impression",
            count: metrics.all.summary_metrics.IMPRESSION,
            bgClr: "#F0F1FA",
            clr: "#6A78D1",
        },
    ]
}

export default MetricsHandler
