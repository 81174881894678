import React from "react"
import { connect } from "react-redux"
import PostCollaboration from "../../PostCollaboration"

const PostCollaborationContainer = ({ post, token, userImageMapping }) => {
    /**Todo: Ask for status how to use */
    return (
        <PostCollaboration
            post={post}
            token={token}
            onStatusChange={(status) => {}}
            userImageMapping={userImageMapping}
        />
    )
}

const mapStateToProps = ({ userReducer, postReducer }) => {
    return {
        userImageMapping: userReducer.workplaceUsers[postReducer.companyId],
    }
}

export default connect(mapStateToProps)(PostCollaborationContainer)
