/**
 * @description These are the social platform post data normalizers which generalizes the data from all the platforms into a given format
 * @param {*} data Data from different formats
 * @returns
 * ```
 * {
 *  title, social, subText, image
 * }
 * ```
 */

const twitter = (data) => ({
    id: data?.data?.id,
    title: data?.data?.name,
    social: "twitter",
    subText: data?.data?.screen_name,
    image: data?.data?.profile_image_url_https,
})
const linkedin = (data) => ({
    id: data.id,
    title: data.localizedFirstName + " " + data.localizedLastName,
    social: "linkedin",
    subText: data.vanityName,
    image: data?.profilePicture?.displayImage,
})
const linkedinPage = (data) => ({
    id: data.id,
    title: data.localizedFirstName + " " + data.localizedLastName,
    social: "linkedin",
    subText: data.vanityName,
    image: data?.profilePicture?.displayImage,
})
const linkedinCompanyPage = (data) => ({
    id: data?.id,
    title: data?.localizedName,
    social: "linkedin",
    subText: data?.vanityName,
    image: data?.logoV2?.["original~"]?.elements?.[0]?.identifiers?.[0]?.identifier,
})
const facebook = (data) => ({
    id: data.id,
    access_token: data?.access_token,
    social: "facebook",
    title: data?.name,
    image: data?.picture?.data?.url ?? data?.cover?.source ?? data?.image,
    subText: data?.name,
})
const facebookCompanyPage = (data) => ({
    id: data.id,
    access_token: data?.access_token,
    social: "facebook",
    title: data?.name,
    image: data?.cover?.source ?? data?.image,
    subText: data?.category || data?.name,
})
const instagram = (data) => ({
    id: data?.id,
    igPageId: data?.instagram_business_account?.id,
    access_token: data?.access_token,
    image: data?.instagram_business_account?.profile_picture_url,
    social: "instagram",
    title: data?.instagram_business_account?.username,
    subText: data?.instagram_business_account?.username,
})
const instagramCompanyPage = (data) => ({
    id: data?.id,
    igPageId: data?.instagram_business_account?.id,
    access_token: data?.access_token,
    image: data?.instagram_business_account?.profile_picture_url,
    social: "instagram",
    title: data?.instagram_business_account?.username,
    subText: data?.instagram_business_account?.username,
})

const pinterest = ({
    id,
    account_type,
    profile_image,
    username,
    website_url,
    access_token,
}) => ({
    id: id,
    access_token: access_token,
    image: profile_image,
    social: "pinterest",
    title: username,
    subText: username,
    type: account_type,
})

const normalizer = {
    twitter,
    linkedin,
    facebook,
    instagram,
    pinterest,
    linkedinCompanyPage,
    facebookCompanyPage,
    instagramCompanyPage,
}

export function accountNormalizer(data, type) {
    return normalizer[type](data)
}

const twitterConnect = (data) => ({
    id: data?.profileId,
    title: data?.userName,
    social: "twitter",
    subText: data?.userName,
    image: data?.profilePicture,
    enable: data?.isUserAccount,
})
const linkedinConnect = (data) => ({
    id: data?.profileId ?? null,
    title: data?.profileName ?? null,
    social: "linkedin",
    subText: data?.profileName ?? null,
    image: data?.profilePicture ?? null,
    enable: data?.isUserAccount ?? null,
})
const linkedinCompanyPageConnect = (data) => ({
    id: data?.pageId,
    title: data?.pageName,
    social: "linkedin",
    subText: data?.pageName,
    image: data?.pagePicture,
    enable: data?.isPageAccount,
})
const facebookConnect = (data) => ({
    id: data.pageId,
    social: "facebook",
    title: data?.pageName,
    image: data?.pagePicture,
    subText: data?.pageName,
    enable: data?.isUserAccount,
})
const facebookCompanyPageConnect = (data) => ({
    id: data.groupId,
    social: "facebook",
    title: data?.groupName,
    image: data?.groupPicture,
    subText: data?.groupName,
    enable: data?.isGroupAccount,
})
const instagramConnect = (data) => ({
    id: data?.pageId,
    igPageId: data?.pageId,
    image: data?.pagePicture,
    social: "instagram",
    title: data?.pageName,
    subText: data?.pageName,
    enable: data?.isUserAccount,
})

const pinterestConnect = ({
    profileId,
    profilerPicture,
    userName,
    boards,
    isUserAccount,
}) => ({
    id: profileId,
    image: profilerPicture,
    social: "pinterest",
    title: userName,
    subText: userName,
    boards: boards,
    enable: isUserAccount,
})

const normalizerConnect = {
    twitterConnect,
    linkedinConnect,
    facebookConnect,
    instagramConnect,
    pinterestConnect,
    linkedinCompanyPageConnect,
    facebookCompanyPageConnect,
}

export function socialNormalizer(data, type) {
    return normalizerConnect[type + "Connect"](data)
}
