import React from "react"
import "./icon.scss"
import { ReactComponent as LinkedInCompanySVG } from "../../icons/socialIcons/linkedin-company-page.svg"

const LinkedInCompanyIcon = ({ active, style }) => {
    return (
        <div
            className={active ? "social-icon-active" : "social-icon-inactive"}
            style={style}
        >
            <LinkedInCompanySVG />
        </div>
    )
}

export default LinkedInCompanyIcon
