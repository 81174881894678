import { createMessage } from "Actions/messageAction"
import Axios from "axios"
import { getHeaders, getOrg } from "../../../Actions/userAction"

/**
 * mediaHandler for handling all media related related stuff
 * use by initalizing it with the end point and then use the handler provided by this.
 *
 * @example
 * ```
 *     const useMedia = new mediaHandler(your auth token);
 * ```
 */
class mediaHandler {
    constructor(apiEndPoint, relatedId) {
        this.mediaApi = Axios.create({
            baseURL: apiEndPoint,
        })
        this.relatedId = relatedId
    }

    async getBobFromFile(file) {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = function (e) {
                    const dataWithOtherInfo = e.target.result.split(",")[1]
                    resolve(dataWithOtherInfo)
                }
            } catch (error) {
                reject("Failed to Get the uploaded file data")
            }
        })
    }

    /**
     * Upload file to  the server and get the media id
     *
     * @example
     * ```
     *     {
     *      "id": 9,
     *      "mediaId": "media_1630355551",
     *      "mediaType": "image/jpeg",
     *      "mediaPath": "https://com-awrsomesuite-media-service.s3.ap-south-1.amazonaws.com/media_1630355551test.jpeg",
     *      "isEnabled": 1,
     *      "userId": 12,
     *      "relatedId": "",
     *      "status": "success"
     *     }
     *```
     *
     * */
    async uploadMedia(file, postMedia) {
        if (!(file instanceof File)) return
        if(!postMedia && file.size > 5242880){
            createMessage("danger" , "File size greater than 5mb, Please upload smaller media")
            return null
        }

        let fileNameToSend = file.name
        fileNameToSend = fileNameToSend.replace(/ /g,"_")

        try {
            const fileToSend = await this.getBobFromFile(file)
            let org = getOrg()
            const data = {
                fileName: fileNameToSend,
                dataType: file.type,
                data: fileToSend,
                relatedId: this.relatedId,
                productId: 1,
                organisationId: org?.id
            }
            const header = getHeaders()
            const mediaUploadResult = await this.mediaApi.post(
                "/media?action=upload",
                data,
                header
            )

            if (mediaUploadResult.data.code === 200) {
                return mediaUploadResult.data.model
            } else {
                return new Error(
                    mediaUploadResult.data.msg ?? "Could not upload the file due to some technical issue"
                )
            }
        } catch (error) {
            createMessage("danger", "Could not upload the media")
        }
    }

    async uploadMediaOther(fileName, type, fileId, platform = "drive", token) {
        try {
            let org = getOrg()
            const data = {
                fileName: fileName,
                dataType: type || "image/jpeg" ,
                fileId: fileId,
                relatedId: this.relatedId,
                productId: 1,
                token: token,
                organisationId: org?.id
            }

            const header = getHeaders()
            const mediaUploadResult = await this.mediaApi.post(
                `/media_platform?platform=${platform}`,
                data,
                header
            )

            if (mediaUploadResult.data.code === 200) {
                return mediaUploadResult.data.model
            } else {
                return new Error(
                    "Could not upload the file due to some technical issue"
                )
            }
        } catch (error) {
            throw new Error("Could not upload the media")
        }
    }

    /**
     * get Uploaded media details with the media id
     *
     * @example
     * ```
     *     {
     *      "id": 9,
     *      "mediaId": "media_1630355551",
     *      "mediaType": "image/jpeg",
     *      "mediaPath": "https://com-awrsomesuite-media-service.s3.ap-south-1.amazonaws.com/media_1630355551test.jpeg",
     *      "isEnabled": 1,
     *      "userId": 12,
     *      "relatedId": "",
     *      "status": "success"
     *     }
     *```
     *
     * */
    async getMediaById(mediaId) {
        if (!mediaId)
            return new Error("Could not find the media id in the parameters")

        try {
            const headers = getHeaders()
            const mediaUploadResult = await this.mediaApi.get(
                `/media?media_id=${mediaId}`,
                headers
            )

            if (mediaUploadResult.data.code === 200) {
                return mediaUploadResult.data.model
            } else {
                return new Error(
                    "Could not load the file due to some technical issue"
                )
            }
        } catch (error) {
            return new Error("Could not upload the media")
        }
    }

    /**
     * get all Uploaded media details for the user using the auth token
     *
     * @example
     * ```
     *     [ {
     *      "id": 9,
     *      "mediaId": "media_1630355551",
     *      "mediaType": "image/jpeg",
     *      "mediaPath": "https://com-awrsomesuite-media-service.s3.ap-south-1.amazonaws.com/media_1630355551test.jpeg",
     *      "isEnabled": 1,
     *      "userId": 12,
     *      "relatedId": "",
     *      "status": "success"
     *     } ]
     *```
     *
     * */
    async getAllMediaForUser() {
        try {
            const headers = getHeaders()
            const mediaUploadResult = await this.mediaApi.get(`/media`, headers)

            if (mediaUploadResult.data.code === 200) {
                return mediaUploadResult.data.model
            } else {
                return new Error(
                    "Could not load the file due to some technical issue"
                )
            }
        } catch (error) {
            return new Error("Could not upload the media")
        }
    }
}

export default mediaHandler
