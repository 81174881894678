import Axios from "axios"
import { getHeaders } from "../../../Actions/userAction"
import {
    linkedApiUrl,
    socialPostsApi,
    getAllPlatformPosts,
    baseURL,
} from "../../apis"
import { associateWorkflowWithPost } from "../../../Actions/workflowAction"
import { LINKED_IN } from "../../../Data/SocialMedialTypes"
import { createMessage } from "../../../Actions/messageAction"
import { LINKEDIN_PAGE, LINKEDIN_USER } from "../../../constants"
import { DRAFT, SCHEDULE } from "../Types/postStatusTypes"
import {
    deleteFirstComment,
    postFirstComment,
    updateFirstComment,
} from "Actions/postAction"

const linkedInApi = Axios.create({
    baseURL: socialPostsApi("linkedin"),
})

/**
 * generic or Atomic api to post as a company or user to the linked account.
 *
 * @param {object} data { text, path, brandId, publish, status.... } it contains the
 *      post related data
 * @param {*} authToken auth token for the current user.
 * @param {*} configuration configuration to define the type of posting social company or user.
 *                          - media for the file object
 *
 * @returns Error | reposes data of api.
 */
export const createLinkedInPost = async (data, configuration, firstComment) => {
    //TODO: check if we need on response or two....
    if (!data) return
    let response

    try {
        if (configuration.companyPost) {
            response = await postToCompanyFeed(data, configuration)
        } else {
            response = await postToUserFeed(data, configuration)
        }

        if (!response.code) {
            if (
                (response.status === SCHEDULE || response.status === DRAFT) &&
                response.isApprovalRequired === 1
            ) {
                const platform = configuration.companyPost
                    ? LINKEDIN_PAGE
                    : LINKEDIN_USER
                await associateWorkflowWithPost(data.brandId, response.id, platform)
            }

            if (firstComment) {
                postFirstComment(
                    LINKED_IN,
                    response.brandId,
                    response.id,
                    firstComment,
                    null,
                    response
                )
            }
            return response
        } else {
            return {
                status: "ERROR",
                failureReason: response.msg,
                platform: configuration.companyPost ? LINKEDIN_PAGE : LINKEDIN_USER,
                data,
            }
        }
    } catch (error) {
        console.log({ error })
        return {
            status: "ERROR",
            failureReason: "Couldn't create linkedin post.",
            platform: configuration.companyPost ? LINKEDIN_PAGE : LINKEDIN_USER,
            data,
        }
    }
}

/**
 * make an atomic api call with lots of filters and get post array or
 * single posts of  in return.
 *
 * TODO: Do the error handling.
 *
 * @param {*} config { brandId , postId, status, userId }
 *
 * @return if postId is not present then it will return the
 * Array of the posts.
 * or if the postId is present then Single post will be return.
 */
export const getLinkedInPost = async (config) => {
    if (!config.brandId) return

    let headers = getHeaders()

    if (!headers) return

    let params = {
        brandId: config.brandId,
        platform: config.key ? `linkedin_${config.key}` : LINKED_IN,
    }

    if (config.status) params.status = config.status
    if (config.page) params.page = config.page
    if (config.limit) params.limit = config.limit
    if (config.postId) params.postId = config.postId
    if (config.sortBy) params.sortBy = config.sortBy
    if (config.id) params.dbId = config.id
    if (config.fromDate) params.fromDate = config.fromDate
    if (config.toDate) params.toDate = config.toDate
    if (config.orderBy) params.orderBy = config.orderBy
    params.key = "posts"

    headers.params = params

    try {
        const result = await Axios.get(getAllPlatformPosts, headers)

        if (result.data.code === 200) {
            //normalize post data based on respective social platform
            let posts = result.data.model.map((post) => ({
                ...post,
                path: post.mediaUrl,
            }))
            return posts
        } else return null
    } catch (error) {
        return null
    }
}

export const getLinkedInPostById = async (config) => {
    try {
        if (!config.brandId) return

        let headers = getHeaders()
        if (!headers) return

        headers.params = {
            brandId: config.brandId,
            dbId: config.id,
        }

        let response = await Axios.get(linkedApiUrl, headers)

        return response.data.model
    } catch (err) {
        console.log(err)
        return null
    }
}

/**
 * Post metric starts here.
 * get likes, comments and other metric related stuff.
 */

/**
 * generic api to get the post metric - likes, comments and summery.
 * @param {*} data -  data contains {postId, brandId}
 * @param {*} config - "likes" to enable likes, "comments" to enable comments
 */
async function getPostMetric(data, config) {
    let headersData = config.headers

    if (!headersData) {
        headersData = getHeaders(config.token)
    }

    let key = null

    if (config.likes) key = "likes"

    if (config.comments) key = "comments"

    let response = await Axios.get(
        `${linkedApiUrl}/socialactions/?brandId=${data.brandId}&postId=${
            data.postId
        }${key && "&key=" + key}}`,
        headersData
    )

    return response.data
}

/**
 * single api to get the like on individual post.
 *
 * @param {*} data - {postId, brandId}.
 * @param {*} authToken - auth token of the current user.
 *
 * @returns Error | model response.
 */
export const getLinkedPostLikes = async (data, authToken) => {
    let headers = getHeaders()

    let response
    try {
        response = await getPostMetric(data, { headers, likes: true })

        if (response.model) {
            return response.model
        } else {
            return new Error(response.msg)
        }
    } catch (error) {
        return new Error(
            "could not get the likes for the post",
            data.brandId,
            data.postId
        )
    }
}

/**
 * single api to get the comments on individual post.
 *
 * @param {*} data - {postId, brandId}.
 * @param {*} authToken - auth token of the current user.
 *
 * @returns Error | model response.
 */
export const getLinkedPostComments = async (data, authToken) => {
    let headers = getHeaders()

    let response
    try {
        response = await getPostMetric(data, { headers, comments: true })

        if (response.model) {
            return response.model
        } else {
            return new Error(response.msg)
        }
    } catch (error) {
        return new Error(
            "could not get the likes for the post",
            data.brandId,
            data.postId
        )
    }
}

/**
 * single api to get the summary of individual post.
 *
 * @param {*} data - {postId, brandId}.
 * @param {*} authToken - auth token of the current user.
 *
 * @returns Error | model response.
 */
export const getLinkedPostSummery = async (data, authToken) => {
    let headers = getHeaders()

    let response
    try {
        response = await getPostMetric(data, { headers })

        if (response.model) return response.model
        else return new Error(response.msg)
    } catch (error) {
        return new Error(
            "could not get the likes for the post",
            data.brandId,
            data.postId
        )
    }
}

/**
 * Create Posts metric starts here.
 */
// request body data Object
let mockRequestObj = {
    brandId: 1, // brand id
    text: "", // text of the post
    postType: "", // post type image
    mediaContent: [],
    tag: "", // add this feature later
    label: "",
    publishDate: "2022-03-25T02:33:01.6676075+05:30",
    status: "",
    version: "",
    isApprovalRequired: 1,
    timeZone: "",
    key: "user",
    created_on: null,
}

/**
 * singleton api to post to user feed only this is for internal use only not for export.
 *
 * @param {object} data { text, path, brandId, publish, status.... } it contains the
 *      post related data
 * @param {*} config contains { headers }
 *
 */
async function postToUserFeed(data, config) {
    let headersData = config.headers
    let request = { ...mockRequestObj }

    if (!headersData) {
        headersData = getHeaders()
    }

    Object.keys(data).map((item) => {
        if (request.hasOwnProperty(item)) {
            request[item] = data[item]
        }
    })
    request.platform = LINKEDIN_USER

    if (request.postType === "APPLICATION") {
        request.postType = "DOCUMENT"
        request.mediaContent[0].mediaType = "DOCUMENT"
    }

    try {
        let postResult = await Axios.post(`${linkedApiUrl}`, request, headersData)

        if (postResult.data.code === 200) return postResult.data.model
        else {
            return postResult.data
        }
    } catch (error) {
        return { code: 400, msg: "Failed Facebook post creation." }
    }
}

/**
 * singleton api to post to company feed only this is for internal use only not for export.
 *
 * @param {object} data { text, path, brandId, publish, status.... } it contains the
 *      post related data
 * @param {*} config contains { headers }
 */
async function postToCompanyFeed(data, config) {
    let headersData = getHeaders()
    let request = { ...mockRequestObj }

    Object.keys(data).forEach((item) => {
        if (request.hasOwnProperty(item)) {
            request[item] = data[item]
        }
    })
    request.platform = LINKEDIN_PAGE

    if (request.publishDate?.length === 0) delete request.publishDate
    if (request.postType === "APPLICATION") {
        request.postType = "DOCUMENT"
        request.mediaContent[0].mediaType = "DOCUMENT"
    }
    try {
        let postResult = await Axios.post(`${linkedApiUrl}`, request, headersData)

        if (postResult.data.code === 200) return postResult.data.model
        else {
            return postResult.data
        }
    } catch (error) {
        return { code: 400, msg: "Failed Linkedin post creation." }
    }
}

export const updateLinkedinPost = async (post) => {
    const headers = getHeaders()
    try {
        const updateResult = await linkedInApi.put("", post, headers)

        if (updateResult.data.code === 200) {
            if (post.firstComment && post.firstComment[LINKED_IN]) {
                let commentFunction = post.commentId
                    ? updateFirstComment
                    : postFirstComment

                await commentFunction(
                    LINKED_IN,
                    updateResult.data.model.brandId,
                    updateResult.data.model.id,
                    post.firstComment[LINKED_IN],
                    null,
                    updateResult.data.model
                )
            } else if (post.commentId) {
                await deleteFirstComment(
                    LINKED_IN,
                    updateResult.data.model.brandId,
                    updateResult.data.model.id
                )
            }
            return updateResult.data.model
        } else {
            return new Error(updateResult.data.msg)
        }
    } catch (error) {
        return new Error("Linkedin post updation failed.")
    }
}

export const editLinkedinPostSchedule = async (post) => {
    const headers = getHeaders()
    headers.params = {
        key: LINKED_IN,
    }
    const newData = {
        ...post,
    }
    try {
        const res = await linkedInApi.put("", newData, headers)
        if (res.data.model) {
            // associateWorkflow(res.data.model.id) // Dont know what this is for
            createMessage("success", res.data.msg)
            return res.data.model
        }
        return null
    } catch (err) {
        createMessage("danger", err.response.data)
        return null
    }
}

export const getLinkedinPostNew = async (brandId, limit, social) => {
    let headers = getHeaders()
    try {
        let url = `${baseURL}/v2/linkedin/publish/posts?brand_id=${brandId}`
        if (limit) url += `&limit=${limit}`
        if (social) url += `&platform=${social}`
        const result = await Axios.get(url, headers)
        if (result?.data?.code !== 200) return null
        return result.data
    } catch (error) {
        return null
    }
}

export const getLinkedinPostNext = async (brandId, next, limit, platform) => {
    let headers = getHeaders()
    try {
        let url = `${baseURL}/v2/linkedin/publish/posts?brand_id=${brandId}&after=${next}`
        if (limit) url += `&limit=${limit}`
        if (platform) url += `&platform=${platform}`
        const result = await Axios.get(url, headers)
        if (result?.data?.code !== 200) return null
        return result.data
    } catch (error) {
        return null
    }
}

export const getLinkedinPostByDateNew = async ({
    brandId,
    fromDate,
    toDate,
    platform,
}) => {
    let headers = getHeaders()
    try {
        let url = `${baseURL}/v2/linkedin/publish/posts?brand_id=${brandId}&start_date=${fromDate}&end_date=${toDate}`
        if (platform) url += `&platform=${platform}`
        const result = await Axios.get(url, headers)
        if (result?.data?.code !== 200) return null
        return result.data
    } catch (error) {
        console.log(error)
        createMessage("danger", "Error while getting posts")
        return null
    }
}
