import { useEffect, useState } from "react"

/**
 * @description Hook to toggle the component, specially if outside click
 * @param {Ref} ref
 * @param {Boolean} outsideClick default : True
 * @returns [visible, toggle]
 */
const ToggleHook = (ref, outsideClick = true) => {
    const [visible, toggle] = useState(false)

    useEffect(() => {
        function handleClick(e) {
            if (!visible) return
            if (
                !(
                    ref &&
                    ref.current &&
                    ref.current.contains(e.target) &&
                    outsideClick
                )
            ) {
                toggle(false)
            }
        }
        document.addEventListener("click", handleClick)
        return () => document.removeEventListener("click", handleClick)
    })

    function togglable() {
        if (visible) toggle(false)
        else toggle(true)
    }

    return [visible, togglable]
}

export default ToggleHook
