import React, { forwardRef, useCallback, useState } from "react"
import Dialog from "./Dialog"
import ExternalLibConnector from "./ExternalLibsConnector"
import { ReactComponent as Image } from "../../icons/image-light.svg"
import "./MediaSelectionDialog.scss"

const MediaSelectionDialog = forwardRef(({ files, companyId, invokeByLinkedin, postType, ...props }, ref) => {
    const _C = "aui-media-selection-dialog-wrapper"
    const [open, setOpen] = useState(false)

    const show = useCallback(() => {
        setOpen(true)
    }, [])
    ref.current = show

    function close() {
        setOpen(false)
    }

    return (
        <div className={_C + "-container"}>
            <div
                onClick={show}
                style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            >
                <Image />
            </div>
            <ExternalLibConnector open={open} onClose={close}>
                <Dialog onClose={close} {...props} files={files} companyId={companyId} invokeByLinkedin={invokeByLinkedin}
                    postType={postType}
                />
            </ExternalLibConnector>
        </div>
    )
})

export default MediaSelectionDialog
