import React, { useEffect, lazy, Suspense, useLayoutEffect } from "react"
import { Switch, Route, Redirect, useHistory } from "react-router-dom"
import { connect } from "react-redux"

import { useTracking } from "react-tracking"
import { PAGE_VIEW } from "./constants"
import Guard from "Awesome-ui/Guard"
import { setSocialDetails } from "Actions/socialAction"
import RedirectSignIn from "./components/formPages/signIn/RedirectSignin"

const WorkPlace = lazy(() => import("./components/Pages/WorkPlace"))
const Setting = lazy(() => import("./components/Pages/Setting"))
const ComposePage = lazy(() => import("./components/Pages/Publish"))
const SettingPage = lazy(() => import("./components/Pages/SettingPages"))
const WorkflowPage = lazy(() => import("./components/Pages/WorkflowPage"))
const CollaborationPage = lazy(() => import("./components/Pages/Collaboration"))

const Publish = lazy(() => import("./components/Pages/Social/SubRoutes/Publish"))
const Inbox = lazy(() => import("components/Pages/Social/SubRoutes/Inbox"))
const Team = lazy(() => import("components/Pages/Social/SubRoutes/Team"))
const Heybio = lazy(() => import("components/Pages/Social/SubRoutes/Heybio"))

const DashBoard = lazy(() => import("./components/Templates/DashBoard"))
const Social = lazy(() => import("./components/Templates/Social"))
const Compose = lazy(() => import("./components/Templates/Publish"))
const Collaboration = lazy(() => import("./components/Templates/Collaboration"))

const OauthCallback = lazy(() =>
    import("./components/ExtraPages/callbackUrls/oauthCallback")
)
const SimpleDashboard = lazy(() => import("./components/Templates/SimpleDashboard"))

const Organizations = lazy(() =>
    import("./components/Pages/Organizations/Organizations")
)
const CreateBrand = lazy(() =>
    import("components/Pages/CreateBrand/CreateBrand/CreateBrand")
)
const InviteTeam = lazy(() =>
    import("components/Pages/CreateBrand/InviteTeam/InviteTeam")
)
const ConnectSocial = lazy(() =>
    import("components/Pages/CreateBrand/ConnectSocial/ConnectSocial")
)
const OnBoardBrand = lazy(() =>
    import("./components/Pages/OnboardingPages/OnBoardBrand/OnBoardBrand")
)
const OnBoardSocial = lazy(() =>
    import("./components/Pages/OnboardingPages/OnBoardSocial/OnBoardSocial")
)
const InvitesMembers = lazy(() =>
    import("./components/Pages/OnboardingPages/InviteMenbers/InvitesMembers")
)
const OnBoardingDone = lazy(() =>
    import("./components/Pages/OnboardingPages/SuccessPage/OnBoardingDone")
)
const Workspace = lazy(() =>
    import("./components/Pages/OnboardingPages/Workspace/Workspace")
)
const GettingStarted = lazy(() => import("components/Templates/GettingStarted"))
const Error = lazy(() => import("./components/Pages/Error/Error"))
const Analytics = lazy(() => import("./components/Pages/Analytics/Analytics"))
const BetaTester = lazy(() => import("./components/Pages/BetaTesterPage"))
const TwitterCallback = lazy(() =>
    import("components/Pages/auth-callback/twitterCallback")
)
const Tools = lazy(() => import("components/Pages/Tools/Tools"))
const Settings = lazy(() => import("components/Pages/Social/SubRoutes/Settings"))

const redirectRoute = [
    "/",
    "/publish",
    "/collaboration",
    "/account-setting",
    "/account-setting/:type",
    "/social/publish/:socialPlatform/:company/:brand/:subType",
    "/social/inbox/:socialPlatform/:company/:brand/:subType",
    "/social/team/:socialPlatform/:company/:brand/:subType",
    "/social/hey-bio/:socialPlatform/:company/:brand/:subType",
    "/account-setting",
    "/account-setting/:settingView",
    "/organizations",
]

function Routes({ token, groups, isOnBoarding, workspaceFilled }) {
    const history = useHistory()
    const { Track, trackEvent } = useTracking()

    useLayoutEffect(() => {
        function trackPageView() {
            trackEvent({ type: PAGE_VIEW, page: window.location.pathname })
        }

        history.listen(trackPageView)
    }, [])

    useEffect(() => {
        if (workspaceFilled && (!groups || groups?.length === 0)) {
            history.push("/onboarding/createWorkspace")
        }
    })

    const redirects = () => {
        let links = []
        redirectRoute.map((route, i) => {
            return links.push(<Redirect from={`${route}`} to="/login" key={i} />)
        })
        return links
    }

    /**
     * This will be used to collect the user data from the app.
     * data related to the user most visit, favourite and business
     * realted activity etc.
     */
    function trackEventHandler(page, type, data) {
        trackEvent({ page, type, ...data })
    }

    return (
        <Suspense fallback={<div></div>}>
            <Switch>
                <Redirect from="/workplace" to="/" />
                <Route exact from="/error" component={Error} />
                <Route
                    exact
                    path="/login"
                    render={({ history, location }) => (
                        <RedirectSignIn
                            params={new URLSearchParams(location.search)}
                            history={history}
                        />
                    )}
                />
                {!token && redirects()}
                {!isOnBoarding && token && <Redirect from="/login" to="/" />}
                <Route
                    exact
                    path="/getting-started"
                    render={() => (
                        <Track>
                            <GettingStarted />
                        </Track>
                    )}
                />
                <Route
                    exact
                    path="/twitter/callback"
                    render={() => <TwitterCallback />}
                />
                <Route
                    exact
                    path="/onboarding/createWorkspace"
                    render={() => (
                        <Track>
                            <Workspace track={trackEventHandler} />
                        </Track>
                    )}
                />
                <Route
                    exact
                    path="/onboarding/createBrand"
                    render={() => (
                        <Track>
                            <OnBoardBrand track={trackEventHandler} />
                        </Track>
                    )}
                />
                <Route
                    exact
                    path="/onboarding/:companyId/:brandId/connectSocial"
                    render={() => (
                        <Track>
                            <OnBoardSocial track={trackEventHandler} />
                        </Track>
                    )}
                />
                <Route
                    exact
                    path="/onboarding/inviteTeam"
                    render={() => (
                        <Track>
                            <InvitesMembers track={trackEventHandler} />
                        </Track>
                    )}
                />
                <Route
                    exact
                    path="/onboarding/done"
                    render={() => <OnBoardingDone />}
                />
                <Route
                    exact
                    path="/oauthcallback"
                    render={(routeProps) => <OauthCallback {...routeProps} />}
                />
                <Route
                    exact
                    path="/brand/create-brand"
                    render={() => (
                        <Track>
                            <CreateBrand track={trackEventHandler} />
                        </Track>
                    )}
                />
                <Route
                    exact
                    path="/:companyId/brand/:brandId/connect-social"
                    render={() => (
                        <Track>
                            <ConnectSocial track={trackEventHandler} />
                        </Track>
                    )}
                />
                <Route
                    exact
                    path="/:companyId/brand/:brandId/invite-team"
                    render={() => (
                        <Track>
                            <InviteTeam track={trackEventHandler} />
                        </Track>
                    )}
                />
                <Route
                    exact
                    path="/organizations"
                    render={(routeProps) => (
                        <Track>
                            <SimpleDashboard>
                                <Organizations track={trackEventHandler} />
                            </SimpleDashboard>
                        </Track>
                    )}
                />
                <Route
                    exact
                    path="/"
                    render={(routeProps) => (
                        <DashBoard
                            {...routeProps}
                            type="workplace"
                            track={trackEventHandler}
                        >
                            <WorkPlace track={trackEventHandler} />
                        </DashBoard>
                    )}
                />
                <Route
                    exact
                    path="/account-setting"
                    render={(routeProps) => (
                        <Track>
                            <DashBoard
                                {...routeProps}
                                type="setting"
                                track={trackEventHandler}
                                noSubNav={true}
                            >
                                <Setting track={trackEventHandler} />
                            </DashBoard>
                        </Track>
                    )}
                />
                <Route
                    exact
                    path="/account-setting/:settingView"
                    render={(routeProps) => (
                        <Track>
                            <DashBoard
                                {...routeProps}
                                type="setting"
                                track={trackEventHandler}
                                noSubNav={true}
                            >
                                <SettingPage
                                    {...routeProps}
                                    track={trackEventHandler}
                                />
                            </DashBoard>
                        </Track>
                    )}
                />
                <Route
                    path="/social/publish/:socialPlatform/:company/:brand/:subType"
                    render={(routeProps) => {
                        const { socialPlatform, subType, brand, company } =
                            routeProps.match.params
                        setSocialDetails(
                            routeProps.match.params,
                            socialPlatform,
                            subType,
                            brand,
                            company
                        )
                        return (
                            <Track>
                                <Social
                                    {...routeProps}
                                    page="publish"
                                    track={trackEventHandler}
                                >
                                    <Publish
                                        {...routeProps}
                                        track={trackEventHandler}
                                    />
                                </Social>
                            </Track>
                        )
                    }}
                />
                <Route
                    path="/social/inbox/:socialPlatform/:company/:brand/:subType"
                    render={(routeProps) => {
                        const { socialPlatform, subType, brand, company } =
                            routeProps.match.params
                        setSocialDetails(
                            routeProps.match.params,
                            socialPlatform,
                            subType,
                            brand,
                            company
                        )
                        return (
                            <Track>
                                <Social
                                    {...routeProps}
                                    page="inbox"
                                    track={trackEventHandler}
                                >
                                    <Inbox
                                        {...routeProps}
                                        track={trackEventHandler}
                                    />
                                </Social>
                            </Track>
                        )
                    }}
                />
                <Route
                    path="/social/team/:socialPlatform/:company/:brand/:subType"
                    render={(routeProps) => {
                        const { socialPlatform, subType, brand, company } =
                            routeProps.match.params
                        setSocialDetails(
                            routeProps.match.params,
                            socialPlatform,
                            subType,
                            brand,
                            company
                        )
                        return (
                            <Track>
                                <Social
                                    {...routeProps}
                                    page="team"
                                    track={trackEventHandler}
                                >
                                    <Team
                                        {...routeProps}
                                        track={trackEventHandler}
                                    />
                                </Social>
                            </Track>
                        )
                    }}
                />
                <Route
                    path="/social/hey-bio/:socialPlatform/:company/:brand/:subType"
                    render={(routeProps) => {
                        const { socialPlatform, subType, brand, company } =
                            routeProps.match.params
                        setSocialDetails(
                            routeProps.match.params,
                            socialPlatform,
                            subType,
                            brand,
                            company
                        )
                        return (
                            <Track>
                                <Social
                                    {...routeProps}
                                    page="hey-bio"
                                    track={trackEventHandler}
                                >
                                    <Heybio
                                        {...routeProps}
                                        track={trackEventHandler}
                                    />
                                </Social>
                            </Track>
                        )
                    }}
                />
                <Route
                    path="/social/analytics/:socialPlatform/:company/:brand/:subType"
                    render={(routeProps) => {
                        const { socialPlatform, subType, brand, company } =
                            routeProps.match.params
                        setSocialDetails(
                            routeProps.match.params,
                            socialPlatform,
                            subType,
                            brand,
                            company
                        )
                        return (
                            <Track>
                                <Social
                                    {...routeProps}
                                    page="analytics"
                                    track={trackEventHandler}
                                >
                                    <Analytics
                                        {...routeProps}
                                        track={trackEventHandler}
                                    />
                                </Social>
                            </Track>
                        )
                    }}
                />
                <Route
                    exact
                    path="/publish"
                    render={(routeProps) => (
                        <Track>
                            <Compose
                                {...routeProps}
                                type="publish"
                                track={trackEventHandler}
                            >
                                <ComposePage track={trackEventHandler} />
                            </Compose>
                        </Track>
                    )}
                />
                <Route
                    path="/social/settings/:socialPlatform/:company/:brand/:subType"
                    render={(routeProps) => {
                        const { socialPlatform, subType, brand, company } =
                            routeProps.match.params
                        setSocialDetails(
                            routeProps.match.params,
                            socialPlatform,
                            subType,
                            brand,
                            company
                        )
                        return (
                            <Track>
                                <Social
                                    {...routeProps}
                                    page="settings"
                                    track={trackEventHandler}
                                >
                                    <Settings
                                        {...routeProps}
                                        track={trackEventHandler}
                                    />
                                </Social>
                            </Track>
                        )
                    }}
                />
                <Route
                    exact
                    path="/creator-studio"
                    render={(routeProps) => (
                        <Track>
                            <Tools {...routeProps} track={trackEventHandler} />
                        </Track>
                    )}
                />
                <Route
                    exact
                    path="/workflow"
                    render={(routeProps) => (
                        <Track>
                            <Compose
                                {...routeProps}
                                type="publish"
                                track={trackEventHandler}
                            >
                                <WorkflowPage track={trackEventHandler} />
                            </Compose>
                        </Track>
                    )}
                />
                <Guard scope="collaborate_get">
                    <Route
                        exact
                        path="/team"
                        render={(routeProps) => (
                            <Track>
                                <Collaboration
                                    {...routeProps}
                                    type="collaboration"
                                    track={trackEventHandler}
                                >
                                    <CollaborationPage track={trackEventHandler} />
                                </Collaboration>
                            </Track>
                        )}
                    />
                </Guard>

                <Route
                    exact
                    path="/beta-tester"
                    render={(routeProps) => <BetaTester {...routeProps} />}
                />

                <Redirect from="/permission/:id" to="/permission" />
                <Redirect from="/role/:id" to="/role" />
                <Redirect from="/:id" to="/error" />
            </Switch>
        </Suspense>
    )
}

const mapStateToProps = ({ userReducer, workplaceReducer }) => ({
    token: userReducer.token,
    workspaceFilled: workplaceReducer.fill,
    groups: workplaceReducer.groups,
    isOnBoarding: userReducer.isOnBoarding,
})

export default connect(mapStateToProps)(Routes)
