import React, { useState, useEffect, useRef } from "react"
import TagIcon from "../TagIcon"
import useToggle from "../../../hooks/ToggleHook"
import "./listPicker.scss"
import TooltipWrapper from "components/commonComps/Tooltip/TooltipWrapper"

/**
 *
 * @param {Array} array : [{name, color, id}]
 * @param {Function} addTagToList : add tag to the list
 * @param {Function} addToSelected : select a Tag
 * @param {Boolean} tagIcon : show tag icon in dropdown list
 * @param {String} direction : left | right
 */

function ListPicker({ array, addTagToList, addToSelected, tagIcon, direction }) {
    const [search, changeSearch] = useState("")
    const [filteredArray, filter] = useState([...array])
    const listPickerRef = useRef(null)
    const [visible, toggle] = useToggle(listPickerRef)
    const _C = "aui-list-picker"

    useEffect(() => {
        filter([...array])
    }, [array])

    function filterArray(e) {
        changeSearch(e.target.value)
        let newArr = [...array].filter(({ name }) =>
            name.toLowerCase().includes(e.target.value.toLowerCase())
        )
        filter(newArr)
    }

    function addNew(tagName) {
        addTagToList(tagName)
        changeSearch("")
        filter([...array])
    }

    function pressEnter(e, tag) {
        if (e.key === "|" || e.key === " ") e.preventDefault()
        else if (e.key === "Enter") {
            if (filteredArray.length > 0) return
            addNew(tag)
        }
    }

    return (
        <div className={_C} ref={listPickerRef}>
            <div
                className={_C + "-icon-wrapper"}
                onClick={(e) => {
                    e.preventDefault()
                    toggle(!visible)
                }}
            >
                <TooltipWrapper text={"Add post Label"} direction={"top"} style={{maxWidth: "5rem", padding: "0.25rem"}} >
                    <TagIcon
                        border
                        toggle={visible}
                        tooltip="Add Labels"
                        dashed
                        background="white"
                        zIndexVal={1}
                    />
                </TooltipWrapper>
                <span>Tags</span>
            </div>
            {visible && (
                <div className={_C + "-dropdown " + _C + "-dropdown-" + direction}>
                    <div className={_C + "-search"}>
                        <input
                            className={_C + "-input"}
                            type="text"
                            value={search}
                            onChange={filterArray}
                            onKeyDown={(e) => pressEnter(e, search)}
                            placeholder="Search or Create Tag"
                            autoFocus={true}
                        />
                        <span
                            onClick={() => toggle(!visible)}
                            className={_C + "-cross"}
                        >
                            +
                        </span>
                    </div>
                    <div className={_C + "-container"}>
                        <div className={_C + "-container-list"}>
                            {filteredArray.map((tag, i) => (
                                <div
                                    key={i}
                                    className={_C + "-container-list-item"}
                                    onClick={() => {
                                        addToSelected(tag)
                                        toggle(!visible)
                                    }}
                                    style={{
                                        color: tag.color,
                                    }}
                                >
                                    {tagIcon && (
                                        <TagIcon
                                            bgClr={tag.color}
                                            small
                                            zIndexVal={1}
                                        />
                                    )}
                                    <span
                                        style={{
                                            whiteSpace: "nowrap",
                                            color: tag.color,
                                        }}
                                    >
                                        {tag.name}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default React.memo(ListPicker)
