import React from "react"
import ReactDOM from "react-dom"
import "./index.scss"
import * as serviceWorker from "./serviceWorker"
import { Provider } from "react-redux"
import store, { persister } from "./store"
import DataTracker from "./configuration/DataTracker"
import CatchFallback from "./components/Pages/Error/CatchFallback"
import { PersistGate } from "redux-persist/integration/react"

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate persistor={persister}>
                <CatchFallback>
                    <DataTracker />
                </CatchFallback>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
