import axios from "axios"
import productionApis from "./envLinks/api.production"
import developmentApis from "./envLinks/api.development"
import testingApis from "./envLinks/api.release"

const apiMap = {
    production: productionApis,
    development: developmentApis,
    release: testingApis,
}

export const {
    clarityKey,
    accountsLink,
    baseURLAuthAndCompany,
    baseURL,
    newAuthService,
    workflow,
    minifyUrl,
    notificationApi,
    notificationSettingApi,
    collaborationCommentUrl,
    collaborationTaskUrl,
    activityURL,
    mediaApi,
    bulkApi,
    bitly,
    analyticsApi,
    subscriptionApi,
    plansApi,
    heyBioUrl,
    heyBioLink,
} = apiMap[process.env.REACT_APP_ENV]


export const logoutApi = `${newAuthService}/logout`
export const signUp = `${newAuthService}/signup`
export const signIn = `${newAuthService}/login`
export const getAccessToken = `${baseURLAuthAndCompany}/accessToken`
export const resetPassword = `${newAuthService}/resetPassword`
export const getPlans = `${subscriptionApi}/`

/**Social based getUrl Functions */
const typesMap = {
    page: "companypage",
    group: "group",
}
export function socialSignIn(social) {
    return `${baseURL}/v2/${social.toLowerCase()}/signin`
}
export function socialDisconnect(social) {
    return `${baseURL}/v2/${social.toLowerCase()}/signin`
}
export function socialPages(social, type) {
    if (social === "facebook" && type === "group") {
        return `${baseURL}/v2/${social.toLowerCase()}/${type}`
    }
    return `${baseURL}/v2/${social.toLowerCase()}/${typesMap[type] ?? "companypage"}`
}

export const analyticApi = axios.create({
    baseURL: analyticsApi,
})
export function socialProfileInfo(social) {
    return `${baseURL}/v2/${social.toLowerCase()}/profileinfo`
}

export function accountInfo(social) {
    return `${baseURL}/v2/${social.toLowerCase()}/connect`
}

export const socialPostsApi = (social) =>
    `${baseURL}/v2/${social.toLowerCase()}`

export const socialCreatePostApi = `${baseURL}/social/account/posts`

export const getAllPlatformPosts = `${baseURL}/social/account/posts`

export const getPermission = `${baseURLAuthAndCompany}/permission`
export const getRole = `${baseURLAuthAndCompany}/role`
export const postUser = `${baseURLAuthAndCompany}/user`
export const getUserDetails = `${newAuthService}/user`
export const updateUser = `${newAuthService}/user`
export const getWorkspaceUsers = `${baseURLAuthAndCompany}/user`

export const addUserToBrand = `${baseURLAuthAndCompany}/user`
export const updateUserInBrand = `${baseURLAuthAndCompany}/user`
export const getUsersInBrand = `${baseURLAuthAndCompany}/user`

export const organization = `${newAuthService}/organisation`

export const getCompanies = `${baseURLAuthAndCompany}/company`
export const addCompany = `${baseURLAuthAndCompany}/company`
export const updateCompany = `${baseURLAuthAndCompany}/company`

export const getBrands = `${baseURL}/brand`
export const addBrand = `${baseURL}/brand`
export const editBrand = `${baseURL}/brand`

export const deleteBrandApi = `${baseURLAuthAndCompany}/brand`

export const getSocialPosts = `${baseURL}/posts`
export const getAllSocialPostsByBrandId = `${baseURL}/posts`

export const getAllWorkflowByBrandId = `${workflow}`
export const createWorkflowByBrandId = `${workflow}`
export const updateWorkflowByBrandId = `${workflow}`
export const addWorkflowToPostByBrandId = `${workflow}/approval`
export const associatePostWithWorkflow = `${workflow}`
export const getAssociatedWorkflowWithPost = `${workflow}`
export const approvePostWithWorkflow = `${workflow}/approval`

export const getCollaborationCommentThreads = `${collaborationCommentUrl}`
export const getCollaborationSubCommentsInThread = `${collaborationCommentUrl}`
export const addCollaborationComment = `${collaborationCommentUrl}`
export const addCollaborationSubComment = `${collaborationCommentUrl}`

export const createCollaborationTask = `${collaborationTaskUrl}`
export const getAllCollaborationTask = `${collaborationTaskUrl}`
export const updateCollaborationTask = `${collaborationTaskUrl}`

// complete social micro service api url for the api calls.
export const getSocialDetails = `${baseURL}/social/account/userinfo`

export const getAllActivityForGivenBrand = `${activityURL}/post`
export const getAllActivityForGivenPost = `${activityURL}`

export const linkedApiUrl = `${baseURL}/v2/linkedin`
export const twitterApiUrl = `${baseURL}/v2/twitter`
export const instagramApiUrl = `${baseURL}/v2/instagram`
export const facebookUrl = `${baseURL}/v2/facebook`
export const metricsUrl = (platform) =>
    `${baseURL}/v2/${platform?.toLowerCase()}/metrics`

export const publishPostApiUrl = `${bulkApi}`
export const labelUrl = `${baseURL}/social/account/labels`
export const instagramComment = `${baseURL}/v2/instagram/comments`
export const createBoardsApi = `${baseURL}/v2/pinterest/boards`


// shopify 
export const shopifyService = "https://3xotq6g5y5.execute-api.ap-south-1.amazonaws.com";

// HeyBio APIs
export const heyBioProfileUrl = `${heyBioUrl}/profile`
export const heyBioPageUrl = `${heyBioUrl}/page`
export const heyBioLandingPage = `${heyBioLink}`

export const captionsApi = `${baseURL}/social/captions`

// Comments
export const getCommentsApi = (social) => {
    return `${baseURL}/v2/${social}/comment`
}

export const enabledFeatures = `${baseURL}/enabled_features`

//Dms
export const getDmsApi = (social) => {
    return `${baseURL}/v2/${social}/dm`
}

//Analytics
export const getAnalyticsApi = (social) => {
    if (social === "linkedin") {
        return `${baseURL}/v2/${social}/pageAnalytics`
    }
    return `${baseURL}/v2/${social}/analytics`
}

export const linkedinPageAnalyticsApi = `${baseURL}/v2/linkedin/pageAnalytics`
export const linkedinShareanalyticsApi = `${baseURL}/v2/linkedin/shareAnalytics`

//queue 
export const queueApi = `${baseURL}/post/schedule/queue`
