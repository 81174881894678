import store from "../store"
import Axios from "axios"
import { createMessage } from "./messageAction"
import {
    ADD_BRANDS,
    UPDATE_BRANDS_DETAILS,
    UPDATE_BRAND_DETAILS,
    FILTER_BRANDS,
    ADD_NEW_BRAND,
    BRAND_SETTING_TOGGLE,
    UPDATE_BRAND_DETAILS_IN_COMPANY,
    REMOVE_BRAND_DETAILS,
    ADD_USER_TO_BRAND,
    UPDATE_USER_ROLE_IN_BRAND,
    GET_ALL_USERS_IN_BRAND,
    DELETE_USER_FROM_BRAND,
    BRANDS_LOADING,
    END_LOADING,
    UPDATE_TEMPORARY_BRAND_KEY,
    ADD_TEMPORARY_BRAND,
    UPDATE_TEMPORARY_BRAND,
    RESET_TEMPORARY_BRAND,
    UPDATE_LABELS,
} from "../ActionTypes/brandsActionTypes"
import {
    ADD_NEW_BRAND_TO_GROUP,
    DELETE_USER_FROM_WORKSPACE,
    REMOVE_BRAND_COMPANY,
} from "../ActionTypes/workplaceType"
import { DELETE_USER } from "../ActionTypes/userActionTypes"
import { ADD_BRAND_SETTING_ID } from "../ActionTypes/settingActionType"
import {
    UPDATE_BRANDS_SOCIAL_CHANNELS,
    UPDATE_SINGLE_BRAND_SOCIAL_CHANNELS,
} from "../ActionTypes/socialActionType"
import {
    getBrands,
    deleteBrandApi,
    addUserToBrand,
    getUsersInBrand,
    updateUserInBrand,
    addBrand,
    editBrand,
    mediaApi,
    queueApi,
} from "../components/apis"
import { saveBrandForOnBoarding, getHeaders } from "./userAction"
import { openSocialConnector } from "./socialAction"
import mediaHandler from "../components/Helper/mediaHandler/mediaHandler"
import { getLabels } from "../components/Helper/SocialPostHandler/LabelHandler"
import { addWorkspaceUser } from "./workplaceAction"
import { unConsumePlan } from "./transactionAction"
import { CONSUME_ACCOUNT } from "ActionTypes/transactionActionTypes"

const { dispatch, getState } = store
const DANGER = "danger"

export const addBrandsToCompany = (id, brands) => {
    dispatch({ type: ADD_BRANDS, payload: { id, brands } })
}

export const updateLabels = async (brand_id) => {
    const data = await getLabels(brand_id)
    const labelsArray = data?.map((labelData) => labelData.labelName)
    dispatch({
        type: UPDATE_LABELS,
        payload: { brandId: brand_id, labels: labelsArray },
    })
}
/**
 * @description Get details of brands with given ids (1,2,3)
 * @param {[id]} ids
 */
export const getBrandsDetails = async (ids, callback) => {
    try {
        const res = await Axios.get(getBrands, {
            params: {
                id: ids,
            },
            ...getHeaders(),
        })
        let brandsObj = {}
        let brandsChannels = {}
        res.data.model.forEach((brand) => {
            brandsObj[brand.id] = { ...brand }
            brandsChannels[brand.id] = {}
            brand.socialMedia.socials.forEach((social) => {
                if (social.enable)
                    brandsChannels[brand.id][social.name.toLowerCase()] = social
            })
        })
        dispatch({ type: UPDATE_BRANDS_DETAILS, payload: { brandsObj } })

        dispatch({
            type: UPDATE_BRANDS_SOCIAL_CHANNELS,
            payload: brandsChannels,
        })
        if (callback) callback()
        return brandsObj
    } catch (err) {
        console.log(err)
    } finally {
        if (callback) callback()
    }
}

/**
 * @description Update the brand Details in the Brands Obj
 * @param {*} id
 */
export const updateBrandsDetails = async (id) => {
    try {
        const res = await Axios.get(`${getBrands}?id=${id}`, getHeaders())

        if (res.data.code === 200) {
            dispatch({ type: UPDATE_BRAND_DETAILS, payload: res.data.model[0] })
            let brandsChannels = {}
            res.data.model[0].socialMedia.socials.forEach((social) => {
                if (social.enable) brandsChannels[social.name.toLowerCase()] = social
            })
            dispatch({
                type: UPDATE_SINGLE_BRAND_SOCIAL_CHANNELS,
                payload: { id, channels: brandsChannels },
            })
            return res.data.model[0]
        }
    } catch (err) {
        if (err.response) createMessage(DANGER, err.response.data)
    }
}

/**
 * @description Update details of the brand in the company
 * @param {*} details
 */
export const updateBrandDetailsInCompany = async (details) => {
    try {
        dispatch({ type: UPDATE_BRAND_DETAILS_IN_COMPANY, payload: details })
    } catch (error) {
        if (error.response) createMessage(DANGER, error.response)
        else createMessage(DANGER, "Something went wrong !")
    }
}

/**
 * @description Open and close brand Settings Side drawer
 * @param {*} id
 * @param {*} companyId
 * @param {*} isOpen
 */
export const toggleBrandSetting = (id, companyId, isOpen) => {
    if (isOpen)
        dispatch({
            type: ADD_BRAND_SETTING_ID,
            payload: { brandId: id, companyId },
        })
    openSocialConnector(id, companyId, "BRAND_SETTINGS")
    dispatch({ type: BRAND_SETTING_TOGGLE, payload: { isOpen } })
}

export const filterCompanyBrands = (id, filteredBrands) => {
    dispatch({ type: FILTER_BRANDS, payload: { id, filteredBrands } })
}

/**
 * add brand for the onboarding new user
 * @param {*} data
 */
export const addBarding = async (data) => {
    try {
        const res = await Axios.post(getBrands, data, getHeaders())
        if (res.data.model) {
            const result = await Axios.get(
                `${getBrands}?id=${res.data.model.id}`,
                getHeaders()
            )
            if (result.data.model) {
                saveBrandForOnBoarding(result.data.model)

                dispatch({
                    type: ADD_NEW_BRAND,
                    payload: { id: data.companyId, brand: result.data.model[0] },
                })
                dispatch({
                    type: ADD_NEW_BRAND_TO_GROUP,
                    payload: { id: data.companyId, brand: result.data.model[0] },
                })
                return result.data?.model?.[0]
            }
        } else {
            createMessage(DANGER, res.data.msg)
        }
    } catch (err) {
        if (err.response) createMessage(DANGER, err.response.data)
    }
}

export const updateOnboardingBrand = async (id) => {
    try {
        const result = await Axios.get(getBrands, {
            params: {
                id: id,
            },
            ...getHeaders(),
        })
        if (result.data.model) {
            saveBrandForOnBoarding(result.data.model)
            dispatch({ type: UPDATE_BRAND_DETAILS, payload: result.data.model[0] })
        } else {
            createMessage(DANGER, result.data.msg)
        }
    } catch (err) {
        if (err.response) createMessage(DANGER, err.response.data)
    }
}

/**
 * @description Add a new brand to the company and brandDetails which is already fetched
 * @param {*} workspaceId
 * @param {*} brand
 */
export const addBrandObjectToData = async (workspaceId, brand) => {
    try {
        dispatch({
            type: ADD_NEW_BRAND,
            payload: { id: workspaceId, brand: brand },
        })
        dispatch({
            type: ADD_NEW_BRAND_TO_GROUP,
            payload: { id: workspaceId, brand: brand },
        })
    } catch (err) {
        if (err.response) createMessage(DANGER, err.response.data)
    }
}

/**
 * @description Remove Brand from Company
 * @param {*} id
 * @param {*} companyId
 */
export const deleteBrand = async (id, companyId) => {
    try {
        const result = await Axios.delete(deleteBrandApi, {
            ...getHeaders(),
            params: {
                id: id,
                workspace_id: companyId,
            },
        })
        if (result.data.code === 200) {
            dispatch({
                type: REMOVE_BRAND_DETAILS,
                payload: { workspaceId: companyId, brandId: id },
            })
            dispatch({
                type: REMOVE_BRAND_COMPANY,
                payload: { workspaceId: companyId, brandId: id },
            })
            unConsumePlan(CONSUME_ACCOUNT, 1)
            createMessage("success", "Brand Deletion Successful")
        }
    } catch (error) {}
}

/**
 *
 * @param {Object} brandBody Updated body of brand
 * @param {Number} companyId
 * @param {Function} [callback]
 */
export const editBrandDetails = async (
    brandBody,
    companyId,
    callback = () => {}
) => {
    try {
        const res = await Axios.put(editBrand, brandBody, getHeaders())
        if (res.data.code === 200) {
            updateBrandsDetails(res.data.model.id)
            updateBrandDetailsInCompany({ ...brandBody, companyId })
            createMessage("success", "Brand Updated Successfully")
        }
    } catch (error) {
        createMessage("danger", "Brand cannot be updated")
    } finally {
        callback()
    }
}

/**
 * @description Get all the team members of given brand
 * @param workspace_id
 * @param brand_id
 */
export const getTeamMembers = async (workspace_id, brand_id) => {
    try {
        const response = await Axios.get(getUsersInBrand, {
            params: {
                workspace_id,
                brand_id,
            },
            ...getHeaders(),
        })
        if (response.data.model) {
            dispatch({
                type: GET_ALL_USERS_IN_BRAND,
                payload: {
                    team: response.data.model,
                    brandId: brand_id,
                },
            })
        } else {
            createMessage("danger", response.data.msg)
            dispatch({ type: END_LOADING })
        }
    } catch (err) {
        if (err.response) createMessage("danger", err.response.data)
    }
}

/**
 * @description update the team member of a brand
 * @param id : userId
 * @param workspace_id
 * @param brand_id
 * @param role_id
 * @param callback
 */
export const updateTeamMemberRole = async (
    id,
    workspace_id,
    brand_id,
    role_id,
    callback
) => {
    try {
        dispatch({ type: BRANDS_LOADING, userId: id })
        const response = await Axios.put(
            updateUserInBrand,
            { id, workspace_id, brand_id, role_id },

            getHeaders()
        )
        if (response.data.code === 200) {
            dispatch({
                type: UPDATE_USER_ROLE_IN_BRAND,
                payload: {
                    member: response.data.model,
                    brandId: brand_id,
                    userId: id,
                },
            })
            createMessage("success", "Team Member Role updated Successfully!")
        } else {
            createMessage("danger", response.data.msg)
            dispatch({ type: END_LOADING })
        }
    } catch (err) {
        if (err.response) createMessage("danger", err.response.data)
    } finally {
        callback()
    }
}

/**
 * @description add new team member to a brand
 * @param member
 * @example
 * {
        "user_name":"MEMBER1",
        "password" :"12345",
        "first_name":"MANISH",
        "last_name":"KUMAR",
        "email_id":"kpmkp212saasa0s1s@gmail.com",
        "phone_number":"9001708601",
        "workspace_id":94,
        "brand_id":238,
        "role_id":0
    }
    * @param callback 
    */
export const addTeamMember = async (member, callback = () => {}) => {
    const companyId = getState().workplaceReducer.currentCompanyId
    try {
        if (!member.email_id) {
            createMessage("danger", "Please Enter a valid Email")
            return
        }
        dispatch({ type: BRANDS_LOADING, userId: true })
        const response = await Axios.post(
            addUserToBrand,
            { ...member, product_id: 1 },
            getHeaders()
        )
        if (response.data.model) {
            dispatch({
                type: ADD_USER_TO_BRAND,
                payload: {
                    member: response.data.model,
                    brandId: member.brand_id,
                },
            })
            addWorkspaceUser(response.data.model, companyId)
            createMessage("success", "Team Member added Successfully!")
        } else {
            createMessage("danger", response.data.msg)
            dispatch({ type: END_LOADING })
        }
        callback()
    } catch (err) {
        if (err.response) createMessage("danger", err.response.data)
    }
}

/**
 * @description delete the team member of a brand
 * @param id : userId
 * @param workspace_id
 * @param brand_id
 * @param callback
 */
export const deleteTeamMember = async (
    user_id,
    workspace_id,
    brand_id,
    user,
    callback,
    removeWorkspace
) => {
    try {
        dispatch({ type: BRANDS_LOADING })
        const response = await Axios.delete(updateUserInBrand, {
            params: { user_id, workspace_id, brand_id },
            ...getHeaders(),
        })
        if (response.data.code !== 200) {
            createMessage("danger", response.data.msg)
            return dispatch({ type: END_LOADING })
        }

        if (brand_id) {
            dispatch({
                type: DELETE_USER_FROM_BRAND,
                payload: {
                    brandId: brand_id,
                    userId: user_id,
                },
            })
        }
        if (removeWorkspace) {
            dispatch({
                type: DELETE_USER_FROM_WORKSPACE,
                payload: { workspaceId: workspace_id, userId: user_id },
            })
        }
        if (user) {
            dispatch({
                type: DELETE_USER,
                payload: { workspaceId: workspace_id, users: user },
            })
        }

        createMessage("success", "User Deleted")

        callback()
    } catch (err) {
        if (err.response) createMessage("danger", err.response.data)
    }
}

export function addTemporaryBrand(companyId) {
    dispatch({ type: ADD_TEMPORARY_BRAND, payload: { companyId } })
}

export async function deleteTemporaryBrand(id, companyId) {
    try {
        const result = await Axios.delete(deleteBrandApi, {
            ...getHeaders(),
            params: {
                id: id,
                workspace_id: companyId,
            },
        })
        if (result.data.code === 200) {
            dispatch({
                type: RESET_TEMPORARY_BRAND,
            })
            // createMessage("success", "Brand Deletion Successful")
        }
    } catch (error) {}
}

export function resetTemporaryBrand() {
    dispatch({ type: RESET_TEMPORARY_BRAND })
}

export function updateTemporaryBrand(key, value) {
    dispatch({ type: UPDATE_TEMPORARY_BRAND_KEY, payload: { key, value } })
}

/**
 * @description Update the temporary brand Details during new brand creation
 * @param {*} id
 */
export const fetchAndUpdateTemporaryBrand = async (id) => {
    try {
        const res = await Axios.get(`${getBrands}?id=${id}`, getHeaders())

        if (res.data.code === 200) {
            dispatch({ type: UPDATE_TEMPORARY_BRAND, payload: res.data.model[0] })
            return res.data.model[0]
        } else {
            createMessage(DANGER, res.data.msg)
        }
    } catch (err) {
        createMessage(DANGER, err.response?.data)
    }
}

/**
 * @description Intermediate saving of brand to the backend
 * @param {*} id
 * @param {*} companyId
 * @param {Function} callback
 */
export const saveTemporaryBrand = async (brand, callback = () => {}) => {
    let imgRes
    const { currentCompanyId } = getState().workplaceReducer

    try {
        if (brand.icon) {
            const mediaHandlerService = new mediaHandler(mediaApi, currentCompanyId)
            imgRes = await mediaHandlerService.uploadMedia(brand.icon)
            delete brand.icon
            brand.icon = imgRes.mediaPath
        }

        const res = await Axios.post(addBrand, brand, {
            ...getHeaders(),
        })

        if (res.data.code === 200) {
            dispatch({ type: UPDATE_TEMPORARY_BRAND, payload: res.data.model })
            callback(res.data.model)
        } else {
            createMessage(DANGER, res.data.msg)
        }
    } catch (err) {
        createMessage(DANGER, err.response)
    }
}

/**
 * @description add saved brand to company.
 * @param {*} id BrandId
 * @param {*} companyId
 */
export const addTemporaryBrandToCompany = async (id, companyId) => {
    try {
        const res = await Axios.get(`${getBrands}?id=${id}`, getHeaders())
        if (res.data.model) {
            dispatch({
                type: ADD_NEW_BRAND,
                payload: { id: companyId, brand: res.data.model[0] },
            })
            dispatch({
                type: ADD_NEW_BRAND_TO_GROUP,
                payload: { id: companyId, brand: res.data.model[0] },
            })
        } else {
            createMessage(DANGER, res.data.msg)
        }
    } catch (err) {
        if (err.response) createMessage(DANGER, err.response.data)
    }
}

export async function saveTeamMember(team, brandId, companyId, callback = () => {}) {
    await Promise.all(
        team.map((member) =>
            addTeamMember({
                ...member,
                role_id: member.roleId,
                brand_id: brandId,
                workspace_id: companyId,
            })
        )
    )
    callback()
}

export const getQueueDetails = async (id, platform) => {
    try {
        const res = await Axios.get(queueApi, {
            params: {
                brand_id: id,
                platform: platform,
            },
            ...getHeaders(),
        })
        const TimeDataFromApi = {}
        const SlotEnable = {}
        res.data.model.forEach((item) => {
            const day = item.day
            if (item.timeslot) {
                const time = item.timeslot.map((timeslot) => timeslot.time)
                TimeDataFromApi[day] = time
            } else TimeDataFromApi[day] = []
            SlotEnable[day] = item.enable
        })
        return { queueSlot: TimeDataFromApi, enable: SlotEnable }
    } catch (err) {
        console.log(err)
    }
}

export async function postChanges(day, type, data, timeslot, toggle, id, platform) {
    const addTimeslot = async (requestData) => {
        try {
            await Axios.post(queueApi, requestData, {
                params: {
                    brand_id: id,
                    platform: platform,
                },
                ...getHeaders(),
            })
        } catch (err) {
            console.log(err)
        }
    }

    const insertTimeInSortedArray = (sortedArray, newTime) => {
        if (sortedArray.includes(newTime)) {
            createMessage("danger", "Timeslot already exists")
            return sortedArray.join(" || ")
        }

        const compareTimes = (timeA, timeB) => {
            const parseTime = (time) => {
                const [hours, minutes, period] = time
                    .match(/(\d+):(\d+)([APM]{2})/)
                    .slice(1)
                const normalizeHours = (hours, period) =>
                    hours === 12 ? (period === "PM" ? 0 : 12) : hours
                return {
                    hours: normalizeHours(parseInt(hours), period),
                    minutes: parseInt(minutes),
                    period: period,
                }
            }

            const parsedTimeA = parseTime(timeA)
            const parsedTimeB = parseTime(timeB)

            if (parsedTimeA.period !== parsedTimeB.period) {
                return parsedTimeA.period === "AM" ? -1 : 1
            } else if (parsedTimeA.hours !== parsedTimeB.hours) {
                return parsedTimeA.hours - parsedTimeB.hours
            } else {
                return parsedTimeA.minutes - parsedTimeB.minutes
            }
        }

        const insertIndex = sortedArray.findIndex(
            (time) => compareTimes(newTime, time) < 0
        )

        if (insertIndex === -1) {
            sortedArray.push(newTime)
        } else {
            sortedArray.splice(insertIndex, 0, newTime)
        }

        return sortedArray.join(" || ")
    }

    const formatTime = (timeString) => {
        const secondColonIndex = timeString.indexOf(":", timeString.indexOf(":") + 1)
        return secondColonIndex !== -1
            ? timeString.slice(0, secondColonIndex) +
                  timeString.slice(secondColonIndex + 1)
            : timeString
    }

    const deleteTimeslot = () => {
        const newArray = timeslot[day].filter((time) => data !== time).join(" || ")
        const requestData = {
            brandId: id,
            platform: platform,
            dayData: newArray,
            day: day,
        }
        return addTimeslot(requestData)
    }

    const addTimeslotRequest = () => {
        const requestData = {
            brandId: id,
            platform: platform,
            dayData: insertTimeInSortedArray(timeslot[day], formatTime(data)),
            day: day,
        }
        return addTimeslot(requestData)
    }

    const toggleTimeslot = () => {
        const enable = toggle[day] ? "N" : "Y"
        const requestData = {
            brandId: id,
            platform: platform,
            dayData: timeslot[day].join(" || "),
            day: day,
            enable: enable,
        }
        return addTimeslot(requestData)
    }

    switch (type) {
        case "ADD":
            return addTimeslotRequest()
        case "DELETE":
            return deleteTimeslot()
        case "TOGGLE":
            return toggleTimeslot()
        default:
            console.log("Invalid operation type")
    }
}

export const getQueueForCreation = async (id, platform, date) => {
    try {
        const res = await Axios.get(queueApi, {
            params: {
                brand_id: id,
                platform: platform,
                date: date,
            },
            ...getHeaders(),
        })

        return res.data.model
    } catch (err) {
        console.log(err)
    }
}
export const getQueuePost = async (id, platform, date, status) => {
    try {
        const res = await Axios.get(queueApi, {
            params: {
                brand_id: id,
                platform: platform,
                date: date,
                status: status,
            },
            ...getHeaders(),
        })
        return res.data.model
    } catch (err) {
        console.log(err)
    }
}
