export function SocialSubTypeChecker(type, key) {
    return type?.toUpperCase() === "LINKEDIN" || type?.toUpperCase() === "FACEBOOK"
}

export function isSocialSubtype(social, key) {
    if (
        social.toLowerCase() === "pinterest" ||
        social.toLowerCase() === "twitter" ||
        social.toLowerCase() === "instagram"
    ) {
        return false
    }
    if (social.toLowerCase() === "facebook") {
        return key.toLowerCase() === "group"
    }
    if (social.toLowerCase() === "linkedin") {
        return key.toLowerCase() === "page"
    }
}
