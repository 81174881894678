import React, { useState } from "react"
import { createUseStyles } from "react-jss"

import AwesomeModel from "Awesome-ui/AwesomeModel"
import Text from "@awesomesuite-frontend/awesome-nebula/dist/Text"
import Button from "@awesomesuite-frontend/awesome-nebula/dist/Button"

import BrandDownGrade from "./Components/BrandDownGrade"
import UserDownGrade from "./Components/UserDownGrade"
import "./DownGradeStyle.scss"
import { accountsLink } from "components/apis"
import { connect } from "react-redux"

let useStyles = createUseStyles(() => ({
    deleteIcon: {
        height: 20,
        cursor: "pointer",
    },
    undoIcon: {
        color: "#1CBBD4",
        background: "#E5F5F8",
        borderRadius: 4,
        cursor: "pointer",
        padding: "4px 12px",
    },
    downGradeBottom: {
        display: "flex",
        justifyContent: "space-between",
        padding: 12,
        borderTop: "1px solid #D2DBE6",
        boxShadow:
            "0px 0px 0px transparent, 0px 0px 0px transparent, 0px -2px 4px #D2DBE6",
    },
    downGradeContent: {
        maxHeight: 690,
        padding: "24px 12px",
    },
    highlight: {
        color: "#F2547D",
    },
    cancleBtn: {
        background: "#EDF2F7",
        border: "1px solid #D2DBE6",
    },
    submitBtn: {
        background: "#F2547D",
    },
}))

let description = [
    <Text color="#fff" fontSize={15} varient="body" margin={"6px 0"}>
        Deleting these resources would also delete all your data associated with
        them.
    </Text>,
    <Text color="#fff" fontSize={15} varient="body" margin={0}>
        Deleting everything cannot be undone, so we want to make sure.
    </Text>,
]

let DownGradePlan = ({ userDegrade, brandDegrade, isExceededPlan }) => {
    let classes = useStyles()
    let [open, setopen] = useState(true)

    async function submitChoice() {
        window.location(accountsLink);
    }

    if(!isExceededPlan) return <></>

    return (
        <AwesomeModel
            width="45%"
            open={open}
            setOpen={setopen}
            title="🤔 It looks like you are trying to downgrade your plan.
                However there are still some resources attached to your
                workspace."
            description={description}
            background="#F2547D"
            disablePadding
            disableClose
        >
            <div className={`downgrade-scroll ${classes.downGradeContent}`}>
                {brandDegrade && <BrandDownGrade classes={classes} />}
                {userDegrade && <UserDownGrade classes={classes} />}
            </div>
            <div className={classes.downGradeBottom}>
                <span></span>
                <Button
                    className={classes.submitBtn}
                    onClick={submitChoice}
                    radius={4}
                >
                    <span style={{ color: "#fff" }}>Upgrade</span>
                </Button>
            </div>
        </AwesomeModel>
    )
}

let mapStateToProps = ({ transactionReducer }) => ({
    isExceededPlan: transactionReducer.isExceededPlan,
    userDegrade: transactionReducer.subscription?.remainingNoOfUser < 0,
    brandDegrade: transactionReducer.subscription?.remainingNoOfAccounts < 0,
})

export default connect(mapStateToProps)(DownGradePlan)
