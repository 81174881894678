import {
    SET_COMPANY,
    SET_ALL_SOCIAL_ACCOUNTS,
    TOGGLE_SOCIAL_ACCOUNT_TO_POST_ON,
    SELECT_PROFILE_TO_VIEW,
    ADD_PUBLISH_FILES,
    DELETE_PUBLISH_FILES,
    UPDATE_POST_SCHEDULED_DATE,
    UPDATE_POST_SCHEDULED_TIME,
    PUBLISH_LOADING,
    PUBLISH_LOADING_END,
    UPDATE_PUBLISH_POST,
    UPDATE_PUBLISH_TEXT,
    UPDATE_ALL_PUBLISH_POST,
    CLEAR_ALL_PUBLISH_POSTS,
    RESET_PUBLISH_POST,
    UPDATE_CREATED_PUBLISH_POST,
    PREVIOUS_PUBLISH_PAGE,
    SET_PUBLISH_FILTER_STATUS,
    SET_PUBLISH_TAB,
    TOGGLE_PUBLISH_MINIFY_LINK,
    SHUFFLE_PUBLISH_FILES,
    DUPLICATE_PUBLISH_FILES,
    CHANGE_PUBLISH_POST,
    UPDATE_PUBLISH_TIME_ZONE,
    SET_PUBLISH_USER_FILTER_STATUS,
    SET_PUBLISH_SORT_FILTER_STATUS,
    SET_PUBLISH_SEARCH_FILTER_STATUS,
    SET_PUBLISH_DATE_FILTER_STATUS,
    SET_PUBLISH_FILTER,
    UPDATE_PUBLISH_CREATED_POST,
    PUBLISH_APPEND_TEXT,
} from "../ActionTypes/publishActionType"
import { statusIconsObj } from "../components/Utilities/PostRelatedComponents/PostStatusIcons"

function decodePostText(text) {
    return decodeURIComponent(text || "")
}

const initialState = {
    loading: false,
    post: { text: "" },
    files: {
        twitter: [],
        instagram: [],
        facebook: [],
        linkedin: [],
        pinterest: [],
    },
    publishMinifyLink: true,
    allSocialAccounts: [],
    publishCompanyId: null,
    timezone: "Asia/Kolkata",
    page: 1,
    hasMore: true,

    tabs: ["Compose", "Scheduled", "Drafts", "Published", "Approvals"],
    publishTab: 0,

    publishPostStatusArray: {
        schedule: {
            statusName: "Scheduled",
            statusKey: "schedule",
            statusColor: "#00AEEF",
            statusFill: "#00A4BD",
            index: 0,
            Icon: statusIconsObj["schedule"],
        },
        publish: {
            statusName: "Published",
            statusKey: "publish",
            statusColor: "#25C16F",
            statusFill: "#25C16F",
            index: 1,
            Icon: statusIconsObj["publish"],
        },
        unpublish: {
            statusName: "Unpublished",
            statusKey: "unpublish",
            statusColor: "#FFCA28",
            statusFill: "rgba(254, 181, 26, 0.1)",
            index: 2,
            Icon: statusIconsObj["unpublish"],
        },
        draft: {
            statusName: "Drafted",
            statusKey: "draft",
            statusColor: "#99ACC2",
            statusFill: "rgba(153, 172, 194, 0.2)",
            index: 3,
            Icon: statusIconsObj["draft"],
        },
    },

    currentPublishFilterStatus: null,

    /*
        creator: {createdBy, handleName}
     */
    creator: null,

    selectedProfileToView: null,

    scheduleDate: null,
    scheduleTime: null,

    allPublishPosts: null,
    allPublishPostsIds: null,
    allPublishPostsObjects: {},

    userPublishFilterStatus: 0,
    sortPublishFilterStatus: 0,
    searchPublishFilterStatus: "",
    datePublishFilterStatus: null,
    filterPublish: false,
    accountsLoading: false,
}

const publishReducer = (state = initialState, action) => {
    try {
        switch (action.type) {
            case PUBLISH_LOADING:
                return { ...state, loading: true }
            case PUBLISH_LOADING_END:
                return { ...state, loading: false }
            case SET_COMPANY:
                return { ...state, publishCompanyId: action.payload.companyId }
            case SET_ALL_SOCIAL_ACCOUNTS:
                return {
                    ...state,
                    allSocialAccounts: action.payload.socialAccounts,
                }
            case TOGGLE_SOCIAL_ACCOUNT_TO_POST_ON:
                return {
                    ...state,
                    allSocialAccounts: [...state.allSocialAccounts].map((account) =>
                        action.payload.social === account.type &&
                            action.payload.brandId === account.brandId &&
                            action.payload.key === account.key
                            ? {
                                ...account,
                                added: !account.added,
                            }
                            : account
                    ),
                }
            case SELECT_PROFILE_TO_VIEW:
                return {
                    ...state,
                    selectedProfileToView: {
                        ...action.payload.profile,
                        ref: action.payload.previewRef,
                    },
                }
            case UPDATE_PUBLISH_POST:
                return { ...state, post: action.payload.post }
            case UPDATE_PUBLISH_TEXT:
                return {
                    ...state,
                    post: {
                        ...state.post,
                        text: {
                            ...state.post.text,
                            [action.payload.social + "_text"]: action.payload.text,
                        },
                    },
                }
            case ADD_PUBLISH_FILES:
                return {
                    ...state,
                    files: {
                        ...state.files,
                        [action.payload.social]: [
                            ...state.files[action.payload.social],
                            action.payload.file,
                        ],
                    },
                }
            case DELETE_PUBLISH_FILES:
                // const updatedFiles = [...state.files].filter(
                //     (file, i) =>
                //         file.name !== action.payload.name ||
                //         i !== action.payload.position
                // )
                // return { ...state, files: updatedFiles }

                const updatedFiles = [...state.files[action.payload.social]].filter(
                    (file, i) =>
                        file.name !== action.payload.name ||
                        i !== action.payload.position
                )
                const newFiles = {
                    ...state.files,
                    [action.payload.social]: updatedFiles,
                }
                return {
                    ...state,
                    files: newFiles,
                }

            case SHUFFLE_PUBLISH_FILES:
                // return { ...state, files: action.payload.files }

                const activeShuffleSocial = action.payload.social
                return {
                    ...state,
                    files: {
                        ...state.files,
                        [activeShuffleSocial]: action.payload.files,
                    },
                }

            case DUPLICATE_PUBLISH_FILES:
                const toDuplicate = state.files[action.payload.social]
                return {
                    ...state,
                    files: {
                        twitter: toDuplicate,
                        instagram: toDuplicate,
                        facebook: toDuplicate,
                        linkedin: toDuplicate,
                        pinterest: toDuplicate,
                    },
                }

            case TOGGLE_PUBLISH_MINIFY_LINK:
                return {
                    ...state,
                    publishMinifyLink: !state.publishMinifyLink,
                }
            case UPDATE_POST_SCHEDULED_DATE:
                return { ...state, scheduleDate: action.payload.date }
            case UPDATE_POST_SCHEDULED_TIME:
                return { ...state, scheduleTime: action.payload.time }
            case UPDATE_ALL_PUBLISH_POST:
                let updatedChannelPosts = { ...state.allPublishPostsObjects }
                let updatedChannelsPostArray = state.allPublishPosts
                    ? [...state.allPublishPosts]
                    : []
                let updatedChannelPostIds = []
                action.payload.posts.forEach((post) => {
                    updatedChannelsPostArray.push(post)
                    updatedChannelPostIds.push(post.id)
                    if (updatedChannelPosts[post.id]) return

                    updatedChannelPosts[post.id] = {
                        ...post,
                        text: decodePostText(post?.text),
                    }
                })
                return {
                    ...state,
                    allPublishPostsIds:
                        state.page === 1
                            ? updatedChannelPostIds
                            : [
                                ...(state.channelPostIds ?? []),
                                ...updatedChannelPostIds,
                            ],
                    allPublishPostsObjects: updatedChannelPosts,
                    allPublishPosts: updatedChannelsPostArray ?? null,
                    loading: false,
                    hasMore:
                        action.payload.posts.length >= action.payload.limit
                            ? true
                            : false,
                }
            case CLEAR_ALL_PUBLISH_POSTS:
                return { ...state, allPublishPosts: null, page: 1, hasMore: true }
            case RESET_PUBLISH_POST:
                const tempAccounts = state.allSocialAccounts
                    ? state.allSocialAccounts.map((account) => {
                          return {
                              ...account,
                              added: false,
                          }
                      })
                    : []
                return {
                    ...state,
                    post: { text: "" },
                    files: {
                        twitter: [],
                        instagram: [],
                        facebook: [],
                        linkedin: [],
                        pinterest: [],
                    },
                    creator: null,
                    scheduleDate: null,
                    scheduleTime: null,
                    loading: false,
                    timezone: null,
                    selectedProfileToView: null,
                    allSocialAccounts: tempAccounts,
                }
            case UPDATE_CREATED_PUBLISH_POST:
                let updatedPosts = [...state.allPublishPosts].map((post) =>
                    post.id === action.payload.post.id ? action.payload.post : post
                )
                return { ...state, allPublishPosts: updatedPosts }
            case PREVIOUS_PUBLISH_PAGE:
                return { ...state, page: state.page + 1 }
            case SET_PUBLISH_FILTER_STATUS:
                return { ...state, currentPublishFilterStatus: action.payload }
            case SET_PUBLISH_TAB:
                return { ...state, publishTab: action.payload }
            case UPDATE_PUBLISH_TIME_ZONE:
                return { ...state, timezone: action.payload }
            case CHANGE_PUBLISH_POST:
                let updatedPost = state.post ? { ...state.post } : {}
                updatedPost[action.payload.key] = action.payload.value
                return { ...state, post: updatedPost }
            case SET_PUBLISH_USER_FILTER_STATUS:
                return { ...state, userPublishFilterStatus: action.payload.name }
            case SET_PUBLISH_SORT_FILTER_STATUS:
                return { ...state, sortPublishFilterStatus: action.payload.name }
            case SET_PUBLISH_SEARCH_FILTER_STATUS:
                return { ...state, searchPublishFilterStatus: action.payload.name }
            case SET_PUBLISH_DATE_FILTER_STATUS:
                return { ...state, datePublishFilterStatus: action.payload.date }
            case SET_PUBLISH_FILTER:
                return { ...state, filterPublish: action.payload.stat }
            case UPDATE_PUBLISH_CREATED_POST:
                let allUpdatedPosts = {
                    ...state.allPublishPosts,
                    [action.payload.post.id]: action.payload.post,
                }
                return { ...state, allPublishPosts: allUpdatedPosts }
            case PUBLISH_APPEND_TEXT:
                return appendText(state, action.payload)
            default:
                return state
        }
    } catch (err) { }
}

export default publishReducer

/**
 * append the text to existig social text.
 * if social is provided then it will update text for only one social.
 * if social is not provided it will update for each social available.
 */
function appendText(state, payload) {
    let { social, text } = payload

    let post = state.post ? { ...state.post } : {}

    if (!post.text) {
        post.text = {
            facebook_text: text,
            instagram_text: text,
            linkedin_text: text,
            twitter_text: text,
        }

        return {
            ...state,
            post: post,
        }
    }

    if (social) {
        let key = social.toLowerCase() + "_text"
        post.text[key] = post.text[key] ? post.text[key] + text : text
    } else {
        Object.keys(post.text).forEach((item) => {
            post.text[item] = post.text[item] ? post.text[item] + text : text
        })
    }

    return {
        ...state,
        post: post,
    }
}
