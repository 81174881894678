import Axios from "axios"
import store from "../store"
import {
    getBrands,
    socialPages,
    socialDisconnect,
    captionsApi,
    enabledFeatures,
    twitterApiUrl,
    accountInfo,
} from "../components/apis"
import { createMessage } from "./messageAction"
import {
    CHANGE_ACTIVE_SOCIAL_BRAND,
    CHANGE_SOCIAL,
    CHANGE_SOCIAL_VIEW,
    CLOSE_SOCIAL_CONNECTOR,
    OPEN_SOCIAL_CONNECTOR,
    OPEN_SOCIAL_MODEL,
    SOCIAL_CREATE_POST,
    SOCIAL_CREATE_POST_CLOSE,
    SOCIAL_FAILED,
    SOCIAL_LOADING,
    SOCIAL_PROFILE_DETAILS_SUCCESS,
    UPDATE_URL,
    UPDATE_SINGLE_BRAND_SOCIAL_CHANNELS,
    SOCIAL_PROFILE_DETAILS_LOADING,
    ASSOCIATE_SOCIAL_PAGE_WITH_BRAND,
    CHANGE_POST_FILTER_STATUS,
    REMOVE_SOCIAL_PROFILE,
    DISABLE_SOCIAL_LOGIN_IN_ACTIVE_BRAND,
    CHANGE_SOCIAL_SUBTYPE,
    TOGGLE_PINTEREST_CREATE_BOARD,
    SET_CAPTIONS,
    COMPANIES_LOADING,
    PROFILE_DETAILS_LOADING,
    SET_ENABLED_FEATURES,
    SET_GRID_VIEW,
    SET_PAGES,
    SET_TWITTER_CREDS,
    SET_ACTIVE_SETTINGS,
} from "../ActionTypes/socialActionType"
import {
    UPDATE_BRANDS_DETAILS,
    DISABLE_SOCIAL_LOGIN_IN_BRANDS,
    UPDATE_BRAND_DETAILS,
} from "../ActionTypes/brandsActionTypes"
import {
    accountNormalizer,
    socialNormalizer,
} from "../components/Helper/Normalizers/SocialAccountInfoNormalizer"
import { getHeaders } from "./userAction"
import { FACEBOOK, INSTAGRAM, LINKED_IN } from "../Data/SocialMedialTypes"
import { getWorkspaceById } from "./workplaceAction"
import {
    COLLABORATION_SELECT_BRAND,
    COLLABORATION_SELECT_COMPANY,
} from "ActionTypes/collaborationActionTypes"

const { dispatch, getState } = store

const baseSubtype = {
    twitter: "user",
    instagram: "page",
    facebook: "page",
    linkedin: "user",
    pinterest: "user",
}

function changeSocialView(view) {
    dispatch({ type: CHANGE_SOCIAL_VIEW, payload: { socialView: view } })
}

function changeSocial(social) {
    dispatch({ type: CHANGE_SOCIAL, payload: { social } })
}

function setSocialDetails(params, social, subType, brandId, companyId) {
    const { paramsUrl } = getState().socialReducer

    dispatch({ type: CHANGE_SOCIAL, payload: { social } })
    dispatch({ type: CHANGE_SOCIAL_SUBTYPE, payload: { subType } })

    dispatch({ type: COLLABORATION_SELECT_BRAND, payload: { brandId } })
    dispatch({ type: COLLABORATION_SELECT_COMPANY, payload: { companyId } })

    if (!paramsUrl || JSON.stringify(params) !== JSON.stringify(paramsUrl)) {
        dispatch({ type: UPDATE_URL, payload: { params } })
    }
}

async function changeActiveSocialBrand(brandId, companyId) {
    let brand = getState().brandsReducer.brandDetails[brandId]
    let company = getWorkspaceById(companyId)

    if (!brand) {
        dispatch({ type: SOCIAL_LOADING })
        try {
            const res = await Axios.get(getBrands, {
                params: {
                    id: brandId,
                },
                ...getHeaders(),
            })
            if (res.data.model) {
                dispatch({
                    type: CHANGE_ACTIVE_SOCIAL_BRAND,
                    payload: { brand: res.data.model[0], company },
                })
                let obj = {}
                obj[brandId] = res.data.model[0]
                dispatch({
                    type: UPDATE_BRANDS_DETAILS,
                    payload: { brandsObj: obj },
                })
                let brandsChannels = {}
                res.data.model[0].socialMedia.socials.forEach((social) => {
                    if (social.enable)
                        brandsChannels[social.name.toLowerCase()] = social
                })
                dispatch({
                    type: UPDATE_SINGLE_BRAND_SOCIAL_CHANNELS,
                    payload: { id: brandId, channels: brandsChannels },
                })
            } else {
                dispatch({ type: SOCIAL_FAILED })
                createMessage("danger", res.data.msg)
            }
        } catch (err) {
            if (err.response) {
                dispatch({ type: SOCIAL_FAILED })
                createMessage("danger", err.response.data)
            } else {
                createMessage(
                    "danger",
                    "Brand details fetching failed please reload page or go to dashboard"
                )
                dispatch({ type: SOCIAL_FAILED })
            }
        } finally {
            dispatch({ type: SOCIAL_FAILED })
        }
    } else
        dispatch({
            type: CHANGE_ACTIVE_SOCIAL_BRAND,
            payload: { brand, company },
        })
}

function resetSocialBrandsAndSocial() {
    dispatch({
        type: CHANGE_ACTIVE_SOCIAL_BRAND,
        payload: { brand: null, company: null },
    })
    dispatch({
        type: CHANGE_SOCIAL,
        payload: { social: null },
    })
}

async function changePostFilterStatus(status) {
    dispatch({ type: CHANGE_POST_FILTER_STATUS, payload: { status } })
}

function updateUrl(params) {
    const { paramsUrl } = getState().socialReducer
    if (!paramsUrl || JSON.stringify(params) !== JSON.stringify(paramsUrl)) {
        dispatch({ type: UPDATE_URL, payload: { params } })
    }
}

/**
 * Use this method to open the social connector Model with extra information like from
 * where it called and current active social.
 *
 * @param {*} brandId - brand id
 * @param {*} workspaceId - workspace in with brand belong
 * @param {["TWITTER", "FACEBOOK", "INSTAGRAM", "LINKEDIN"]} socialType - active social type.
 * @param {["WORKSPACE", "SOCIAL"]} from - this represent from which place request to open model came.
 */
function openSocialModel(brandId, workspaceId, socialType, from) {
    if (brandId && workspaceId && from) {
        dispatch({
            type: OPEN_SOCIAL_MODEL,
            payload: { brandId, workspaceId, socialType, from },
        })
    }
}

/**
 * This method will push details to the social connector
 * this is not to open model.
 *
 * @param {*} brandId
 * @param {*} workspaceId - workspace in with brand belong
 * @param {*} from  - this represent from which place request to open model came.
 */
function openSocialConnector(brandId, workspaceId, from) {
    if (brandId && workspaceId && from) {
        dispatch({
            type: OPEN_SOCIAL_CONNECTOR,
            payload: { brandId, workspaceId, from },
        })
    }
}

/**
 * Method to close the social connector and the data related to it.
 */
function closeSocialConnector() {
    dispatch({ type: CLOSE_SOCIAL_CONNECTOR })
}

export async function getAccountDetails(brandId, social) {
    try {
        if (!brandId || !social) return

        const headers = getHeaders()
        headers.params = {
            brand_id: brandId,
        }
        const res = await Axios.get(accountInfo(social), headers)

        if (res.data.code === 200) {
            return res.data.model
        }
    } catch (err) {
        console.log(err)
    }
}

/**
 * This is the Atomic api call to get the user social profile details
 * for any type of the social i.e. "TWITTER", "FACEBOOK", "INSTAGRAM" and "LINKEDIN".
 *
 * @param {String} brandId - brand id
 * @param {["TWITTER", "FACEBOOK", "INSTAGRAM", "LINKEDIN"]} socialType - active social type.
 * @param {Function} callback
 * @param {["page","group"]} subType type of request for linkedin and fb
 */
export async function getSocialProfileDetails(
    brandId,
    socialType,
    callback = null,
    subType
) {
    if (!brandId || !socialType) return
    try {
        dispatch({
            type: SOCIAL_PROFILE_DETAILS_LOADING,
            payload: {
                brandId: brandId,
                socialType: socialType.toUpperCase(),
                type: subType,
            },
        })
        const result = await getAccountDetails(brandId, socialType)
        if (!result) {
            if (callback) callback()
            return null
        }
        let generatedData = []
        if (
            socialType.toUpperCase() === LINKED_IN.toUpperCase() &&
            subType?.toLowerCase() === "page"
        ) {
            let normalizedData = result
                ? socialNormalizer(result, "linkedinCompanyPage")
                : null
            generatedData.push({
                brandId: brandId,
                socialType: socialType.toUpperCase(),
                data: normalizedData,
                type: subType,
            })
        } else if (
            socialType.toUpperCase() === FACEBOOK.toUpperCase() &&
            subType?.toLowerCase() === "group"
        ) {
            let normalizedData = result
                ? socialNormalizer(result, "facebookCompanyPage")
                : null
            generatedData.push({
                brandId: brandId,
                socialType: socialType.toUpperCase(),
                data: normalizedData,
                type: subType,
            })
        }
        let normalizedData = result
            ? socialNormalizer(result, socialType.toLowerCase())
            : null
        generatedData.push({
            brandId: brandId,
            socialType: socialType.toUpperCase(),
            data: normalizedData,
            type: baseSubtype[socialType.toLowerCase()],
        })

        generatedData.forEach((item) => {
            let socialChannels =
                getState().socialReducer.brandSocialChannels?.[brandId]

            const brand = getState().brandsReducer.brandDetails?.[brandId]

            socialChannels[socialType.toLowerCase()] = {
                name: socialType.toUpperCase(),
                id: item.data.id,
                icon: item.data.image,
                enable: 1,
                key: subType?.toUpperCase(),
            }
            dispatch({
                type: SOCIAL_PROFILE_DETAILS_SUCCESS,
                payload: item,
            })
            dispatch({
                type: UPDATE_SINGLE_BRAND_SOCIAL_CHANNELS,
                payload: { id: brandId, brandId, channels: socialChannels },
            })

            brand.socialMedia.socials = brand.socialMedia.socials.map((social) => {
                if (
                    social.name.toUpperCase() === socialType.toUpperCase() &&
                    social.key.toUpperCase() === item.type.toUpperCase()
                ) {
                    return {
                        name: socialType.toUpperCase(),
                        id: item.data.id || social.id,
                        icon: item.data.image,
                        enable: item.data.enable,
                        key: item.type.toUpperCase(),
                    }
                }
                return social
            })

            dispatch({
                type: UPDATE_BRAND_DETAILS,
                payload: brand,
            })
        })
        if (callback) callback()
        return generatedData
    } catch (error) {
        console.log(error)
        if (callback) callback()
    }
}

/**
 * This is the Atomic api call to detach social profile from brandId
 * for any type of the social i.e. "TWITTER", "FACEBOOK", "INSTAGRAM" and "LINKEDIN".
 *
 * @param {String} brandId - brand id
 * @param {["TWITTER", "FACEBOOK", "INSTAGRAM", "LINKEDIN"]} socialType - active social type.
 * @param {Function} callback
 * @param {["page","group"]} subType type of request for linkedin and fb
 */
export async function removeSocialProfile(
    brandId,
    socialType,
    callback = () => {},
    subType
) {
    let params = { brandId }
    if (subType) params.key = subType.toLowerCase()

    try {
        const res = await Axios.delete(socialDisconnect(socialType), {
            params,
            ...getHeaders(),
        })

        if (
            socialType.toLowerCase() === "twitter" ||
            socialType.toLowerCase() === LINKED_IN
        ) {
            let { activeSocialBrand } = getState().socialReducer

            if (activeSocialBrand && activeSocialBrand.id === brandId)
                dispatch({
                    type: DISABLE_SOCIAL_LOGIN_IN_ACTIVE_BRAND,
                    payload: {
                        socialType,
                        social: socialType,
                        subType,
                    },
                })
            dispatch({
                type: DISABLE_SOCIAL_LOGIN_IN_BRANDS,
                payload: { brandId, social: socialType, subType },
            })
        }

        if (res.data.code === 200) {
            callback && callback()
        }

        if (res.data.model) {
            dispatch({
                type: REMOVE_SOCIAL_PROFILE,
                payload: {
                    brandId,
                    social: socialType.toUpperCase(),
                    type: subType,
                },
            })
            createMessage(
                "success",
                (subType ?? "Account") + " Successfully Disconnected"
            )
        } else {
            createMessage("danger", res.data.msg)
        }
    } catch (err) {
        createMessage("danger", err?.response?.message)
    } finally {
        callback()
    }
}

/**
 * @description Remove Social Pages
 * @param {String} brandId
 * @param {String} social
 * @param {[String]} subType
 */

function removeSocialProfiles(brandId, socialType, subType) {
    dispatch({
        type: REMOVE_SOCIAL_PROFILE,
        payload: {
            brandId,
            social: socialType.toUpperCase(),
            type: subType,
        },
    })
}

/**
 * @description Get all the pages for a particular brandId and Social
 *
 * @param {String} social
 * @param {Number} brandId
 * @param {String} subType
 */
async function getPages(social, brandId, subType) {
    try {
        let res
        if (subType) {
            res = await Axios.get(socialPages(social, subType?.toLowerCase()), {
                params: {
                    brandId,
                },
                ...getHeaders(),
            })
        } else {
            res = await Axios.get(socialPages(social), {
                params: {
                    brandId,
                },
                ...getHeaders(),
            })
        }

        if (res.data.model) {
            let allPages

            if (social.toLowerCase() === INSTAGRAM) {
                allPages = res.data.model.map((page) =>
                    accountNormalizer(page, social?.toLowerCase() + "CompanyPage")
                )
            } else if (
                social.toLowerCase() === FACEBOOK &&
                subType.toLowerCase() === "page"
            ) {
                allPages = res.data.model.data.map((page) =>
                    accountNormalizer(page, social?.toLowerCase())
                )
            } else if (social.toLowerCase() !== LINKED_IN) {
                allPages = res.data.model.data.map((page) =>
                    accountNormalizer(page, social?.toLowerCase() + "CompanyPage")
                )
            } else {
                allPages = Object.values(res.data.model.results).map((page) =>
                    accountNormalizer(page, social?.toLowerCase() + "CompanyPage")
                )
            }
            let payload = {
                social: social,
                brandId: brandId,
                subType: subType || "Page",
                pages: allPages,
            }
            dispatch({
                type: SET_PAGES,
                payload,
            })

            return allPages
        }
    } catch (err) {
        if (err.response) {
            createMessage("danger", err.response.data)
        }
    }
    return null
}

export const removePages = (social, brandId, subType) => {
    dispatch({
        type: SET_PAGES,
        payload: {
            social: social,
            brandId: brandId,
            subType: subType || "Page",
            pages: null,
        },
    })
}

/**
 * @description Associate any social page with particular brand
 * @param {*} social
 * @param {*} brandId
 * @param {*} pageId
 * @param {*} access_token
 * @param {Object} page
 * @param {["Pages","Groups"]} type
 */
async function associatePageWithBrand(
    social,
    brandId,
    pageId,
    access_token,
    page,
    type
) {
    const pageIdNameMappings = {
        facebook: { group: "fbgroupId", page: "fbpageId" },
        instagram: "fbpageId",
        linkedin: "pageId",
    }
    const pageNameNameMappings = {
        linkedin: "pageName",
    }
    if (!social || !(typeof social === "string" || social instanceof String)) return

    try {
        const params = {
            brandId,
            pageAccessToken: access_token,
        }
        if (pageIdNameMappings[social]?.[type?.toLowerCase()])
            params[pageIdNameMappings[social]?.[type?.toLowerCase()]] = pageId
        else params[pageIdNameMappings[social]] = pageId

        if (social === "instagram") params["igpageId"] = page.igPageId

        if (pageNameNameMappings[social]) {
            params[pageNameNameMappings[social]] = page.subText
        }

        const res = await Axios.post(
            socialPages(social, type?.toLowerCase()),
            {},
            {
                params,
                ...getHeaders(),
            }
        )

        if (res.data.model) {
            let page
            if (social === "linkedin" && type.toLowerCase() === "page")
                page = accountNormalizer(
                    Object.values(res.data.model.results)[0],
                    "linkedinCompanyPage"
                )
            else if (
                social.toLowerCase() === FACEBOOK &&
                type?.toLowerCase() === "group"
            ) {
                page = accountNormalizer(
                    res.data.model,
                    social.toLowerCase() + "CompanyPage"
                )
            } else page = accountNormalizer(res.data.model, social.toLowerCase())

            dispatch({
                type: ASSOCIATE_SOCIAL_PAGE_WITH_BRAND,
                payload: {
                    page: page,
                    social: social?.toUpperCase(),
                    brandId,
                    title: page?.title,
                    subText: page?.subText,
                    image: page?.image,
                    type,
                },
            })
        } else {
            createMessage("danger", res.data.msg)
        }
    } catch (err) {
        if (err.response) {
            createMessage("danger", err.response.data)
        }
    }
}

// Captions

export const setCaptions = (brandId, captions) => {
    dispatch({
        type: SET_CAPTIONS,
        payload: { brandId: brandId, captions: captions },
    })
}

export const createCaption = async (brandId, name, desc) => {
    const captions = getState().socialReducer.captions
    try {
        const headers = getHeaders()

        const res = await Axios.post(
            captionsApi,
            {
                brandId: Number(brandId),
                captionName: name,
                captionDescription: desc,
            },
            headers
        )

        if (res.data.code === 200) {
            setCaptions(
                brandId,
                captions[brandId]
                    ? [...captions[brandId], res.data.model]
                    : [res.data.model]
            )
            createMessage("success", "Caption Created Successfully")
            return true
        } else {
            createMessage("danger", res.data.msg)
        }
        return false
    } catch (err) {
        createMessage("danger", err.message)
    }
}

export const getCaptions = async (brandId) => {
    try {
        const headers = getHeaders()
        headers.params = {
            brandId: brandId,
        }
        const res = await Axios.get(captionsApi, headers)

        if (res.data.code === 200) {
            setCaptions(brandId, res.data.model)
            return res.data.model
        } else {
            createMessage("danger", res.data.msg)
        }
        return null
    } catch (err) {
        createMessage("danger", err.message)
    }
}

export const updateCaption = async (id, brandId, name, desc) => {
    let captions = getState().socialReducer.captions[brandId]
    try {
        const headers = getHeaders()

        const res = await Axios.put(
            captionsApi,
            {
                id: id,
                brandId: Number(brandId),
                captionName: name,
                captionDescription: desc,
            },
            headers
        )

        if (res.data.code === 200) {
            captions = captions.map((cap) => {
                return cap.id === id ? res.data.model : cap
            })
            setCaptions(brandId, captions)
            createMessage("success", "Caption Updated Successfully")
            return res.data.model
        } else {
            createMessage("danger", res.data.msg)
        }
        return null
    } catch (err) {
        createMessage("danger", err.message)
    }
}

export const deleteCaption = async (id, brandId) => {
    let captions = getState().socialReducer.captions[brandId]
    try {
        const headers = getHeaders()
        headers.data = {
            id: id,
        }

        const res = await Axios.delete(captionsApi, headers)

        if (res.data.code === 200) {
            captions = captions?.filter((cap) => cap.id !== id)
            setCaptions(brandId, captions)
            createMessage("success", "Caption Deleted Successfully")
            return true
        } else {
            createMessage("danger", res.data.msg)
        }
        return null
    } catch (err) {
        createMessage("danger", err.message)
    }
}

/**
 * open the post model in the social dashboard.
 *
 * @param {object} brand - brad details id, name, timeZone
 * @param {string} workspaceId - workspaceId
 */
export const createNewPost = (brand, workspaceId) => {
    dispatch({
        type: SOCIAL_CREATE_POST,
        payload: { brand: brand, workspaceId: workspaceId },
    })
}

/**
 * to close the post model
 */
export const closePostCreation = () => {
    dispatch({ type: SOCIAL_CREATE_POST_CLOSE })
}

export const changeSocialSubtype = (subType) => {
    dispatch({ type: CHANGE_SOCIAL_SUBTYPE, payload: { subType } })
}

export const togglePinterestCreateBoard = () => {
    dispatch({ type: TOGGLE_PINTEREST_CREATE_BOARD })
}

export const setCompaniesLoading = (load) => {
    dispatch({ type: COMPANIES_LOADING, payload: load })
}

export const setProfilesLoading = (loadState) => {
    dispatch({ type: PROFILE_DETAILS_LOADING, payload: loadState })
}

export const setEnabledFeatures = async () => {
    try {
        const headers = getHeaders()
        const res = await Axios.get(enabledFeatures, headers)

        if (res.data.model) {
            const features = {}
            res.data.model.forEach((feature) => {
                features[feature.featureName] = {
                    twitter: feature.twitter,
                    linkedin: feature.linkedIn_user,
                    linkedinPage: feature.linkedIn_page,
                    facebook: feature.facebook_page,
                    facebookGroup: feature.facebook_group,
                    instagram: feature.instagram,
                    pinterest: feature.pinterest,
                }
            })
            dispatch({ type: SET_ENABLED_FEATURES, payload: features })
        }
    } catch (err) {
        console.log(err)
    }
}

const capitalize = (str) => {
    if (!str) return
    return str.toLowerCase().replace(/^[a-z]/, (L) => L.toUpperCase())
}

const checkFeatureAvailability = (feature, social, type) => {
    const { enabledFeatures } = getState().socialReducer

    if (
        !enabledFeatures ||
        !enabledFeatures[feature] ||
        typeof social !== "string"
    ) {
        return false
    }

    const socialLowerCase = social.toLowerCase()
    const typeLowerCase = type?.toLowerCase()

    if (
        (socialLowerCase === "facebook" && typeLowerCase === "group") ||
        (socialLowerCase === "linkedin" && typeLowerCase === "page")
    ) {
        const socialKey = `${socialLowerCase}${capitalize(typeLowerCase)}`
        return enabledFeatures[feature][socialKey] || false
    }

    return enabledFeatures[feature][socialLowerCase] || false
}

const setActiveSettings = (value) => {
    dispatch({ type: SET_ACTIVE_SETTINGS, payload: value })
}

const setGridView = (value) => {
    dispatch({ type: SET_GRID_VIEW, payload: value })
}

const setTwitterCreds = async (brandId, clientKey, clientSecret) => {
    const headers = getHeaders()
    try {
        const res = await Axios.post(
            `${twitterApiUrl}/client-creds`,
            {
                brandId,
                clientKey,
                clientSecret,
            },
            headers
        )
        if (res.data?.code !== 200) {
            createMessage("danger", res.data?.msg)
            return false
        }
        console.log(res)
        return res
    } catch (err) {
        if (err.response) {
            createMessage("danger", err.response.data)
        }
        return null
    }
}

const isTwitterCredsExist = async (brandId) => {
    const headers = getHeaders()
    try {
        const res = await Axios.get(
            `${twitterApiUrl}/client-creds?brandId=${brandId}&clientExist=true`,
            headers
        )
        if (res.data?.code !== 200) {
            createMessage("danger", res.data?.Error)
            throw new Error(res.data?.Error)
        }
        return res
    } catch (err) {
        if (err.response) {
            createMessage("danger", err.response.data)
        }
        return null
    }
}

const disableTwitter = async (brandId) => {
    const headers = getHeaders()
    try {
        const res = await Axios.delete(
            `${twitterApiUrl}/client-creds?brandId=${brandId}`,
            headers
        )
        if (res.data?.code !== 200) {
            createMessage("danger", res.data?.Error)
            throw new Error(res.data?.Error)
        }
        return res
    } catch (err) {
        if (err.response) {
            createMessage("danger", err.response.data)
        }
        return null
    }
}

export {
    changeSocial,
    changeSocialView,
    changeActiveSocialBrand,
    changePostFilterStatus,
    updateUrl,
    openSocialConnector,
    openSocialModel,
    closeSocialConnector,
    getPages,
    associatePageWithBrand,
    removeSocialProfiles,
    checkFeatureAvailability,
    resetSocialBrandsAndSocial,
    setSocialDetails,
    setGridView,
    setTwitterCreds,
    isTwitterCredsExist,
    disableTwitter,
    setActiveSettings,
}
