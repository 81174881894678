import moment from "moment"
import {
    createTwitterPost,
    editTwitterPostSchedule,
    getTwitterPost,
    getTwitterPostByDateNew,
    getTwitterPostById,
    getTwitterPostNew,
    getTwitterPostNext,
    twitterTextFormatter,
    updateTwitterPost,
} from "./Twitter"
import {
    changePostFilterStatus,
    closePostCreation,
} from "../../../Actions/socialAction"
import {
    createLinkedInPost,
    getLinkedInPost,
    updateLinkedinPost,
    editLinkedinPostSchedule,
    getLinkedInPostById,
    getLinkedinPostNext,
    getLinkedinPostNew,
    getLinkedinPostByDateNew,
} from "./LinkedIn"
import {
    createInstagramPost,
    getInstagramPost,
    updateInstagramPost,
    editInstagramPostSchedule,
    getInstagramPostById,
    getInstagramPostNew,
    getInstagramPostNext,
    getInstagramPostByDateNew,
} from "./Instagram"
import {
    createFacebookPost,
    getFacebookPost,
    updateFacebookPost,
    editFacebookPostSchedule,
    getFacebookPostById,
    getFacebookPostNew,
    getFacebookPostNext,
    getFacebookPostByDateNew,
} from "./FaceBook"
import {
    getPintersetPost,
    createPrintSter,
    updatePinterest,
    editPinterestPostSchedule,
    getPintersetPostById,
    getPinterestPostNext,
    getPinterestPostNew,
    getPinterestPostByDateNew,
} from "./pinterest"
import {
    LINKED_IN,
    TWITTER,
    compareSocialMedia,
    INSTAGRAM,
    FACEBOOK,
    PINTEREST,
} from "../../../Data/SocialMedialTypes"
import {
    closeSocialPostManager,
    addPost,
    postLoadingEnd,
    checkTimeDateZone,
} from "../../../Actions/postAction"
import { createMessage } from "../../../Actions/messageAction"
import { bulkPostPublish } from "./bulkPublish"
import { checkForFiles } from "./processPostBeforeRequest"
import { clearAllSocialPosts } from "../../../Actions/postAction"
import { checkFeatureAvailability } from "../../../Actions/socialAction"
import { delay } from "Actions/AnalyticsAction"

/**
 * atomic handler to publish post  manage time zone and post to every single social platform
 * with good failure handling.
 *
 * @param {*} timeZone
 * @param {*} userDetails userDetails={first_name, ...data}
 * @param {*} postText
 * @param {*} postDate
 * @param {*} postTime
 * @param {*} postStatus
 * @param {*} files
 * @param {*} allSocialAccountsArray
 */
export const publishPostHandler = async (
    timeZone,
    userDetails,
    postText,
    postDate,
    postTime,
    postStatus,
    files,
    allSocialAccountsArray,
    workflow,
    other = {},
    cb = () => {}
) => {
    let newPostDate = postDate && postTime ? postDate + `T` + postTime + `Z` : null

    let tagsList = other.labelList
    let newLabel = ""
    if (tagsList) {
        for (let i = 0; i < tagsList.length; i++) {
            if (i === 0) {
                newLabel = newLabel + tagsList[i].name
            } else {
                newLabel = newLabel + " || " + tagsList[i].name
            }
        }
    }

    let newPost = {
        text: postText,
        path: null,
        enable: 1,
        postType: "TEXT",
        status: postStatus === "draft" ? "draft" : "publish",
        createdBy: userDetails.user_name,
        label: newLabel,
    }

    if (newPostDate) {
        newPost.publishDate = newPostDate
        newPost.status = "schedule"
    }

    // we need the file upload paths only.
    // newPost = checkForFiles(newPost, files)

    let newPost_twitter = {
        ...newPost,
        text: postText.twitter_text,
        firstComment: checkFeatureAvailability("FIRST_LEVEL_POST", "twitter")
            ? other?.firstComment?.twitter
            : null,
    }
    let newPost_instagram = {
        ...newPost,
        text: postText.instagram_text,
        firstComment: checkFeatureAvailability("FIRST_LEVEL_POST", "instagram")
            ? other?.firstComment?.instagram
            : null,
    }
    let newPost_facebook = {
        ...newPost,
        text: postText.facebook_text,
        firstComment: checkFeatureAvailability("FIRST_LEVEL_POST", "facebook")
            ? other?.firstComment?.facebook
            : null,
    }
    let newPost_linkedin = {
        ...newPost,
        text: postText.linkedin_text,
        firstComment: checkFeatureAvailability("FIRST_LEVEL_POST", "instagram")
            ? other?.firstComment?.linkedin
            : null,
    }
    let newPost_pinterest = {
        ...newPost,
        text: "",
        firstComment: checkFeatureAvailability("FIRST_LEVEL_POST", "instagram")
            ? other?.firstComment?.pinterest
            : null,
    }

    newPost_twitter = checkForFiles(newPost_twitter, files.twitter)
    newPost_instagram = checkForFiles(newPost_instagram, files.instagram)
    newPost_facebook = checkForFiles(newPost_facebook, files.facebook)
    newPost_linkedin = checkForFiles(newPost_linkedin, files.linkedin)
    newPost_pinterest = checkForFiles(newPost_pinterest, files.pinterest)

    let approvalRequired = []
    Object.keys(workflow).forEach((brandId) => {
        if (workflow[brandId]) {
            workflow[brandId].levels.forEach((level) => {
                if (
                    level.teams.includes(userDetails.user_name) &&
                    level.step !== workflow[brandId].levels.length
                ) {
                    if (!approvalRequired.includes(brandId))
                        approvalRequired.push(brandId)
                }
            })
        }
    })

    /*
    {
        "brandId": 346,
        "postType": "IMAGE",
        "mediaUrl": "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_960_720.jpg",
        "mediaName": "dads",
        "text":"dk",
        "enable": 1,
        "status": "publish",
        "key":"page",
        "socialMediaPlatform": "facebook"
    },
    {
        createdBy: "u"
        enable: 1
        key: "page"
        path: null
        postType: "TEXT"
        platform: "instagram"
        status: "publish"
        text: "jkhdfj"
    }
    */

    const allPublishPostArray = allSocialAccountsArray
        .map((account) =>
            account.added
                ? account.type?.toLowerCase() === "twitter"
                    ? {
                          ...newPost_twitter,
                          platform: `${account.type?.toLowerCase()}_${account.key.toLowerCase()}`,
                          key: account.key.toLowerCase() ?? "user",
                          brandId: account.brandId,
                          isApprovalRequired: approvalRequired.includes(
                              String(account.brandId)
                          )
                              ? newPost_twitter.status === "schedule"
                                  ? 1
                                  : 0
                              : 0,
                      }
                    : account.type?.toLowerCase() === "facebook"
                    ? {
                          ...newPost_facebook,
                          platform: `${account.type?.toLowerCase()}_${account.key.toLowerCase()}`,
                          key: account.key.toLowerCase() ?? "page",
                          brandId: account.brandId,
                          isApprovalRequired: approvalRequired.includes(
                              String(account.brandId)
                          )
                              ? newPost_facebook.status === "schedule"
                                  ? 1
                                  : 0
                              : 0,
                      }
                    : account.type?.toLowerCase() === "instagram"
                    ? {
                          ...newPost_instagram,
                          platform: `${account.type?.toLowerCase()}_${account.key.toLowerCase()}`,
                          key: account.key.toLowerCase() ?? "page",
                          brandId: account.brandId,
                          isApprovalRequired: approvalRequired.includes(
                              String(account.brandId)
                          )
                              ? newPost_instagram.status === "schedule"
                                  ? 1
                                  : 0
                              : 0,
                      }
                    : account.type?.toLowerCase() === "linkedin"
                    ? {
                          ...newPost_linkedin,
                          platform: `${account.type?.toLowerCase()}_${account.key.toLowerCase()}`,
                          key: account.key.toLowerCase() ?? "user",
                          brandId: account.brandId,
                          isApprovalRequired: approvalRequired.includes(
                              String(account.brandId)
                          )
                              ? newPost_linkedin.status === "schedule"
                                  ? 1
                                  : 0
                              : 0,
                      }
                    : account.type?.toLowerCase() === "pinterest"
                    ? {
                          ...newPost_pinterest,
                          platform: `${account.type?.toLowerCase()}_${account.key.toLowerCase()}`,
                          key: account.key.toLowerCase() ?? "page",
                          brandId: account.brandId,
                          title: other.title,
                          destinationLink: other.destinationLink,
                          boardId: other.boardId,
                          isApprovalRequired: approvalRequired.includes(
                              String(account.brandId)
                          )
                              ? newPost_pinterest.status === "schedule"
                                  ? 1
                                  : 0
                              : 0,
                      }
                    : null
                : null
        )
        .filter((account) => account !== null)

    try {
        const res = await bulkPostPublish(allPublishPostArray, "postCreation")
        if (res.data.code === 200) {
            createMessage("success", "Post published")
        } else createMessage("danger", res.date.msg)
    } catch (err) {
        createMessage("danger", err.response)
    } finally {
        cb()
    }
}

/**
 * atomic handler to create post  manage time zone and post to every single social platform
 * with good failure handling. Right now we support only twitter post only but we will scale this handler to create post for all
 * the social media platform.
 *
 * @param {object} brandDetails - brand details for which post is getting created.
 * @param {object} userDetails - details containing user related information {firstName, token etc};
 * @param {string} postText - post Text is formatted text with the compressed links and other encoding stuff
 * @param {string} postDate - if post is scheduled then use this to create a schedule post.
 * @param {["publish", "draft"]} postStatus - post status for the post.
 * @param {string} mediaId - media uploaded by user *right now we will support only one media.
 * @param {Array} socialArray - type of user selected social media platform.
 */
export const createPostHandler = async (
    brandId,
    timeZone,
    userDetails,
    postText,
    postDate,
    postTime,
    postStatus,
    files,
    socialArray,
    other = {},
    version,
    postStatusArray
) => {
    let newPostDate =
        postDate && postDate.length > 0 && postTime && postTime.length > 0
            ? postDate + `T` + postTime + `Z`
            : null

    let tagsList = other.labelList
    let newLabel = ""
    if (tagsList) {
        for (let i = 0; i < tagsList.length; i++) {
            if (i === 0) {
                newLabel = newLabel + tagsList[i].name
            } else {
                newLabel = newLabel + " || " + tagsList[i].name
            }
        }
    }

    let newPost = {
        text: "",
        path: null,
        enable: 1,
        postType: "TEXT",
        status: postStatus === "draft" ? "draft" : "publish",
        createdBy: userDetails.first_name,
        brandId: parseInt(brandId),
        isApprovalRequired: other.isApprovalRequired,
        label: newLabel,
        tag: "",
        boardId: other.boardId,
        title: other.title,
        destinationLink: other.destinationLink,
        version: "1.0",
        timeZone,
        labelList: tagsList,
        created_on: moment().format("YYYY-MM-DDTHH:mm:ss") + "Z",
    }

    let postConfiguration = {}

    if (postStatus === "draft") {
        newPost.status = "draft"
        newPost.publishDate = null
    } else if (postStatus === "publish") {
        newPost.publishDate = moment().format("YYYY-MM-DDTHH:mm:ss") + "Z"
    } else if (newPostDate) {
        newPost.status = "schedule"
        newPost.publishDate = newPostDate
    }

    let newPost_twitter = { ...newPost, text: postText.twitter_text }
    let newPost_instagram = { ...newPost, text: postText.instagram_text }
    let newPost_facebook = { ...newPost, text: postText.facebook_text }
    let newPost_linkedin = { ...newPost, text: postText.linkedin_text }
    let newPost_pinterest = { ...newPost }

    newPost_twitter = checkForFiles(newPost_twitter, files.twitter)
    newPost_instagram = checkForFiles(newPost_instagram, files.instagram)
    newPost_facebook = checkForFiles(newPost_facebook, files.facebook)
    newPost_linkedin = checkForFiles(newPost_linkedin, files.linkedin)
    newPost_pinterest = checkForFiles(newPost_pinterest, files.pinterest)

    // post to all selected platform
    let twitterResp
    let linkedinResp
    let instagramResp
    let facebookResp
    let pinterestResp

    await Promise.all(
        socialArray.map(async (item) => {
            const [platform, key] = item.split("_")
            if (compareSocialMedia(platform, TWITTER)) {
                postConfiguration.companyPost = false
                newPost_twitter.key = key
                newPost_twitter.platform = item
                try {
                    twitterResp = await createTwitterPost(
                        newPost_twitter,
                        checkFeatureAvailability("FIRST_LEVEL_POST", "twitter")
                            ? other?.firstComment?.twitter
                            : null
                    )
                } catch (err) {}
            } else if (compareSocialMedia(platform, LINKED_IN)) {
                postConfiguration.companyPost = key === "page" ? true : false
                newPost_linkedin.key = key
                newPost_linkedin.platform = item
                try {
                    linkedinResp = await createLinkedInPost(
                        newPost_linkedin,
                        postConfiguration,
                        checkFeatureAvailability("FIRST_LEVEL_POST", "linkedin")
                            ? other?.firstComment?.linkedin
                            : null
                    )
                } catch (err) {}
            } else if (compareSocialMedia(platform, INSTAGRAM)) {
                postConfiguration.companyPost = false
                newPost_instagram.key = key
                newPost_instagram.platform = item
                try {
                    instagramResp = await createInstagramPost(
                        newPost_instagram,
                        postConfiguration,
                        checkFeatureAvailability("FIRST_LEVEL_POST", "instagram")
                            ? other?.firstComment?.instagram
                            : null
                    )
                } catch (err) {}
            } else if (compareSocialMedia(platform, FACEBOOK)) {
                postConfiguration.companyPost = key === "group" ? true : false
                newPost_facebook.key = key
                newPost_facebook.platform = item
                try {
                    facebookResp = await createFacebookPost(
                        newPost_facebook,
                        postConfiguration,
                        checkFeatureAvailability("FIRST_LEVEL_POST", "facebook")
                            ? other?.firstComment?.facebook
                            : null
                    )
                } catch (err) {}
            } else if (compareSocialMedia(platform, PINTEREST)) {
                postConfiguration.companyPost = false
                newPost_pinterest.key = key
                newPost_pinterest.platform = item
                try {
                    pinterestResp = await createPrintSter(
                        newPost_pinterest,
                        postConfiguration,
                        checkFeatureAvailability("FIRST_LEVEL_POST", "pinterest")
                            ? other?.firstComment?.pinterest
                            : null
                    )
                } catch (err) {}
            }
        })
    )

    let createdPosts = {}
    let errMsg = []

    function checkNotError(res) {
        return !(res?.status === "ERROR") && !(res?.status === "unpublish")
    }

    if (checkNotError(twitterResp)) createdPosts.twitter = twitterResp
    else errMsg.push(twitterResp)
    if (checkNotError(linkedinResp)) createdPosts.linkedin = linkedinResp
    else errMsg.push(linkedinResp)
    if (checkNotError(facebookResp)) createdPosts.facebook = facebookResp
    else errMsg.push(facebookResp)
    if (checkNotError(instagramResp)) createdPosts.instagram = instagramResp
    else errMsg.push(instagramResp)
    if (checkNotError(pinterestResp)) createdPosts.pinterest = pinterestResp
    else errMsg.push(pinterestResp)

    addPost(createdPosts)

    if (errMsg.length > 0) {
        return errMsg
    }

    createMessage("success", "Post Creation successful")
    changePostFilterStatus(postStatusArray?.[postStatus])
    closePostCreation()
    clearAllSocialPosts()
    closeSocialPostManager()
}

/**
 * make a atomic api call to get list of post from different  socials.
 *
 * TODO: make this paginated later till then use this as a atomic api call.
 *
 * @param {["twitter","instagram", "linkedin", "facebook"]} social
 * @param {*} brandId
 * @param {Number} [page=1]
 * @param {Number} [limit=15]
 * @param {*} withStatus
 */
export const getAllPosts = async (
    social,
    brandId,
    page,
    limit,
    withStatus,
    sortBy,
    orderBy
) => {
    const config = {
        brandId: brandId,
        page,
        limit,
        sortBy: sortBy || "publishDate",
        orderBy: orderBy || "DESC",
    }

    if (withStatus) config.status = withStatus

    const socialSplit = social.split("_")
    if (socialSplit.length === 2) {
        config.key = socialSplit[1]
    }

    let result = null

    // switch (social) {
    switch (socialSplit[0]) {
        case TWITTER:
            result = await getTwitterPost(config)
            break
        case FACEBOOK:
            result = await getFacebookPost(config)
            break
        case INSTAGRAM:
            result = await getInstagramPost(config)
            break
        case LINKED_IN:
            result = await getLinkedInPost(config)
            break
        case PINTEREST:
            result = await getPintersetPost(config)
            break
        default:
            // TODO: add a generic  get post and mak call to all socials.
            return null
    }

    if (!result) {
        postLoadingEnd()
        return []
    }

    return result
}

/*
 * make a atomic api call to get list of post from different  socials.
 *
 * TODO: make this paginated later till then use this as a atomic api call.
 *
 * @param {["twitter","instagram", "linkedin", "facebook"]} social
 * @param {*} brandId
 * @param {Number} [page=1]
 * @param {Number} [limit=15]
 * @param {*} withStatus
 * @param {*} fromDate
 * @param {*} toDate
 */
export const getAllPostsByDate = async (
    social,
    brandId,
    withStatus,
    fromDate,
    toDate,
    page,
    limit
) => {
    const config = {
        brandId: brandId,
        fromDate,
        toDate,
        sortBy: "publishDate",
        key: "posts",
        page,
        limit,
    }

    if (withStatus) config.status = withStatus

    const socialSplit = social.split("_")
    if (socialSplit.length === 2) {
        config.key = socialSplit[1]
    }

    let result = null

    // switch (social) {
    switch (socialSplit[0]) {
        case TWITTER:
            result = await getTwitterPost(config)
            break
        case FACEBOOK:
            result = await getFacebookPost(config)
            break
        case INSTAGRAM:
            result = await getInstagramPost(config)
            break
        case LINKED_IN:
            result = await getLinkedInPost(config)
            break
        case PINTEREST:
            result = await getPintersetPost(config)
            break
        default:
            // TODO: add a generic  get post and mak call to all socials.
            return null
    }

    if (!result) {
        postLoadingEnd()
        return []
    }

    return result
}

/**
 * make a atomic api call to get list of post from different  socials.
 *
 * TODO: make this paginated later till then use this as a atomic api call.
 *
 * @param {*} brandId
 * @param {*} postId
 */
export const getSinglePosts = async (social, config) => {
    if (!config.brandId) return
    let result = null

    switch (social) {
        case TWITTER:
            result = await getTwitterPost(config)
            break
        case FACEBOOK:
            result = await getFacebookPost(config)
            break
        case INSTAGRAM:
            result = await getInstagramPost(config)
            break
        case LINKED_IN:
            result = await getLinkedInPost(config)
            break
        case PINTEREST:
            result = await getPintersetPost(config)
            break
        default:
            return null
    }

    if (!result) return []

    return result
}

export const getPostById = async (social, config) => {
    if (!config.brandId) return
    let result = null

    switch (social) {
        case TWITTER:
            result = await getTwitterPostById(config)
            break
        case FACEBOOK:
            result = await getFacebookPostById(config)
            break
        case INSTAGRAM:
            result = await getInstagramPostById(config)
            break
        case LINKED_IN:
            result = await getLinkedInPostById(config)
            break
        case PINTEREST:
            result = await getPintersetPostById(config)
            break
        default:
            return null
    }

    if (!result) return []
    return result
}

export const editPostDateTimeHandler = async (
    post,
    brandDetails,
    postDate,
    postTime,
    socialType
) => {
    let newPostDate = null

    const parsedDate = moment(postDate).format("YYYY-MM-DD")

    newPostDate = parsedDate + `T` + postTime + `Z`
    const newPost = { ...post }

    newPost.publishDate = newPostDate
    if (compareSocialMedia(socialType, TWITTER)) {
        const response = await editTwitterPostSchedule(newPost)
        return response
    } else if (compareSocialMedia(socialType, INSTAGRAM)) {
        const response = await editInstagramPostSchedule(newPost)
        return response
    } else if (compareSocialMedia(socialType, FACEBOOK)) {
        const response = await editFacebookPostSchedule(newPost)
        return response
    } else if (compareSocialMedia(socialType, LINKED_IN)) {
        const response = await editLinkedinPostSchedule(newPost)
        return response
    } else if (compareSocialMedia(socialType, PINTEREST)) {
        const response = await editPinterestPostSchedule(newPost)
        return response
    }

    return null
}

/**
 * handle timezone for the post getting created according
 *
 * @param {*} brandTimeZone brand timeZone if present
 * @param {*} postDate selected post creation date
 *
 * @returns scheduled Time according to timeZone.
 */
function handlePostTimeZone(brandTimeZone, postDate, time) {
    if (brandTimeZone) {
        let selectedDateAndTime = new Date(postDate)
        let oldTimezone = moment.tz.guess()
        if (oldTimezone == null) oldTimezone = "Asia/Kolkata"
        selectedDateAndTime.setHours(time.split(":")[0])
        selectedDateAndTime.setMinutes(time.split(":")[1])

        return selectedDateAndTime
    }

    return null
}

export const handlePostText = (text, socialType) => {
    let formatterText = text

    if (compareSocialMedia(socialType, TWITTER))
        formatterText = twitterTextFormatter(formatterText)

    return formatterText
}

export async function handlePostUpdate(
    social,
    post,
    files,
    scheduleDate,
    scheduleTime,
    postStatus,
    timeZone,
    version,
    mediaChange
) {
    let newPostDate = scheduleDate + `T` + scheduleTime + `Z`

    if (postStatus === "draft") {
        post.status = "draft"
        post.publishDate = null
    } else if (newPostDate && scheduleTime) {
        post.publishDate = newPostDate
        post.status = "schedule"
    }
    if (postStatus) post.status = postStatus

    let newLabel = ""
    let tagsList = post.labelList ? post.labelList : []
    for (let i = 0; i < tagsList.length; i++) {
        if (i == 0) {
            newLabel = newLabel + tagsList[i].name
        } else {
            newLabel = newLabel + " || " + tagsList[i].name
        }
    }

    // post.label = post.labelList ? post.labelList[0].name : post.label
    post.label = newLabel

    const splitArr = social.toLowerCase().split("_")
    const dynamicSocial = `${splitArr[0]}_text`
    post.text = post.text[dynamicSocial] ?? ""
    post = checkForFiles(post, files[splitArr[0]])

    if (mediaChange) {
        post.version = `${parseFloat(version) + 1}` + ".0"
    } else {
        post.version = version
    }
    post.timeZone = timeZone

    let updateFunction = {
        facebook: updateFacebookPost,
        twitter: updateTwitterPost,
        linkedin: updateLinkedinPost,
        instagram: updateInstagramPost,
        pinterest: updatePinterest,
        "": updateFacebookPost,
    }[social.split("_")[0].toLowerCase()]

    return updateFunction(post)
}

export async function handleScheduleDateUpdate(
    post,
    scheduleDate,
    scheduleTime,
    social,
    brandId
) {
    let newPostDate =
        moment(scheduleDate).format("YYYY-MM-DD") + `T` + scheduleTime + ":00Z"

    if (
        checkTimeDateZone(
            moment(scheduleDate).format("YYYY-MM-DD"),
            scheduleTime,
            post?.timeZone || "IST"
        )
    ) {
        createMessage("warn", "Please Enter a Valid Schedule Date and Schedule Time")
        return "ERROR"
    }

    let updateFunction = {
        facebook: updateFacebookPost,
        twitter: updateTwitterPost,
        linkedin: updateLinkedinPost,
        instagram: updateInstagramPost,
        "": updateFacebookPost,
    }[post?.platform?.split("_")[0]?.toLowerCase() || social]
    return updateFunction({ ...post, publishDate: newPostDate, brandId })
}

const getMediaType = (mediaUrl) => {
    if (mediaUrl?.match(/\.(jpe?g|gif|png)/i)) return "IMAGE"
    if (mediaUrl?.match(/\.(mp4|mov|avi|wmv|flv|3gp|webm|mkv|ogg)/i)) return "VIDEO"
    return null
}

const transformerPost = (post, social) => {
    let formattedPost = null
    // if (getMediaType(post.media_url) === "VIDEO") return formattedPost
    switch (social) {
        case "facebook":
            formattedPost = {
                title: post.title || "",
                id: post.id || "",
                permalink: post.permalink || "",
                username: post.username || "",
                publishDate: post.published_at || "",
                status: "publish",
            }
            if (post.children?.data && post.children?.data?.length > 0) {
                formattedPost.mediaUrl = post.children.data
                // formattedPost.mediaUrl = post.children.data.map((child) => {
                //     const type = getMediaType(child.media_url);
                //     return type !== "VIDEO" && { src: child.media_url, type }
                // })?.filter(Boolean)
            } else {
                formattedPost.mediaUrl = [
                    { src: post.media_url, type: getMediaType(post.media_url) },
                ]
            }
            break
        case "instagram":
            formattedPost = {
                title: post.caption || "",
                id: post.id || "",
                mediaUrl: [post.media_url] ?? [],
                permalink: post.permalink || "",
                username: post.username || "",
                publishDate: post.timestamp || "",
                status: "publish",
            }
            if (post.children?.data && post.children?.data?.length > 0) {
                formattedPost.mediaUrl = post.children.data
                // formattedPost.mediaUrl = post.children.data.map((child) => {
                //     const type = getMediaType(child.media_url);
                //     return type !== "VIDEO" && { src: child.media_url, type }
                // })?.filter(Boolean)
            } else {
                formattedPost.mediaUrl = [
                    { src: post.media_url, type: getMediaType(post.media_url) },
                ]
            }
            break
        case "twitter":
            formattedPost = {
                title: post.title || "",
                id: post.id || "",
                mediaUrl: [post.media_url] ?? [],
                permalink: post.permalink || "",
                username: post.username || "",
                publishDate: post.published_at || "",
                status: "publish",
            }
            if (post.children?.data && post.children?.data?.length > 0) {
                formattedPost.mediaUrl = post.children.data
                // formattedPost.mediaUrl = post.children.data.map((child) => {
                //     const type = getMediaType(child.media_url);
                //     return type !== "VIDEO" && { src: child.media_url, type }
                // })?.filter(Boolean)
            } else {
                formattedPost.mediaUrl = [
                    { src: post.media_url, type: getMediaType(post.media_url) },
                ]
            }
            break
        case "linkedin":
            formattedPost = {
                title: post.title || "",
                id: post.id || "",
                mediaUrl: [post.media_url] ?? [],
                permalink: post.permalink || "",
                username: post.username || "",
                publishDate: post.published_at || "",
                status: "publish",
            }
            if (post.children?.data && post.children?.data?.length > 0) {
                formattedPost.mediaUrl = post.children.data
                // formattedPost.mediaUrl = post.children.data.map((child) => {
                //     const type = getMediaType(child.media_url);
                //     return type !== "VIDEO" && { src: child.media_url, type }
                // })?.filter(Boolean)
            } else {
                formattedPost.mediaUrl = [
                    { src: post.media_url, type: getMediaType(post.media_url) },
                ]
            }
            break
        case "pinterest":
            formattedPost = {
                title: post.title || "",
                id: post.id || "",
                mediaUrl: [post.media_url] ?? [],
                permalink: post.permalink || "",
                username: post.username || "",
                publishDate: post.published_at || "",
                status: "publish",
            }
            if (post.children?.data && post.children?.data?.length > 0) {
                formattedPost.mediaUrl = post.children.data
                // formattedPost.mediaUrl = post.children.data.map((child) => {
                //     const type = getMediaType(child.media_url);
                //     return type !== "VIDEO" && { src: child.media_url, type }
                // })?.filter(Boolean)
            } else {
                formattedPost.mediaUrl = [
                    { src: post.media_url, type: getMediaType(post.media_url) },
                ]
            }
            break
        default:
            break
    }
    return formattedPost
}

const transformer = (data, social) => {
    return {
        posts: data?.model?.data
            ?.map((post) => transformerPost(post, social))
            ?.filter(Boolean),
        pagination: data?.model?.paging?.cursors,
    }
}

const socialToApiFunctionMap = {
    twitter: getTwitterPostNew,
    facebook: getFacebookPostNew,
    instagram: getInstagramPostNew,
    linkedin: getLinkedinPostNew,
    pinterest: getPinterestPostNew,
}

export const handlePublishPost = async (
    brandId,
    social = "",
    limit,
    iteration = 1
) => {
    const _social = social.toLowerCase()
    if (!_social) return null
    const apiFunction = socialToApiFunctionMap[_social.split("_")[0]]
    if (!apiFunction) return null
    try {
        return transformer(
            await apiFunction(brandId, limit, social),
            _social.split("_")[0]
        )
    } catch (error) {
        if (iteration >= 2) {
            console.log(`Error while getting ${social} posts:`, error)
            createMessage("danger", "Error while getting posts")
            return null
        }
        await delay(300)
        return handlePublishPost(brandId, social, limit, iteration + 1)
    }
}

const socialNextToApiFunctionMap = {
    twitter: getTwitterPostNext,
    facebook: getFacebookPostNext,
    instagram: getInstagramPostNext,
    linkedin: getLinkedinPostNext,
    pinterest: getPinterestPostNext,
}

export const handlePublishPostNext = async (
    brandId,
    social,
    next,
    limit,
    iteration = 1
) => {
    if (social) return
    const apiFunction =
        socialNextToApiFunctionMap[social.split("_")[0].toLowerCase()]
    if (!apiFunction || !next) return null
    try {
        const res = await apiFunction(brandId, next, limit, social)
        return transformer(res, social)
    } catch (error) {
        if (iteration >= 2) {
            console.log(`Error while getting ${social} posts:`, error)
            createMessage("danger", "Error while getting posts")
            return null
        }
        await delay(300)
        return handlePublishPostNext(brandId, social, next, limit, iteration + 1)
    }
}

const socialByDateApiFunctionMap = {
    twitter: getTwitterPostByDateNew,
    facebook: getFacebookPostByDateNew,
    instagram: getInstagramPostByDateNew,
    linkedin: getLinkedinPostByDateNew,
    pinterest: getPinterestPostByDateNew,
}

export const getAllPostsByDateNew = async (
    social,
    brandId,
    fromDate,
    toDate,
    iteration = 1
) => {
    const config = {
        brandId,
        fromDate,
        toDate,
        platform: social,
    }
    console.log(social)
    const apiFunction =
        socialByDateApiFunctionMap[social.split("_")[0].toLowerCase()]
    if (!apiFunction) return null
    try {
        return transformer(await apiFunction(config), social)
    } catch (error) {
        if (iteration >= 2) {
            console.log(`Error while getting ${social} posts:`, error)
            createMessage("danger", "Error while getting posts")
            return null
        }
        await delay(300)
        return getAllPostsByDateNew(social, brandId, fromDate, toDate, iteration + 1)
    } finally {
        postLoadingEnd()
    }
}
