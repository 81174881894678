import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux"
import ToggleHook from "../../../../hooks/ToggleHook"
import Avatar from "../../Avatar/Avatar"
import { getJoinedStatusColor, getRoleColor } from "../../../Helper/getUserTypeColor"
import {
    getTeamMembers,
    addTeamMember,
    updateTeamMemberRole,
    deleteTeamMember,
} from "../../../../Actions/brandsAction"
import Loader from "../../../commonComps/Loaders"
import { deletePopup, addPopup } from "../../../../Actions/messageAction"
import { ReactComponent as Bin } from "../../../icons/bin.svg"
import { ReactComponent as Edit } from "../../../icons/editpen.svg"
import ThreeDotOptionDropdown from "../../ThreeDotOptionsDropdown"
import { createUseStyles } from "react-jss"
import { ReactComponent as Crown } from "../../../icons/crown.svg"
import { SingleRow, Cell } from "@awesomesuite-frontend/awesome-nebula"
import UserInputDropdown from "../../UserInputDropdown/UserInputDropdown"
import { createMessage } from "../../../../Actions/messageAction"
import Validator from "validator"
import Guard from "Awesome-ui/Guard"
import { consumePlan, unConsumePlan } from "Actions/transactionAction"
import { CONSUME_USER } from "ActionTypes/transactionActionTypes"
import { getRoleDetails, getRoleDropdownContent, roleMapping } from "Actions/roleAction"
import { CRITICAL } from "../../../../constants"

const useStyles = createUseStyles({
    userTeamsEmail: {
        maxWidth: "25ch",
        whiteSpace: "nowrap",
        overflowX: "hidden",
        textOverflow: "ellipsis",
    },
})

export function GetUserRole({ user, roles, fontSize, height, width }) {
    let newRole
    if (user) {
        if (user.role) newRole = user.role
        else if (user.role_id) newRole = user.role_id
    }

    if (typeof newRole === "number" && roles) {
        if (Array.isArray(roles)) {
            newRole = roles.find((role) => role?.id === user?.role_id).rolename
        } else {
            newRole = roles[user.role_id]?.rolename
        }
    }

    if (!newRole) return <></>
    // if (!user && !user?.role && !roles[user?.role_id]?.roleName) return (<></>)
    return (
        <div
            className="get-user-role"
            style={{
                ...getRoleColor(`${newRole}`.toLowerCase()),
                fontSize,
                height,
                width,
            }}
        >
            {newRole.toLowerCase() === "admin" && (
                <div className="adminCrown" style={{ paddingRight: "3px" }}>
                    <Crown />
                </div>
            )}
            {newRole[0].toUpperCase() + newRole.substring(1).toLowerCase()}
        </div>
    )
}

const Teams = ({
    company,
    roles,
    members,
    brandId,
    brandsLoading,
    currentUserData,
    users,
    userImageMapping,
}) => {
    //TODO: Handle different selection to open
    // const [edit, changeEdit] = useState([])
    // const [isDeleting, setDeleting] = useState(false) //this is a index
    //this takes index
    // 0 to .... : while editing
    // -2 while adding
    // -1 normally
    const classes = useStyles()
    const [isEditing, setEditing] = useState(-1)
    const [currentUser, setCurrentUser] = useState([])
    const [loading, setLoading] = useState(false)

    const [updateTempUser, setUpdateTempUser] = useState({})
    const [updateDD, setUpdateDD] = useState({})

    const [testUser, setTestUser] = useState([])
    const [testDropdown, setTestDropdown] = useState([])

    const dropdownContent = getRoleDropdownContent()

    useEffect(() => {
        if (!members) getTeamMembers(company.id, brandId)
    }, [])

    function GetJoinedStatus({ userStatus }) {
        const currentUserStatus = userStatus === 1 ? "Joined" : "Pending"
        return (
            <div
                className="team-user-status"
                style={{
                    ...getJoinedStatusColor(currentUserStatus),
                    fontWeight: "500",
                }}
            >
                {currentUserStatus}
            </div>
        )
    }

    function confirmUpdate(id, roleId) {
        updateTeamMemberRole(id, company.id, brandId, roleId, () => {
            setEditing(-1)
            deletePopup()
        })
    }

    /*
     calling save function with isEditing = -2
    */
    async function saveTheUser(user, idx) {
        setLoading(true)
        await addTeamMember(
            {
                email_id: user.email_id,
                workspace_id: company.id,
                brand_id: brandId,
                role_id: testDropdown[idx].id,
                user_name: user.email_id.split("@")[0],
                status: 0,
            },
            () => {
                setEditing(-1)
                if (idx === currentUser.length - 1) {
                    setLoading(false)
                    setEditing(-1)
                    setCurrentUser([])
                }
            }
        )
    }

    function updateUser(id, roleId) {
        setLoading(true)
        addPopup({
            text: "Do you want to update the team member's role?",
            title: "Update Role",
            cancel: () => {
                deletePopup()
                setLoading(false)
            },
            proceed: () => {
                confirmUpdate(id, roleId)
                setLoading(false)
            },
            action: "Update",
            loading: brandsLoading,
        })
    }

    function confirmDelete(userId, companyId, brandId, user) {
        deleteTeamMember(userId, companyId, brandId, user, () => {
            setEditing(-1)
            deletePopup()
        })
    }

    function deleteTheUser(userId, companyId, brandId, user) {
        setLoading(true)
        addPopup({
            text: `You are About to Delete the user ${user.first_name ?? ""} ${user.last_name ?? ""
                }. The action cannot be undone, Are you sure?`,
            title: "Delete Team Member",
            cancel: () => {
                deletePopup()
                setLoading(false)
            },
            proceed: () => {
                confirmDelete(userId, companyId, brandId, user)
                unConsumePlan(CONSUME_USER, 1)
                setLoading(false)
            },
            action: "Delete",
            loading: brandsLoading,
            category: CRITICAL,
            showIcon: false,
        })
    }

    function discard() {
        addPopup({
            text: "Do you want to discard the changes?",
            title: "Discard",
            cancel: () => {
                deletePopup()
            },
            proceed: () => {
                setEditing(-1)
                deletePopup()
            },
            action: "Discard",
            loading: false,
        })
    }

    function singleDiscard(idx) {
        const filteredUsers = currentUser.filter((user, i) => i != idx)
        setCurrentUser(filteredUsers)

        const filteredTestUser = testUser.filter((testUse, i) => i != idx)
        setTestUser(filteredTestUser)

        const filteredDD = testDropdown.fill((DD, i) => i != idx)
        setTestDropdown(filteredDD)

        if (filteredUsers.length === 0) {
            setEditing(-1)
        }
    }

    function masterAddUsers() {
        for (let i = 0; i < currentUser.length; i++) {
            if (!Validator.isEmail(currentUser[i].email_id?.trim())) {
                createMessage("warn", "Please Enter a Valid Email")
                return
            }
            if (!testDropdown[i].id) {
                createMessage("warn", "Please Select a Role for the Team Member")
                return
            }
        }

        setLoading(true)
        for (let i = 0; i < currentUser.length; i++) {
            let isAllowed = consumePlan(CONSUME_USER, 1, false, currentUser[i])
            if (!isAllowed) return setLoading(false);
            saveTheUser(currentUser[i], i)
        }
    }

    function addCollaborators() {
        setEditing(-1)

        let currUser = Array.isArray(currentUser) ? [...currentUser] : []
        currUser.push({})

        let currTestUser = Array.isArray(currentUser) ? [...testUser] : []
        currTestUser.push("")

        let currDropdown = Array.isArray(currentUser) ? [...testDropdown] : []
        currDropdown.push({})


        // TODO: need re-work on this part
        // let isAllowed = consumePlan(CONSUME_USER, currUser.length, true)
        // if (!isAllowed) return

        setCurrentUser(currUser)
        setTestUser(currTestUser)
        setTestDropdown(currDropdown)
        setEditing(-2)
    }

    function getOptions(user, i) {
        let arr = []

        if (currentUserData.id === user.id && currentUserData.userRole === "owner") return arr
        arr.push({
            name: "Edit",
            onClick: () => {
                setCurrentUser(user)
                setUpdateTempUser(user)
                setTestUser(user.email_id)
                setUpdateDD(roleMapping(getRoleDetails(user.role)))
                console.log(dropdownContent.filter((content) => {
                    return (
                        content.id === user.roleId ||
                        content.name.toLowerCase() ===
                        user.role.toLowerCase()
                    )
                })[0])
                setTestDropdown(
                    dropdownContent.filter((content) => {
                        return (
                            content.id === user.roleId ||
                            content.name.toLowerCase() ===
                            user.role.toLowerCase()
                        )
                    })[0]
                )
                setEditing(i)
            },
            icon: <Edit />,
        })

        if (currentUserData.id !== user.id) {
            arr.push({
                name: "Delete",
                onClick: () => {
                    deleteTheUser(user.id, company.id, brandId, user)
                },
                icon: <Bin />,
                style: { color: "#F2545B" },
            })
        }

        return arr
    }

    return (
        <div className="teamsSection">
            {members ? (
                <>
                    {members.map((user, i) => (
                        <div
                            className={
                                "user " + (isEditing === i ? "user-editing" : "")
                            }
                            key={i}
                        >
                            {isEditing !== i ? (
                                <>
                                    <SingleRow>
                                        <Cell width={"50%"}>
                                            <div className="details">
                                                <div className="image">
                                                    <Avatar
                                                        firstName={
                                                            user.user_name
                                                                ? user.user_name
                                                                : user.first_name
                                                        }
                                                        lastName={
                                                            user.last_name
                                                                ? user.last_name
                                                                : user.email_id
                                                        }
                                                        icon={user.picture}
                                                        height="3rem"
                                                        width="3rem"
                                                    />
                                                </div>
                                                <div className="name">
                                                    {user.first_name &&
                                                        user.last_name
                                                        ? `${user.first_name} ${user.last_name}`
                                                        : user.user_name || "-"}
                                                    <br />
                                                    <div
                                                        className={`email ${classes.userTeamsEmail}`}
                                                    >
                                                        {user.email_id}
                                                    </div>
                                                </div>
                                            </div>
                                        </Cell>
                                        <Cell width={"20%"}>
                                            <GetUserRole user={user} roles={roles} />
                                        </Cell>
                                        <Cell width={"20%"}>
                                            <div className="wrap">
                                                {
                                                    <GetJoinedStatus
                                                        userStatus={user.status}
                                                    />
                                                }
                                            </div>
                                        </Cell>
                                        {!(currentUserData.id === user.id && currentUserData.userRole === "owner") && currentUserData.userRole === "owner" && <Cell width={"10%"}>
                                            <ThreeDotOptionDropdown
                                                options={getOptions(user, i)}
                                                enableIcon
                                                idName="addIcons"
                                            />
                                        </Cell>
                                        }
                                    </SingleRow>
                                </>
                            ) : (
                                <>
                                    {loading ? (
                                        <Loader
                                            h="2rem"
                                            w="2rem"
                                            b="2px"
                                            clr="white"
                                            bk="#99acc2"
                                            t="1s"
                                        />
                                    ) : (
                                        <UserInputDropdown
                                            key={i}
                                            inputField={true}
                                            inputValue={updateTempUser.email_id}
                                            setInputValue={(val) => {
                                                setUpdateTempUser({
                                                    ...updateTempUser,
                                                    email_id: val,
                                                })
                                            }}
                                            inputPlaceholder="Enter Email or Username"
                                            inputDisabled={true}
                                            inputType="email"
                                            dropdownOne={true}
                                            dropDownOneContent={dropdownContent}
                                            dropDownOneValue={updateDD}
                                            setDropdownOneValue={setUpdateDD}
                                            dropdownOneDisabled={false}
                                            button={true}
                                            buttonAction={() => {
                                                if (testUser === "") return
                                                updateUser(
                                                    updateTempUser.id,
                                                    updateDD.id
                                                )
                                            }}
                                            buttonText={"Update"}
                                            cancel={true}
                                            cancelAction={() => discard()}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    ))}
                    {isEditing === -2 && (
                        <div className="user-inputs">
                            {[...Array(currentUser.length)].map((e, i) => {
                                return (
                                    <UserInputDropdown
                                        key={i}
                                        inputField={true}
                                        inputValue={currentUser[i].email_id}
                                        setInputValue={(val) => {
                                            let inpVal = [...currentUser]
                                            inpVal[i] = {
                                                ...inpVal[i],
                                                email_id: val,
                                            }
                                            setCurrentUser(inpVal)
                                        }}
                                        inputPlaceholder="Enter Email or Username"
                                        inputDisabled={false}
                                        inputType="email"
                                        dropdownOne={true}
                                        dropDownOneContent={dropdownContent}
                                        dropDownOneValue={testDropdown[i]}
                                        setDropdownOneValue={(data) => {
                                            let currDD = [...testDropdown]
                                            currDD[i] = data
                                            setTestDropdown(currDD)
                                        }}
                                        dropdownOneDisabled={false}
                                        cancel={true}
                                        cancelAction={() => singleDiscard(i)}
                                    />
                                )
                            })}
                        </div>
                    )}
                    {!loading && (
                        <div className="addNum">
                            <Guard scope="user_create">
                                <div>
                                    <div className="fun" style={{ width: "0" }}>
                                        <button
                                            className={`plus`}
                                            onClick={() => addCollaborators()}
                                        >
                                            +
                                        </button>
                                        Add More Collaborators
                                    </div>
                                </div>
                            </Guard>
                        </div>
                    )}
                    {isEditing === -2 && (
                        <div
                            className={"addMembers"}
                            onClick={() => {
                                if (!loading) masterAddUsers()
                            }}
                        >
                            {loading ? (
                                <div
                                    style={{
                                        position: "relative",
                                        marginLeft: "1rem",
                                    }}
                                >
                                    <Loader
                                        w="1rem"
                                        h="1rem"
                                        b="2px"
                                        clr="gray"
                                        bk="rgba(128,128,128,0.35)"
                                        t="1s"
                                    />
                                </div>
                            ) : (
                                "Add Members"
                            )}
                        </div>
                    )}
                </>
            ) : (
                <Loader h="2rem" w="2rem" b="2px" clr="white" bk="#99acc2" t="1s" />
            )}
        </div>
    )
}

const mapStateToProps = (
    { userReducer, roleReducer, brandsReducer },
    { brandId, company }
) => {
    const currentUser = { ...userReducer.user, userRole: userReducer.userRole }
    return {
        currentUserData: currentUser,
        roles: roleReducer.role,
        brandsLoading: brandsReducer.loading,
        members: brandsReducer.team[brandId],
        userImageMapping: userReducer.workplaceUsers[company?.id]
    }
}

export default connect(mapStateToProps)(Teams)
