import { checkTimeValidity, addDateAndTime } from "../HandleDateAndTime"

export function checkPost(
    post,
    scheduleDate,
    scheduleTime,
    channelsToPostOn,
    timeZone,
    postStatus = null,
    files = null
) {
    if (!post?.text) return "Please enter a some post Content to post."
    let errorString = ""
    if (channelsToPostOn?.includes("twitter_user") && !post?.text?.twitter_text)
        errorString = errorString + "twitter, "
    if (channelsToPostOn?.includes("instagram_page") && !post?.text?.instagram_text)
        errorString = errorString + "instagram, "
    if (
        (channelsToPostOn?.includes("facebook_page") ||
            channelsToPostOn?.includes("facebook_group")) &&
        !post?.text?.facebook_text
    )
        errorString = errorString + "facebook, "
    if (
        (channelsToPostOn?.includes("linkedin_user") ||
            channelsToPostOn?.includes("linkedin_page")) &&
        !post?.text?.linkedin_text
    )
        errorString = errorString + "linkedin, "
    if (channelsToPostOn?.includes("pinterest_user") && !post?.title)
        errorString = errorString + "pinterest, "
    if (errorString.length > 0)
        return `Please enter some post Content to ${errorString} post.`
    if (!timeZone || timeZone === "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi")
        return "Please Select a timezone for the post"
    if (
        scheduleDate ^ scheduleTime ||
        (postStatus === "schedule" && (!scheduleDate || !scheduleTime))
    )
        return "Please Enter a valid date and time."

    if (
        channelsToPostOn?.includes("pinterest_user") &&
        (!files || !files["pinterest"]?.length || files["pinterest"]?.length > 1)
    ) {
        return !files || !files["pinterest"]?.length
            ? "Pinterest Posts cannot be created without Media"
            : "Pinterest Posts can only have one Media"
    }
    if (
        channelsToPostOn?.includes("instagram_page") &&
        (postStatus === "schedule" || postStatus === "publish") &&
        (!files || !files["instagram"]?.length)
    ) {
        return "Instagram posts cannot be created without an image"
    }
    // if (
    //     scheduleDate &&
    //     scheduleTime &&
    //     !checkTimeValidity(addDateAndTime(scheduleDate, scheduleTime))
    // )
    //     return "Post cant be scheduled in the Past. Please Enter a Valid Schedule date."

    return
}

export function checkForFiles(post, files) {
    if (files && files.length > 0) {
        let mediaFiles = files.map((file, i) => {
            const postType = file.postType === "reel" ? "REEL" : file.mediaType?.split("/")[0]?.toUpperCase() ?? "IMAGE"
            return ({
                mediaUrl: file.mediaPath ?? file.preview,
                mediaName: file.mediaPath ?? file.preview,
                mediaType: postType,
                displayOrder: i + 1,
            })
        })
        post.postType = files[0].mediaType?.split("/")[0]?.toUpperCase() ?? "IMAGE"
        post.mediaContent = mediaFiles
    }
    return post
}
