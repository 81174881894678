import React, { useState } from "react"
import { connect } from "react-redux"

import { ReactComponent as DeleteIcon } from "components/icons/delete.svg"

import Avatar from "components/Utilities/Avatar/Avatar"
import Grid from "@awesomesuite-frontend/awesome-nebula/dist/Grid"
import Guard from "Awesome-ui/Guard"
import Loader from "components/commonComps/Loaders"
import { addPopup, deletePopup } from "Actions/messageAction"
import { unConsumePlan } from "Actions/transactionAction"
import { CONSUME_USER } from "ActionTypes/transactionActionTypes"
import { deleteTeamMember } from "Actions/brandsAction"

const UserItem = (props) => {
    let { deleteHandle, classes, userLoading } = props
    let { id, icon, first_name, user_name, last_name } = props.user

    return (
        <Grid
            container
            margin="0 12px"
            padding="8px"
            border="1px solid #D2DBE6"
            align="center"
        >
            <Grid item xs={2}>
                <Avatar firstName={first_name || user_name} icon={icon} />
            </Grid>
            <Grid item xs={8} style={{ textAlign: "start" }}>
                {first_name || user_name}&nbsp; {last_name}
            </Grid>
            <Grid item xs={2} container align="center" justify="center">
                {userLoading ? (
                    <div style={{ position: "relative" }}>
                        <Loader
                            h="1rem"
                            w="1rem"
                            b="2px"
                            clr="#33475b"
                            bk="rgba(255,255,255,0.25)"
                            t="1s"
                        />
                    </div>
                ) : (
                    <Guard scope="user_delete" show>
                        <DeleteIcon
                            className={classes.deleteIcon}
                            onClick={() => deleteHandle(id, first_name)}
                        />
                    </Guard>
                )}
            </Grid>
        </Grid>
    )
}

let UserDownGrade = (props) => {
    let { remainingNoOfUser, workspace, classes = {} } = props
    const [userLoading, setuserLoading] = useState({})

    if (!workspace) return null
    const handleDeleteUser = async (userId) => {
        deletePopup()
        setuserLoading((prev) => ({ ...prev, [userId]: true }))
        await deleteTeamMember(userId, workspace.id, null, () => {}, true)
        unConsumePlan(CONSUME_USER)
        setuserLoading((prev) => ({ ...prev, [userId]: false }))
    }

    const cancle = (userId) => {
        setuserLoading((prev) => ({ ...prev, [userId]: false }))
        deletePopup()
    }

    function removeUser(userId, name) {
        let popupData = {
            title: "Delete User",
            text: `You are about to delete "${name}" user.`,
            cancel: () => cancle(userId),
            proceed: () => handleDeleteUser(userId),
            action: "Delete",
            category: "critical",
        }
        deletePopup()
        addPopup(popupData)
    }

    return (
        <Grid marginBottom="14px">
            <Grid
                margin="0 12px"
                padding="8px"
                border="1px solid #D2DBE6"
                style={{ textAlign: "start" }}
            >
                Users {`( `}
                <span className={classes.highlight}>{workspace.users?.length}</span>
                {` / `}
                {workspace.users?.length + remainingNoOfUser}
                {`)`}
            </Grid>
            {workspace.users?.map((user, index) => (
                <UserItem
                    user={user}
                    deleteHandle={removeUser}
                    classes={classes}
                    userLoading={userLoading[user.id]}
                    key={index}
                />
            ))}
        </Grid>
    )
}

let mapStateToProps = ({ workplaceReducer, transactionReducer }) => ({
    workspace: workplaceReducer.currentActiveWorkspace,
    remainingNoOfUser: transactionReducer.subscription?.remainingNoOfUser || 0,
})

export default connect(mapStateToProps)(UserDownGrade)
