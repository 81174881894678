import Axios from "axios"
import { getHeaders } from "../../../Actions/userAction"
import { getAllPlatformPosts, socialPostsApi, baseURL } from "../../apis"
import { associateWorkflowWithPost } from "../../../Actions/workflowAction"
import { FACEBOOK } from "../../../Data/SocialMedialTypes"
import { createMessage } from "../../../Actions/messageAction"
import { FACEBOOK_GROUP, FACEBOOK_PAGE } from "../../../constants"
import { DRAFT, SCHEDULE } from "../Types/postStatusTypes"
import {
    deleteFirstComment,
    postFirstComment,
    updateFirstComment,
} from "Actions/postAction"

const facebookApi = Axios.create({
    baseURL: socialPostsApi("facebook"),
})

/**
 * Atomic post creation api for creation of post in both page and company
 * based on the configuration.
 *
 * Note : here companyPost is reffed to the face book group.
 *
 **/
export const createFacebookPost = async (data, config, firstComment) => {
    if (!data) return

    try {
        let response = {}
        if (config.companyPost) {
            response = await createPostForGroup(data)
        } else {
            response = await createPostForPage(data)
        }
        if (response.code)
            return {
                status: "ERROR",
                failureReason: response.msg,
                platform: config.companyPost ? FACEBOOK_GROUP : FACEBOOK_PAGE,
                data,
            }

        if (
            (response.status === SCHEDULE || response.status === DRAFT) &&
            response.isApprovalRequired === 1
        ) {
            const platform = config.companyPost ? FACEBOOK_GROUP : FACEBOOK_PAGE
            await associateWorkflowWithPost(data.brandId, response.id, platform)
        }

        if (firstComment) {
            postFirstComment(
                FACEBOOK,
                response.brandId,
                response.id,
                firstComment,
                null,
                response
            )
        }
        return response
    } catch (error) {
        console.log({ error })
        return {
            status: "ERROR",
            failureReason: "Couldn't create facebook post",
            platform: config.companyPost ? FACEBOOK_GROUP : FACEBOOK_PAGE,
            data,
        }
    }
}

/**
 * make an atomic api call with lots of filters and get post array or
 * single posts of  in return.
 *
 * TODO: Do the error handling.
 *
 * @param {*} config { brandId , postId, status, userId }
 *
 * @return if postId is not present then it will return the
 * Array of the posts.
 * or if the postId is present then Single post will be return.
 */
export const getFacebookPost = async (config) => {
    if (!config.brandId) return

    let headers = getHeaders()

    if (!headers) return

    let params = {
        brandId: config.brandId,
        platform: config.key ? `facebook_${config.key}` : FACEBOOK,
    }

    // Add filters
    if (config.status) params.status = config.status
    if (config.page) params.page = config.page
    if (config.limit) params.limit = config.limit
    if (config.postId) params.postId = config.postId
    if (config.sortBy) params.sortBy = config.sortBy
    if (config.id) params.dbId = config.id
    if (config.fromDate) params.fromDate = config.fromDate
    if (config.toDate) params.toDate = config.toDate
    if (config.orderBy) params.orderBy = config.orderBy
    params.key = "posts"

    headers.params = params

    try {
        const result = await Axios.get(getAllPlatformPosts, headers)

        if (result.data.code === 200) {
            //normalize post data based on respective social platform
            let posts = result.data.model.map((post) => ({
                ...post,
                path: post.mediaUrl,
            }))
            return posts
        } else return null
    } catch (error) {
        return null
    }
}

export const getFacebookPostById = async (config) => {
    try {
        if (!config.brandId) return

        let headers = getHeaders()
        if (!headers) return

        headers.params = {
            brandId: config.brandId,
            dbId: config.id,
        }

        let response = await facebookApi.get("", headers)
        console.log(response)
        return response.data.model
    } catch (err) {
        console.log(err)
        return null
    }
}

// POST create API'S STARTS HERE

// request body data Object
let mockRequestObj = {
    brandId: null, // brand id
    text: "", // text of the post
    postType: "", // post type image
    mediaContent: [],
    tag: "", // add this feature later
    label: "",
    publishDate: "2022-03-25T02:33:01.6676075+05:30",
    status: "",
    version: "",
    isApprovalRequired: 1,
    timeZone: "",
    key: "page",
    created_on: null,
}

/**
 * TAG: PRIVATE
 * create post for the facebook page.
 * @param {*} data post request
 * @returns return post object if success or Error Class with reason.
 */
const createPostForPage = async (data) => {
    const headers = getHeaders()

    const request = { ...mockRequestObj }

    Object.keys(data).forEach((item) => {
        if (request.hasOwnProperty(item)) {
            request[item] = data[item]
        }
    })

    // if (request.text) request.text = encodeURIComponent(request.text)
    request.platform = FACEBOOK_PAGE

    if (request.publishDate?.length === 0) delete request.publishDate

    try {
        const postResult = await facebookApi.post("", request, headers)

        if (postResult.data.code === 200) {
            return postResult.data.model
        } else {
            throw new Error(postResult.data.msg)
        }
    } catch (error) {
        throw new Error("Facebook post creation failed")
    }
}

/**
 * TAG: PRIVATE
 * create post for the facebook group.
 * @param {*} data post request
 * @returns return post object if success or Error Class with reason.
 */
const createPostForGroup = async (data) => {
    const headers = getHeaders()

    const request = { ...mockRequestObj }

    Object.keys(data).forEach((item) => {
        if (request.hasOwnProperty(item)) {
            request[item] = data[item]
        }
    })

    // if (request.text) request.text = encodeURIComponent(request.text)
    request.platform = FACEBOOK_GROUP

    try {
        const postResult = await facebookApi.post("", request, headers)

        if (postResult.data.code === 200) return postResult.data.model
        else {
            return { code: 400, msg: postResult.data.msg }
        }
    } catch (error) {
        return { code: 400, msg: "Facebook post creation failed." }
    }
}

/**
 * TAG: PUBIC
 * added the facebook page to the brand...
 *
 * https://facebookApi/companypage?brandId={brandId}&fbpageId=${fpageId}&pageAccessToken=${token}
 */
export const addCompanyToBrand = async (brandId, pageId, accessToken) => {
    const headers = getHeaders()
    try {
        const companyResult = await facebookApi.post(
            `/companypage?brandId=${brandId}&fbpageId=${pageId}&pageAccessToken=${accessToken}`,
            null,
            headers
        )
        return companyResult
    } catch (error) {
        return "Failed Api: could not add company to the the api."
    }
}

export const updateFacebookPost = async (post) => {
    const headers = getHeaders()
    try {
        const updateResult = await facebookApi.put("", post, headers)

        if (updateResult.data.code === 200) {
            if (post.firstComment && post.firstComment[FACEBOOK]) {
                let commentFunction = post.commentId
                    ? updateFirstComment
                    : postFirstComment

                await commentFunction(
                    FACEBOOK,
                    updateResult.data.model.brandId,
                    updateResult.data.model.id,
                    post.firstComment[FACEBOOK],
                    null,
                    updateResult.data.model
                )
            } else if (post.commentId) {
                await deleteFirstComment(
                    FACEBOOK,
                    updateResult.data.model.brandId,
                    updateResult.data.model.id
                )
            }
            return updateResult.data.model
        } else {
            return new Error(updateResult.data.msg)
        }
    } catch (error) {
        return new Error("Facebook post updation failed.")
    }
}

export const editFacebookPostSchedule = async (post) => {
    const headers = getHeaders()
    headers.params = {
        key: FACEBOOK,
    }
    const newData = {
        ...post,
    }
    try {
        const res = await facebookApi.put("", newData, headers)
        if (res.data.model) {
            // associateWorkflow(res.data.model.id) // Dont know what this is for
            createMessage("success", res.data.msg)
            return res.data.model
        }
        return null
    } catch (err) {
        createMessage("danger", err.response.data)
        return null
    }
}

export const getFacebookPostByDateNew = async ({
    brandId,
    fromDate,
    toDate,
    platform,
}) => {
    let headers = getHeaders()
    try {
        let url = `${baseURL}/v2/facebook/publish/posts?brand_id=${brandId}&start_date=${fromDate}&end_date=${toDate}`
        if (platform) url += `&platform=${platform}`
        const result = await Axios.get(url, headers)
        if (result?.data?.code !== 200) return null
        return result.data
    } catch (error) {
        console.log(error)
        createMessage("danger", "Error while getting posts")
        return null
    }
}

export const getFacebookPostNew = async (brandId, limit, social) => {
    let headers = getHeaders()
    try {
        let url = `${baseURL}/v2/facebook/publish/posts?brand_id=${brandId}`
        if (limit) url += `&limit=${limit}`
        if (social) url += `&platform=${social}`
        const result = await Axios.get(url, headers)
        if (result?.data?.code !== 200) return null
        return result.data
    } catch (error) {
        return null
    }
}

export const getFacebookPostNext = async (brandId, next, limit, platform) => {
    let headers = getHeaders()
    try {
        let url = `${baseURL}/v2/facebook/publish/posts?brand_id=${brandId}&after=${next}`
        if (limit) url += `&limit=${limit}`
        if (platform) url += `&platform=${platform}`
        const result = await Axios.get(url, headers)
        if (result?.data?.code !== 200) return null
        return result.data
    } catch (error) {
        return null
    }
}
