import React from "react"
import moment from "moment"
import { ReactComponent as Facebook } from "../../../icons/socialIcons/facebook.svg"
import { ReactComponent as Instagram } from "../../../icons/socialIcons/instagram.svg"
import { ReactComponent as Linkedin } from "../../../icons/socialIcons/linkedin.svg"
import { ReactComponent as Twitter } from "../../../icons/socialIcons/twitter.svg"
import "./CollaborationPostStrip.scss"

const socialIcons = {
    LINKEDIN: <Linkedin />,
    FACEBOOK: <Facebook />,
    TWITTER: <Twitter />,
    INSTAGRAM: <Instagram />,
}

function GetIcons({ media }) {
    return socialIcons[media]
}

const CollaborationPostStrip = React.memo(({ post }) => {
    const c = "aui-post-collaboration-strip"
    return (
        <div className={c}>
            <div className={c + "-image"}>
                <img src={post.image} alt={"Social Post"} />
            </div>
            <div className={c + "-content"}>
                <div className={c + "-top"}>
                    <div className={c + "-text"}>{post.text}</div>
                    <div className={c + "-creator"}>{post.createdBy[0]}</div>
                </div>
                <div className={c + "-bottom"}>
                    <div className={c + "-icon"}>
                        <GetIcons media={post.socialMediaType} />
                    </div>
                    <div className={c + "-time"}>
                        {moment(post.publishDate).format("DD MMM YYYY")}
                    </div>
                    <div className={c + "-tag"}>Tag</div>
                </div>
            </div>
        </div>
    )
})

export default CollaborationPostStrip
