import React, { memo } from "react"
import { ReactComponent as DocxIcon } from "./fileIcon/docx.svg"
import { ReactComponent as TxtIcon } from "./fileIcon/txt.svg"
import { ReactComponent as Mp4Icon } from "./fileIcon/mp4.svg"
import { ReactComponent as SvgIcon } from "./fileIcon/svg.svg"
import { ReactComponent as PDFIcon } from "./fileIcon/pdf-file.svg"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

const icons = {
    pdf: <PDFIcon />,
    docx: <DocxIcon />,
    txt: <TxtIcon />,
    mp4: <Mp4Icon />,
    svg: <SvgIcon />,
    document: <PDFIcon />,
}

/**
 * @description assigns different icons based on your file type. Basically, it picks mediaType property or you can pass custom file type in form of "type/fileType"
 * @param {File} file
 * @param {String} [mediaType="mediaType"] default: mediaType
 * @param {String} [postType="postType"]
 * @param {Object} [style]
 * @param {Function} [openFilePreview]
 */
export const GetFile = memo(
    ({
        file,
        invoke = false,
        mediaType = "mediaType",
        mediaContent = "mediaContent",
        style = {},
        props,
        openPreview = () => {},
    }) => {
        function getType() {
            if (file.status === "publish") {
                return file[mediaContent] && file[mediaContent]?.[0]
                    ? file[mediaContent]?.[0][mediaType]
                    : null
            } else {
                return file[mediaType]?.split("/")?.[1]
            }
        }
        const type = getType()
        if (file?.source === "Drive") {
            const fileName = file.mediaName
                .split(`${file.mediaId}`)[1]
                .replace("pdf", "")
            file.name = fileName
        }
        return icons[type?.toLowerCase()] &&
            file.postType?.toUpperCase() !== "REEL" &&
            file.mediaType?.toUpperCase() !== "REEL" ? (
            <div
                style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                    objectFit: "cover",
                    ...style,
                }}
                onClick={openPreview}
            >
                {icons[type.toLowerCase()]}
                {!invoke && (
                    <div
                        style={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            width: "100%",
                            padding: "0.15rem 0.3rem",
                            background: "rgba(0,0,0,0.75)",
                            color: "white",
                            textAlign: "center",
                            height: "20px",
                        }}
                    >
                        {file.name}
                    </div>
                )}
            </div>
        ) : (
            <LazyLoadImage
                src={
                    file.preview ??
                    file.mediaPath ??
                    file.mediaUrl ??
                    file.src ??
                    file.media_url
                }
                alt={file.name || ""}
                effect="blur"
                width={"100%"}
                height={"100%"}
            />
        )
    }
)
