export const SET_COMPANY = "SET_COMPANY"
export const SELECT_PROFILE_TO_VIEW = "SELECT_PROFILE_TO_VIEW"
export const ADD_PUBLISH_FILES = "ADD_PUBLISH_FILES"
export const DELETE_PUBLISH_FILES = "DELETE_PUBLISH_FILES"
export const TOGGLE_PUBLISH_MINIFY_LINK = "TOGGLE_PUBLISH_MINIFY_LINK"
export const SELECT_CHANNEL_TO_POST_ON = "SELECT_CHANNEL_TO_POST_ON"
export const UPDATE_POST_SCHEDULED_DATE = "UPDATE_POST_SCHEDULED_DATE"
export const UPDATE_POST_SCHEDULED_TIME = "UPDATE_POST_SCHEDULED_TIME"
export const PUBLISH_LOADING = "PUBLISH_LOADING"
export const PUBLISH_LOADING_END = "PUBLISH_LOADING_END"
export const SET_ALL_SOCIAL_ACCOUNTS = "SET_ALL_SOCIAL_ACCOUNTS"
export const TOGGLE_SOCIAL_ACCOUNT_TO_POST_ON = "TOGGLE_SOCIAL_ACCOUNT_TO_POST_ON"
export const CHANGE_MINIFY_LINK = "CHANGE_MINIFY_LINK"
export const UPDATE_PUBLISH_POST = "UPDATE_PUBLISH_POST"
export const UPDATE_PUBLISH_TEXT = "UPDATE_PUBLISH_TEXT"
export const UPDATE_ALL_PUBLISH_POST = "UPDATE_ALL_PUBLISH_POST"
export const CLEAR_ALL_PUBLISH_POSTS = "CLEAR_ALL_PUBLISH_POSTS"
export const RESET_PUBLISH_POST = "RESET_PUBLISH_POST"
export const UPDATE_CREATED_PUBLISH_POST = "UPDATE_CREATED_PUBLISH_POST"
export const PREVIOUS_PUBLISH_PAGE = "PREVIOUS_PUBLISH_PAGE"
export const SET_PUBLISH_FILTER_STATUS = "SET_PUBLISH_FILTER_STATUS"
export const SET_PUBLISH_TAB = "SET_PUBLISH_TAB"
export const SHUFFLE_PUBLISH_FILES = "SHUFFLE_PUBLISH_FILES"
export const DUPLICATE_PUBLISH_FILES = "DUPLICATE_PUBLISH_FILES"
export const CHANGE_PUBLISH_POST = "CHANGE_PUBLISH_POST"
export const UPDATE_PUBLISH_TIME_ZONE = "UPDATE_PUBLISH_TIME_ZONE"
export const SET_PUBLISH_USER_FILTER_STATUS = "SET_PUBLISH_USER_FILTER_STATUS"
export const SET_PUBLISH_SORT_FILTER_STATUS = "SET_PUBLISH_SORT_FILTER_STATUS"
export const SET_PUBLISH_SEARCH_FILTER_STATUS = "SET_PUBLISH_SEARCH_FILTER_STATUS"
export const SET_PUBLISH_DATE_FILTER_STATUS = "SET_PUBLISH_DATE_FILTER_STATUS"
export const SET_PUBLISH_FILTER = "SET_PUBLISH_FILTER"
export const UPDATE_PUBLISH_CREATED_POST = "UPDATE_PUBLISH_CREATED_POST"
export const PUBLISH_APPEND_TEXT = "PUBLISH_APPEND_TEXT"