import { useEffect, useState } from "react"

/**
 *
 * @param {Link} url URL whose meta data has to be extracted
 * @returns {Object|undefined} This function returns an object of MetaTags which has same name as that used in HTML. Returns `undefined` if website is of `mixed-content` or not a valid URL.
 *
 */
export async function getMeta(url) {
    try {
        if (!/(^http(s?):\/\/[^\s$.?#].[^\s]*)/i.test(url)) return undefined

        const html = await (await fetch(url)).text() // html as text
        const doc = await new DOMParser().parseFromString(html, "text/html") // parse the text to convert into DOM String

        //meta tags object
        let metaTags = {}
        //get title pf website
        metaTags.title = Array.from(doc.getElementsByTagName("title"))[0].innerHTML
        //get icon of website
        metaTags.icon = Array.from(doc.getElementsByTagName("link"))
            .filter((node) => node.getAttribute("rel") === "icon")[0]
            .getAttribute("href")

        //extract all the meta tags with the name of `link`
        Array.from(doc.getElementsByTagName("link")).forEach((node) => {
            let property = node.getAttribute("rel")
            let href = node.getAttribute("href")
            if (property) metaTags[property] = href
        })

        //extract all the meta tags with the name of `meta`
        Array.from(doc.getElementsByTagName("meta")).forEach((node) => {
            let property = node.getAttribute("property")
            let name = node.getAttribute("name")
            let content = node.getAttribute("content")
            if (property) metaTags[property] = content
            else if (name) metaTags[name] = content
        })
        return metaTags
    } catch (err) {
    }
}

/**
 * @description hook to get meta data of the website with given link
 * @param {Link} url
 */
export const useLinkMeta = (url) => {
    const [meta, setMeta] = useState(null)

    useEffect(() => {
        ;(async () => {
            const metaTags = await getMeta(url)
            setMeta(metaTags)
        })()
    }, [url])

    return meta
}
