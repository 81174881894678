import React from "react"
import LinkedinSlider from "../ImageViews/LinkedinSlider"
import "./previewFile.scss"
import { closeFilePreview } from "Actions/messageAction"
import { connect } from "react-redux"

/**
 * @description Component for file/image preview
 * @param {File[]} files
 * @param {Number} index : CurrentIndex
 * @param {Function} closePreview
 * @returns
 */
const PreviewFile = ({ files, index }) => {
    const _C = "aui-preview-file"
    return (
        index !== null && (
            <div className={_C + "-backdrop"} onClick={closeFilePreview}>
                <div className={_C} onClick={(e) => e.stopPropagation()}>
                    <LinkedinSlider files={files} currentIndex={index} />
                </div>
                <span className={_C + "-preview-cancel"} onClick={closeFilePreview}>
                    +
                </span>
            </div>
        )
    )
}

const mapStatesToProps = ({messageReducer}) => {
    return {
        files: messageReducer.previewFiles,
        index: messageReducer.filePreviewIndex
    }
}

export default connect(mapStatesToProps)(PreviewFile)
