export const FACEBOOK = "facebook"
export const LINKED_IN = "linkedin"
export const TWITTER = "twitter"
export const INSTAGRAM = "instagram"
export const PINTEREST = "pinterest"

export const compareSocialMedia = (social, socialToCheckWith) => {
    if (!social || !socialToCheckWith) return false

    return normalize(social) === normalize(socialToCheckWith)
}

export const getSocialName = (social) => {
    const newSocial = normalize(social)
    if (
        newSocial !== FACEBOOK &&
        newSocial !== LINKED_IN &&
        newSocial !== TWITTER &&
        newSocial !== INSTAGRAM &&
        newSocial !== PINTEREST
    )
        return false
    return newSocial
}

function normalize(input) {
    return input.toLowerCase().trim()
}
