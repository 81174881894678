import React from "react"
import Arrow from "../HelpSideNavbar/images/right-arrow.svg"
import "./ResourceCenterCard.scss"

/**
 * Resource Card Used In ResourceCenterContainer Component
 *
 * @param {String} name
 * @param {String} description
 * @param {String} image
 * @param {String} background
 *
 */

const ResourceCenterCard = ({ name, image, description, background }) => {
    return (
        <div className="resource-center-card">
            {image && (
                <div style={{ background }} id="img-container">
                    <img src={image} alt={name} />
                </div>
            )}
            <div>
                <h6>{name}</h6>
                <p>{description}</p>
            </div>
            <img src={Arrow} alt="arrow" />
        </div>
    )
}

export default ResourceCenterCard
