import Axios from "axios"
import { createMessage } from "../../../Actions/messageAction"
import { getHeaders } from "../../../Actions/userAction"
import { TWITTER } from "../../../Data/SocialMedialTypes"
import {
    socialPostsApi,
    twitterApiUrl,
    getAllPlatformPosts,
    baseURL,
} from "../../apis"
import { associateWorkflow } from "./associateWorkflowWithPost"
import { associateWorkflowWithPost } from "../../../Actions/workflowAction"
import { TWITTER_USER } from "../../../constants"
import { DRAFT, SCHEDULE } from "../Types/postStatusTypes"
import {
    deleteFirstComment,
    postFirstComment,
    updateFirstComment,
} from "Actions/postAction"

const twitterApi = Axios.create({
    baseURL: socialPostsApi("twitter"),
})

/**
 * create post for twitter particularly.
 *
 * @param {*} data - object with the post date created by the postHandler.
 * @param {*} authToken - user token who is creating the post.
 * @param {Function} cb - Callback function
 */
export const createTwitterPost = async (data, firstComment) => {
    // TODO: throw error for much better handling of failure case.
    if (!data) return

    //TODO: do we need to create separate Text modifier.

    // create a Deep copy of the object
    const newData = {}
    Object.keys(data).forEach((item) => {
        newData[item] = data[item]
    })
    newData.text = data.text
    newData.socialMediaType = TWITTER

    let headers = getHeaders()

    if (newData.publishDate?.length === 0) delete newData.publishDate

    try {
        const res = await twitterApi.post("", newData, headers)

        if (res.data.code !== 200) {
            return {
                status: "ERROR",
                failureReason: res.data.msg,
                platform: TWITTER_USER,
                data,
            }
        }

        const { brandId, id, status, isApprovalRequired } = res.data.model

        if (firstComment) {
            postFirstComment(
                TWITTER,
                brandId,
                id,
                firstComment,
                null,
                res.data.model
            )
        }

        if ((status === SCHEDULE || status === DRAFT) && isApprovalRequired === 1)
            await associateWorkflowWithPost(brandId, id, TWITTER_USER)

        createMessage("success", res.data.msg)
        return res.data.model
    } catch (err) {
        if (err.response) createMessage("danger", err.response.data)
        return {
            status: "ERROR",
            failureReason: "Couldn't create twitter post.",
            platform: TWITTER_USER,
            data,
        }
    }
}

/**
 * make an atomic api call with lots of filters and get post array or
 * single posts of  in return.
 *
 * TODO: Do the error handling.
 *
 * @param {*} config { brandId , postId, status, userId }
 *
 * @return if postId is not present then it will return the
 * Array of the posts.
 * or if the postId is present then Single post will be return.
 */
export const getTwitterPost = async (config) => {
    if (!config.brandId) return

    let headers = getHeaders()

    if (!headers) return

    let params = {
        brandId: config.brandId,
        platform: config.key ? `twitter_${config.key}` : TWITTER,
    }

    if (config.status) params.status = config.status
    if (config.page) params.page = config.page
    if (config.limit) params.limit = config.limit
    if (config.postId) params.postId = config.postId
    if (config.sortBy) params.sortBy = config.sortBy
    if (config.id) params.dbId = config.id
    if (config.fromDate) params.fromDate = config.fromDate
    if (config.toDate) params.toDate = config.toDate
    if (config.orderBy) params.orderBy = config.orderBy
    params.key = "posts"

    headers.params = params

    try {
        const result = await Axios.get(getAllPlatformPosts, headers)

        if (result.data.code === 200) {
            //normalize post data based on respective social platform
            let posts = result.data.model.map((post) => ({
                ...post,
                path: post.mediaUrl,
            }))
            return posts
        } else return null
    } catch (error) {
        return null
    }
}

export const getTwitterPostById = async (config) => {
    try {
        if (!config.brandId) return

        let headers = getHeaders()
        if (!headers) return

        headers.params = {
            brandId: config.brandId,
            dbId: config.id,
        }

        let response = await twitterApi.get("", headers)

        return response.data.model
    } catch (err) {
        console.log(err)
        return null
    }
}

export const twitterTextFormatter = (text) => {
    if (!text) return
    return decodeURIComponent(text)
}

export const editTwitterPostSchedule = async (post) => {
    const headers = getHeaders()
    headers.params = {
        key: TWITTER,
    }
    const newData = {
        ...post,
    }

    try {
        const res = await twitterApi.put("", newData, headers)

        if (res.data.model) {
            createMessage("success", res.data.msg)
            associateWorkflow(res.data.model.id)
            return res
        }
        return null
    } catch (err) {
        createMessage("danger", err.response.data)
        return null
    }
}

/**
 * get All post related to twitter
 * Note : this is api is not available  right now
 *
 * @param {*} brandId - brand id for which we need to fetch the post for.
 * @returns
 */
export const getTwitterPosts = async (brandId) => {
    // TODO: throw error for much better handling of failure case.
    if (!brandId) return null

    //TODO: do we need to create separate Text modifier.

    let headers = getHeaders()

    headers.params = {
        brandId: brandId,
    }

    try {
        const res = await twitterApi.get("", headers)
        if (res.data.model) {
            return res.data.model
        } else {
            createMessage("danger", "Cant fetch posts")
        }
    } catch (err) {
        createMessage("danger", "Cant fetch posts")
    }
}

/**
 * generic api to get the post metric - likes, comments and summery.
 * @param {*} data -  data contains {postId, brandId}
 * @param {*} config - "likes" to enable likes, "comments" to enable comments
 */
async function getPostMetric(data, config) {
    let headersData = config.headers

    if (!headersData) {
        headersData = getHeaders(config.token)
    }

    let key = null

    if (config.likes) key = "likes"

    if (config.comments) key = "comments"

    let response = await Axios.get(
        `${twitterApiUrl}/socialactions/?brandId=${data.brandId}&postId=${
            data.postId
        }${key && "&key=" + key}}`,
        headersData
    )

    return response.data
}

/**
 * single api to get the like on individual post.
 *
 * @param {*} data - {postId, brandId}.
 * @param {*} authToken - auth token of the current user.
 *
 * @returns Error | model response.
 */
export const getTwitterPostLikes = async (data, authToken) => {
    let headers = getHeaders()

    let response
    try {
        response = await getPostMetric(data, { headers, likes: true })

        if (response.model) {
            return response.model
        } else {
            return new Error(response.msg)
        }
    } catch (error) {
        return new Error(
            "could not get the likes for the post",
            data.brandId,
            data.postId
        )
    }
}

/**
 * single api to get the comments on individual post.
 *
 * @param {*} data - {postId, brandId}.
 * @param {*} authToken - auth token of the current user.
 *
 * @returns Error | model response.
 */
export const getTwitterPostComments = async (data, authToken) => {
    let headers = getHeaders()

    let response
    try {
        response = await getPostMetric(data, { headers, comments: true })

        if (response.model) {
            return response.model
        } else {
            return new Error(response.msg)
        }
    } catch (error) {
        return new Error(
            "could not get the likes for the post",
            data.brandId,
            data.postId
        )
    }
}

/**
 * single api to get the summary of individual post.
 *
 * @param {*} data - {postId, brandId}.
 * @param {*} authToken - auth token of the current user.
 *
 * @returns Error | model response.
 */
export const getTwitterPostSummery = async (data, authToken) => {
    let headers = getHeaders()

    let response
    try {
        response = await getPostMetric(data, { headers })

        if (response.model) return response.model
        else return new Error(response.msg)
    } catch (error) {
        return new Error(
            "could not get the likes for the post",
            data.brandId,
            data.postId
        )
    }
}

export const updateTwitterPost = async (post) => {
    // post.text = encodeURIComponent(post.text)
    const headers = getHeaders()
    try {
        const updateResult = await twitterApi.put("", post, headers)

        if (updateResult.data.code === 200) {
            if (post.firstComment && post.firstComment[TWITTER]) {
                let commentFunction = post.commentId
                    ? updateFirstComment
                    : postFirstComment

                await commentFunction(
                    TWITTER,
                    updateResult.data.model.brandId,
                    updateResult.data.model.id,
                    post.firstComment[TWITTER],
                    null,
                    updateResult.data.model
                )
            } else if (post.commentId) {
                await deleteFirstComment(
                    TWITTER,
                    updateResult.data.model.brandId,
                    updateResult.data.model.id
                )
            }
            return updateResult.data.model
        } else {
            return new Error(updateResult.data.msg)
        }
    } catch (error) {
        return new Error("Twitter post updation failed.")
    }
}

export const getTwitterPostNew = async (brandId, limit, social) => {
    let headers = getHeaders()
    try {
        let url = `${baseURL}/v2/twitter/publish/posts?brand_id=${brandId}`
        if (limit) url += `&limit=${limit}`
        if (social) url += `&platform=${social}`
        const result = await Axios.get(url, headers)
        if (result?.data?.code !== 200) return null
        return result.data
    } catch (error) {
        console.log(error)
        return null
    }
}

export const getTwitterPostNext = async (brandId, next, limit, platform) => {
    let headers = getHeaders()
    try {
        let url = `${baseURL}/v2/twitter/publish/posts?brand_id=${brandId}&after=${next}`
        if (limit) url += `&limit=${limit}`
        if (platform) url += `&platform=${platform}`
        const result = await Axios.get(url, headers)
        if (result?.data?.code !== 200) return null
        return result.data
    } catch (error) {
        createMessage("danger", "Error while getting posts")
        return null
    }
}

export const getTwitterPostByDateNew = async ({
    brandId,
    fromDate,
    toDate,
    platform,
}) => {
    let headers = getHeaders()
    try {
        let url = `${baseURL}/v2/twitter/publish/posts?brand_id=${brandId}&start_date=${fromDate}&end_date=${toDate}`
        if (platform) url += `&platform=${platform}`
        const result = await Axios.get(url, headers)
        if (result?.data?.code !== 200) return null
        return result.data
    } catch (error) {
        console.log(error)
        createMessage("danger", "Error while getting posts")
        return null
    }
}
