/**
 * @description generate random colors
 * @returns Random Color
 */

export default function getColor() {
    return "#" + (((1 << 24) * Math.random()) | 0).toString(16)
}

/**
 * @description get random color from list
 * @returns Random Color from list
 */

const colors = [
    "#7DD1FF",
    "#EEBD71",
    "#14AAF4",
    "#00C0C9",
    "#3DDC84",
    "#3FC1A9",
    "#FD9A00",
    "#E363E3",
    "#796EFF",
    "#7086AA",
    "#A4CF30",
    "#33475B",
    "#AA7946",
    "#E8384F",
    "#F06A6A",
    "#3E6B6C",
]

export function getColorFromList() {
    const ind = Math.floor(Math.random() * colors.length)
    return colors[ind]
}

const cardColors = {
    A: "#fedaa3",
    B: "#ffc0ce",
    C: "#ffecc7",
    D: "#b4ffdb",
    E: "#a9fffa",
    F: "#ecd3ff",
    G: "#d4d1ff",
    H: "#d2e8ff",
    I: "#fedaa3",
    J: "#ffc0ce",
    K: "#ffecc7",
    L: "#b4ffdb",
    M: "#b4ffdb",
    N: "#a9fffa",
    O: "#d4d1ff",
    P: "#ecd3ff",
    Q: "#d2e8ff",
    R: "#ffc0ce",
    S: "#ffecc7",
    T: "#d4d1ff",
    U: "#d4d1ff",
    V: "#ecd3ff",
    W: "#ffc0ce",
    X: "#d2e8ff",
    Y: "#a9fffa",
    Z: "#fedaa3",
}

/**
 * @description get random card colors from list
 * @returns Random Card Color from list
 */
export function getCardColor(name) {
    const ind = name[0].toUpperCase()
    return cardColors[ind]
}
