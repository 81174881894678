import React , {useEffect, useState} from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { GetFile } from "../GetFileIcon"
import "./style.scss"


const getItemStyle = (isDragging, draggableStyle, itemStyle = {}) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // background: isDragging ? "lightgreen" : "grey",
    ...draggableStyle,
    ...itemStyle,
})

const getListStyle = (isDraggingOver, listStyle = {}) => ({
    // background: isDraggingOver ? "lightblue" : "lightgrey",
    ...listStyle,
})

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

/**
 *
 * @param {[File]} files
 * @example ```
 * {name, preview, ...}
 * ```
 * @param {Function} onFilesShuffle : onFileShuffle(files)
 * @param {Integer} height
 *
 */
const FilesDragShuffler = ({ files, onFilesShuffle, activeDuplication, previewChannel, height = 10 }) => {
    const _C = "aui-files-drag-shuffler"
    function onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        const shuffledFiles = reorder(
            files,
            result.source.index,
            result.destination.index
        )

        onFilesShuffle(shuffledFiles , previewChannel, activeDuplication)
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                    <div
                        className={_C}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver, {
                            height: `${height + 0.5}rem`,
                            maxHeight: `${height + 0.5}rem`,
                        })}
                        {...provided.droppableProps}
                    >
                        {files.map((file, i) => (
                            <Draggable
                                key={file.preview}
                                draggableId={file.preview}
                                index={i}
                            >
                                {(provided, snapshot) => (
                                    <div
                                        className={_C + "-item"}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style,
                                            {
                                                maxHeight: `${height}rem`,
                                                minHeight: `${height}rem`,
                                            }
                                        )}
                                    >
                                            <GetFile
                                                file={file}
                                                style={{
                                                maxHeight: "100%",
                                                maxWidth: "100%",
                                            }}
                                        />
                                    
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default FilesDragShuffler
