import Config from "Awesome-ui/Config/Config"
import React from "react"
import { connect } from "react-redux"
import { changePostManagerOption } from "../../../../Actions/postAction"
import ToolTip from "../../ToolTip"

import "./optionSideNav.scss"

const OptionSidenav = React.memo(({ allOptions, option, state, social }) => {
    const theme =
        localStorage.getItem("SocialTheme") &&
            localStorage.getItem("SocialTheme") === "black"
            ? 0
            : 1
    const _C =
        theme === 1 ? "aui-post-manager-sidenav-white" : "aui-post-manager-sidenav"
    function handleOptionClick(i) {
        if (!allOptions[state][i]?.active) return
        changePostManagerOption(i)
    }

    return (
        <div className={_C}>
            {state && allOptions &&
                allOptions[state]?.map(({ title, icon, active }, i) => (
                    <Config key={i} features={["METRIC"]} social={social} show={title !== "Engagement"} >
                        <div
                            key={i}
                            className={
                                _C +
                                "-option " +
                                (i === option ? _C + "-option-active" : "") +
                                (!active ? _C + "-option-disabled" : "")
                            }
                            onClick={() => handleOptionClick(i)}
                        >
                            {icon ? (
                                typeof icon === "string" ? (
                                    <img src={icon} alt={title} />
                                ) : (
                                    React.createElement(icon)
                                )
                            ) : (
                                <div></div>
                            )}
                            <ToolTip
                                dir="left"
                                name={title?.toUpperCase()}
                                clr={"#fff"}
                                bg={"#516F90"}
                            />
                        </div>
                    </Config>
                ))}
        </div>
    )
})

const mapStateToProps = ({ postReducer }) => {
    return {
        allOptions: postReducer.allOptions,
        option: postReducer.option,
        state: postReducer.state,
    }
}

export default connect(mapStateToProps)(OptionSidenav)
