import React from "react"
import FaceBookIcon from "./FaceBookIcon"
import LinkedInIcon from "./LinkedInIcon"
import TwitterIcon from "./TwitterIcon"
import InstagramIcon from "./InstagramIcon"
import PinterestIcon from "./PinterestIcon"
import FaceBookGroupIcon from "./FaceBookGroupIcon"
import LinkedInCompanyIcon from "./LinkedInCompanyIcon"

/**
 * atomic component for all social medial icons
 * mapping for provide the name of the social media platform
 * and with the active key it can control the color of the social media
 * if active is true then color full logo will come if it is false then
 * inactive icon will be returned.
 *
 * @param {Object} object
 * @param {string} object.name - for the name of the social
 * @param {true} object.active - change color based on boolean value
 * @returns
 */
const SocialIconsMapping = ({ name, active, style, subType }) => {
    const socialIcons = {
        facebook: <FaceBookIcon active={active} style={style} />,
        facebook_page: <FaceBookIcon active={active} style={style} />,
        facebook_group: <FaceBookIcon active={active} style={style} />,
        facebookgroup: <FaceBookGroupIcon active={active} style={style} />,
        linkedin: <LinkedInIcon active={active} style={style} />,
        linkedin_user: <LinkedInIcon active={active} style={style} />,
        linkedin_page: <LinkedInIcon active={active} style={style} />,
        linkedingroup: <LinkedInCompanyIcon active={active} style={style} />,
        twitter: <TwitterIcon active={active} style={style} />,
        twitter_user: <TwitterIcon active={active} style={style} />,
        instagram: <InstagramIcon active={active} style={style} />,
        instagram_page: <InstagramIcon active={active} style={style} />,
        pinterest: <PinterestIcon active={active} style={style} />,
        pinterest_user: <PinterestIcon active={active} style={style} />,
    }
    if(subType && subType === 1)return socialIcons[name?.toLowerCase() + "group"] ?? socialIcons[name?.toLowerCase()]
    return socialIcons[name?.toLowerCase()] ?? <></>
}
export default SocialIconsMapping
