import store from "../store"
import axios from "axios"
import { createMessage } from "./messageAction"
import {
    UPDATE_BRAND_WORKFLOW,
    TOGGLE_FETCH,
} from "../ActionTypes/workflowActionTypes"
import {
    getAllWorkflowByBrandId,
    createWorkflowByBrandId,
    addWorkflowToPostByBrandId,
    getAssociatedWorkflowWithPost,
} from "../components/apis"
import { getHeaders } from "./userAction"
import { toggleBrandSetting } from "./brandsAction"
import { clearAllPublishPosts } from "./publishAction"
import { clearPosts, getAllSocialsPosts, resetPage } from "./postAction"

const { dispatch, getState } = store

export function associateWorkflowWithPost(brandId, postId, platform) {
    return new Promise(async (resolve) => {
        let workflow = getState().workflowReducer.workflow[brandId]

        try {
            if (!workflow) {
                workflow = await getBrandWorkflow(brandId)
            }
            const res = await axios.post(
                `${addWorkflowToPostByBrandId}`,
                {
                    postId,
                    brandId,
                    platform,
                    workflowId: workflow.workflowId,
                },
                {
                    ...getHeaders(),
                }
            )
            if (!res.data.code != 200) {
                createMessage("danger", res.data.msg)
            }
        } catch (err) {
            createMessage("danger", err)
        } finally {
            resolve()
        }
    })
}

/**
 * @description Get workflow by brandId
 * @param {Number|String} brandId
 * @returns
 */
export async function getBrandWorkflow(brandId) {
    return new Promise(async (resolve) => {
        let workflow = getState().workflowReducer.workflow[brandId]
        if (workflow) return
        try {
            const res = await axios.get(`${getAllWorkflowByBrandId}`, {
                ...getHeaders(),
                params: {
                    brand_id: `${brandId}`,
                },
            })

            if (res.data.model) {
                res.data.model.sort((first, second) => {
                    if (first.workflowId > second.workflowId) {
                        return -1
                    } else if (first.workflowId < second.workflowId) {
                        return 11
                    }
                    return 0
                })
                dispatch({
                    type: UPDATE_BRAND_WORKFLOW,
                    payload: {
                        brandId,
                        pipelines: res.data.model.length ? res.data.model[0] : null,
                    },
                })
                return res.data.model[0]
            } else {
                createMessage("danger", res.data.msg)
                return null
            }
        } catch (err) {
            createMessage("danger", err.message)
            return null
        } finally {
            resolve()
        }
    })
}

/**
 *
 * @param {String|Number} brandId
 * @param {String|Number} companyId
 * @param {String} workflowName
 * @param {String[][]} levels
 */
export async function saveBrandWorkflow(
    brandId,
    companyId,
    workflowName,
    levels,
    editWorkflow
) {
    let brandWorkflow = getState().workflowReducer.workflow[brandId]
    let res
    if (editWorkflow === true) {
        res = await axios.put(
            createWorkflowByBrandId,
            {
                brandId: brandId,
                workflowName,
                levels,
                workflowId: brandWorkflow.workflowId,
            },
            {
                ...getHeaders(),
            }
        )
    } else {
        res = await axios.post(
            createWorkflowByBrandId,
            {
                brandId: brandId,
                workflowName,
                levels,
            },
            {
                ...getHeaders(),
            }
        )
    }
    if (!res.data.model) {
        createMessage("danger", res.data.msg)
    } else {
        createMessage(
            "success",
            `Workflow ${editWorkflow ? "updated" : "created"} successfully!`
        )
        toggleBrandSetting(brandId, companyId, false)
        dispatch({
            type: UPDATE_BRAND_WORKFLOW,
            payload: {
                brandId,
                pipelines: res.data.model,
            },
        })
    }
}

export function toggleFetch() {
    dispatch({
        type: TOGGLE_FETCH,
    })
    if (window.location.pathname.includes("/publish")) {
        clearAllPublishPosts()
        getAllSocialsPosts()
    }
    else {
        clearPosts()
        resetPage()
    }
}

export async function fetchWorkflowAssociated(brandIds, postIds) {
    try {
        const headers = getHeaders().headers
        const res = await axios.get(getAssociatedWorkflowWithPost, {
            headers: headers,
            params: {
                brand_id: brandIds,
                post_id: postIds,
            },
        })

        if (res.data.code === 200 && res.data.model?.length > 0) {
            return res.data.model
        }
        return null
    }
    catch (err) {
        console.log(err)
        return null
    }
}