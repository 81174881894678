import React, { useEffect, useState, useRef } from "react"
import axios from "axios"
import GifList from "./GifList"
import Input from "../Components/Input/Input"

export const gifApi = axios.create({
    baseURL: "https://tenor.googleapis.com/v2",
})

// TODO: get the new tokens.
const config = {
    key: process.env.REACT_APP_TENOR_KEY,
    client_key: process.env.REACT_APP_DRIVE_CLIENT_ID,
    media_filter: "minimal",
}

// TODO: fix these using these because intersection observer is not taking react state.
let lastKey = null
let qSearch = ""

const Gif = ({ setCheckoutFiles }) => {
    const [gif, setGif] = useState({})
    const scrollRef = useRef(null)
    const [loading, setLoading] = useState(false)

    function handelObserver(entries) {
        if (!entries) return
        const target = entries[0]
        if (target.isIntersecting && loading === false) {
            fetchDetails()
        }
    }

    // tenorApi.Search
    useEffect(() => {
        function init() {
            const options = {
                threshold: 1,
                root: null,
            }
            const observer = new IntersectionObserver(handelObserver, options)
            if (scrollRef.current) {
                observer.observe(scrollRef.current)
            }

            return () => {
                observer.disconnect(scrollRef.current)
            }
        }
        init()
    }, [scrollRef.current])

    /**
     *  handle the gif fetch
     *  depend on the conditions by default it will search for the trends and if
     *  user is searching the we will search for that key.
     */
    async function fetchDetails() {
        setLoading(true)

        let params = {
            limit: "20",
            pos: lastKey,
            ...config,
        }

        let url = "search"

        if (qSearch && qSearch.length > 0) {
            url = "search"
            params.q = qSearch
        } else {
            const currentTrend = await gifApi.get("trending_terms", {
                params,
            })
            params.q = currentTrend.data.results[0]
        }

        const trend = await gifApi.get(url, {
            params,
        })

        lastKey = trend.data.next
        const list = trend.data.results
        setGif((prev) => {
            const old = { ...prev }
            if (old[0]) old[0] = [...old[0], ...list.slice(0, 10)]
            else old[0] = [...list.slice(0, 10)]

            if (old[1]) old[1] = [...old[1], ...list.slice(10, 20)]
            else old[1] = [...list.slice(10, 20)]
            return old
        })

        setLoading(false)
    }

    /**
     * search post trigger function to set the values to the defaults.
     */
    async function searchPost() {
        lastKey = null
        setLoading(true)
        setGif({ 0: [], 1: [] })
        fetchDetails()
    }

    /**
     * handle the file select
     * @param {number} col column number from 0 - 2
     * @param {*} id image id to select
     */
    async function handlerSelect(col, id) {
        setGif((prev) => {
            const newPrevObj = { ...prev }

            const newPrev = [...newPrevObj[col]]

            const getIndex = newPrev.findIndex((item) => item.id === id)
            let newVal = { ...newPrev[getIndex] }
            newVal.selected = !newVal.selected

            newPrev[getIndex] = newVal

            setCheckoutFiles((checkFile) => {
                let newCheckFile = [...checkFile]
                if (newVal.selected) {
                    let checkImg = newCheckFile.findIndex(
                        (item) => item.id === newVal.id
                    )
                    if (checkImg === -1) newCheckFile.push(newVal)
                } else {
                    newCheckFile = newCheckFile.filter(
                        (item) => item.id !== newVal.id
                    )
                }
                return newCheckFile
            })

            return {
                ...newPrevObj,
                [col]: newPrev,
            }
        })
    }

    return (
        <div className="media-picker">
            <div className="media-content-wrapper">
                <div className="media-content">
                    <div className="media-Search">
                        <Input
                            id="media-Search"
                            placeHolder="Search gifs"
                            type="text"
                            style={{ flex: "1" }}
                            onKeyHandler={searchPost}
                            changeHandler={(data) => {
                                qSearch = data
                            }}
                        />
                        <div className="media-btn" onClick={searchPost}>
                            Search
                        </div>
                    </div>
                    <div className="image-content image-content-2">
                        <GifList
                            gif={gif}
                            handlerSelect={handlerSelect}
                            scrollRef={scrollRef}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Gif
