import React, { useEffect, useState, useRef, useCallback } from "react"
import axios from "axios"
import {
    addCollaborationComment,
    getCollaborationSubCommentsInThread,
    getAssociatedWorkflowWithPost,
    approvePostWithWorkflow,
} from "../../apis"
import { createMessage } from "../../../Actions/messageAction"
import CollaborationPostStrip from "./CollaborationPostStrip"
import WorkflowComponent from "../CreatedWorkflowComponent"
import Avatar from "../Avatar/Avatar"
import ChatBox from "./ChatBox"
import { getHeaders } from "../../../Actions/userAction"
import useFetch from "../../../hooks/useFetch"
import { connect } from "react-redux"

import "./postCollaborations.scss"
import { toggleFetch } from "../../../Actions/workflowAction"
import {
    startMessageChannel,
    closeMessage,
    updatePage,
    addNewComment,
} from "../../../Actions/userMessagingAction"

/**
 * @description Show the post related collaboration
 * @param {*} post Post related to collaboration
 * @param {*} token auth token
 * @param {Boolean} showPost To show post
 * @param {Function} onStatusChange Function to do something on status change
 */

const PostCollaboration = ({
    post,
    token,
    showPost,
    onStatusChange,
    userImageMapping,
    user,
    users,
    roles,
    collaborations,
    page,
    hasMore,
    loading,
}) => {
    const _C = "aui-post-collaboration"
    const headers = getHeaders().headers
    const [workflow, setWorkflow] = useState(null)
    const [levelData, setLevelData] = useState(null)
    const [forApproval, setForApproval] = useState(false)
    const [lastApprovedLevel, setLastApprovedLevel] = useState(-1)

    const brandId = `${post.brandId}_${post.id}`
    // let lastApprovedLevel = -1

    /* eslint-disable */
    useEffect(() => {
        getWorkflow()
    }, [forApproval])
    /* eslint-enable */

    const observer = useRef()
    const lastElementRef = useCallback(
        (node) => {
            if (observer.current) observer.current.disconnect()
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    updatePage(page + 1)
                    boxRef.current.scrollTop = 500
                }
            })
            if (node) observer.current.observe(node)
        },
        [hasMore, page]
    )

    useEffect(() => {
        if (brandId && brandId !== -1) {
            startMessageChannel("Post", brandId)
        }
        return () => {
            closeMessage()
        }
    }, [])

    const addCommentRef = useRef()
    const boxRef = useRef()
    const containerBottomRef = useRef()

    function scrollBottom() {
        if (containerBottomRef.current)
            containerBottomRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            })
    }

    useEffect(() => {
        if (boxRef.current && page === 0 && collaborations.length > 0) {
            boxRef.current.scrollTop = boxRef.current.scrollHeight
        }
    }, [page])

    async function onComment(comment, cb) {
        try {
            let res

            res = await axios.post(
                addCollaborationComment,
                {
                    relationId: `${post.brandId}_${post.id}`,
                    comment: comment,
                },
                { headers }
            )
            if (res.data.model) {
                let rawRes = res.data.model
                const newComment = {
                    name:
                        userImageMapping?.[rawRes.createdBy]?.first_name &&
                        userImageMapping?.[rawRes.createdBy]?.last_name
                            ? `${userImageMapping?.[rawRes.createdBy]?.first_name} ${
                                  userImageMapping?.[rawRes.createdBy]?.last_name
                              }`
                            : rawRes.createdBy.split("@")[0],
                    comment: rawRes.deception,
                    time: rawRes.createdOn,
                    image: userImageMapping[rawRes.createdBy]?.picture,
                    id: rawRes.id,
                    ...rawRes,
                }
                addNewComment(newComment)
                scrollBottom()
            } else {
                createMessage("danger", res.data.msg)
            }
            if (cb) cb()
        } catch (err) {
            createMessage("danger", err)
        }
    }

    async function getWorkflow() {
        try {
            const res = await axios.get(getAssociatedWorkflowWithPost, {
                headers: headers,
                params: {
                    brand_id: post.brandId,
                    post_id: post.id,
                },
            })
            if (!res.data.model || !res.data.model.length) {
                setWorkflow([])
                setLevelData([])
                // createMessage("warn", "No Associated Workflow with given Post")
            } else {
                const levels = res.data.model[0].levels
                levels &&
                    levels.sort((first, second) => {
                        if (first.step < second.step) return -1
                        if (first.step > second.step) return 1
                        return 0
                    })
                let userWorkflow = []
                let levelDataTemp = []

                for (let i = 0; i < levels.length; i++) {
                    const { teams, isRequiredAll } = levels[i]
                    let level = []
                    let approvedCnt = 0
                    let rejectedCnt = 0
                    let pendingCnt = 0

                    for (let j = 0; j < teams.length; j++) {
                        const { isApproved, userName, approvalId } = teams[j]
                        const userFound = users.find(
                            (user) => user.user_name === userName
                        )
                        level.push({
                            image: "",
                            email: "user@gmail.com",
                            workflowUser: userFound,
                            ...teams[j],
                        })
                        if (teams[j].isApproved === 1) approvedCnt++
                        else if (teams[j].isApproved === -1) rejectedCnt++
                        else pendingCnt++
                    }

                    if (isRequiredAll) {
                        if (approvedCnt === teams.length) setLastApprovedLevel(i)
                    } else if (approvedCnt > 0) setLastApprovedLevel(i)
                    levelDataTemp.push({
                        isRequiredAll: isRequiredAll,
                        approvedCnt: approvedCnt,
                        rejectedCnt: rejectedCnt,
                        pendingCnt: pendingCnt,
                        levelSize: teams.length,
                    })
                    userWorkflow.push(level)
                }
                setWorkflow(userWorkflow)
                setLevelData(levelDataTemp)
            }
        } catch (err) {
            if (err) {
                createMessage("danger", err.data)
            }
        }
    }
    let currentUserWorkflowData
    let currentUserLeve
    useEffect(() => {
        workflow &&
            workflow.map((level, i) => {
                currentUserWorkflowData = level.find(
                    (entry) => entry.userName === user.user_name
                )
                if (currentUserWorkflowData) {
                    if (
                        currentUserWorkflowData.isApproved === 0 &&
                        i <= lastApprovedLevel + 1
                    ) {
                        if (post.status !== "publish" && post.status !== "unpublish")
                            setForApproval(true)
                    }
                }
            })
    }, [workflow])

    async function doApproval(status, message) {
        let currentUserWorkflow
        workflow &&
            workflow.map((level) => {
                for (let i = 0; i < level.length; i++) {
                    if (level[i].userName === user.user_name) {
                        if (
                            !currentUserWorkflow ||
                            currentUserWorkflow.isApproved !== 0
                        )
                            currentUserWorkflow = level[i]
                        break
                    }
                }
            })

        let params = {
            approval_id: `${currentUserWorkflow.approvalId}`,
        }
        let reqBody = {
            action: "approve",
            reason: message,
        }
        if (status === "reject") {
            reqBody = {
                action: "reject",
                reason: message,
            }
            params = {
                approval_id: `${currentUserWorkflow.approvalId}`,
            }
        }
        try {
            const res = await axios.put(`${approvePostWithWorkflow}`, reqBody, {
                headers,
                params,
            })
            if (res.data.model) {
                onStatusChange(status)
                setForApproval(false)
                if (status === "reject")
                    createMessage("success", "Rejection Successful!")
                else createMessage("success", "Approval Successful!")
                toggleFetch()
            } else {
                createMessage("danger", res.data.msg)
            }
        } catch (err) {
            if (err.data) createMessage("danger", err.data)
        }
    }

    return (
        <div className={_C}>
            <div className={_C + "-top-wrapper"}>
                {showPost && (
                    <>
                        <CollaborationPostStrip post={post} />
                        <div className={_C + "-publisher"}>
                            <div>Publisher: </div>
                            <div className={_C + "-publisher-wrapper"}>
                                <div className={_C + "-publisher-image"}>
                                    <Avatar
                                        firstName={post.createdBy}
                                        lastName=""
                                        icon={post.createdBy.image}
                                        height="44px"
                                        width="44px"
                                        fontsize="1rem"
                                    />
                                </div>
                                <div className={_C + "-publisher-name"}>
                                    {post.createdBy}
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {workflow && workflow.length > 0 && post.status !== "publish" && (
                    <WorkflowComponent
                        workflow={workflow}
                        levelData={levelData}
                        lastApprovedLevel={lastApprovedLevel}
                        users={users}
                        roles={roles}
                    />
                )}
            </div>
            <ChatBox
                comments={collaborations}
                onComment={onComment}
                doApproval={doApproval}
                userImageMapping={userImageMapping}
                forApproval={forApproval}
                loading={loading}
                addCommentRef={addCommentRef}
                boxRef={boxRef}
                lastElementRef={lastElementRef}
                containerBottomRef={containerBottomRef}
                user={user}
            />
        </div>
    )
}

const mapStateToProps = ({
    userReducer,
    roleReducer,
    workplaceReducer,
    userMessageingReducer,
}) => {
    return {
        users: workplaceReducer.currentActiveWorkspace.users,
        user: userReducer.user,
        roles: roleReducer.role,
        collaborations: userMessageingReducer.messages,
        page: userMessageingReducer.page,
        hasMore: userMessageingReducer.hasMore,
        loading: userMessageingReducer.loading,
    }
}

export default connect(mapStateToProps)(PostCollaboration)
