import React from "react"
import { createUseStyles } from "react-jss"
import { ReactComponent as CancelIcon } from "./Assets/cancel-icon.svg"
import Text from "@awesomesuite-frontend/awesome-nebula/dist/Text"

const useStyles = createUseStyles(() => ({
    backgroundCompound: {
        position: "fixed",
        height: "100%",
        width: "100%",
        background: "rgba(45,62,80,0.72)",
        top: 0,
        left: 0,
        zIndex: 1000000002,
    },
    createFolderModal: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: ({ width }) => (width ? width : 400),
        backgroundColor: "#fff",
        borderRadius: "6px",
    },
    modalHeader: {
        background: ({ background }) =>
            background
                ? background
                : "linear-gradient(90deg,#2cd5f8,#20cbfa,#25c1fa,#36b6f9,#49abf5,#55a3f1,#629bed,#6d93e7,#758ce2,#7d86dc,#847fd6,#8b78cf)",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "6px 6px 0 0",
        padding: 12,
        position: "relative",
    },
    modelTitle: {
        margin: "0px 2rem 0 1rem",
    },
    cancelLogo: {
        position: "absolute",
        top: 10,
        right: 0,
        marginRight: "1rem",
        padding: "0.5rem",
        borderRadius: "50%",
        height: 32,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.24)",
        },
    },
    modalContent: {
        padding: ({ disablePadding }) => (!disablePadding ? "2rem 1.5rem" : 0),
        borderRadius: "0 0 6px 6px",
        border: "1px solid var(--border-color)",
    },
}))

const AwesomeModel = ({
    width,
    open,
    subText,
    description,
    title,
    setOpen,
    children,
    background,
    disablePadding,
    disableClose,
}) => {
    const classes = useStyles({ width, background, disablePadding })

    if (!open) {
        return null
    }

    return (
        <div className={classes.backgroundCompound}>
            <div className={classes.createFolderModal}>
                <div className={classes.modalHeader}>
                    <div className={classes.modelTitle}>
                        <Text color="#fff" varient="body" weight={600} fontSize={18}>
                            {title}
                        </Text>
                        {subText && (
                            <Text color="#fff" varient="subText" marginLeft="1rem">
                                {subText}
                            </Text>
                        )}
                        {description}
                    </div>
                    {!disableClose && (
                        <div
                            className={classes.cancelLogo}
                            onClick={() => setOpen(false)}
                        >
                            <CancelIcon />
                        </div>
                    )}
                </div>
                <div className={classes.modalContent}>{children}</div>
            </div>
        </div>
    )
}

export default AwesomeModel
