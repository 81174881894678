import {
    CLEAR_MESSAGE,
    CREATE_MESSAGE,
    MODIFY_ALERT,
    MODIFY_OFFER,
    ADD_POPUP,
    DELETE_POPUP,
    ADD_NOTIFICATION,
    DELETE_NOTIFICATION,
    TOGGLE_HELP,
    OPEN_FILE_PREVIEW,
    CHANGE_PREVIEW_INDEX,
    CLOSE_FILE_PREVIEW,
    UPDATE_META_TITLE,
    RESET_META_TITLE,
    VIEW_TRIAL_ENDED,
    HIDE_TRIAL_ENDED,
    VIEW_PLAN_POPUP,
    HIDE_PLAN_POPUP,
} from "../ActionTypes/messageActionTypes"
import { getHeaders } from "./userAction"

import axios from "axios"
import store from "../store"
import { notificationApi } from "../components/apis"
const { dispatch, getState } = store

const api = axios.create({
    baseURL: notificationApi,
})

export const createMessage = (status, msg) => {
    if (!msg) return
    dispatch({
        type: CREATE_MESSAGE,
        payload: { status: status, msg: msg },
    })
}

export const clearMessage = () => {
    dispatch({ type: CLEAR_MESSAGE })
}

export const modifyOffer = (msg) => {
    dispatch({ type: MODIFY_OFFER, payload: { offer: msg } })
}

export const modifyAlert = (msg) => {
    dispatch({ type: MODIFY_ALERT, payload: { alert: msg } })
}

/**
 * 
 * @param {Object} popup
 * @example {
        title: String,
        text: String,
        cancel: Function,
        proceed: Function,
        action: "Delete",
        loading: Boolean,
    } 
 */
export const addPopup = (popup) => {
    dispatch({ type: ADD_POPUP, payload: { popup } })
}

export const deletePopup = () => {
    dispatch({ type: DELETE_POPUP })
}

export const addNotifications = (notification) => {
    dispatch({ type: ADD_NOTIFICATION, payload: { notification } })
}

export const deleteNotification = (id) => {
    dispatch({ type: DELETE_NOTIFICATION, payload: { notificationId: id } })
}

export const toggleHelp = () => {
    dispatch({ type: TOGGLE_HELP })
}

export const getNotification = async () => {
    try {
        const notification = await api.get("/", {
            params: {},
            ...getHeaders(),
        })
        if (notification.data.model) {
            dispatch({
                type: ADD_NOTIFICATION,
                payload: { notification: notification.data.model },
            })
        }
    } catch (error) {
        if (error.response) createMessage("danger", error.response.data.message)
        else createMessage("danger", "Something Went Wrong !")
    }
}

export const getNotificationById = async (id) => {
    try {
        const notification = await api.get(`/sentnotifiaction?id=${id}`, {
            ...getHeaders(),
        })
        if (notification.data.model) {
            dispatch({
                type: ADD_NOTIFICATION,
                payload: { notification: notification.data.model },
            })
        } else createMessage("danger", notification.data.message)
    } catch (error) {
        if (error.response) createMessage("danger", error.response.data.message)
        else createMessage("danger", "Something Went Wrong !")
    }
}

// "emailid": "email@gmail.com",
// "username": "myname",
// "postname": "link to the post",
// "brandname": "brand name",
// "created": "2021-06-10T15:13:54Z",
// "pref": 1, // 0 for only Inapp; 1 for only email; 2 for both inapp and email notifiaction
// "type": 1 // 0 for post creation;1 for post publition; 2 for post publition failed

export const putNotification = async (data) => {
    try {
        const notification = await api.post("/sentnotifiaction", data, {
            ...getHeaders(),
        })
        if (notification.data.model) return notification.data.model
        else createMessage("danger", notification.data.message)
    } catch (error) {
        if (error.response) createMessage("danger", error.response.data.message)
        else createMessage("danger", "Something Went Wrong !")
    }

    return null
}

export const openFilePreview = (index, files) => {
    dispatch({
        type: OPEN_FILE_PREVIEW,
        payload: {
            index,
            files,
        },
    })
}

export const changePreviewIndex = (index) => {
    dispatch({
        type: CHANGE_PREVIEW_INDEX,
        payload: {
            index,
        },
    })
}

export const closeFilePreview = () => {
    dispatch({
        type: CLOSE_FILE_PREVIEW,
    })
}

export const updateMetaTitle = (title) => {
    dispatch({ type: UPDATE_META_TITLE, payload: { title } })
}

export const resetMetaTitle = () => {
    dispatch({ type: RESET_META_TITLE })
}

export function ViewTrialEnded() {
    dispatch({ type: VIEW_TRIAL_ENDED })
}

export function hideTrialEnded() {
    dispatch({ type: HIDE_TRIAL_ENDED })
}

export function ViewPlanPopup() {
    dispatch({ type: VIEW_PLAN_POPUP })
}

export function hidePlanPopup() {
    dispatch({ type: HIDE_PLAN_POPUP })
}
