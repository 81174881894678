import React, { useState, useEffect, useRef } from "react"
import "./UserInputDropdown.scss"
import { ReactComponent as ArrowDown } from "./Assets/ArrowDown.svg"
import { ReactComponent as CheckTrue } from "./Assets/checkTrue.svg"
import { ReactComponent as CheckFalse } from "./Assets/checkFalse.svg"

const useOutsideAlerter = (ref, handleClose) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                handleClose(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [ref, handleClose])
}

const UserInputDropdown = ({
    inputField,
    inputValue,
    setInputValue,
    inputPlaceholder,
    inputDisabled,
    inputType,
    dropdownOne,
    dropDownOneContent,
    dropDownOneValue,
    setDropdownOneValue,
    dropdownOneDisabled,
    dropdownTwo,
    dropDownTwoContent,
    dropDownTwoValue,
    setDropdownTwoValue,
    dropdownTwoDisabled,
    button,
    buttonAction,
    buttonText,
    cancel,
    cancelAction,
    longInput = false
}) => {
    const [dropDownOneOpen, setDropDownOneOpen] = useState(false)
    const [dropDownTwoOpen, setDropDownTwoOpen] = useState(false)

    function dropdownOneContains(name) {
        const found = dropDownOneContent.filter(
            (content) => content?.id === name?.id
        )
        return found.length
    }
    function dropdownTwoContains(name) {
        const found = dropDownTwoContent.filter(
            (content) => content?.id === name?.id
        )
        return found.length
    }

    const wrapperRef1 = useRef(null)
    const wrapperRef2 = useRef(null)
    useOutsideAlerter(wrapperRef1, setDropDownOneOpen)
    useOutsideAlerter(wrapperRef2, setDropDownTwoOpen)

    return (
        <div className="userInputContainer">
            {inputField && (
                <div className="userInputField">
                    <input
                        placeholder={inputPlaceholder}
                        type={inputType}
                        className={`${longInput? "longInput":"userInput"} `}
                        value={inputValue}
                        onChange={(e) => {
                            setInputValue(e.target.value)
                        }}
                        disabled={inputDisabled}
                        autoComplete={"off"}
                    />
                </div>
            )}
            {dropdownOne && (
                <div className={`userDropdownOne ${longInput && "increaseWidth"}`} ref={wrapperRef1}>
                    <div
                        className="dropDownValue"
                        onClick={
                            !dropdownOneDisabled
                                ? () => {
                                      setDropDownOneOpen(!dropDownOneOpen)
                                  }
                                : () => {}
                        }
                    >
                        <div className="currentDropdownValue">
                            {dropdownOneContains(dropDownOneValue)
                                ? dropDownOneValue?.name
                                : "Select"}
                        </div>
                        <div className="dropdownArrow">
                            <ArrowDown />
                        </div>
                    </div>
                    <div
                        className="dropDownList"
                        style={{
                            border: !dropDownOneOpen ? "none" : "1px solid #D6DFE8",
                        }}
                    >
                        {dropDownOneOpen &&
                            dropDownOneContent.map((content, key) => {
                                return (
                                    <div
                                        key={key}
                                        className="dropDownListItem"
                                        onClick={() => {
                                            setDropdownOneValue(content)
                                            setDropDownOneOpen(false)
                                        }}
                                    >
                                        <div
                                            className={`listItemWrapper ${
                                                dropDownOneValue === content
                                                    ? "listItemWrapper-selected"
                                                    : ""
                                            }`}
                                        >
                                            <div className="checkBox">
                                                {dropDownOneValue?.id === content?.id ? (
                                                    <CheckTrue />
                                                ) : (
                                                    <CheckFalse />
                                                )}
                                            </div>
                                            {content.image && (
                                                <div className="listItemIcon">
                                                    <img
                                                        src={content.image}
                                                        alt=""
                                                    />
                                                </div>
                                            )}
                                            <div className="listItemContent">
                                                <div className="listItemHeading">
                                                    {content.name}
                                                </div>
                                                <div className="listItemDesc">
                                                    {content.desc}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </div>
            )}
            {dropdownTwo && (
                <div className="userDropdownOne" ref={wrapperRef2}>
                    <div
                        className="dropDownValue"
                        onClick={
                            !dropdownTwoDisabled
                                ? (event) => {
                                      setDropDownTwoOpen(!dropDownTwoOpen)
                                      event.stopPropagation()
                                  }
                                : () => {}
                        }
                    >
                        <div className="currentDropdownValue">
                            {dropdownTwoContains(dropDownTwoValue)
                                ? dropDownTwoValue.name
                                : "Select"}
                        </div>
                        <div className="dropdownArrow">
                            <ArrowDown />
                        </div>
                    </div>
                    <div
                        className="dropDownList"
                        style={{
                            border: !dropDownTwoOpen ? "none" : "1px solid #D6DFE8",
                        }}
                    >
                        {dropDownTwoOpen &&
                            dropDownTwoContent.map((content, key) => {
                                return (
                                    <div
                                        key={key}
                                        className="dropDownListItem"
                                        onClick={() => {
                                            setDropdownTwoValue(content)
                                            setDropDownTwoOpen(false)
                                        }}
                                    >
                                        <div
                                            className={`listItemWrapper ${
                                                dropDownTwoValue === content
                                                    ? "listItemWrapper-selected"
                                                    : ""
                                            }`}
                                        >
                                            <div className="checkBox">
                                                {dropDownTwoValue.id === content.id ? (
                                                    <CheckTrue />
                                                ) : (
                                                    <CheckFalse />
                                                )}
                                            </div>
                                            {content.image && (
                                                <div className="listItemIcon">
                                                    <img
                                                        src={content.image}
                                                        alt=""
                                                    />
                                                </div>
                                            )}
                                            <div className="listItemContent">
                                                <div className="listItemHeading">
                                                    {content.name}
                                                </div>
                                                {content.desc && (
                                                    <div className="listItemDesc">
                                                        {content.desc}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </div>
            )}
            {button && (
                <div
                    className="userButton"
                    onClick={() => {
                        buttonAction()
                    }}
                >
                    {buttonText}
                </div>
            )}
            {cancel && (
                <div
                    className="cancelButton"
                    onClick={() => {
                        cancelAction()
                    }}
                >
                    +
                </div>
            )}
        </div>
    )
}

export default UserInputDropdown
