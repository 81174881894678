import Axios from "axios"
import { getHeaders } from "../../../Actions/userAction"
import { publishPostApiUrl, getAllPlatformPosts } from "../../apis"

export const bulkPostPublish = async (postArray, requestType) => {
    const payload = {
        requestType,
        rows: postArray,
    }
    return Axios.post(publishPostApiUrl, payload, {
        ...getHeaders(),
    })
}

export const getAllPublishPosts = async (brandIds, social, page, limit, status) => {
    let params = {
        brandId: brandIds.join(","),
        page: page - 1 ?? 0,
        sortBy: "publishDate",
        limit,
    }

    if (social) params.platform = social.toLowerCase()
    if (status) params.status = status.toLowerCase()

    return Axios.get(getAllPlatformPosts, {
        ...getHeaders(),
        params,
    })
}
