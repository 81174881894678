import React from "react"

import "./loader.scss"

/**
   @param {String} h Height of Loader
   @param {String} w Width of Loader
   @param {String} b Border size of Loader
   @param {String} bk Border color of Loader
   @param {String} clr Clr of Loader
   @param {String} t time of spinning the Loader
   @param {Object?} style Custom style
   @param {*?} customClass Custom Class
*/
const Loader = ({ h, w, b, clr, bk, t, style = {}, customClass }) => {
    const _C = "aui-spin-loader"
    return (
        <div
            className={`${_C} ${customClass ?? ""}`}
            style={{
                height: h,
                width: w,
                border: `${b} solid ${bk}`,
                borderTopColor: clr,
                borderBottomColor: clr,
                animationDuration: t,
                ...style,
            }}
        ></div>
    )
}

/**
 * Line Placeholder Skeleton
 * @param {Object?} style custom style
 * @param {*?} customClass Custom Class
 * @param {*} width width of skeleton
 * @param {*} height height of skeleton
 * @param {*} interval interval of skeleton
 */

export const LineSkeletonLoader = ({
    style = {},
    width,
    height,
    interval,
    customClass,
}) => {
    const _C = "aui-line-skeleton-loader"
    return (
        <div
            className={`${_C} ${customClass ?? ""}`}
            style={{
                width,
                height,
                animationDuration: interval,
                ...style,
            }}
        ></div>
    )
}

/**
 * Circle Placeholder Skeleton
 * @param {Object?} style custom style
 * @param {*?} customClass Custom Class
 * @param {*} width width of skeleton
 * @param {*} height height of skeleton
 * @param {*} interval interval of skeleton
 */

export const CircleSkeletonLoader = ({
    style = {},
    width,
    height,
    interval,
    customClass,
}) => {
    const _C = "aui-circle-skeleton-loader"
    return (
        <div
            className={`${_C} ${customClass ?? ""}`}
            style={{
                width,
                height,
                animationDuration: interval,
                ...style,
            }}
        ></div>
    )
}

/**
 * Box container Placeholder Skeleton
 * @param {Object?} style custom style
 * @param {*?} customClass Custom Class
 * @param {*} width width of skeleton
 * @param {*} height height of skeleton
 */

export const ContainerSkeletonLoader = ({
    style = {},
    width,
    height,
    children,
    customClass,
}) => {
    const _C = "aui-container-skeleton-loader"
    return (
        <div
            className={`${_C} ${customClass ?? ""}`}
            style={{ width, height, ...style }}
        >
            {children}
        </div>
    )
}

export default Loader
