import React from "react"
import { openFilePreview } from "../../../../Actions/messageAction"
import { GetFile } from "../../GetFileIcon/index.js"
import "./twitterGridView.scss"

function getPostClass(length) {
    if (length === 1) return "normal"
    if (length > 1 && length < 5) return `twitter-grid-${length}`
    else return `twitter-grid-4`
}

const TwitterGridView = ({ files }) => {
    function openPreview(index) {
        openFilePreview(index, files)
    }

    return files && files.length > 0 ? (
        <div 
            className={`aui-comment-image ${getPostClass(files.length)}`}
            style={{display: files?.length === 1 ? "block" : "grid"}}
        >
            {files.slice(0, 4).map((file, index) => {
                return (
                    <div className={`photo-${index + 1}`} key={index}>
                        <GetFile
                            file={file}
                            openPreview={(e) => {
                                e.stopPropagation()
                                openPreview(index)
                            }}
                        />
                        {files.length > 4 && index === 3 && (
                            <span
                                className="overlay"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    openPreview(index)
                                }}
                            >
                                <p>+{files.length - 4}</p>
                            </span>
                        )}
                    </div>
                )
            })}
        </div>
    ) : (
        <></>
    )
}

export default TwitterGridView
