import React, { memo, useState } from "react"
import "./Input.scss"

/**
 * Custom input and text-field
 *
 *  Future update Validators will come later
 */
const Input = memo(
    ({
        id,
        rows,
        value,
        type,
        placeHolder,
        disabled,
        label,
        changeHandler,
        textField,
        style,
        inputStyle,
        labelStyle,
        onKeyHandler,
        icon,
    }) => {
        const [select, setSelect] = useState(false)
        const [inValue, setValue] = useState(value)

        return (
            <div className="aui-input" style={{ ...style }}>
                {label && (
                    <label
                        htmlFor={id}
                        className="aui-input-label"
                        style={{ ...labelStyle }}
                    >
                        {label}
                    </label>
                )}
                <div
                    className={`aui-input-wrapper ${
                        disabled ? "input-disabled" : ""
                    } ${label && "input-label-spacer"} ${
                        select ? "aui-input-select" : ""
                    }`}
                >
                    {icon && <div className="input-icon"></div>}
                    {textField ? (
                        <textarea
                            className="unsplash-textarea"
                            onFocus={() => setSelect(true)}
                            onBlur={() => setSelect(false)}
                            id={id}
                            value={inValue}
                            rows={rows ? rows : 1}
                            placeholder={placeHolder && placeHolder}
                            disabled={disabled}
                            onChange={(e) => {
                                setValue(e.target.value)
                                changeHandler(e.target.value)
                            }}
                            style={{ ...inputStyle }}
                            onKeyDown={(btnKey) => {
                                if (btnKey.key === "Enter") {
                                    onKeyHandler(inValue)
                                }
                            }}
                            spellCheck={true}
                        />
                    ) : (
                        <input
                            className="unsplash-input"
                            onFocus={() => setSelect(true)}
                            onBlur={() => setSelect(false)}
                            id={id}
                            value={inValue}
                            type={type}
                            placeholder={placeHolder && placeHolder}
                            disabled={disabled}
                            onChange={(e) => {
                                setValue(e.target.value)
                                changeHandler(e.target.value)
                            }}
                            style={{ ...inputStyle }}
                            onKeyDown={(btnKey) => {
                                if (btnKey.key === "Enter") {
                                    onKeyHandler(inValue)
                                }
                            }}
                        />
                    )}
                </div>
            </div>
        )
    }
)

export default Input
