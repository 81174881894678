import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
    tooltipWrapper: {
        textAlign: "center",
        maxWidth: "15rem",
        width: "max-content",
        position: "absolute !important",
        zIndex: 1000,
        display: "none",
    },
    tooltipContainer: {
        borderRadius: "4px",
        backgroundColor: "#33475B",
        color: "white",
        padding: "7px 17px",
    },
    title: {
        marginBottom: "0.5rem",
        fontSize: "0.85rem",
        width: "100%",
        color: "white",
        fontWeight: "500",
    },
    tooltipContent: {
        color: "white",
        width: "100%",
        fontSize: "0.75rem",
    },
    top: {
        bottom: "100%",
        left: "50%",
        transform: "translate(-50%, 0)",
        marginBottom: "15px",

        "&::after": {
            content: '" "',
            position: "absolute",
            top: "100%",
            left: "50%",
            marginLeft: "-6px",
            borderWidth: "6px",
            borderStyle: "solid",
            borderColor: "#33475B transparent transparent transparent",
        },
    },
    bottom: {
        top: "100%",
        left: "50%",
        transform: "translate(-50%, 0)",
        marginTop: "15px",

        "&::after": {
            content: '" "',
            position: "absolute",
            bottom: "100%",
            left: "50%",
            marginLeft: "-6px",
            borderWidth: "6px",
            borderStyle: "solid",
            borderColor: "transparent transparent #33475B transparent",
        },
    },
    right: {
        left: "100%",
        top: "50%",
        transform: "translate(0 , -50%)",
        marginLeft: "15px",

        "&::after": {
            content: '" "',
            position: "absolute",
            top: "50%",
            right: "100%",
            marginTop: "-6px",
            borderWidth: "6px",
            borderStyle: "solid",
            borderColor: "transparent #33475B transparent transparent",
        },
    },
    left: {
        right: "100%",
        top: "50%",
        transform: "translate(0, -50%)",
        marginRight: "15px",

        "&::after": {
            content: '" "',
            position: "absolute",
            top: "50%",
            left: "100%",
            marginTop: "-6px",
            borderWidth: "6px",
            borderStyle: "solid",
            borderColor: "transparent transparent transparent #33475B",
        },
    },
})

const Tooltip = ({ title, text, style, direction = "bottom" }) => {
    const classes = useStyles()
    return (
        <div
            className={`${classes.tooltipWrapper} invisible ${classes[direction]} `}
        >
            <div className={classes.tooltipContainer} style={{ ...style }}>
                {title && <div className={classes.title}>{title}</div>}
                <div className={classes.tooltipContent}>{text}</div>
            </div>
        </div>
    )
}

export default Tooltip
