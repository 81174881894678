import React, { useState, useEffect } from "react"
import Loader from "../../../commonComps/Loaders"
import MetricsHandler from "../../../Helper/SocialPostHandler/MetricsHandler"
import { ReactComponent as ReactionIcon } from "../../../icons/reactions.svg"
import { ReactComponent as ShareIcon } from "../../../icons/shares.svg"
import { ReactComponent as CommentIcon } from "../../../icons/comment.svg"
import "./style.scss"
import { connect } from "react-redux"

const PostEngagement = ({ post, social, brandId }) => {
    const _C = "aui-post-engagement"
    const [metrics, setMetrics] = useState(undefined)
    const [gradient, setGradient] = useState(null)

    useEffect(() => {
        MetricsHandler(post, social, brandId).then((metrics) => {
            if (!metrics) {
                setMetrics(null)
                return
            }
            setMetrics(metrics)
            let sum = 0
            let total = metrics.reduce((total, cur) => total + cur.count, 0)
            if (total === 0) setGradient("")
            setGradient(
                metrics
                    .reduce((str, metric) => {
                        sum += metric.count
                        str.push(`${metric.clr} 0 ${(sum / total) * 360}deg `)
                        return str
                    }, [])
                    .join(", ")
            )
        })
    }, [])

    const icons = {
        Reactions: <ReactionIcon />,
        Shares: <ShareIcon />,
        Comment: <CommentIcon />,
    }

    return (
        <div className={_C}>
            <div className={_C + "-head"}>Post Engagement</div>
            {metrics === undefined ? (
                <Loader
                    h="4rem"
                    w="4rem"
                    b="6px"
                    bk="#eaf0f6"
                    clr="#D6DFE8"
                    t="1s"
                />
            ) : metrics === null ? (
                "Cant fetch Metrics. Please try later."
            ) : (
                <div className={_C + "-body"}>
                    <div
                        className={_C + "-chart"}
                        style={{
                            backgroundImage: `conic-gradient(${gradient})`,
                        }}
                    >
                        <div></div>
                    </div>
                    <div className={_C + "-table"}>
                        {metrics.map(({ name, count, bgClr, clr }, i) => (
                            <div key={i} className={_C + "-metric-row"}>
                                <div
                                    className={_C + "-icon"}
                                    style={{ background: bgClr, fill: clr }}
                                >
                                    {icons[name]}
                                </div>
                                <div className={_C + "-name"}>{name}</div>
                                <div className={_C + "-count"}>{count}</div>
                            </div>
                        ))}
                        <div className={_C + "-metric-row-total"}>
                            <div className={_C + "-name"}>Total Interactions</div>
                            <div className={_C + "-count"}>
                                {metrics.reduce(
                                    (total = 0, { count = 0 }) => total + count,
                                    0
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default connect(({ socialReducer }) => ({
    brandId: socialReducer.activeSocialBrand?.id,
    social: socialReducer?.social,
}))(PostEngagement)
