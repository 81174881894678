import { themeBannerData, themeLinksData } from "../hey-bio/src/shared/Data/Data"
import { heyThemeData } from "../hey-bio/src/shared/Data/HeyThemeData"
import {
    ADD_NEW_SECTION,
    REMOVE_SECTION,
    UPDATE_SECTION,
    ADD_DATA_TO_SECTION,
    ADD_SOCIAL_DATA_TO_SECTION,
    REMOVE_DATA_FROM_SECTION,
    UPDATE_DATA_FORM_SECTION,
    LOAD_HEY_SECTIONS,
    SET_HEY_THEME,
    SET_HEY_PROFILE_ID,
    SET_HEY_ID,
    SET_HEY_NAME,
    SET_HEY_DESCRIPTION,
    SET_HEY_IMAGE,
    CLEAR_HEY_PROFILE_DATA,
    SET_HEYBIO_LOADING,
    SET_PAGE_ID,
    SET_SECTIONS,
    SET_SECTION_DATA,
    SET_RELATED_ID,
} from "../ActionTypes/HeyBioActionTypes"
import {
    BACKGROUND,
    THEME,
    LINK,
    SOCIALS,
    FONT_FAMILY,
    BANNER,
    PROPERTIES,
} from "../constants/index"

const initialState = {
    theme: {
        primaryColor: "#FFFFFF",
        secondaryColor: "#FFFFFF",
        accentColor1: "#FFC72C",
        accentColor2: "#FDC0D2",
        borderColor1: "#170C05",
        borderColor2: "#170C05",
        borderColor3: "#170C05",
        textColor1: "#170C05",
        textColor2: "#170C05",
        textColor3: "#170C05",

        background: "#FFFFFF",
        subBg: "#84a98c",
        fontFamily: "Roboto",
        fontColor: "#2f3e46",

        linkRadius: "0",
        linkBorder: "1px solid black",
        linkShadow: "none",

        bannerRadius: "16px",
        bannerButtonRadius: "21px",
        boxButtonBorder: null,
        boxImageBorderRadius: "0px",
        bannerPadding: "0",
        bannerBorder: null,
        bannerShadow: "2px 3px 6px rgba(0,0,0,0.25)",
        bannerBG: null,
    },
    // api variables
    themeVariables: {
        theme: "COMIC_THEME_1",
        background: "#FFFFFF",
        fontType: "Roboto",
        linkType: "SHARP_1",
        bannerType: "CURVED",
        socialType: "COLOR",
        properties: {},
    },
    apiThemeVariables: {
        theme: "COMIC_THEME_1",
        background: "#FFFFFF",
        fontType: "Roboto",
        linkType: "SHARP_1",
        bannerType: "CURVED",
        socialType: "COLOR",
        properties: {},
    },
    sections: [],
    sectionData: {},
    profileData: {
        id: "",
        heyId: "",
        name: "",
        desc: "",
        image: null,
    },
    pageId: null,
    loading: false,
    related_id: null,
}

const HeyBioReducer = (state = initialState, action) => {
    const { type } = action
    switch (type) {
        case ADD_NEW_SECTION:
            return addNewSection(state, action.data)
        case REMOVE_SECTION:
            return removeSection(state, action.data)
        case UPDATE_SECTION:
            return state
        case ADD_DATA_TO_SECTION:
            return addDataToSection(state, action.data, action.linkIdx)
        case ADD_SOCIAL_DATA_TO_SECTION:
            return addSocialDataToSection(state, action.data, action.sectionIdx)
        case REMOVE_DATA_FROM_SECTION:
            return removeDataFromSection(state, action.data)
        case UPDATE_DATA_FORM_SECTION:
            return state
        case LOAD_HEY_SECTIONS:
            return state
        case SET_HEY_THEME:
            return handleHeyTheme(state, action.payload)
        case SET_HEY_PROFILE_ID:
            return handleHeyProfileId(state, action.payload)
        case SET_HEY_ID:
            return handleHeyId(state, action.payload)
        case SET_HEY_NAME:
            return handleHeyName(state, action.payload)
        case SET_HEY_DESCRIPTION:
            return handleHeyDescription(state, action.payload)
        case SET_HEY_IMAGE:
            return handleHeyImage(state, action.payload)
        case CLEAR_HEY_PROFILE_DATA:
            return clearHeyData(state, action.payload)
        case SET_HEYBIO_LOADING:
            return setLoading(state, action.payload)
        case SET_PAGE_ID:
            return setPageId(state, action.payload)
        case SET_SECTIONS:
            return setSections(state, action.payload)
        case SET_SECTION_DATA:
            return setSectionData(state, action.payload)
        case SET_RELATED_ID:
            return setRelatedId(state, action.payload)
        default:
            return state
    }
}

export default HeyBioReducer

const setLoading = (state, payload) => {
    return {
        ...state,
        loading: payload,
    }
}

const setSections = (state, payload) => {
    return {
        ...state,
        sections: payload ? payload : [],
    }
}

const setSectionData = (state, payload) => {
    return {
        ...state,
        sectionData: payload,
    }
}

const setPageId = (state, pageId) => {
    return {
        ...state,
        pageId: pageId,
    }
}

/**
 *
 * @param {*} state
 * @param {*} data  - name, type
 */
const addNewSection = (state, data) => {
    let sections = state.sections ? state.sections : []
    let sectionData = state.sectionData ? state.sectionData : {}

    sections.push(data)
    const index = sections.length - 1

    sectionData[data.id] = []

    return {
        ...state,
        sections,
        sectionData,
    }
}

/**
 *
 * @param {*} state
 * @param {*} data
 */
const removeSection = (state, data) => {
    const { id, index } = data
    let sections = { ...state.sections }

    sections = sections.filter((item, ind) => item.id !== id && id !== ind)

    let sectionData = { ...state.sectionData }
    delete sectionData[id]

    return {
        ...state,
        sections: sections,
        sectionData: sectionData,
    }
}

/**
 *
 * @param {*} state
 * @param {*} data
 */
const updateSection = (state, data) => {
    // todo: do not allow the type in data.
    const { id, index, name } = data

    let sections = { ...state.sections }
    const sectionIndex = sections.filter(
        (item, ind) => item.id !== id || index !== ind
    )

    if (sectionIndex < 0) return state

    sections[sectionIndex].name = name

    return {
        ...state,
        sections: sections,
    }
}

/**
 *
 * @param {*} state
 * @param {*} data
 */
const addDataToSection = (state, data, linkIdx) => {
    const { sectionId, sectionIdx, properties } = data

    const sectionData = state.sectionData
    let currSection = sectionData[sectionId]

    if (!currSection) currSection = []

    const id = getidimportant(data.title, properties.skey, currSection.length)

    if (linkIdx === null) {
        currSection.push({ ...data, id: id, ...properties })
    } else {
        Object.keys(data).forEach((key) => {
            if (currSection[linkIdx].hasOwnProperty(key))
                currSection[linkIdx][key] = data?.[key]
        })
        Object.keys(properties).forEach((key) => {
            if (currSection[linkIdx].hasOwnProperty(key))
                currSection[linkIdx][key] = properties?.[key]
        })
    }

    sectionData[sectionId] = currSection

    return {
        ...state,
        sectionData: { ...sectionData },
    }
}

/**
 *
 * @param {*} state
 * @param {*} data
 */
const addSocialDataToSection = (state, data, sectionIdx) => {
    const { sectionData, sections } = state

    const newData = data.map((socialLink) => {
        return Object.assign(socialLink, socialLink.properties)
    })
    sectionData[sections[sectionIdx].id] = newData

    return {
        ...state,
        sectionData: { ...sectionData },
    }
}

/**
 *
 * @param {*} state
 * @param {*} data
 */
const removeDataFromSection = (state, data) => {
    const { sectionIdx, sectionId, id } = data

    const sectionData = state.sectionData
    let currSection = sectionData[sectionId]

    if (!currSection) return state

    currSection = currSection.filter((item, index) => index !== id)
    sectionData[sectionId] = currSection

    return {
        ...state,
        sectionData: { ...sectionData },
    }
}

/**
 *
 * @param {*} state
 * @param {*} data
 */
const updateDataInSection = (state, data) => {}

/**
 * create idimportant returns the same output key for the given
 * inputs
 *
 * @param {*} name name is for the normal component
 * @param {*} key this is for some other cased
 * @param {*} id
 */
const getidimportant = (name, key, id) => {
    if (name) return name + id
    if (key) return key + id
    return id
}

const handleHeyTheme = (state, payload) => {
    let currTheme = { ...state.theme } || {}
    let themeVariables = { ...state.themeVariables }
    let apithemes = { ...state.apiThemeVariables }

    switch (payload.type) {
        case FONT_FAMILY:
            currTheme = { ...currTheme, fontFamily: payload.data }
            themeVariables = { ...themeVariables, fontType: payload.data }
            apithemes = payload.api
                ? { ...apithemes, fontType: payload.data }
                : { ...apithemes }
            break
        case LINK:
            let linkStyle = themeLinksData[payload.data]
            let background = currTheme.background
            themeVariables = { ...themeVariables, linkType: payload.data }
            apithemes = payload.api
                ? { ...apithemes, linkType: payload.data }
                : { ...apithemes }
            currTheme = { ...currTheme, ...linkStyle }
            currTheme.background = background
            break
        case THEME:
            let newThemeData = heyThemeData[payload.data]
            themeVariables = Object.keys(heyThemeData).includes(payload.data)
                ? { ...themeVariables, theme: payload.data }
                : themeVariables
            apithemes = payload.api
                ? Object.keys(heyThemeData).includes(payload.data)
                    ? { ...apithemes, theme: payload.data }
                    : apithemes
                : { ...apithemes }
            currTheme = newThemeData
                ? { ...currTheme, ...newThemeData.theme }
                : currTheme
            break
        case BACKGROUND:
            themeVariables = { ...themeVariables, background: payload.data }
            apithemes = payload.api
                ? { ...apithemes, background: payload.data }
                : { ...apithemes }
            currTheme = { ...currTheme, background: payload.data }
            break
        case BANNER:
            let newBannerData = themeBannerData[payload.data]
            themeVariables = { ...themeVariables, bannerType: payload.data }
            apithemes = payload.api
                ? { ...apithemes, bannerType: payload.data }
                : { ...apithemes }
            currTheme = { ...currTheme, ...newBannerData }
            break
        case SOCIALS:
            themeVariables = { ...themeVariables, socialType: payload.data }
            apithemes = payload.api
                ? { ...apithemes, socialType: payload.data }
                : { ...apithemes }
            break
        case PROPERTIES:
            themeVariables = {
                ...themeVariables,
                properties: { ...themeVariables.properties, ...payload.data },
            }
            apithemes = payload.api
                ? {
                      ...apithemes,
                      properties: { ...themeVariables.properties, ...payload.data },
                  }
                : { ...apithemes }
            break
        default:
    }

    return {
        ...state,
        themeVariables,
        apiThemeVariables: apithemes,
        theme: currTheme,
    }
}

// Hey Profile Related Data

/**
 *
 * @param {*} state
 * @param {*} profile_id
 */
const handleHeyProfileId = (state, profile_id) => {
    return {
        ...state,
        profileData: {
            ...state.profileData,
            id: profile_id,
        },
    }
}

/**
 *
 * @param {*} state
 * @param {*} hey_id
 */
const handleHeyId = (state, hey_id) => {
    return {
        ...state,
        profileData: {
            ...state.profileData,
            heyId: hey_id,
        },
    }
}

/**
 *
 * @param {*} state
 * @param {*} hey_name
 */
const handleHeyName = (state, hey_name) => {
    return {
        ...state,
        profileData: {
            ...state.profileData,
            name: hey_name,
        },
    }
}

/**
 *
 * @param {*} state
 * @param {*} hey_desc
 */
const handleHeyDescription = (state, hey_desc) => {
    return {
        ...state,
        profileData: {
            ...state.profileData,
            desc: hey_desc,
        },
    }
}

/**
 *
 * @param {*} state
 * @param {*} hey_image
 */
const handleHeyImage = (state, hey_image) => {
    return {
        ...state,
        profileData: {
            ...state.profileData,
            image: hey_image,
        },
    }
}

const clearHeyData = (state, payload) => {
    return payload.full
        ? {
              ...initialState,
          }
        : {
              ...state,
              profileData: {
                  id: "",
                  heyId: state.profileData.heyId,
                  name: "",
                  desc: "",
                  image: null,
              },
          }
}

const setRelatedId = (state, id) => {
    return {
        ...state,
        related_id: Number(id),
    }
}
