import React from "react"
import randomColor from "../../Helper/getRandomColor"

/**
 * @description : A component to show Avatar Image with an optional Icon at the bottom
 * @param {String} width Width of Icon in any unit, if bottom icon is to be used, it must be in px
 * @param {String} height height of Icon in any unit, if bottom icon is to be used, it must be in px
 * @param {String} border border for Image in CSS   of "1px solid gray"
 * @param {String} src Source of Image
 * @param {Boolean} round Shape of Image is Round : 50%
 * @param {String} margin margin in CSS syntax of "t r b l" / "y x" / "margin"
 * @param {Boolean} image if there is some image to be shown in Icon Image
 * @param {String/ReactComponent} icon : icon image to show in bottom
 * @param {Boolean} svg  if icon image is an SVG
 * @param {String} borderRadius border Radius for Image : default 50%
 * @param {String} bgClr Background Clr
 */

const IconImage = ({
    width,
    height,
    border,
    src,
    borderRadius,
    round,
    margin,
    image,
    svg,
    icon,
    bgClr,
}) => {
    const clr = bgClr ?? randomColor()
    function GetIcon() {
        const styles = {
            width: width
                ? `${parseInt(width.substr(0, width.length - 2)) / 2.5}px`
                : "auto",
            height: height
                ? `${parseInt(height.substr(0, height.length - 2)) / 2.5}px`
                : "auto",
            position: "absolute",
            borderRadius: round ? "50%" : borderRadius,
            background: src
                ? `url(${src}) no-repeat center`
                : `${clr} no-repeat center`,
            backgroundSize: "contain",
            right: "0",
            bottom: "0",
        }

        return svg ? (
            <div style={styles}>{icon}</div>
        ) : (
            <img src={icon} alt="icon" style={styles} />
        )
    }

    return (
        <div
            style={{
                width: width ?? "auto",
                height: height ?? "auto",
                border: border,
                borderRadius: round ? "50%" : borderRadius,
                background: src
                    ? `url(${src}) no-repeat center`
                    : `${clr} no-repeat center`,
                margin: margin,
                position: "relative",
                backgroundSize: "contain",
            }}
        >
            {image && (
                <img
                    src={src}
                    style={{
                        height: "100%",
                        width: "auto",
                        borderRadius: round ? "50%" : borderRadius,
                    }}
                    alt="Placeholder"
                />
            )}
            {icon && <GetIcon />}
        </div>
    )
}

export default IconImage
