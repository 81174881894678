import React from "react"
import ConfigContext from "Awesome-ui/ConfigContext/ConfigContext"

const ConfigHandler = ({
    social,
    features,
    show,
    enabledFeatures,
    condition,
    children,
}) => {
    if (show) return children ?? null
    if (!enabledFeatures) return null

    let disabled = condition === "all" ? true : false

    features.forEach((feature) => {
        if (condition === "all") {
            if (enabledFeatures[feature] && enabledFeatures[feature][social])
                disabled = false
        } else if (social === "any") {
            if (enabledFeatures[feature]) {
                let isOneEnabled = false
                Object.values(enabledFeatures[feature]).map((enable) => {
                    if (!enable) return null
                    disabled = false
                    isOneEnabled = true
                })
                if (!isOneEnabled) disabled = true
            }
        } else {
            if (!enabledFeatures[feature] || !enabledFeatures[feature][social])
                disabled = true
        }
    })
    if (disabled) return null
    return children ?? null
}

/**
 * Roles and Permission Guard component. you can wrap this component and
 * provide scope and other properties. please use GruradContext.Provider
 * to make this component work.
 *
 * @param {*} scope name of the permission.
 * @param {*} show pass it if you want to hide instead of removing component.
 * @param {*} opacity control the opacity of the hidden component.
 *
 * @returns Guard component.
 */
const Config = ({ social, features, show, condition, ...props }) => {
    return (
        <ConfigContext.Consumer>
            {(context) => (
                <ConfigHandler
                    social={social}
                    features={features}
                    show={show}
                    {...context}
                    {...props}
                />
            )}
        </ConfigContext.Consumer>
    )
}

export default Config
