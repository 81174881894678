export const UPDATE_USER_VISIT = "UPDATE_USER_VISIT"
export const USER_LOGIN = "USER_LOGIN"
export const USER_LOGOUT = "USER_LOGOUT"
export const USER_LOADING = "USER_LOADING"
export const USER_STOP_LOADING = "USER_STOP_LOADING"
export const USER_GET_DETAILS = "USER_GET_DETAILS"
export const TOGGLE_NOTIFICATION = "TOGGLE_NOTIFICATION"
export const UPDATE_COMPANY = "UPDATE_COMPANY"
export const ADD_ONBOARDING_WORKSPACE = "ADD_ONBOARDING_WORKSPACE"
export const ADD_ONBOARDING_BRAND = "ADD_ONBOARDING_BRAND"
export const ADD_ONBOARDING_TEAM = "ADD_ONBOARDING_TEAM"
export const GET_ORGANIZATION_LOADING = "GET_ORGANIZATION_LOADING"
export const GET_ORGANIZATION_DETAILS = "GET_ORGANIZATION_DETAILS"
export const GET_ORGANIZATION_FAILED = "GET_ORGANIZATION_FAILED"
export const GET_ALL_USERS_IN_ORGANIZATION = "GET_ALL_USERS_IN_ORGANIZATION"
export const GET_ALL_USERS_IN_ORG_LOADING = "GET_ALL_USERS_IN_LOADING"
export const GET_ALL_USERS_IN_ORG_FAILED = "GET_ALL_USERS_IN_FAILED"
export const GLOBAL_LOADING_OPEN = "GLOBAL_LOADING"
export const GLOBAL_LOADING_CLOSE = "GLOBAL_LOADING_CLOSE"
export const UPDATE_WORKSPACE_USER = "UPDATE_WORKSPACE_USER"
export const UPDATE_USER = "UPDATE_USER"
export const DELETE_USER ="DELETE_USER"

// // user onboarding actions
export const ENABLE_ON_BOARDING = "ENABLE_ON_BOARDING"
export const DISABLE_ON_BOARDING = "DISABLE_ON_BOARDING"

export const SET_NOTIFICATION_SETTINGS = "SET_NOTIFICATION_SETTINGS"
export const SAVE_NOTIFICATION_SETTINGS = "SAVE_NOTIFICATION_SETTINGS"
export const UPDATE_NOTIFICATION_SETTINGS = "UPDATE_NOTIFICATION_SETTINGS"

export const SUBSCRIPTION_STATUS = "SUBSCRIPTION_STATUS"
export const SUBSCRIPTION_END_DATE = "SUBSCRIPTION_END_DATE"
export const DETAILS_LOADING = "DETAILS_LOADING"
export const NO_SUBSCRIPTION = "NO_SUBSCRIPTION"
export const USER_ROLE = "USER_ROLE"