import {
    OPEN_SOCIAL_POST_MANAGER,
    CLOSE_SOCIAL_POST_MANAGER,
    CHANGE_POST_MANAGER_OPTION,
    UPDATE_SOCIAL_POST,
    CHANGE_POST,
    SELECT_CHANNEL_TO_VIEW,
    SELECT_KEY_TO_VIEW,
    ADD_FILES,
    DELETE_FILES,
    TOGGLE_MINIFY_LINK,
    SELECT_CHANNEL_TO_POST_ON,
    UPDATE_POST_SCHEDULED_DATE,
    UPDATE_POST_SCHEDULED_TIME,
    UPDATE_CREATED_POST,
    CLEAR_POSTS,
    UPDATE_ALL_POSTS,
    ADD_POST,
    RESET_PAGE,
    PREVIOUS_PAGE,
    UPDATE_APPROVALS_PAGE,
    POST_LOADING,
    POST_LOADING_END,
    UPDATE_ALL_SOCIAL_POSTS,
    CLEAR_ALL_SOCIAL_POSTS,
    SHUFFLE_FILES,
    REORDER_POSTS,
    UPDATE_TIMEZONE,
    RESET_HAS_MORE,
    CHANGE_POST_MULTISOCIAL,
    DUPLICATE_FILES,
    SET_LABEL_FILTER_STATUS,
    SET_USER_FILTER_STATUS,
    SET_SORT_FILTER_STATUS,
    SET_SEARCH_FILTER_STATUS,
    SET_DATE_FILTER_STATUS,
    SET_FILTER,
    MEDIA_CHANGE,
    OPEN_SCHEDULE_DATE_UPDATE_MODAL,
    CLOSE_SCHEDULE_DATE_UPDATE_MODAL,
    SET_ROW_LIMIT,
    POST_ERROR,
    POST_APPEND_TEXT,
    SET_NOTIFICATION_POST,
    SET_ORDER,
    TOKEN_SAVING,
    REFETCHQUEUE
} from "../ActionTypes/postActionType"
import { ReactComponent as EditIcon } from "../components/icons/compose.svg"
import { ReactComponent as DiscussIcon } from "../components/icons/discuss.svg"
import { ReactComponent as ActivityIcon } from "../components/icons/activity.svg"
import { ReactComponent as EngagementIcon } from "../components/icons/Engagement.svg"
import moment from "moment"
import { act } from "@testing-library/react"

function decodePostText(text) {
    return (text || "")
}


const initialState = {
    token: null,
    loading: false,
    approvalsLoading: false,
    allOptions: {
        create: [
            { title: "Create", active: 1, icon: EditIcon },
            { title: "Discuss", active: 0, icon: DiscussIcon },
            { title: "Engagement", active: 0, icon: EngagementIcon },
            // { title: "Activity", active: 0, icon: ActivityIcon },
        ],
        edit: [
            { title: "Edit", active: 1, icon: EditIcon },
            { title: "Discuss", active: 1, icon: DiscussIcon },
            { title: "Engagement", active: 0, icon: EngagementIcon },
            // { title: "Activity", active: 1, icon: ActivityIcon },
        ],
        view: [
            { title: "Engagement", active: 1, icon: EngagementIcon },
            { title: "Edit", active: 0, icon: EditIcon },
            { title: "Discuss", active: 1, icon: DiscussIcon },
            // { title: "Activity", active: 1, icon: ActivityIcon },
        ],
    },
    bottomBarOptions: {
        Workflow_NonTopUser: [{ title: "Send For Approval" }, { title: "Draft" }],
        Workflow_TopUser: [
            { title: "Publish", SecondaryTitle: "Schedule Post" },
            { title: "Send for Approval" },
            { title: "Draft" },
        ],
        NoWorkflow: [
            { title: "Publish", SecondaryTitle: "Schedule Post" },
            { title: "Draft" },
        ],
    },
    /**
     * State: {"create","edit","view"}
     * post: Post currently active
     * option: Index according to state {
        create: [
            { title: "Create", active: 1, icon: EditIcon },
            // { title: "Collaborate", active: 0, icon: PeopleIcon },
            // { title: "Activity", active: 0, icon: ActivityIcon },
        ],
        edit: [
            { title: "Edit", active: 1, icon: EditIcon },
            { title: "Collaborate", active: 1, icon: PeopleIcon },
            { title: "Activity", active: 1, icon: ActivityIcon },
        ],
        view: [
            // { title: "View", active: 1, icon: PostIcon },
            { title: "Engagement", active: 1 },
            { title: "Activity", active: 1, icon: ActivityIcon },
        ]
    }
     * brandId: Id of brand with which post is associated
     * companyId
     */
    state: null,
    post: null,
    option: 0,
    brandId: null,
    companyId: null,
    // files: [],
    files: {
        twitter: [],
        instagram: [],
        facebook: [],
        linkedin: [],
        pinterest: [],
    },
    minifyLink: true,
    errors: null, // for error reporting.
    /*
        creator: {createdBy, handleName}
     */
    creator: null,
    selectedChannelToView: null,
    selectedKeyToView: null,
    channelsToPostOn: [],
    scheduleDate: null,
    scheduleTime: null,
    channelPostIds: null,
    channelPosts: {}, //indicator of post loading

    allChannelPosts: null, //indicator of post loading
    allChannelPostsObjects: {},

    page: 1,
    approvalsPage: 1,
    hasMore: true,
    hasMoreApprovals: true,

    userFilterStatus: 0,
    sortFilterStatus: 0,
    labelFilterStatus: [],
    searchFilterStatus: "",
    dateFilterStatus: null,
    filter: false,

    fromBulkPublish: false,
    version: "",
    timeZone: "Asia/Kolkata",
    mediaChange: false,
    updatingDate: false,
    rowLimit: 15,
    notificationPosts: {},
    sortBy: "publishDate",
    orderBy: "DESC",
    refetchQueue: false

}

const postReducer = (state = initialState, action) => {
    try {
        switch (action.type) {
            case POST_LOADING:
                return { ...state, loading: true, approvalsLoading: true }
            case POST_LOADING_END:
                return { ...state, loading: false, approvalsLoading: false }
            case OPEN_SOCIAL_POST_MANAGER:
                const activeSocial = action.payload.socialChannel.toLowerCase()
                return {
                    ...state,
                    state: action.payload.state,
                    option: action.payload.option,
                    post: action.payload.post,
                    brandId: action.payload.brandId,
                    companyId: action.payload.companyId,
                    socialMedia: action.payload.socialChannel?.toLowerCase(),
                    files: {
                        ...state.files,
                        [activeSocial]:
                            action.payload.post?.mediaContent?.map((file) => ({
                                ...file,
                                preview: file.mediaUrl,
                            })) ?? [],
                    },
                    minifyLink: true,
                    creator: action.payload.creator,
                    channelsToPostOn: [...action.payload.allChannels],
                    selectedChannelToView:
                        action.payload.socialChannel.toLowerCase() ?? "twitter",
                    selectedKeyToView: action?.payload?.key?.toLowerCase() ?? "user",
                    scheduleDate: action.payload.scheduleDate,
                    scheduleTime: action.payload.scheduleTime,
                    fromBulkPublish: action.payload.fromBulkPublish,
                    version: action.payload.version ?? "1.0",
                    timeZone: action.payload.timeZone || moment.tz?.guess(),
                    userFilterStatus: 0,
                    sortFilterStatus: 0,
                    labelFilterStatus: [],
                    searchFilterStatus: "",
                    mediaChange: false,
                    errors: null,
                }
            case CLOSE_SOCIAL_POST_MANAGER:
                return {
                    ...state,
                    post: null,
                    state: null,
                    option: 0,
                    brandId: null,
                    companyId: null,
                    socialChannel: null,
                    channelsToPostOn: [],
                    scheduleDate: null,
                    scheduleTime: null,
                    fromBulkPublish: false,
                    version: "",
                    files: {
                        twitter: [],
                        instagram: [],
                        facebook: [],
                        linkedin: [],
                        pinterest: [],
                    },
                    userFilterStatus: 0,
                    sortFilterStatus: 0,
                    labelFilterStatus: [],
                    searchFilterStatus: "",
                    mediaChange: false,
                    errors: null,
                }
            case CHANGE_POST_MANAGER_OPTION:
                return { ...state, option: action.payload.option }
            case UPDATE_SOCIAL_POST:
                return { ...state, post: action.payload.post }
            case CHANGE_POST:
                let updatedPost = state.post ? { ...state.post } : {}
                updatedPost[action.payload.key] = action.payload.value
                return { ...state, post: updatedPost }
            case CHANGE_POST_MULTISOCIAL:
                let updatedPostSocial = state.post ? { ...state.post } : {}
                let arr = action.payload.key.split(".")
                updatedPostSocial[arr[0]][arr[1]] = action.payload.value
                return { ...state, post: updatedPostSocial }
            case TOGGLE_MINIFY_LINK:
                return { ...state, minifyLink: !state.minifyLink }
            case SELECT_CHANNEL_TO_VIEW:
                return { ...state, selectedChannelToView: action.payload.channel }
            case SELECT_KEY_TO_VIEW:
                return { ...state, selectedKeyToView: action.payload.key }
            case ADD_FILES:
                const currentSocial = action.payload.previewChannel
                return {
                    ...state,
                    mediaChange: true,
                    files: {
                        ...state.files,
                        [currentSocial]: [
                            ...state.files[currentSocial],
                            action.payload.file,
                        ],
                    },
                }
            case DELETE_FILES:
                const updatedFiles = [
                    ...state.files[action.payload.previewChannel],
                ].filter(
                    (file, i) =>
                        file.name !== action.payload.name ||
                        i !== action.payload.position
                )
                const newFiles = {
                    ...state.files,
                    [action.payload.previewChannel]: updatedFiles,
                }
                return {
                    ...state,
                    files: newFiles,
                    mediaChange: true,
                }
            case SHUFFLE_FILES:
                const activeShuffleSocial = action.payload.previewChannel
                return {
                    ...state,
                    // files: action.payload.files,
                    files: {
                        ...state.files,
                        [activeShuffleSocial]: action.payload.files,
                    },
                    mediaChange: true,
                }
            case DUPLICATE_FILES:
                const toDuplicate = state.files[action.payload.previewChannel]
                return {
                    ...state,
                    files: {
                        twitter: toDuplicate,
                        instagram: toDuplicate,
                        facebook: toDuplicate,
                        linkedin: toDuplicate,
                        pinterest: toDuplicate,
                    },
                }
            case SELECT_CHANNEL_TO_POST_ON:
                let updatedChannelsToPostOn = [...state.channelsToPostOn]

                if (Array.isArray(action.payload.channel))
                    return {
                        ...state,
                        channelToPostOn: [
                            ...state.channelsToPostOn,
                            ...action.payload.channel,
                        ],
                    }
                if (updatedChannelsToPostOn.includes(action.payload.channel)) {
                    updatedChannelsToPostOn = [...updatedChannelsToPostOn].filter(
                        (channel) => channel !== action.payload.channel
                    )
                } else
                    updatedChannelsToPostOn = [
                        ...updatedChannelsToPostOn,
                        action.payload.channel,
                    ]
                return {
                    ...state,
                    channelsToPostOn: [...updatedChannelsToPostOn],
                    selectedChannelToView:
                        action.payload.channel === state.selectedChannelToView
                            ? null
                            : state.selectedChannelToView,
                }
            case UPDATE_POST_SCHEDULED_DATE:
                return { ...state, scheduleDate: action.payload.date }
            case UPDATE_POST_SCHEDULED_TIME:
                return { ...state, scheduleTime: action.payload.time }
            case CLEAR_POSTS:
                return {
                    ...state,
                    channelPosts: {},
                    channelPostIds: null,
                    hasMore: true,
                }
            case UPDATE_ALL_POSTS:
                let updatedChannelPosts = { ...state.channelPosts }
                let updatedChannelPostIds = []
                action.payload.posts.forEach((post) => {
                    updatedChannelPostIds.push(post.id)
                    if (updatedChannelPosts?.[post.id]) return
                    updatedChannelPosts[post.id] = {
                        ...post,
                        text: decodePostText(post?.text),
                    }
                })
                return {
                    ...state,
                    channelPostIds:
                        state.page === 1
                            ? updatedChannelPostIds
                            : [
                                ...(state.channelPostIds ?? []),
                                ...updatedChannelPostIds,
                            ],
                    channelPosts: updatedChannelPosts,
                    loading: false,
                    hasMore:
                        action.payload.posts.length >= state.rowLimit ? true : false,
                }
            case UPDATE_ALL_SOCIAL_POSTS:
                let allUpdatedChannelPosts = { ...state.allChannelPostsObjects }
                action.payload.allPosts.forEach((post) => {
                    let key = `${post.id}###${post.platform}`
                    if (allUpdatedChannelPosts[key]) return
                    allUpdatedChannelPosts[key] = {
                        ...post,
                        text: decodePostText(post?.text),
                    }
                })
                return {
                    ...state,
                    allChannelPostsObjects: allUpdatedChannelPosts,
                    allChannelPosts: allUpdatedChannelPosts
                        ? Object.values(allUpdatedChannelPosts)
                        : null,
                    loading: false,
                    hasMoreApprovals:
                        action.payload.allPosts.length > 0 ? true : false,
                }
            case CLEAR_ALL_SOCIAL_POSTS:
                return {
                    ...state,
                    allChannelPosts: null,
                    approvalsPage: 1,
                    hasMoreApprovals: true,
                }
            case RESET_HAS_MORE:
                return {
                    ...state,
                    hasMoreApprovals: false,
                }
            case ADD_POST:
                return {
                    ...state,
                    channelPostIds: [
                        action.payload.post?.id,
                        ...state.channelPostIds,
                    ],
                    channelPosts: {
                        [action.payload.post?.id]: {
                            ...action.payload.post,
                            text: decodePostText(action.payload.post?.text),
                        },
                        ...state.channelPosts,
                    },
                }
            case RESET_PAGE:
                return { ...state, page: 1, hasMore: true }
            case PREVIOUS_PAGE:
                return { ...state, page: state.page + 1 }
            case UPDATE_APPROVALS_PAGE:
                return { ...state, approvalsPage: state.approvalsPage + 1 }
            case UPDATE_CREATED_POST:
                let updatedPosts = {
                    ...state.channelPosts,
                    [action.payload.post.id]: action.payload.post,
                }
                return { ...state, channelPosts: updatedPosts }
            case REORDER_POSTS:
                let channelPostUpdated = { ...state.channelPosts }
                let reorderedPostIds = action.payload.posts.map((post) => {
                    channelPostUpdated[post.id] = post
                    return post.id
                })
                if (reorderedPostIds.join(",") === state.channelPostIds.join(","))
                    return state
                return {
                    ...state,
                    channelPosts: channelPostUpdated,
                    channelPostIds: reorderedPostIds,
                }
            case UPDATE_TIMEZONE:
                return { ...state, timeZone: action.payload.timeZone }
            case SET_LABEL_FILTER_STATUS:
                return { ...state, labelFilterStatus: action.payload.name }
            case OPEN_SCHEDULE_DATE_UPDATE_MODAL:
                return { ...state, updatingDate: true }
            case CLOSE_SCHEDULE_DATE_UPDATE_MODAL:
                return { ...state, updatingDate: false }
            case SET_USER_FILTER_STATUS:
                return { ...state, userFilterStatus: action.payload.name }
            case SET_SORT_FILTER_STATUS:
                return { ...state, sortFilterStatus: action.payload.name }
            case SET_SEARCH_FILTER_STATUS:
                return { ...state, searchFilterStatus: action.payload.name }
            case SET_DATE_FILTER_STATUS:
                return { ...state, dateFilterStatus: action.payload.date }
            case SET_FILTER:
                return { ...state, filter: action.payload.stat }
            case MEDIA_CHANGE:
                return { ...state, mediaChange: true }
            case SET_ROW_LIMIT:
                return { ...state, rowLimit: action.payload.limit }
            case POST_ERROR:
                return postCreationErrors(state, action.payload)
            case POST_APPEND_TEXT:
                return appendText(state, action.payload)
            case SET_NOTIFICATION_POST:
                return setNotificationPost(state, action.payload)
            case SET_ORDER:
                return setOrder(state, action.payload)
            case TOKEN_SAVING:
                return { ...state, token: action.payload }
            case REFETCHQUEUE:
                return { ...state, refetchQueue: action.payload }
            default:
                return state
        }
    } catch (err) { }
}

export default postReducer

function postCreationErrors(state, payload) {
    return {
        ...state,
        errors: payload,
    }
}

/**
 * append the text to existig social text.
 * if social is provided then it will update text for only one social.
 * if social is not provided it will update for each social available.
 */
function appendText(state, payload) {
    let { social, text } = payload

    let post = state.post ? { ...state.post } : {}

    if (!post.text) {
        post.text = {
            facebook_text: text,
            instagram_text: text,
            linkedin_text: text,
            twitter_text: text,
        }

        return {
            ...state,
            post: post,
        }
    }

    if (social) {
        let key = social.toLowerCase() + "_text"
        post.text[key] = post.text[key] ? post.text[key] + text : text
    } else {
        Object.keys(post.text).forEach((item) => {
            post.text[item] = post.text[item] ? post.text[item] + text : text
        })
    }

    return {
        ...state,
        post: post,
    }
}

const setNotificationPost = (state, payload) => {
    return {
        ...state,
        notificationPosts: {
            ...state.notificationPosts,
            [payload.id]: payload,
        },
    }
}

const setOrder = (state, payload) => {
    return {
        ...state,
        sortBy: payload.sortBy,
        orderBy: payload.orderBy,
    }
}
