import track from "react-tracking"
import { getHeaders } from "../Actions/userAction"
import App from "../App"
import { analyticApi } from "../components/apis"
import store from "../store"

const { getState } = store

const DataTracker = track(
    { app: "social" },
    {
        dispatch: trackingHandler,
    }
)(App)

export default DataTracker

const failedEvents = []

/**
 * All evetns for the data tracking comes here to get publish
 * @param {*} event user intraction data {type: , page, app, title: };
 */
function trackingHandler(event) {
    const { userReducer } = getState()
    const { user } = userReducer

    if (!user) {
        failedEvents.push(event)
        setTimeout(() => {
            trackingHandler(event)
        }, 2000)
        return
    }

    event.count = 1

    const data = {
        productId: 1,
        severity: "ANALYTICAL",
        object: event.type,
        eventType: event.category || event.type || event.key,
        message: JSON.stringify(event),
        isDeleted: "",
        currentUser: user.id,
    }


    analyticApi.post("", data, getHeaders())
}
