import React from "react"
import { GetFile } from "../GetFileIcon/index.js"
import Loader from "../../commonComps/Loaders"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

import "./imageBar.scss"

const getItemStyle = (isDragging, draggableStyle, itemStyle = {}) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // background: isDragging ? "lightgreen" : "grey",
    ...draggableStyle,
    ...itemStyle,
})

const getListStyle = (isDraggingOver, listStyle = {}) => ({
    // background: isDraggingOver ? "lightblue" : "lightgrey",
    ...listStyle,
})

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

/**
 * @description Uploaded File Preview Component
 * Only include one of filesUploading or fileCache not both
 * @param {Files[]} files
 * @param {Number} [filesUploading] Number of Files that are currently uploading
 * @param {Function} deleteFile : (index, filename)=>{}
 * @param {Files[]} fileCache Files Array that are currently uploading
 * @
 */
const FilPreviewBar = ({ canShuffle = false, ...props }) => {
    return props.files &&
        (props.files.length || props.filesUploading || props.fileCache?.length) ? (
        canShuffle ? (
            <DraggableFilePreviewBar {...props} />
        ) : (
            <NonDraggableFilePreviewBar {...props} />
        )
    ) : (
        <></>
    )
}

function NonDraggableFilePreviewBar({
    files,
    filesUploading,
    deleteFile,
    previewChannel,
    fileCache,
    activeDuplication,
    setDisable,

}) {
    const _C = "file-bar"
    function remove() {

    }
    return files && (files.length || filesUploading || fileCache?.length) ? (
        <div className={_C + " scroll-modern"}>
            <div className={_C + "-container"}>
                <div className={_C + "-uploaded"}>
                    {files.map((file, index) => {
                        return (
                        <div className="file-images" key={index}>
                            <GetFile file={file} />
                            <span
                                className="file-image-cancel"
                                onClick={() => {
                                            setDisable(false)
                                            deleteFile(index, file.name, previewChannel,activeDuplication)}}
                            >
                                +
                            </span>
                        </div>
                    )})}
                </div>
                {fileCache?.length || filesUploading ? (
                    <div className={_C + "-uploading"}>
                        {fileCache ? (
                            fileCache.map((file, index) => {
                                return (
                                    <div className="file-images" key={index}>
                                        <img
                                            key={index}
                                            src={file.preview}
                                            alt={file.name}
                                        />
                                    </div>
                                )
                            })
                        ) : (
                            <></>
                        )}
                        {filesUploading ? (
                            [...Array(filesUploading)].map((_, index) => (
                                <div key={index} className="file-box">
                                    <Loader
                                        h="2rem"
                                        w="2rem"
                                        b="4px"
                                        bk="#eaf0f6"
                                        clr="#D6DFE8"
                                        t="1s"
                                    />
                                </div>
                            ))
                        ) : (
                            <></>
                        )}
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    ) : (
        <></>
    )
}

function DraggableFilePreviewBar({
    files,
    filesUploading,
    deleteFile,
    fileCache,
    onShuffle,
    previewChannel,
    activeDuplication,
    setDisable,
    disable
}) {
    const _C = "file-bar"
    function onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        const shuffledFiles = reorder(
            files,
            result.source.index,
            result.destination.index
        )

        onShuffle(shuffledFiles , previewChannel, activeDuplication)
    }

    return files && (files.length || filesUploading || fileCache?.length) ? (
        <div className={_C + " scroll-modern"}>
            <div className={_C + "-container"}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided, snapshot) => (
                            <div
                                className={_C + "-uploaded"}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver, {})}
                                {...provided.droppableProps}
                            >
                                {files.map((file, index) => {
                                 return (
                                    <Draggable
                                        key={file.preview}
                                        draggableId={file.preview}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                className="file-images"
                                                key={index}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style,
                                                    {}
                                                )}
                                            >
                                                <GetFile file={file}/>
                                                <span
                                                    className="file-image-cancel"
                                                    onClick={() =>{
                                                        setDisable(false)
                                                        deleteFile(index, file.name, previewChannel,activeDuplication)
                                                    }}
                                                >
                                                    +
                                                </span>
                                            </div>
                                        )}
                                    </Draggable>
                                )})}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                {fileCache?.length || filesUploading ? (
                    <div className={_C + "-uploading"}>
                        {fileCache ? (
                            fileCache.map((file, index) => {
                                return (
                                    <div className="file-images" key={index}>
                                        <img
                                            key={index}
                                            src={file.preview}
                                            alt={file.name}
                                        />
                                    </div>
                                )
                            })
                        ) : (
                            <></>
                        )}
                        {filesUploading ? (
                            [...Array(filesUploading)].map((_, index) => (
                                <div key={index} className="file-box">
                                    <Loader
                                        h="2rem"
                                        w="2rem"
                                        b="4px"
                                        bk="#eaf0f6"
                                        clr="#D6DFE8"
                                        t="1s"
                                    />
                                </div>
                            ))
                        ) : (
                            <></>
                        )}
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    ) : (
        <></>
    )
}

export default FilPreviewBar
