import React from "react"
import "./icon.scss"
import { ReactComponent as FaceBookSVG } from "../../icons/socialIcons/facebook.svg"

const FaceBookIcon = ({ active, style }) => {
    return (
        <div
            className={active ? "social-icon-active" : "social-icon-inactive"}
            style={style}
        >
            <FaceBookSVG />
        </div>
    )
}

export default FaceBookIcon
