import {
    LOAD_CUSTOMER_DETAILS,
    LOAD_CUSTOMER_DETAILS_SUCCESS,
    LOAD_CUSTOMER_DETAILS_FAILED,
    LOAD_SUBSCRIPTION_DETAILS,
    LOAD_SUBSCRIPTION_DETAILS_SUCCESS,
    LOAD_SUBSCRIPTION_DETAILS_FAILED,
    LOAD_TRANSACTION_DETAILS,
    LOAD_TRANSACTION_SUCCESS,
    LOAD_TRANSACTION_FAILED,
    LOAD_PLAN_DETAILS_SUCCESS,
    LOAD_PLAN_DETAILS_LOADING,
    LOAD_PLAN_DETAILS_FAILED,
    SET_SUBSCRIPTION_PLANS,
    CONSUME_PLAN,
    UNCONSUME_PLAN
} from "../ActionTypes/transactionActionTypes"

const initialState = {
    /** @deprecated */
    card: null,
    /** @deprecated */
    userDetails: null,
    /**
     * numberOfAccounts: 1
     *  numberOfEvents: 20
     *  numberOfUser: 1
     *  organisationId: 114
     *  planId: 1
        productId: 1
        referenceNextPlanId: 0
        remainingNoOfAccounts: 0
        remainingNoOfEvent: 0
        remainingNoOfUser: 0
     */
    subscription: {
        enable: 1,
        endDate: null,
        id: 0,
        planId: 0,
        productId: 0,
        organisationId: 114,
        referenceNextPlanId: 0,
        numberOfAccounts: 0,
        numberOfEvents: 0,
        numberOfUser: 0,
        remainingNoOfAccounts: 0,
        remainingNoOfEvent: 0,
        remainingNoOfUser: 0,
        startDate: null,
        status: null,
        subscriptionId: null,
        userId: null,
    },
    // if true open a pop up to down grade the plan resources.
    isExceededPlan: false,
    /** @deprecated */
    plans: null,
    /** @deprecated */
    transactionList: null,
    loading: false,
    plansLoading: false,
    /** @deprecated */
    transactionLoading: false,
    subscriptionLoading: false,
    fill: false,
    subscriptionPlans: null,
}

const transactionReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_CUSTOMER_DETAILS:
            return { ...state, loading: true }
        case LOAD_TRANSACTION_DETAILS:
            return {
                ...state,
                transactionLoading: true,
            }
        case LOAD_SUBSCRIPTION_DETAILS:
            return {
                ...state,
                subscriptionLoading: true,
            }
        case LOAD_PLAN_DETAILS_LOADING:
            return {
                ...state,
                plansLoading: true,
            }
        case LOAD_CUSTOMER_DETAILS_FAILED:
            return {
                ...state,
                loading: false,
            }
        case LOAD_TRANSACTION_FAILED:
            return {
                ...state,
                transactionLoading: false,
            }
        case LOAD_SUBSCRIPTION_DETAILS_FAILED:
            return {
                ...state,
                subscriptionLoading: false,
            }
        case LOAD_PLAN_DETAILS_FAILED:
            return {
                ...state,
                plansLoading: false,
            }
        case LOAD_CUSTOMER_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                fill: true,
                userDetails: action.payload.customer,
                card: action.payload.card,
            }
        case LOAD_TRANSACTION_SUCCESS:
            return {
                ...state,
                transactionList: action.payload.transaction,
                transactionLoading: false,
            }
        case LOAD_SUBSCRIPTION_DETAILS_SUCCESS:
            return handlLoadSubscription(state, action.payload)
        case CONSUME_PLAN:
            return {
                ...state,
                subscription: { ...state.subscription, ...action.payload },
            }
        case UNCONSUME_PLAN:
            return unConsumePlan(state, action.payload);
        case LOAD_PLAN_DETAILS_SUCCESS:
            return {
                ...state,
                plansLoading: false,
                plans: action.payload.plans,
            }
        case SET_SUBSCRIPTION_PLANS:
            return {
                ...state,
                subscriptionPlans: action.payload,
            }
        default:
            return state
    }
}

export default transactionReducer

function handlLoadSubscription(state, payload) {
    let isExceededPlan = false

    if (payload.remainingNoOfAccounts < 0) isExceededPlan = true
    if (payload.remainingNoOfUser < 0) isExceededPlan = true

    return {
        ...state,
        subscription: payload,
        isExceededPlan: isExceededPlan,
    }
}

function unConsumePlan(state, payload) {
    let isExceededPlan = false

    if (payload.remainingNoOfAccounts >= 0 && payload.remainingNoOfUser >= 0) 
        isExceededPlan = false
    
    return {
        ...state,
        subscription: payload,
        isExceededPlan: isExceededPlan
    }
}
