import React from "react"
import Cloud from "../Cloud"
import NoNotification from "../../../icons/no-notification.webp"
import "./style.scss"

/**
 * Container For When User Have No Notification
 *
 * Contained No Notification Message
 *
 */

const NoNotificationContainer = ({ msg = "You don't have any notifications" }) => {
    return (
        <div className="no-notification-container">
            <div className="no-notification-img">
                <img src={NoNotification} alt="" />
            </div>
            <p>{msg}</p>
        </div>
    )
}

export default NoNotificationContainer
