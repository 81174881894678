import React from "react"
import { formatDateIntoWord } from "../../../Helper/HandleDateAndTime"
import Avatar from "../../Avatar/Avatar"
import "./creatorTitleBar.scss"

const statusMap = {
    schedule: "Scheduled",
    draft: "Drafts",
    publish: "Published",
    unpublish: "Unpublished",
}

const statusStyles = {
    publish: {
        color: "#00BE9B",
        borderColor: "#00BE9B",
        backgroundColor: "#E2FFFA",
    },
    unpublish: {
        color: "#FFCA28",
        borderColor: "#FFCA28",
        backgroundColor: "rgba(254, 181, 26,0.1)",
    },
    draft: {
        color: "#99ACC2",
        borderColor: "#99ACC2",
        backgroundColor: "rgba(153, 172, 194, 0.2)",
    },
    post: {
        color: "#F2545B",
        borderColor: "#F2545B",
        backgroundColor: "rgba(242,84,91,0.2)",
    },
    schedule: {
        color: "rgb(0, 174, 239)",
        borderColor: "rgb(0, 174, 239)",
        backgroundColor: "rgba(0, 174, 239,0.2)",
    },
}

const CreatorBar = ({ creator, post }) => {
    const _C = "aui-post-manager-creator-bar"
    const { first_name, last_name, user_name, picture } = creator || {}
    const { created_on, status } = post || {}
    return (
        <div className={_C}>
            <div className={_C + (creator ? "-image" : "-image-blank")}>
                {creator && (
                    <Avatar
                        firstName={first_name}
                        lastName={last_name}
                        width="100%"
                        height="100%"
                        round
                        bgClr="green"
                        image={picture}
                        icon={picture}
                        style={{ minHeight: "100%" }}
                    />
                )}
            </div>
            <div className={_C + "-creator"}>
                <div>Publisher</div>
                {user_name && <div>{creator ? user_name : ""}</div>}
            </div>
            {status && <div className={_C + "-status"}>
                <div>Status</div>
                <div style={{ ...statusStyles[status] }}>
                    <span
                        style={{
                            backgroundColor:
                                statusStyles[status]?.color,
                        }}
                    ></span>
                    {statusMap[status]}
                </div>
            </div>}
            <div className={_C + "-time"}>
                <div>Created At</div>
                {<div>{formatDateIntoWord(created_on, true, true, false)}</div>}
            </div>
        </div>
    )
}

export default React.memo(CreatorBar)
