export const OPEN_SOCIAL_POST_MANAGER = "OPEN_SOCIAL_POST_MANAGER"
export const CLOSE_SOCIAL_POST_MANAGER = "CLOSE_SOCIAL_POST_MANAGER"
export const CHANGE_POST_MANAGER_OPTION = "CHANGE_POST_MANAGER_OPTION"
export const UPDATE_SOCIAL_POST = "UPDATE_SOCIAL_POST"
export const CHANGE_POST = "CHANGE_POST"
export const SELECT_CHANNEL_TO_VIEW = "SELECT_CHANNEL_TO_VIEW"
export const SELECT_KEY_TO_VIEW = "SELECT_KEY_TO_VIEW"
export const ADD_FILES = "ADD_FILES"
export const DELETE_FILES = "DELETE_FILES"
export const TOGGLE_MINIFY_LINK = "TOGGLE_MINIFY_LINK"
export const SELECT_CHANNEL_TO_POST_ON = "SELECT_CHANNEL_TO_POST_ON"
export const UPDATE_POST_SCHEDULED_DATE = "UPDATE_POST_SCHEDULED_DATE"
export const UPDATE_POST_SCHEDULED_TIME = "UPDATE_POST_SCHEDULED_TIME"
export const CLEAR_POSTS = "CLEAR_POSTS"
export const UPDATE_ALL_POSTS = "UPDATE_ALL_POSTS"
export const ADD_POST = "ADD_POST"
export const RESET_PAGE = "RESET_PAGE"
export const PREVIOUS_PAGE = "PREVIOUS_PAGE"
export const UPDATE_APPROVALS_PAGE = "UPDATE_APPROVALS_PAGE"
export const POST_LOADING = "POST_LOADING"
export const POST_LOADING_END = "POST_LOADING_END"
export const UPDATE_CREATED_POST = "UPDATE_CREATED_POST"
export const UPDATE_ALL_SOCIAL_POSTS = "UPDATE_ALL_SOCIAL_POSTS"
export const CLEAR_ALL_SOCIAL_POSTS = "CLEAR_ALL_SOCIAL_POSTS"
export const SHUFFLE_FILES = "SHUFFLE_FILES"
export const REORDER_POSTS = "REORDER_POSTS"
export const UPDATE_TIMEZONE = "UPDATE_TIMEZONE"
export const RESET_HAS_MORE = "RESET_HAS_MORE"
export const CHANGE_POST_MULTISOCIAL = "CHANGE_POST_MULTISOCIAL"
export const DUPLICATE_FILES = "DUPLICATE_FILES"
export const SET_LABEL_FILTER_STATUS = "SET_LABEL_FILTER_STATUS"
export const SET_USER_FILTER_STATUS = "SET_USER_FILTER_STATUS"
export const SET_SORT_FILTER_STATUS = "SET_SORT_FILTER_STATUS"
export const SET_SEARCH_FILTER_STATUS = "SET_SEARCH_FILTER_STATUS"
export const SET_DATE_FILTER_STATUS = "SET_DATE_FILTER_STATUS"
export const SET_FILTER = "SET_FILTER"
export const MEDIA_CHANGE = "MEDIA_CHANGE"
export const OPEN_SCHEDULE_DATE_UPDATE_MODAL = "OPEN_SCHEDULE_DATE_UPDATE_MODAL"
export const CLOSE_SCHEDULE_DATE_UPDATE_MODAL = "CLOSE_SCHEDULE_DATE_UPDATE_MODAL"
export const SET_ROW_LIMIT = "SET_ROW_LIMIT"
export const POST_ERROR = "POST_ERROR"
export const POST_APPEND_TEXT = "POST_APPEND_TEXT"
export const SET_NOTIFICATION_POST = "SET_NOTIFICATION_POST"
export const SET_ORDER = "SET_ORDER"
export const TOKEN_SAVING = "TOKEN_SAVING"
export const REFETCHQUEUE ="REFETCHQUEUE"
