import React from "react"
import "./toggleButton.scss"

/**
 *
 * @param {Boolean} active : State of button
 * @param {String} activeClr : Optional | This is to have some custom active color
 * @param {String} width : Width of button
 * @param {String} height : Height of button
 * @param {Function} click : Function for handling click
 * @returns
 */

const ToggleButton = ({ active, activeClr, border, width, height, click }) => {
    const c = "aui-toggle-button"
    return (
        <div
            className={`${c} ${active ? c + "-active" : ""}`}
            style={{
                width: width,
                height: height,
                backgroundColor: active ? activeClr ?? "" : "",
            }}
            onClick={click}
        >
            <div className={c + "-circle"} style={{ width: height }}></div>
        </div>
    )
}

export default ToggleButton
