import {
    UPDATE_USER_VISIT,
    USER_LOADING,
    USER_LOGIN,
    USER_LOGOUT,
    USER_STOP_LOADING,
    USER_GET_DETAILS,
    UPDATE_USER,
    TOGGLE_NOTIFICATION,
    UPDATE_COMPANY,
    ADD_ONBOARDING_WORKSPACE,
    ADD_ONBOARDING_BRAND,
    ADD_ONBOARDING_TEAM,
    GET_ORGANIZATION_DETAILS,
    GET_ORGANIZATION_FAILED,
    GET_ORGANIZATION_LOADING,
    GET_ALL_USERS_IN_ORGANIZATION,
    GET_ALL_USERS_IN_ORG_FAILED,
    GET_ALL_USERS_IN_ORG_LOADING,
    GLOBAL_LOADING_OPEN,
    GLOBAL_LOADING_CLOSE,
    UPDATE_WORKSPACE_USER,
    ENABLE_ON_BOARDING,
    DISABLE_ON_BOARDING,
    SET_NOTIFICATION_SETTINGS,
    SAVE_NOTIFICATION_SETTINGS,
    SUBSCRIPTION_STATUS,
    SUBSCRIPTION_END_DATE,
    DETAILS_LOADING,
    NO_SUBSCRIPTION,
    DELETE_USER,
    USER_ROLE
} from "../ActionTypes/userActionTypes"

const initialState = {
    isSubscriptionActive: null,
    daysRemaining: null,
    firstVisit: true, //this is to track if the user just jumped to the platform
    globalLoading: true,
    user: null,
    isOnBoarding: false,
    token: localStorage.getItem("auth-token"),
    redirectToken: localStorage.getItem("redirect-token"),
    loading: false,
    notificationOpen: false,
    organization: null,
    organizationLoading: false,
    organizationUsersLoading: false,
    organizationUsers: null,
    menuItems: [
        { name: "Publish", link: "/publish", page: "publish", noNav: true },
        { name: "Inbox", page: "inbox" },
        { name: "Analytics", page: "analytics" },
        { name: "Team", link: "/team", page: "team" },
        //{ name :"Media Library" , link:"/media", page:"media"},
        {name: "Hey.bio", icon: "👋", page: "hey-bio" },
        {name:"Settings", page:"settings" , link:"/settings" ,noNav: true }
    ],
    onboardingData: {
        workspace: null,
        brand: null,
        team: [],
        onboardingStatus: false,
    },
    workplaceUsers: {},
    windowClick: null,
    notificationSettings: null,
    detailsLoading: false,
    noSubscription: false,
    userRole:""
}

export const userReducer = (state = initialState, action) => {
    try {
        switch (action.type) {
            case SUBSCRIPTION_STATUS:
                return { ...state, isSubscriptionActive: action.payload.status }
            case DETAILS_LOADING:
                return { ...state, detailsLoading: action.payload }
            case SUBSCRIPTION_END_DATE:
                const numberOfDays = Math.ceil((action.payload.endDate - new Date())/(1000*60*60*24))
                return { ...state, daysRemaining: numberOfDays }
            case UPDATE_USER_VISIT:
                return { ...state, firstVisit: false }
            case USER_LOADING:
                return {
                    ...state,
                    loading: true,
                }

            case USER_STOP_LOADING:
                return {
                    ...state,
                    loading: false,
                }
            case UPDATE_USER:
                return { ...state, user: action.payload.data }
            case DELETE_USER:
                const { users , workspaceId} = action.payload
                var updatedGrp = state.workplaceUsers
                delete updatedGrp[workspaceId][users.id]
                delete updatedGrp[workspaceId][users.user_name]
                delete updatedGrp[workspaceId][users.email_id]
                return {
                    ...state,
                    workplaceUsers: updatedGrp,
                }
            case ENABLE_ON_BOARDING:
                return {
                    ...state,
                    isOnBoarding: true,
                }

            case DISABLE_ON_BOARDING:
                return {
                    ...state,
                    isOnBoarding: false,
                }

            case USER_LOGIN:
                return {
                    ...state,
                    token: action.payload.auth_token,
                    redirectToken: action.payload.redirectToken,
                    userRole:action.payload.role,
                    loading: false,
                }

            case USER_LOGOUT:
                return {
                    ...state,
                    user: null,
                    token: null,
                    loading: false,
                }

            case USER_GET_DETAILS:
                return {
                    ...state,
                    user: action.payload.user ?? state.user,
                    loading: false,
                }
            case USER_ROLE:
                return {
                    ...state,
                    userRole:action.payload
                }
            case UPDATE_COMPANY:
                let user = {}
                if (user) {
                    user = {
                        ...state.user,
                        // user_name: action.payload.name,
                        logo: action.payload.logo,
                    }
                }
                return {
                    ...state,
                    user,
                }
            case TOGGLE_NOTIFICATION:
                return {
                    ...state,
                    notificationOpen: action.payload.isOpen,
                }
            case ADD_ONBOARDING_WORKSPACE:
                return {
                    ...state,
                    onboardingData: {
                        ...state.onboardingData,
                        workspace: action.payload,
                    },
                }
            case ADD_ONBOARDING_BRAND:
                return {
                    ...state,
                    onboardingData: {
                        ...state.onboardingData,
                        brand: action.payload,
                    },
                }
            case ADD_ONBOARDING_TEAM:
                return {
                    ...state,
                    onboardingData: {
                        ...state.onboardingData,
                        team: [...state.onboardingData.team, action.payload],
                    },
                }
            case GET_ORGANIZATION_DETAILS:
                return {
                    ...state,
                    organization: action.payload.data,
                    organizationLoading: false,
                }
            case GET_ORGANIZATION_LOADING:
                return {
                    ...state,
                    organizationLoading: true,
                }
            case GET_ORGANIZATION_FAILED:
                return {
                    ...state,
                    organization: null,
                    organizationLoading: false,
                }
            case GET_ALL_USERS_IN_ORG_LOADING:
                return {
                    ...state,
                    organizationUsersLoading: true,
                }
            case GET_ALL_USERS_IN_ORGANIZATION:
                return {
                    ...state,
                    organizationUsersLoading: false,
                    organizationUsers: action.payload.data,
                }
            case GET_ALL_USERS_IN_ORG_FAILED:
                return {
                    ...state,
                    organizationUsersLoading: false,
                }
            case GLOBAL_LOADING_OPEN:
                return {
                    ...state,
                    globalLoading: true,
                }
            case GLOBAL_LOADING_CLOSE:
                return {
                    ...state,
                    globalLoading: false,
                }
            case UPDATE_WORKSPACE_USER:
                const updatedUsers = state.workplaceUsers
                if (action.payload.users) {
                    updatedUsers[action.payload.id] = updatedUsers[action.payload.id] ? {...updatedUsers[action.payload.id]} : {}
                    for (let i = 0; i < action.payload.users.length; i++) {
                        updatedUsers[action.payload.id][
                            action.payload.users[i].user_name
                        ] = action.payload.users[i]
                        updatedUsers[action.payload.id][action.payload.users[i].id] =
                            action.payload.users[i]
                        updatedUsers[action.payload.id][
                            action.payload.users[i].email_id
                        ] = action.payload.users[i]
                    }
                }
                return {
                    ...state,
                    workplaceUsers: updatedUsers,
                }
            case SET_NOTIFICATION_SETTINGS:
                return { ...state, notificationSettings: action.payload }
            case SAVE_NOTIFICATION_SETTINGS:
                let updatedNotification = [...state.notificationSettings]
                for (let i = 0; i < action.payload.length; i++) {
                    for (let j = 0; j < updatedNotification.length; j++) {
                        if (action.payload[i].id === updatedNotification[j].id) {
                            updatedNotification[j] = {
                                ...updatedNotification[j],
                                ...action.payload[i],
                            }
                            break
                        }
                    }
                }
                return { ...state, notificationSettings: [...updatedNotification] }
            case NO_SUBSCRIPTION: 
                return {
                    ...state,
                    noSubscription: action.payload,
                }
            default:
                return state
        }
    } catch (err) {
    }
}
