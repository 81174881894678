import React from "react"
import CircleCheckBox from "../Components/CircleCheckBox/CircleCheckBox"

const GetImage = ({ index, column, item, handlerSelect }) => {
    const media = item.media_formats
    return (
        <div className="card" key={item.id}>
            <img
                src={media.tinygif.url}
                alt={item.content_description}
                loading="lazy"
                style={{ background: "#fafafa", width: "100%" }}
            />
            <div className={"image-select-btn"}>
                <CircleCheckBox
                    select={item.selected}
                    selectChange={() => handlerSelect(column, item.id)}
                />
            </div>
        </div>
    )
}

/**
 * gif column component shows images in 2 column format
 * @returns Component
 */
const GifList = ({ gif, handlerSelect, scrollRef }) => {
    return (
        <>
            <div className="img-col">
                {gif[0] &&
                    gif[0].map((item, index) => (
                        <GetImage
                            key={index}
                            index={index}
                            item={item}
                            column={0}
                            handlerSelect={handlerSelect}
                        />
                    ))}
                <div
                    key={gif[0]?.length}
                    ref={scrollRef}
                    style={{
                        height: "100px",
                        width: "100%",
                        display: "inline-block",
                    }}
                ></div>
            </div>
            <div className="img-col">
                {gif[1] &&
                    gif[1].map((item, index) => (
                        <GetImage
                            key={index}
                            index={index}
                            item={item}
                            column={1}
                            handlerSelect={handlerSelect}
                        />
                    ))}
            </div>
        </>
    )
}

export default GifList
