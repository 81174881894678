import React, { useRef, useState, useEffect } from "react"
import "./UnsplashPicker.scss"

import { createApi } from "unsplash-js"
import Input from "../Components/Input/Input"
import CircleCheckBox from "../Components/CircleCheckBox/CircleCheckBox"
import Avatar from "@awesomesuite-frontend/awesome-nebula/dist/Avatar"
import Text from "@awesomesuite-frontend/awesome-nebula/dist/Text"
import Grid from "@awesomesuite-frontend/awesome-nebula/dist/Grid"

const GetImage = ({ index, column, item, handlerSelect }) => {
    return (
        <div className="card" key={item.id}>
            <a
                target="_blank"
                href={`${item.user.links.html}?utm_source=AwesomeSuite&utm_medium=referral`}
            >
                <Grid container xs={12} align="center" className="unsplas-creator">
                    <Grid item xs={2}>
                        <Avatar
                            imageSource={item?.user.profile_image.medium}
                            size="xs"
                        />
                    </Grid>
                    <Grid item xs={9} marginLeft={6}>
                        <Text varient="subText" color="#ffff" fontSize={12}>
                            {item.user.name}
                        </Text>
                    </Grid>
                </Grid>
            </a>
            <img
                className="card_picture"
                alt={item.alt_description}
                value=""
                src={item.urls && item.urls.thumb}
                width="190px"
                loading="lazy"
                height="50%"
            ></img>
            <div className={"image-select-btn"}>
                <CircleCheckBox
                    select={item.selected}
                    selectChange={() => handlerSelect(column, item.id)}
                />
            </div>
        </div>
    )
}

/**
 * unsplash picker to get the images from the unsplash
 * @param {*} setCheckoutFile function when user checkout from
 * the unsplash after selecting the files
 * @returns
 */
const UnsplashPicker = ({ setCheckoutFiles }) => {
    const [pictures, setPictures] = useState({})
    const [searchers, setSearchers] = useState("")
    const loaderRef = useRef()
    const [loading, setLoading] = useState(false)

    const unsplashApi = createApi({
        accessKey: process.env.REACT_APP_UNSPLASH_ACCESS_KEY,
    })

    /**
     * infinity scroll handler for the for fetching new images when user scroller
     */
    const handleObserver = (entries) => {
        const target = entries[0]
        if (target.isIntersecting && loading === false) {
            fetchOnScroll()
        }
        // eslint-disable-next-line
    }

    useEffect(() => {
        const option = {
            root: null,
            threshold: 1,
        }
        const observer = new IntersectionObserver(handleObserver, option)
        if (loaderRef.current) {
            observer.observe(loaderRef.current)
        }

        return () => {
            observer.disconnect(loaderRef.current)
        }
        // eslint-disable-next-line
    }, [loaderRef.current])

    /**
     * api handler for unsplash
     */
    async function fetchOnScroll() {
        if (unsplashApi && !loading) {
            setLoading(true)
            const searchObj = {
                count: 44,
            }
            if (searchers) {
                searchObj.query = searchers
            }
            try {
                const photo = await unsplashApi.photos.getRandom(searchObj)
                setPictures((prev) => {
                    const mapContent = photo.response.map((item) => {
                        item.selected = false
                        return item
                    })
                    let column = {}
                    if (!prev[0])
                        column = {
                            0: [...mapContent.slice(0, 3)],
                            1: [...mapContent.slice(4, 7)],
                            2: [...mapContent.slice(8, 11)],
                        }
                    else
                        column = {
                            0: [...prev[0], ...mapContent.slice(0, 3)],
                            1: [...prev[1], ...mapContent.slice(4, 7)],
                            2: [...prev[2], ...mapContent.slice(8, 11)],
                        }
                    return column
                })
            } catch (error) {}
            setLoading(false)
        }
    }

    /**
     * handle the text search on button click
     * @param {*} name - name of search type
     */
    async function onPictureSearch(name) {
        const queryPost = await unsplashApi.photos.getRandom({
            query: name,
            count: 44,
        })
        if (queryPost.response) {
            const posts = queryPost.response.map((item) => {
                item.selected = false
                return item
            })
            const column = {
                0: [...posts.slice(0, 3)],
                1: [...posts.slice(4, 7)],
                2: [...posts.slice(8, 11)],
            }
            setPictures(column)
        }
    }

    /**
     * handle the file select
     * @param {number} col column number from 0 - 2
     * @param {*} id image id to select
     */
    async function handlerSelect(col, id) {
        setPictures((prev) => {
            const newPrevObj = { ...prev }

            const newPrev = [...newPrevObj[col]]

            const getIndex = newPrev.findIndex((item) => item.id === id)
            let newVal = { ...newPrev[getIndex] }
            newVal.selected = !newVal.selected

            newPrev[getIndex] = newVal

            setCheckoutFiles((checkFile) => {
                let newCheckFile = [...checkFile]
                if (newVal.selected) {
                    let checkImg = newCheckFile.findIndex(
                        (item) => item.id === newVal.id
                    )
                    if (checkImg === -1) newCheckFile.push(newVal)
                } else {
                    newCheckFile = newCheckFile.filter(
                        (item) => item.id !== newVal.id
                    )
                }
                return newCheckFile
            })

            return {
                ...newPrevObj,
                [col]: newPrev,
            }
        })
    }

    return (
        <div className="media-picker">
            <div className="media-content-wrapper">
                <div className="pageTitle">
                    Photos by{" "}
                    <a
                        target="_blank"
                        style={{ textDecoration: "underline" }}
                        href="https://unsplash.com/?utm_source=AwesomeSuite&utm_medium=referral"
                    >
                        Unsplash
                    </a>
                </div>
                <div className="media-content">
                    <div className="media-Search">
                        <Input
                            id="media Serch"
                            placeHolder="Search free high-resolution picture"
                            type="text"
                            style={{ flex: "1" }}
                            onKeyHandler={() => onPictureSearch(searchers)}
                            changeHandler={(data) => setSearchers(data)}
                        />
                        <div
                            className="media-btn"
                            onClick={() => onPictureSearch(searchers)}
                        >
                            Search
                        </div>
                    </div>
                    <div className="image-content">
                        {Object.keys(pictures).map((col) => (
                            <div className="img-col">
                                {pictures[col].map((item, index) => {
                                    return (
                                        <GetImage
                                            item={item}
                                            index={index}
                                            column={col}
                                            handlerSelect={handlerSelect}
                                            key={index}
                                        />
                                    )
                                })}
                                {col == 1 && (
                                    <div
                                        ref={loaderRef}
                                        className="loader-ref"
                                        style={{
                                            width: "100%",
                                            height: "8rem",
                                        }}
                                    ></div>
                                )}
                            </div>
                        ))}
                        {Object.keys(pictures).length === 0 && (
                            <div
                                ref={loaderRef}
                                className="loader-ref"
                                style={{
                                    width: "100%",
                                    height: "8rem",
                                }}
                            ></div>
                        )}
                    </div>

                    {loading && (
                        <div style={{ textAlign: "center" }}>Loading...</div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default UnsplashPicker
