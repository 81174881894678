import React from "react"

// export const statusIconsObj = {
//     schedule: <ScheduledIcon />,
//     publish: <PublishedIcon />,
//     unpublish: <UnpublishedIcon />,
//     draft: <DraftedIcon />,
// }
export const statusIconsObj = {
    schedule: <img src={require("../../icons/Scheduled.svg")} alt="schedule" />,
    publish: <img src={require("../../icons/Published.svg")} alt="publish" />,
    unpublish: <img src={require("../../icons/Unpublished.svg")} alt="unpublish" />,
    draft: <img src={require("../../icons/Drafted.svg")} alt="draft" />,
}
