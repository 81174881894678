import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    postUtilities: {
        width: "100%",
        padding: "0.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
    },
    captions: {
        padding: "0.5rem 1rem",
        border: "1px solid #CCDFF2",
        borderRadius: "4px",
        cursor: "pointer",

        "&:hover": {
            boxShadow: "1.5px 3px 6px #CCDFF2"
        }
    },
    commentButton: {
        padding: "0 0.5rem",
        marginLeft: "auto",
        display: "flex",
        cursor: "pointer",
        fontSize: "0.8rem",
    },
    commentWrapper: {
        display :"flex",
    },
    plus: {
        height: "1.25rem",
        minWidth: "1.25rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #CBD6E2",
        borderRadius: "50%",
        color: "#1D9BF0",
        fontSize: "1rem",
        marginRight: "0.5rem"
    },
    text: {
        fontSize: "0.9rem",
    }
})

export default useStyles