import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import "./error.scss"
import { clearMessage } from "../../../Actions/messageAction"

/**
 *
 * @param {String} message
 * @param {["danger"|"success"|"warn"|"info"]} status
 * @param {Function} deleteError
 * @returns
 */

const Error = ({ message, status, deleteError }) => {
    const [vis, toggle] = useState(true)

    useEffect(() => {
        let tim1 = setTimeout(() => {
            toggle(false)
        }, 2500)
        let tim2 = setTimeout(() => {
            clearMessage()
        }, 3000)
        return () => {
            clearTimeout(tim1)
            clearTimeout(tim2)
        }
    }, [deleteError])

    const deleteMsg = () => {
        toggle(false)
        let tim1 = setTimeout(() => {
            clearMessage()
        }, 500)
        return () => clearTimeout(tim1)
    }

    return (
        <div className={`error`}>
            <div className={`msg  ${status}  ${vis ? "slideDown" : "slideUp"}`}>
                <section>
                    {/* <span></span> */}
                    <img src={require(`../../icons/${status}.svg`)} alt={status} />
                    <div>
                        {message &&
                            (typeof message === "object"
                                ? JSON.stringify(message)
                                : message)}
                    </div>
                </section>
                <div>
                    <span onClick={deleteMsg}>&#10005;</span>
                </div>
            </div>
        </div>
    )
}

const mapStatesToProps = ({messageReducer}) => {
    return {
        message: messageReducer.msg,
        status: messageReducer.status,
    }
}

export default connect(mapStatesToProps)(Error)
