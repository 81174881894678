import { createUseStyles } from "react-jss";

const captionsStyles = createUseStyles({
    captionsContainer:{
        zIndex: "10",
        width: "20rem",
        height: "100%",
        backgroundColor: "white",
        boxShadow: "0 1px 4px 0 #151b2614 inset",
        display: "flex",
        flexDirection: "column",
    },
    captionsWrapper:{
        width: "100%",
        overflowY: "auto",
        //borderLeft: "1px solid #D6DFE8",
    },
    captionsHeader:{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "4rem",
        padding: "1rem",
        border: "1px solid #e3e7ec",
        boxShadow: "0 1.5px 4px #e3e7ec",
        color: "#2E3F50",
        fontWeight: "600",
        fontSize: "0.9rem"
    },
    captionsFooter: {
        height: "6rem",
        border: "1px solid #e3e7ec",
        boxShadow: "0 -1.5px 4px #e3e7ec",
        marginTop: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around"
    },
    captionsButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "2rem",
        width: "7.5rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        color: "#A5B6C9",
        backgroundColor: "white",
        border: "1px solid #D6DFE8",
        borderRadius: "4px",
        fontSize: "0.8rem",
        cursor: "pointer",
        margin: "0.5rem"
    },
    captionsButtonBlue: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "2rem",
        width: "7.5rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        color: "white",
        backgroundColor: "#1FB6F0",
        borderRadius: "4px",
        fontSize: "0.8rem",
        cursor: "pointer",
        margin: "0.5rem"
    },
    createCaption: {
        width: "100%",
        height: "2rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    createCaption: {
        height: "2rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#1FB6F0",
        fontWeight: "600",
        cursor: "pointer",
    },
    captions: {
        padding: "1rem",
        overflowY: "auto",
        minHeight: "75vh"
    }
})

export default captionsStyles
