import React from "react"
import Avatar from "../Avatar/Avatar"
import { connect } from "react-redux"
import { GetUserRole } from "../BrandSetting/Pallettes/Teams"
import "./style.scss"

const UserProfileToolTip = ({
    image,
    firstName,
    lastName,
    email,
    direction = "bottom",
    showRole = true,
    user,
    usersList,
    styleObj,
    role,
}) => {
    const _C = "aui-user-profile-tooltip"

    return (
        <div className={_C + " " + _C + "-" + direction} style={{...styleObj}}>
            {
                user && <Avatar
                firstName={user?.user_name?.length > 0 ? user?.user_name : user?.first_name }
                lastName={user?.last_name?.length > 0 ? user?.last_name : user?.email_id}
                icon={user?.picture}
                width="40px"
                height="40px"
                round
                bgClr="green"
                image={image}
                style={{ fontSize: "12px" }}
            />
            }
            <div className={_C + "-content"}>
                <div className={_C + "-title"}>{firstName + " " + lastName}</div>
                <div className={_C + "-handle"}>{email}</div>
            </div>
            {showRole && (
                <GetUserRole
                    user={user}
                    roles={role}
                    fontSize="0.65rem"
                    height="1.7rem"
                    width="6.5rem"
                />
            )}
        </div>
    )
}

const mapStateToProps = ({ userReducer, socialReducer, roleReducer }) => {
    return {
        usersList: userReducer.workplaceUsers[socialReducer?.activeSocialCompany?.id],
        role: roleReducer.role
    }
}

export default connect(mapStateToProps)(UserProfileToolTip)
