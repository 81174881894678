export const ADD_BRANDS = "ADD_BRANDS"
export const EDIT_BRAND = "EDIT_BRAND"
export const FILTER_BRANDS = "FILTER_BRANDS"
export const BRANDS_LOADING = "BRANDS_LOADING"
export const ADD_BRANDS_FAILED = "ADD_BRANDS_FAILED"
export const UPDATE_BRANDS_DETAILS = "UPDATE_BRANDS_DETAILS"
export const ADD_NEW_BRAND = "ADD_NEW_BRAND"
export const SEARCH_IN_GROUP_BRANDS = "SEARCH_IN_GROUP_BRANDS"
export const CLEAR_EVERYTHING = "CLEAR_EVERYTHING"
export const BRAND_SETTING_TOGGLE = "BRAND_SETTING_TOGGLE"
export const UPDATE_BRAND_DETAILS = "UPDATE_BRAND_DETAILS"
export const UPDATE_BRAND_DETAILS_IN_COMPANY = "UPDATE_BRAND_DETAILS_IN_COMPANY"
export const REMOVE_BRAND_DETAILS = "REMOVE_BRAND_DETAILS"
export const ADD_USER_TO_BRAND = "ADD_USER_TO_BRAND"
export const UPDATE_USER_ROLE_IN_BRAND = "UPDATE_USER_ROLE_IN_BRAND"
export const GET_ALL_USERS_IN_BRAND = "GET_ALL_USERS_IN_BRAND"
export const DELETE_USER_FROM_BRAND = "DELETE_USER_FROM_BRAND"
export const TEAM_LOADING = "TEAM_LOADING"
export const END_LOADING = "END_LOADING"
export const DISABLE_SOCIAL_LOGIN_IN_BRANDS = "DISABLE_SOCIAL_LOGIN_IN_BRANDS"
export const ADD_TEMPORARY_BRAND = "ADD_TEMPORARY_BRAND"
export const UPDATE_TEMPORARY_BRAND = "UPDATE_TEMPORARY_BRAND"
export const RESET_TEMPORARY_BRAND = "RESET_TEMPORARY_BRAND"
export const UPDATE_TEMPORARY_BRAND_KEY = "UPDATE_TEMPORARY_BRAND_KEY"
export const UPDATE_LABELS = "UPDATE_LABELS"
