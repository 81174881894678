export const CREATE_MESSAGE = "CREATE_MESSAGE"
export const CLEAR_MESSAGE = "CLEAR_MESSAGE"
export const MODIFY_ALERT = "MODIFY_ALERT"
export const MODIFY_OFFER = "MODIFY_OFFER"
export const ADD_POPUP = "ADD_POPUP"
export const DELETE_POPUP = "DELETE_POPUP"
export const ADD_NOTIFICATION = "ADD_NOTIFICATION"
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION"
export const TOGGLE_HELP = "TOGGLE_HELP"
export const GET_NOTIFICATION_BY_ID = "GET_NOTIFICATION_BY_ID"
export const OPEN_FILE_PREVIEW = "OPEN_FILE_PREVIEW"
export const CHANGE_PREVIEW_INDEX = "CHANGE_PREVIEW_INDEX"
export const CLOSE_FILE_PREVIEW = "CLOSE_FILE_PREVIEW"
export const UPDATE_META_TITLE = "UPDATE_META_TITLE"
export const RESET_META_TITLE = "RESET_META_TITLE"
export const VIEW_TRIAL_ENDED = "VIEW_TRIAL_ENDED"
export const HIDE_TRIAL_ENDED = "HIDE_TRIAL_ENDED"
export const VIEW_PLAN_POPUP = "VIEW_PLAN_POPUP"
export const HIDE_PLAN_POPUP = "HIDE_PLAN_POPUP"
