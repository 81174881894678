import {
    COLLABORATION_SELECT_BRAND,
    COLLABORATION_SELECT_COMPANY,
} from "../ActionTypes/collaborationActionTypes"

const initialState = {
    companyId: null,
    brandId: null,
}

const collaborationReducer = (state = initialState, action) => {
    try {
        switch (action.type) {
            case COLLABORATION_SELECT_BRAND:
                return { ...state, brandId: action.payload.brandId }
            case COLLABORATION_SELECT_COMPANY:
                return {
                    ...state,
                    companyId: action.payload.companyId,
                    brandId: -1,
                }
            default:
                return state
        }
    } catch (err) {
    }
}

export default collaborationReducer
