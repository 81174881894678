import React, { memo } from "react"
import { createUseStyles } from "react-jss"
import { formatDateIntoWord } from "../../../Helper/HandleDateAndTime"
import { ReactComponent as Like } from "../../../../components/icons/facebookIcons/facebook-like.svg"
import { ReactComponent as Comment } from "../../../../components/icons/facebookIcons/facebook-comment.svg"
import { ReactComponent as Share } from "../../../../components/icons/facebookIcons/facebook-share.svg"
import { ReactComponent as Bin } from "../../../../components/icons/bin.svg"
import { ReactComponent as Duplicate } from "../../../../components/icons/duplicate.svg"
import {
    openSocialPostManager,
    deletePost,
    clearPosts,
    resetPage,
} from "Actions/postAction"
import { useTracking } from "react-tracking"
import { CLICK, SOCIAL } from "../../../../constants"
import ThreeDotOptionDropdown from "components/Utilities/ThreeDotOptionsDropdown"
import FacebookGridView from "../../ImageViews/FacebookGridView"
import { useParseTextForLinks, hashtagParser } from "../../../../hooks/msgParser"
import LinkThumbnail from "../../LinkThumbnail"
import noProfilePic from "../../../icons/no_profile_pic/facebook_no_profile_pic.png"
import Config from "Awesome-ui/Config/Config"
import "./facebook.scss"
import { consumePlan } from "Actions/transactionAction"
import { CONSUME_EVENT } from "ActionTypes/transactionActionTypes"
import { useState } from "react"

const useStyles = createUseStyles({
    threeDot: {
        borderRadius: "50%",
        marginLeft: "auto",
    },
    facebookComment: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
    },
    icon: {
        width: "35px",
        height: "100%",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "0.6rem",
        marginBottom: "auto",
        marginTop: "0.6rem",

        "& img": {
            borderRadius: "50%",
            height: "35px",
            width: "auto",
        },
    },
    contentContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "left",
    },
    commentContent: {
        display: "inline-block",
        backgroundColor: "#F2F3F5",
        borderRadius: "15px",
        padding: "0.5rem",
        margin: "0.5rem 0.5rem 0.1rem 0.5rem",
        minWidth: "3rem",
        maxWidth: "50%",
    },
    commentUser: {
        fontWeight: "600",
        textAlign: "left",
        marginRight: "auto",
        width: "100%",
    },
    commentText: {
        textAlign: "left",
        fontSize: "0.9rem",
    },
    buttons: {
        display: "flex",
        margin: "0 0.6rem 0.6rem 0.6rem",
        fontSize: "0.8rem",
    },
    boldText: {
        fontWeight: "600",
        margin: "0 0.4rem",
        fontSize: "0.8rem",
    },
})

/**
 * @description Preview component for Facebook post
 * @param {String} createdBy
 * @param {String} handleName Social handle / username
 * @param {Time} time or publishDate
 * @param {String} text : Post text
 * @param {JSX} icon : Social icon of creator
 * @param {Link} profilePic : Platform profilePic
 * @param {Files} files
 * @param {Boolean} showStats to show like, share, etc icons
 */
const FacebookPost = ({
    createdBy,
    text: rawText,
    files,
    time,
    publishDate,
    icon,
    stat,
    profilePic,
    path,
    mediaUrl,
    showStats,
    social,
    openPost,
    status,
    postStatus,
    post,
    creator,
    companyId,
    brandId,
    editPost = false,
    firstComment,
    ...props
}) => {
    const [text, links] = useParseTextForLinks(rawText ?? "")
    const [readMore, setReadMore] = useState(false)
    const { trackEvent: track } = useTracking()
    const classes = useStyles()

    const getOptions = (post, creator, postStatus, social) => {
        let arr = [
            {
                name: "Duplicate",
                onClick: () => {
                    if (!companyId || (!post.brandId && !brandId)) return
                    if (!consumePlan(CONSUME_EVENT, 1, true)) return
                    openSocialPostManager(
                        "create",
                        0,
                        companyId,
                        brandId || post?.brandId,
                        {
                            text: post?.text || post?.title,
                            postType: post.postType,
                            mediaContent: post?.mediaContent?.length ? post?.mediaContent : [{ mediaUrl: post.mediaUrl }],
                        },
                        social,
                        creator
                    )
                },
                icon: <Duplicate />,
            },
        ]
        if (postStatus !== "publish")
            arr.push({
                name: "Delete",
                onClick: async () => {
                    const success = await deletePost(social, post.id, post.brandId)
                    if (success) {
                        clearPosts()
                        resetPage()
                    }
                },
                icon: <Bin />,
                style: {
                    color: "#F2545B",
                },
            })
        return arr
    }

    return (
        <div
            className="facebookFeedPost "
            onClick={() => {
                openPost && openPost(props.id)
            }}
        >
            <div className="postTop">
                <div className="icon">
                    <img
                        src={
                            profilePic && profilePic.length > 0
                                ? profilePic
                                : noProfilePic
                        }
                        alt="user"
                    />
                </div>
                <div className="title">
                    <div className="name">{createdBy}</div>
                    <div className="time">
                        {formatDateIntoWord(
                            time || publishDate,
                            true,
                            true,
                            !(time || publishDate)
                        )}
                    </div>
                </div>
                {editPost && (
                    <div className={classes.threeDot}>
                        <ThreeDotOptionDropdown
                            onClick={(e) => {
                                track({
                                    type: CLICK,
                                    title: "post drop down open",
                                    category: SOCIAL,
                                })
                                e.stopPropagation()
                            }}
                            options={getOptions(
                                post,
                                creator,
                                postStatus,
                                "facebook"
                            )}
                            idName="postOption"
                            enableIcon
                        />
                    </div>
                )}
            </div>
            <div
                className="text postText"
                dangerouslySetInnerHTML={{
                    __html: hashtagParser(readMore ? text : text?.substring(0, 150)),
                }}
            ></div>
            {!readMore && text?.length > 150 && (
                <span
                    onClick={(e) => {
                        setReadMore(true)
                        e.stopPropagation()
                    }}
                    className="readMore"
                >
                    ...Read More
                </span>
            )}
            {links?.[0] && <LinkThumbnail url={links[0]} column isSocialMedia />}
            <FacebookGridView
                files={
                    files && files.length
                        ? files
                        : path || mediaUrl
                            ? [{ preview: path || mediaUrl }]
                            : []
                }
            />
            {showStats && (
                <div className="stats">
                    <div className="comment">
                        <Comment /> {stat ? stat.comment : ""}
                    </div>
                    <div className="hearts">
                        <Like /> {stat ? stat.like : ""}
                    </div>
                    <div className="share">
                        <Share />
                        {stat ? stat.share : ""}
                    </div>
                </div>
            )}
            {icon}
            {firstComment && (
                <Config features={["FIRST_LEVEL_POST"]} social={"facebook"}>
                    <div className={classes.facebookComment}>
                        <div className={classes.icon}>
                            <img
                                src={
                                    profilePic && profilePic.length > 0
                                        ? profilePic
                                        : noProfilePic
                                }
                                alt="user"
                            />
                        </div>
                        <div className={classes.contentContainer}>
                            <div className={classes.commentContent}>
                                <div className={classes.commentUser}>
                                    {createdBy}
                                </div>
                                <div className={classes.commentText}>
                                    {firstComment}
                                </div>
                            </div>
                            <div className={classes.buttons}>
                                <div className={classes.boldText}>Like</div>
                                <div className={classes.boldText}>Reply</div>
                                <div> 1s</div>
                            </div>
                        </div>
                    </div>
                </Config>
            )}
        </div>
    )
}

export default memo(FacebookPost)
