import {
    UPDATE_GROUP,
    ADD_GROUP,
    GROUP_FAILED,
    CHANGE_WORKPLACE_VIEW,
    ADD_USERS_TO_GROUP,
    ADD_NEW_BRAND_TO_GROUP,
    SEARCH_IN_GROUP,
    SELECT_GROUP,
    CLEAR_EVERYTHING,
    TOGGLE_BRAND_SETTING,
    UPDATE_GROUP_DATA,
    REMOVE_WORKSPACE,
    DELETE_USER_FROM_WORKSPACE,
    REMOVE_BRAND_COMPANY,
    WORKPLACE_COMPANIES_LOADING,
} from "../ActionTypes/workplaceType"

const initialState = {
    groups: null,
    fill: false,
    view: "Card",
    search: "",
    brandSettingOpen: false,
    loading: true,
    currentCompanyId: null,
    currentActiveWorkspace: null,
    workspaceCompaniesLoading: false,
}

const workplaceReducer = (state = initialState, action) => {
    try {
        switch (action.type) {
            case CLEAR_EVERYTHING:
                return {
                    groups: null,
                    fill: false,
                    view: "Card",
                    search: "",
                    brandSettingOpen: false,
                    loading: true,
                    currentCompanyId: null,
                    currentActiveWorkspace: null,
                    workspaceCompaniesLoading: false,
                }
            case ADD_GROUP:
                return addWorkspace(state, action.payload)
            case UPDATE_GROUP:
                return handleWorkspaceAdd(state, action.payload)
            case ADD_NEW_BRAND_TO_GROUP:
                const { id, brand } = action.payload
                return {
                    ...state,
                    groups: state.groups?.map((g) =>
                        g.id === id ? { ...g, brands: !g?.brands ? [brand] : [...g?.brands, brand] } : g
                    ),
                    currentActiveWorkspace:
                        state.currentActiveWorkspace.id === id
                            ? {
                                ...state.currentActiveWorkspace,
                                brands: [
                                    ...(state.currentActiveWorkspace?.brands || []),
                                    brand,
                                ],
                            }
                            : state.currentActiveWorkspace,
                    search: "",
                }
            case ADD_USERS_TO_GROUP:
                const groups = [...state.groups]
                groups.filter((item) => item.id === action.payload.id)[0].users =
                    action.payload.users
                const currentActiveWorkspace =
                    action.payload.id === state.currentActiveWorkspace.id
                        ? {
                            ...state.currentActiveWorkspace,
                            users: action.payload.users,
                        }
                        : state.currentActiveWorkspace

                return {
                    ...state,
                    groups,
                    currentActiveWorkspace,
                }
            case SEARCH_IN_GROUP:
                const { search } = action.payload
                return {
                    ...state,
                    currentActiveWorkspace: action.payload.currentActiveWorkspace,
                    search,
                }
            case SELECT_GROUP:
                const selectedGroup = state.groups.find(
                    (grp) => grp.id === action.payload.select
                )
                return {
                    ...state,
                    currentActiveWorkspace:
                        action.payload.select === "-1"
                            ? state.currentActiveWorkspace
                            : selectedGroup,
                }
            case UPDATE_GROUP_DATA:
                const newGroup = [...state.groups]
                newGroup.filter((item) => item.id === action.payload.id)[0].name =
                    action.payload.name
                const newCurrentActiveWorkspace = state.currentActiveWorkspace
                newCurrentActiveWorkspace.name =
                    newCurrentActiveWorkspace.id === action.payload.id
                        ? action.payload.name
                        : currentActiveWorkspace.name

                return {
                    ...state,
                    currentActiveWorkspace: newCurrentActiveWorkspace,
                    groups: newGroup,
                }
            case CHANGE_WORKPLACE_VIEW:
                return {
                    ...state,
                    view: action.payload?.view,
                }
            case GROUP_FAILED:
                return {
                    ...state,
                    group: [],
                    loading: false,
                }
            case TOGGLE_BRAND_SETTING:
                return {
                    ...state,
                    brandSettingOpen: action.payload.isOpen,
                }
            case REMOVE_WORKSPACE:
                return {
                    ...state,
                    currentActiveWorkspace:
                        action.payload.id === state.currentActiveWorkspace.id
                            ? null
                            : state.currentActiveWorkspace,
                    groups: state.groups
                        ? [...state.groups].filter(
                            (item) => item.id !== action.payload.id
                        )
                        : null,
                }
            case DELETE_USER_FROM_WORKSPACE:
                var updatedGrp = state.groups.map((group) => ({
                    ...group,
                    users: group.users.filter(
                        (user) => user.id !== action.payload.userId
                    ),
                }))
                return {
                    ...state,
                    groups: updatedGrp,
                }
            case REMOVE_BRAND_COMPANY:
                return removeBrand(state, action.payload)
            case WORKPLACE_COMPANIES_LOADING:
                return setWorkspaceCompaniesLoading(state, action.payload)
            default:
                return state
        }
    } catch (err) {
        console.log(err) // to convert to nebula log
    }
}

export default workplaceReducer

function addWorkspace(state, payload) {
    if (!payload.groups[0])
        return {
            ...state,
            fill: true,
            loading: false,
        }
    if (!payload.groups[0]?.roleId)
        payload.groups[0].roleId = 1;
    return {
        ...state,
        groups: [payload.groups[0]],
        currentActiveWorkspace: payload.groups[0],
        currentCompanyId: payload.currentCompanyId,
        fill: true,
        loading: false,
    }
}

function removeBrand(state, payload) {
    const { currentActiveWorkspace, groups } = state
    const { workspaceId, brandId } = payload

    if (currentActiveWorkspace && currentActiveWorkspace.id === workspaceId) {
        currentActiveWorkspace.brands = currentActiveWorkspace.brands.filter(
            (item) => item.brandId !== brandId
        )
    }

    if (groups) {
        let workspaceIndex = groups.findIndex((item) => item.id === workspaceId)
        if (workspaceIndex > -1) {
            groups[workspaceIndex].brands = groups[workspaceIndex].brands.filter(
                (item) => item.brandId !== brandId
            )
        }
    }

    return {
        ...state,
        currentActiveWorkspace: currentActiveWorkspace,
        groups: groups,
    }
}

const setWorkspaceCompaniesLoading = (state, payload) => {
    return {
        ...state,
        workspaceCompaniesLoading: payload,
    }
}

function handleWorkspaceAdd(state, payload) {
    let newAdd = false
    if (!state.groups?.find((grp) => grp.id === payload.id)) newAdd = true

    let groups = [payload.group]

    if (newAdd && state.groups) {
        groups = [...groups, ...state.groups]
    }

    if (!newAdd && state.groups) {
        groups = groups.map((group) =>
            group.id === payload.id ? payload.group : group
        )
    }
    return {
        ...state,
        groups: groups,
        currentActiveWorkspace: payload.group,
        search: "",
    }
}
