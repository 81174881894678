export const PUBLISH = "publish";
export const DRAFT = "draft";
export const SCHEDULE = "schedule"

/**
 * This is the new
 */
export const comparePostStatus = (compareFrom, compareTo) => {
    if (!compareFrom || !compareTo) return true;
    try {
        if (compareFrom.toLowerCase() === compareTo.toLowerCase()) 
            return true
        else return false;
    } catch (error) {
        return false
    }
}
