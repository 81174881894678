import Axios from "axios"
import store from "../store"
import { getRole } from "../components/apis"
import crown from "../components/icons/RoleIcons/crown.webp"
import megaPhone from "../components/icons/RoleIcons/hand-hold-megaphone.webp"
import handShake from "../components/icons/RoleIcons/handshake.webp"
import guest from "../components/icons/RoleIcons/guest.webp"

import {
    ROLE_LOADING,
    ROLE_FAILED,
    ROLE_SUCCESS,
    ROLE_AUTH_FAILED,
} from "../ActionTypes/roleTypes"
import { createMessage } from "./messageAction"

const { dispatch, getState } = store

export const roleMapping = (role) => {
    switch(role.rolename) {
        case 'ADMIN':
            return {
                name: role.rolename[0].toUpperCase() + role.rolename.slice(1).toLowerCase(),
                desc: " Admins have full access. ",
                image: crown,
                id: role.id,
            }
        case 'MANAGER':
            return {
                name: role.rolename[0].toUpperCase() + role.rolename.slice(1).toLowerCase(),
                desc: "They have all access, except invite a user.",
                image: megaPhone,
                id: role.id,
            }
        case 'TEAM MEMBER':
            return {
                name: role.rolename[0].toUpperCase() + role.rolename.slice(1).toLowerCase(),
                desc: "Create & edit posts, schedule & publish content.",
                image: handShake,
                id: role.id,
            }
        case 'GUEST':
            return {
                name: role.rolename[0].toUpperCase() + role.rolename.slice(1).toLowerCase(),
                desc: "Guests can see the content only.",
                image: guest,
                id: role.id,
            }
        default: 
            return {
                name: role.rolename[0].toUpperCase() + role.rolename.slice(1).toLowerCase(),
                desc: "",
                id: role.id,
            }
    }
}

export const getRoleDropdownContent = ()=>{
    const roles = getState().roleReducer.role
    if(!roles) {
        return [];
    }
    const dropdownContent = roles.map((role)=> roleMapping(role))
    return dropdownContent
} 

export const getRoleDetails = (roleName) => {
    const roles = getState().roleReducer.role
    if(!roles) {
        return null;
    }
    const role = roles.filter((role)=> role.rolename.toUpperCase() === roleName.toUpperCase())
    return role[0]
}


/**
 * @deprecated not in use.
 */
export const getRoles = async () => {
    dispatch({ type: ROLE_LOADING })

    try {
        const token = localStorage.getItem("auth-token")
        const res = await Axios.get(getRole, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
        if (res.data.model) {
            let role = res.data.model.map((a) => ({
                ...a,
                image: require("../components/icons/admin.svg"),
                desc: "All your brands could be managed from one Place",
                sel: a.Enable,
            }))
            dispatch({ type: ROLE_SUCCESS, payload: { role } })
            return role
        } else {
            dispatch({ type: ROLE_AUTH_FAILED })
            createMessage("danger", res.data.msg)
        }
    } catch (err) {
        if (err.response) {
            dispatch({ type: ROLE_FAILED })
            createMessage("danger", err.response.data.message)
        }
    }
}

export const getRolesFromPermissions = async (role) => {
    dispatch({ type: ROLE_SUCCESS, payload: { role } })
    return role
}