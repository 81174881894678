import React, { useState, useEffect } from "react"
import ListPicker from "../ListPicker"
import TagIcon from "../TagIcon"
import { getColorFromList } from "../../Helper/getRandomColor"
import "./tagList.scss"

/**
 *
 * @param {Array} list : [{name, color, id}]
 * @param {Function} addTagToList : add tag to the list
 * @param {Function} addToSelected : select a Tag
 * @param {Function} deleteTagFromSelected : Delete a tag from selected list
 * @param {Boolean} tagIcon : show tag icon in dropdown list
 */

function TagList({
    list,
    tagIcon,
    addTagToList: addToList,
    addToSelected: addToSelectedList,
    deleteTagFromSelected: deleteFromSelectedList,
    direction,
    selectedLabelList
}) {
    const _C = "aui-tag-list"
    const [array, appendArray] = useState([])
    const [selectedTags, updateSelected] = useState(selectedLabelList)

    useEffect(()=>{
        updateSelected(selectedLabelList)
    } , [selectedLabelList])

    /* eslint-disable */
    useEffect(() => {
        let arr = []
        for (let i = 0; i < list.length; i++) {
            arr.push({ ...list[i], color: getColorFromList() })
        }
        appendArray(arr)
    }, [list])
    /* eslint-enable */

    async function addTagToList(tagName) {
        if (tagName.trim().length === 0) return
        let tag = {
            name: tagName,
            id: Math.floor(Math.random() * 1000),
            color: getColorFromList(),
        }
        try {
            selectedTags.length < 2 && await addToSelectedList(tag)
            selectedTags.length < 2 && await addToList(tag)
            let arr = [...array, tag]
            selectedTags.length < 2 && updateSelected([...selectedTags, tag])
            selectedTags.length < 2 && appendArray(arr)
        } catch (err) {
        }
    }

    async function addToSelected(tag) {
        if (selectedTags.filter((a) => a.id === tag.id).length > 0) return
        let arr = selectedTags.length < 2 ? [...selectedTags, tag] : selectedTags
        // let arr = [tag]

        try {
            selectedTags.length < 2 && await addToSelectedList(tag)
            updateSelected(arr)
        } catch (err) {
        }
    }

    async function deleteTagFromSelected(deleteTag) {
        try {
            await deleteFromSelectedList(deleteTag)
            let arr = [...selectedTags].filter((tag) => tag.id !== deleteTag.id)
            if (selectedTags.filter((a) => a.id === deleteTag.id).length === 0) {
                let newArray = [...array, { ...deleteTag }]
                appendArray(newArray)
            }
            updateSelected(arr)
        } catch (err) {
        }
    }

    return (
        <div className={_C}>
            <div className={_C + "-container"}>
                {selectedTags && selectedTags.map((tag) => (
                    <div
                        key={tag.id}
                        className={_C + "-item"}
                        style={{
                            borderColor: tag.color,
                            backgroundColor: `${tag.color}`,
                        }}
                    >
                        <TagIcon small bgClr="white" dashed/>
                        <span style={{ whiteSpace: "nowrap", color: "white" }}>
                            {tag.name}
                        </span>
                        <span
                            onClick={() => deleteTagFromSelected(tag)}
                            style={{ transform: "rotateZ(45deg)" }}
                        >
                            +
                        </span>
                    </div>
                ))}
            </div>
            <ListPicker
                array={array}
                addTagToList={addTagToList}
                addToSelected={addToSelected}
                tagIcon={tagIcon}
                direction="right"
            />
        </div>
    )
}

export default TagList
