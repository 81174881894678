import React from "react"
import "./icon.scss"
import { ReactComponent as FaceBookGroupSVG } from "../../icons/socialIcons/facebook-group.svg"

const FaceBookGroupIcon = ({ active, style }) => {
    return (
        <div
            className={active ? "social-icon-active" : "social-icon-inactive"}
            style={style}
        >
            <FaceBookGroupSVG />
        </div>
    )
}

export default FaceBookGroupIcon
