import React from 'react'
import "./NewFaceBookGroup.scss"

function NewFaceBookGroup() {
  return (
    <div className="joinGroup">
        <div className="joinGroupTopPart">
            <div className="joinGroupHeading">
                New FaceBook Group
            </div>
            <div className="joinGroupText">
                Connect with other users of Awesome Social and share your experience, marketing tips, and strategies.
            </div>
            <div className="joinGroupButtonContainer">
                <a href="https://www.facebook.com/groups/awesomesuite" target="_blank" rel="noopener noreferrer">
                    <div className="joinGroupButton">
                        Join Group
                    </div>
                </a>
            </div>
        </div>
        <div className="joinGroupBottomPart">
            <div className="joinGroupImage">
                <img src={require("../../commonComps/UserSideDrawer/UserSideBar/images/facebook-group.webp")} alt="" />
            </div>
        </div>
    </div>
  )
}

export default NewFaceBookGroup