import { createApi } from "unsplash-js"

function fileHandler(data, addFiles, previewChannel, activeDuplication, setDisable) {
    
    data.forEach((file) =>{ 
        if (file.mediaType === "application/pdf"){
            setDisable(true)
            addFiles(file, previewChannel, activeDuplication)
            
        }else {
            addFiles(file, previewChannel, activeDuplication)
            
        }      
})}

function driveUploadHandler(data, addFiles, previewChannel, activeDuplication, setDisable) {
   
    if (data) 
        data.forEach((newFile) =>{
        if (newFile.mimeType === "application/pdf"){
            setDisable(true)
            addFiles({
                name: newFile.name,
                preview: newFile.mediaPath,
                size: newFile.sizeBytes,
                source: "Drive",
                otherDetails: newFile,
                mediaType: "application/pdf",
                ...newFile,
                },
                previewChannel, 
                activeDuplication)
        } else {
            
            addFiles({
                name: newFile.name,
                preview: newFile.mediaPath,
                size: newFile.sizeBytes,
                source: "Drive",
                otherDetails: newFile,
                mediaType: "IMAGE",
                ...newFile,
                },
                previewChannel, 
                activeDuplication)
        }
            
           
    })
}

function handleDropBox(data, addFiles, previewChannel, activeDuplication) {
    if (data) {
        data.forEach((newFile) =>
            addFiles(
                {
                    name: newFile.name,
                    preview: newFile.link,
                    size: newFile.bytes,
                    source: "DropBox",
                    otherDetails: newFile,
                    mediaType: "IMAGE",
                    mediaPath: newFile.link,
                },
                previewChannel,
                activeDuplication
            )
        )
    }
}

function handleUnsplashPicker(data, addFiles, previewChannel, activeDuplication) {
    if (data) {
        const unsplashApi = createApi({
            accessKey: process.env.REACT_APP_UNSPLASH_ACCESS_KEY,
        })

        data.forEach((newFile) => {
            unsplashApi.photos.trackDownload({
                downloadLocation: newFile.links.download_location,
            })
            let crearotDetails = {
                creatorName: newFile.user.name,
                link: `${newFile.user.links.html}?utm_source=AwesomeSuite&utm_medium=referral`,
                platform: "unsplash",
            }
            addFiles(
                {
                    name: newFile.name,
                    preview: newFile.urls.regular,
                    size: newFile.bytes,
                    source: "Unsplash",
                    otherDetails: newFile,
                    mediaType: "IMAGE",
                },
                previewChannel,
                activeDuplication,
                crearotDetails
            )
        })
    }
}

/**
 * tenor gif picker data sample
 *  bg_color: ""
    content_description: "Tennant Glasses GIF"
    created: 1641561566.50048
    itemurl: "https://tenor.com/view/tennant-glasses-gif-24409022"
    media: 
        gif: {size: 993681, dims: [498, 280],…}
        mp4: 
            dims: [640, 360]
            duration: 2.3
            preview: "https://media.tenor.com/images/26654e0ca0a963dc8b2ca40e3e93ba46/tenor.png"
            size: 262953
            url: "https://media.tenor.com/videos/9f59dc654711e126e85c86cdad0bcce5/mp4"
        tinygif: {dims: [220, 124],…}

    TODO: if we need video you can take mp4 but by default it will be gif
 * @param {*} data 
 */
function handleGifPicker(data, addFiles, previewChannel, activeDuplication) {
    if (data) {
        data.forEach((newFile) => {
            const media = newFile.media_formats
            return addFiles(
                {
                    name: newFile.content_description,
                    preview: media?.gif.url,
                    size: media?.gif.size,
                    source: "Gif",
                    otherDetails: newFile,
                    mediaType: "IMAGE",
                },
                previewChannel,
                activeDuplication
            )
        })
    }
}

export {
    fileHandler,
    driveUploadHandler,
    handleDropBox,
    handleUnsplashPicker,
    handleGifPicker,
}
