import { labelUrl } from "../../apis";
import Axios from "axios";
import { getHeaders } from "../../../Actions/userAction";
import { createMessage } from "../../../Actions/messageAction";

export async function addLabel(labelText , brand_id){
    return new Promise(async ()=>{
        let headers = getHeaders();
        if (!headers) return
        let params = {
            brandId: brand_id,
            label_name: labelText,
        }
        headers.params = params
        try {
            const res = await Axios.post(
                labelUrl,
                {},
                headers
            )
            if(res.data.model){
                return res.data.model
            }
            else{
                return null;
            }
        } catch (error) {
            console.log(error.msg); // to convert to nebula log
        }
        return null;
    })
}

export async function getLabels(brand_id , labelText){
    let headers = getHeaders();
    if (!headers) return
    let params = {
        brandId: brand_id,
    }
    if(labelText)params.label_name = labelText

    headers.params = params

    try {
        const res = await Axios.get(
            labelUrl,
            headers
        )
        if(res.data.model){
            return res.data.model;
        }
        else{
            return null;
        }
    } catch (error) {
        // console.log(error.msg) // TO convert to nebula log
    }
    return null;
}