import React from "react"
import Loader from "../../commonComps/Loaders"
import { Button, Text } from "@awesomesuite-frontend/awesome-nebula"

import warningIcon from "../../icons/msg.webp"
import dangerIcon from "../../icons/warning-icon.webp"
import successIcon from "../../icons/open-surprise.webp"

import useStyles from "./PopupAlertStyle"
import { connect } from "react-redux"
import { CRITICAL, WARNING, SUCCESS } from "../../../constants"
import { CONSUME_EVENT } from "ActionTypes/transactionActionTypes"
import PlanPopup from "../PlanPopup"

const popupIcon = (category) => {
    switch (category) {
        case CRITICAL:
            return dangerIcon
        case WARNING:
            return warningIcon
        case SUCCESS:
            return successIcon
        default:
            return warningIcon
    }
}

const PopupAlert = ({ popup }) => {
    const classes = useStyles()
    if (!popup) return <></>

    const {
        title,
        text,
        cancel,
        proceed,
        action,
        subAction,
        loading,
        category,
        icon,
        showIcon = true,
    } = popup

    if (popup.type === CONSUME_EVENT) return <PlanPopup isPlan active={true} onClose={cancel} />

    return (
        <div className={`${classes.popupAlert}`}>
            <div className={`${classes.popupContent}`}>
                <div
                    className={`${classes.popupTitle} ${
                        classes[`popupTitle_${category}`]
                    }`}
                >
                    <Text
                        varient="h4"
                        className={classes.popupTitleText}
                        marginTop="3.5rem"
                        color="#ffffff"
                    >
                        {title}
                    </Text>
                    <span className={`${classes.cross}`} onClick={cancel}>
                        +
                    </span>
                    {showIcon && (
                        <img
                            src={icon ? icon : popupIcon(category)}
                            className={`${classes.popupIcon}`}
                            alt=""
                        />
                    )}
                </div>
                <Text fontSize={14} align="center" margin="2rem" weight={400}>
                    {text}
                </Text>
                <div className={`${classes.bottomAlert}`}>
                    <Button
                        className={`${classes.btn} ${classes.proceed} ${
                            classes[`proceed_${category}`]
                        }`}
                        onClick={proceed}
                    >
                        {loading ? (
                            <Loader
                                h="1rem"
                                w="1rem"
                                b="2px"
                                clr="white"
                                bk="#eaf0f6"
                                t="0.3s"
                            />
                        ) : (
                            action
                        )}
                    </Button>
                    <Button
                        className={`${classes.btn} ${
                            classes[`cancel_${category}`]
                        } ${classes.cancel} `}
                        onClick={cancel}
                    >
                        {subAction || "Cancel"}
                    </Button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ messageReducer }) => {
    return {
        popup: messageReducer.popup,
    }
}

export default connect(mapStateToProps)(PopupAlert)
