import React, { useState } from "react"
import { createMessage } from "../../../Actions/messageAction"

const CommentInput = ({ onComment, doApproval, forApproval}) => {
    const _C = "aui-post-collaboration-comment-input"
    const [status, setStatus] = useState(-1)
    const approvalOptions = ["Approve", "Reject"]
    const [value, setValue] = useState("")

    function handleStatus(statusInd) {
        if (statusInd === 0) {
            doApproval(status , "")
            setValue("")
            setStatus(-1)
        } else setStatus(1)
    }

    function handleSubmit() {
        let formattedComment = value
        if(status === 1)setStatus(0);
        if (!forApproval)
            onComment(formattedComment, () => {
                setValue("")
                setStatus(-1)
            })
        else {
            if (formattedComment.trim().length === 0) {
                createMessage("warn", "Please enter a comment to collaborate!")
                setStatus(-1)
                return
            }
            if (status === -1) {
                createMessage("warn", "Please Approve or Reject the post")
                return
            } else if (status === 0) {
                formattedComment = "|||Approved|||" + formattedComment
                doApproval("approve" , "")
            } else {
                formattedComment = "|||Rejected|||" + formattedComment
                doApproval("reject" , formattedComment)
            }
            onComment(formattedComment, () => {
                setValue("")
                setStatus(-1)
            })
        }
    }

    return (
        <div
            className={
                _C +
                " " +
                (status === 1
                    ? _C + "-rejected"
                    : status === -1
                    ? _C + "-awaited"
                    : "")
            }
        >
            {forApproval && (status === -1 || status === 1) && (
                <div
                    className={
                        _C +
                        "-status-wrapper " +
                        _C +
                        (status === 1
                            ? "-status-wrapper-rejected"
                            : "-status-wrapper-awaited")
                    }
                >
                    {status === -1 ? (
                        <>
                            <span>This post is pending for approval</span>
                            {approvalOptions.map((option, i) => (
                                <div
                                    className={_C + "-status-wrapper-button"}
                                    key={i}
                                    onClick={() => handleStatus(i)}
                                >
                                    {option}
                                </div>
                            ))}
                        </>
                    ) : (
                        <>
                            <span style={{ color: "red" }}>Post Rejected</span>
                        </>
                    )}
                </div>
            )}
            {status === 1 && (
                <div
                    style={{
                        margin: " 0 0 0rem 0px",
                        padding: "0rem 2rem 0 2rem",
                        textAlign: "left",
                        fontSize: "0.95rem",
                        backgroundColor: "#ffe8e9",
                    }}
                >
                    Add Comment
                </div>
            )}
            <div
                className={
                    _C + "-wrapper " + _C + (status === 1 ? "-wrapper-rejected" : "")
                }
            >
                <textarea
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder="Enter Comment"
                />
                <div
                    className={
                        _C +
                        "-button " +
                        ((forApproval && status === -1) || value.trim().length === 0
                            ? _C + "-button-disabled"
                            : "")
                    }
                    onClick={() => {
                      value.trim().length > 0 && handleSubmit()
                    }}
                >
                    Comment
                </div>
            </div>
        </div>
    )
}

export default CommentInput
