import React, { Component } from "react"
import { logout } from "../../../Actions/userAction"

import ErrorIcon from "../../icons/404.webp"
import "./Error.scss"

class CatchFallback extends Component {
    state = { error: null }

    componentDidCatch(error) {
        this.setState({ error })
    }

    FallbackPage = (
        <div className="error-page">
            <div className="error-main">
                <h1>Oops! Something went wrong..</h1>
                <p>
                    Maybe some error has occurred on our side. We are working on it.
                    Till then, you can try & <b>RELOAD</b> to continue.
                </p>
                <img src={ErrorIcon} alt="404-error" className="error-icon" />
                <div className="error-btn-wrapper">
                    <a href="/" className="error-home">
                        Go to Home
                    </a>
                    <div onClick={logout} className="error-log">
                        or log out
                    </div>
                </div>
            </div>
        </div>
    )
    render() {
        return this.state.error === null ||
            process.env.REACT_APP_ENV === "development"
            ? this.props.children
            : this.FallbackPage
    }
}

export default CatchFallback
