import store from "../store"
import {
    SET_ALL_SOCIAL_ACCOUNTS,
    TOGGLE_SOCIAL_ACCOUNT_TO_POST_ON,
    SELECT_PROFILE_TO_VIEW,
    ADD_PUBLISH_FILES,
    DELETE_PUBLISH_FILES,
    TOGGLE_PUBLISH_MINIFY_LINK,
    SELECT_CHANNEL_TO_POST_ON,
    UPDATE_POST_SCHEDULED_DATE,
    UPDATE_POST_SCHEDULED_TIME,
    PUBLISH_LOADING,
    PUBLISH_LOADING_END,
    SET_COMPANY,
    CHANGE_MINIFY_LINK,
    UPDATE_PUBLISH_POST,
    UPDATE_PUBLISH_TEXT,
    CLEAR_ALL_PUBLISH_POSTS,
    RESET_PUBLISH_POST,
    UPDATE_ALL_PUBLISH_POST,
    PREVIOUS_PUBLISH_PAGE,
    SET_PUBLISH_TAB,
    SET_PUBLISH_FILTER_STATUS,
    SHUFFLE_PUBLISH_FILES,
    DUPLICATE_PUBLISH_FILES,
    CHANGE_PUBLISH_POST,
    UPDATE_PUBLISH_TIME_ZONE,
    SET_PUBLISH_USER_FILTER_STATUS,
    SET_PUBLISH_SORT_FILTER_STATUS,
    SET_PUBLISH_SEARCH_FILTER_STATUS,
    SET_PUBLISH_DATE_FILTER_STATUS,
    SET_PUBLISH_FILTER,
    UPDATE_PUBLISH_CREATED_POST,
    PUBLISH_APPEND_TEXT,
} from "../ActionTypes/publishActionType"
import { publishPostHandler } from "../components/Helper/SocialPostHandler/postHandler"
import { testLinkCompressor } from "../components/Helper/textLinkConverter"
import {
    SocialSubTypeChecker,
    isSocialSubtype,
} from "../components/Helper/Normalizers/SocialSubTypeChecker"
import { getSocialProfileDetails } from "./socialAction"
import {
    addDateAndTime,
    checkTimeValidity,
} from "../components/Helper/HandleDateAndTime"
import { getAllPublishPosts } from "../components/Helper/SocialPostHandler/bulkPublish"
import { createMessage } from "./messageAction"
import { checkPost } from "../components/Helper/SocialPostHandler/processPostBeforeRequest"
import { consumePlan } from "./transactionAction"
import { CONSUME_EVENT } from "ActionTypes/transactionActionTypes"

const { dispatch, getState } = store

/**
 * @description Set CompanyId on which the post has to be published
 * @param {*} companyId
 */
function setPublishCompany(companyId) {
    dispatch({ type: SET_COMPANY, payload: { companyId } })
}

async function setAllSocialAccounts() {
    const { socialProfiles } = getState().socialReducer
    const { brands: allBrands, brandDetails } = getState().brandsReducer
    const { publishCompanyId } = getState().publishReducer
    let brands = allBrands[publishCompanyId]

    const extractAccounts = async (res) => {
        if (!brands) return res(null)
        let arr = []
        brands.forEach((brand) => {
            let { brandId, name } = brand
            if (
                brandDetails[brandId] &&
                brandDetails[brandId].socialMedia &&
                brandDetails[brandId].socialMedia.socials
            ) {
                let profiles = []
                brandDetails[brandId].socialMedia.socials.forEach((platform) => {
                    if (!platform.id) return

                    if (SocialSubTypeChecker(platform.name)) {
                        if (
                            socialProfiles[brandId]?.[platform.name]?.[
                            platform.key.toLowerCase()
                            ] === undefined &&
                            socialProfiles[brandId]?.[platform.name]?.[
                            platform.key.toLowerCase()
                            ] !== 0
                        )
                            if (isSocialSubtype(platform.name, platform.key)) {
                                profiles = profiles.filter(
                                    (prof) => prof.social !== platform.name
                                )

                                profiles.push({
                                    social: platform.name,
                                    key: platform.key,
                                })
                            }
                    } else {
                        if (
                            socialProfiles[brandId]?.[platform.name] === undefined &&
                            socialProfiles[brandId]?.[platform.name] !== 0
                        )
                            if (
                                !profiles.find(
                                    (prof) => prof.social === platform.name
                                )
                            ) {
                                profiles.push({
                                    social: platform.name,
                                    key: platform.key,
                                })
                            }
                    }

                    arr.push({
                        name,
                        brandId,
                        type: platform.name,
                        added: false,
                        choose: true,
                        key: platform.key,
                    })
                })

                Promise.all(
                    profiles.map((profile) => {
                        return getSocialProfileDetails(
                            brandId,
                            profile.social,
                            () => { },
                            profile.key
                        )
                    })
                )
            }
        })
        return res([...arr])
    }
    const socialAccounts = await new Promise((res) => extractAccounts(res))
    if (!socialAccounts) return

    dispatch({ type: SET_ALL_SOCIAL_ACCOUNTS, payload: { socialAccounts } })
}

/**
 * Add or remove channel to post on
 * @param {*} brandId
 * @param {*} social
 */
function toggleSocialAccount(brandId, social, key) {
    dispatch({
        type: TOGGLE_SOCIAL_ACCOUNT_TO_POST_ON,
        payload: { brandId, social, key },
    })
}

/**
 * @description Add a file to the files in the post
 * @param {*} file
 * @param {*} metaData - meta data will contain creatorName, link, platform for mensions of creator
 */
function addPublishFiles(file, social, activeDuplication, metaData) {
    dispatch({ type: ADD_PUBLISH_FILES, payload: { file, social } })
    if (metaData) {
        let creditText = `\n\n 📷 Photo by ${metaData.creatorName} on ${metaData.platform}`
        appendPublishText(null, creditText)
    }
    if (activeDuplication === "Similar") duplicatePublishFiles(social)
}

/**
 * @description Delete a file with given name or at position from the files in the post
 * @param {String} name
 * @param {Number} position
 */
function deletePublishFile(position, name, social, activeDuplication) {
    dispatch({ type: DELETE_PUBLISH_FILES, payload: { position, name, social } })
    if (activeDuplication === "Similar") duplicatePublishFiles(social)
}

function shufflePublishFile(files, social, activeDuplication) {
    dispatch({ type: SHUFFLE_PUBLISH_FILES, payload: { files, social } })
    if (activeDuplication === "Similar") duplicatePublishFiles(social)
}

function duplicatePublishFiles(social) {
    dispatch({ type: DUPLICATE_PUBLISH_FILES, payload: { social } })
}

/**
 * @description Update the post in redux
 * @param {Object} post
 */
function updatePublishPost(post) {
    dispatch({
        type: UPDATE_PUBLISH_POST,
        payload: { post },
    })
}

function updatePublishtext(social, text) {
    dispatch({
        type: UPDATE_PUBLISH_TEXT,
        payload: { social, text },
    })
}

/**
 * @description Toggle Minify Link option
 */
function togglePublishMinifyLink() {
    dispatch({ type: TOGGLE_PUBLISH_MINIFY_LINK })
}

/**
 * @description Select the channel for which the post preview is to be seen in the post preview section
 * @param {String} channel
 */
function selectProfileToView(profile, previewRef) {
    dispatch({
        type: SELECT_PROFILE_TO_VIEW,
        payload: { profile, previewRef },
    })
}

function postLoadingEnd() {
    dispatch({ type: PUBLISH_LOADING_END })
}

/**
 * Post create handler to send new post to the post handler for the creation
 *
 *  @description Create the post in the state of reducer
 */
async function createPublishPost(postStatus, callback = () => { }) {
    const { post, allSocialAccounts, files, scheduleDate, scheduleTime, timezone } =
        getState().publishReducer
    const { user } = getState().userReducer
    const { workflow } = getState().workflowReducer

    if (!user) {
        // TODO: control basic details from this place.
        callback()
        return
    }

    if (!post?.text) {
        createMessage("danger", "Please enter a some post Content to publish.")
        callback()
        return
    }

    if (scheduleDate ^ scheduleTime) {
        createMessage("danger", "Please Enter a valid date and time.")
        callback()
        return
    }

    if (timezone === "Asia/Kolkata") {
        createMessage("warn", "Please Enter a Valid Timezone")
        callback()
        return
    }

    const channelsToPostOn = allSocialAccounts?.map((account) => {
        if (account.added) return account?.type?.toLowerCase()
    })

    let isAllowed = consumePlan(
        CONSUME_EVENT,
        channelsToPostOn.filter((a) => a).length
    )

    if (!isAllowed) {
        return callback()
    }

    let err = checkPost(
        post,
        scheduleDate,
        scheduleTime,
        channelsToPostOn,
        postStatus
    )
    if (err) {
        createMessage("warn", err)
        callback()
        return
    }

    if (
        postStatus === "schedule" &&
        scheduleDate &&
        scheduleTime &&
        !checkTimeValidity(addDateAndTime(scheduleDate, scheduleTime))
    ) {
        createMessage(
            "danger",
            "Post cant be scheduled in the Past. Please Enter a Valid Schedule date."
        )
        callback()
        return
    }

    // if (minifyLink) {
    // post.text.twitter_text = await testLinkCompressor(post.text)
    // }
    if (post.text.twitter_text) {
        post.text.twitter_text = await testLinkCompressor(post.text.twitter_text)
    }
    if (post.text.instagram_text) {
        post.text.instagram_text = await testLinkCompressor(post.text.instagram_text)
    }
    if (post.text.facebook_text) {
        post.text.facebook_text = await testLinkCompressor(post.text.facebook_text)
    }
    if (post.text.linkedin_text) {
        post.text.linkedin_text = await testLinkCompressor(post.text.linkedin_text)
    }

    dispatch({ type: PUBLISH_LOADING })
    await publishPostHandler(
        "Asia/Kolkata",
        user,
        post.text,
        scheduleDate,
        scheduleTime,
        postStatus,
        files,
        allSocialAccounts,
        workflow,
        { ...post },
        () => dispatch({ type: RESET_PUBLISH_POST })
    )

    callback()
}

/**
 * @description Get All Posts
 * @param {page?} [page] Page number
 */
async function getPublishPosts(page) {
    const { publishReducer, workplaceReducer, brandsReducer, postReducer } =
        getState()
    const { social, currentPublishFilterStatus } = publishReducer
    const companyId = workplaceReducer.currentActiveWorkspace?.id
    const brands = brandsReducer.filteredBrands[companyId]
    const brandIds = brands.map(({ brandId }) => brandId)

    if (!page) page = publishReducer.page

    dispatch({ type: PUBLISH_LOADING })
    try {
        const res = await getAllPublishPosts(
            brandIds,
            social?.toLowerCase(),
            page,
            postReducer.rowLimit + 1,
            currentPublishFilterStatus?.statusKey
        )
        if (res.data.model) {
            dispatch({
                type: UPDATE_ALL_PUBLISH_POST,
                payload: { posts: res.data.model, limit: postReducer.rowLimit + 1 },
            })
        } else {
            dispatch({
                type: PUBLISH_LOADING_END,
            })
            createMessage("danger", res.data.msg)
        }
    } catch (err) {
        createMessage("danger", "Cannot fetch posts.")
    }
}

/**
 * @description Select or deselect the channel to post on
 * @param {*} channel
 */
function selectChannelToPostOn(channel) {
    dispatch({
        type: SELECT_CHANNEL_TO_POST_ON,
        payload: { channel: channel.toLowerCase() },
    })
}

function changePublishPost(key, value, fromEvent = false) {
    if (fromEvent) value = value.target.value
    dispatch({ type: CHANGE_PUBLISH_POST, payload: { key, value } })
}

/**
 * @description Update Scheduled date of the post
 * @param {*} date
 */
function updatePublishScheduledDate(date) {
    dispatch({
        type: UPDATE_POST_SCHEDULED_DATE,
        payload: { date },
    })
}

/**
 * @description Update Scheduled time of the post
 * @param {*} time
 */
function updatePublishScheduledTime(time) {
    dispatch({
        type: UPDATE_POST_SCHEDULED_TIME,
        payload: { time },
    })
}

/**
 * @description Change state of link minification
 * @param {[-1|0|1]]} state -1 : Bitly, 0 : None,  1 : Awesomly
 */
function changePublishMinifyLink(state) {
    dispatch({
        type: CHANGE_MINIFY_LINK,
        payload: { state },
    })
}

function clearAllPublishPosts() {
    dispatch({ type: CLEAR_ALL_PUBLISH_POSTS })
}

function resetPublishPost() {
    dispatch({ type: RESET_PUBLISH_POST })
}

/** POST PAGINATION */
function previousPublishPage() {
    dispatch({ type: PREVIOUS_PUBLISH_PAGE })
}

function changePublishFilterStatus(status) {
    dispatch({ type: SET_PUBLISH_FILTER_STATUS, payload: status })
}

function changePublishTab(tab) {
    dispatch({ type: SET_PUBLISH_TAB, payload: tab })
}

function updatePublishTimezone(val) {
    dispatch({ type: UPDATE_PUBLISH_TIME_ZONE, payload: val })
}

function setPublishUserFilterStatus(num) {
    dispatch({ type: SET_PUBLISH_USER_FILTER_STATUS, payload: { name: num } })
}

function setPublishSortFilterStatus(num) {
    dispatch({ type: SET_PUBLISH_SORT_FILTER_STATUS, payload: { name: num } })
}

function setPublishSearchFilterStatus(name) {
    dispatch({ type: SET_PUBLISH_SEARCH_FILTER_STATUS, payload: { name: name } })
}

function setPublishdateFilterStatus(date) {
    dispatch({ type: SET_PUBLISH_DATE_FILTER_STATUS, payload: { date: date } })
}

function setPublishOpenFilter(stat) {
    dispatch({ type: SET_PUBLISH_FILTER, payload: { stat: stat } })
}

async function updatePublishPostData(post) {
    dispatch({ type: UPDATE_PUBLISH_CREATED_POST, payload: { post } })
}

/**
 * append new text to existing text.
 * if social is provided then it will update text for only one social.
 * if social is not provided it will update for each social available.
 *
 * @param {*} social social media.
 * @param {*} text text to append.
 */
export const appendPublishText = function (social, text) {
    dispatch({
        type: PUBLISH_APPEND_TEXT,
        payload: { social, text },
    })
}

export {
    setAllSocialAccounts,
    setPublishCompany,
    toggleSocialAccount,
    createPublishPost,
    addPublishFiles,
    deletePublishFile,
    shufflePublishFile,
    selectProfileToView,
    togglePublishMinifyLink,
    selectChannelToPostOn,
    updatePublishScheduledDate,
    updatePublishScheduledTime,
    postLoadingEnd,
    changePublishMinifyLink,
    updatePublishPost,
    updatePublishtext,
    getPublishPosts,
    clearAllPublishPosts,
    previousPublishPage,
    changePublishFilterStatus,
    resetPublishPost,
    changePublishTab,
    duplicatePublishFiles,
    changePublishPost,
    updatePublishTimezone,
    setPublishUserFilterStatus,
    setPublishSortFilterStatus,
    setPublishSearchFilterStatus,
    setPublishdateFilterStatus,
    setPublishOpenFilter,
    updatePublishPostData,
}
