import Axios from "axios"
import store from "../store"
import { getCompanies, getWorkspaceUsers } from "../components/apis"
import { createMessage } from "./messageAction"
import {
    UPDATE_GROUP,
    GROUP_FAILED,
    CHANGE_WORKPLACE_VIEW,
    ADD_USERS_TO_GROUP,
    SEARCH_IN_GROUP,
    SELECT_GROUP,
    UPDATE_GROUP_DATA,
    REMOVE_WORKSPACE,
    ADD_GROUP,
    DELETE_USER_FROM_WORKSPACE,
    WORKPLACE_COMPANIES_LOADING,
} from "../ActionTypes/workplaceType"
import { UPDATE_COMPANY } from "../ActionTypes/socialActionType"
import { UPDATE_WORKSPACE_USER } from "../ActionTypes/userActionTypes"
import { SEARCH_IN_GROUP_BRANDS } from "../ActionTypes/brandsActionTypes"
import { ADD_BRANDS } from "../ActionTypes/brandsActionTypes"
import {
    getHeaders,
    reloadCompanyDetails,
    saveWorkspaceForOnBoarding,
} from "./userAction"
import { COMPANY_ROLE_SUCCESS } from "../ActionTypes/roleTypes"
import { getUsersInBrand } from "../components/apis"
import { getPermissions } from "./permissionAction"
import { setCompaniesLoading } from "./socialAction"

const { dispatch, getState } = store

export async function getAllWorkspaces() {
    try {
        const { companyLoading } = getState().socialReducer
        if (companyLoading) return

        setCompaniesLoading(true)

        const { groups, currentCompanyId } = getState().workplaceReducer
        const currentWorkspace = getWorkspaceById(currentCompanyId)

        if (groups?.length > 0 && currentWorkspace) {
            dispatch({
                type: ADD_BRANDS,
                payload: {
                    id: currentCompanyId,
                    brands: currentWorkspace?.brands,
                },
            })
            dispatch({
                type: UPDATE_WORKSPACE_USER,
                payload: {
                    id: currentCompanyId,
                    users: currentWorkspace?.users,
                },
            })
            return
        }

        const { role } = getState().roleReducer
        if (!role) {
            await getPermissions()
        }

        const headers = getHeaders()
        if (!headers) return

        headers.params = {
            product_id: 1,
        }

        const res = await Axios.get(getCompanies, headers)

        if (res.data.code === 200 && !res.data.model?.length) {
            dispatch({
                type: ADD_GROUP,
                payload: {
                    groups: [],
                    currentCompanyId: null,
                },
            })
            return false
        }

        if (res.data.code === 200) {
            const companyRole = {}
            const companies = res.data.model.map((company) => {
                companyRole[company.id] = role?.find((r) => r.id === company.roleId)
                return company
            })

            dispatch({
                type: ADD_GROUP,
                payload: {
                    groups: companies,
                    currentCompanyId: companies?.[0]?.id,
                },
            })

            dispatch({
                type: COMPANY_ROLE_SUCCESS,
                payload: { roles: companyRole },
            })

            await getAllCompaniesDetails()
            return companies
        } else {
            dispatch({ type: GROUP_FAILED })
            createMessage("danger", "Error while loading workspaces")
            window.location.replace("/error")
            return false
        }
    } catch (err) {
        window.location.replace("/error")
        dispatch({ type: GROUP_FAILED })
        createMessage("danger", "Error while loading workspaces")
        return false
    } finally {
        setCompaniesLoading(false)
    }
}

export const getWorkspaceById = (id) => {
    const { groups } = getState().workplaceReducer
    if (!groups) return null
    const workspace = groups.find((grp) => grp.id === Number(id))
    return workspace
}

export async function getAllCompaniesDetails() {
    const { groups } = getState().workplaceReducer
    const { paramsUrl } = getState().socialReducer

    if (!groups) return

    const headers = getHeaders()

    if (!headers) return

    try {
        const updateGroupPromises = groups.map(async (grp) => {
            if (!grp) return

            const id = grp.id

            if (
                getState().workplaceReducer.groups?.find((grp) => grp.id === id)
                    ?.brands
            )
                return

            dispatch({ type: WORKPLACE_COMPANIES_LOADING, payload: true })

            headers.params = {
                id,
            }

            try {
                const res = await Axios.get(getCompanies, headers)

                if (res.data.model) {
                    dispatch({
                        type: UPDATE_GROUP,
                        payload: { group: res.data.model, id },
                    })
                    dispatch({
                        type: ADD_BRANDS,
                        payload: { id, brands: res.data.model.brands },
                    })
                    dispatch({
                        type: UPDATE_WORKSPACE_USER,
                        payload: { id, users: res.data.model.users },
                    })

                    if (parseInt(paramsUrl.company) === id)
                        dispatch({
                            type: UPDATE_COMPANY,
                            payload: { company: res.data.model },
                        })
                } else {
                    dispatch({ type: GROUP_FAILED })
                    createMessage("danger", res.data.msg)
                    throw new Error("Reload")
                }
            } catch (err) {
                if (err.response) {
                    createMessage("danger", err.response.data)
                }
                dispatch({ type: GROUP_FAILED })
                throw new Error("Reload")
            } finally {
                dispatch({ type: WORKPLACE_COMPANIES_LOADING, payload: false })
            }
        })

        await Promise.all(updateGroupPromises)
    } catch (error) {
        if (error?.response) {
            createMessage("danger", error?.response?.data)
        }
        dispatch({ type: GROUP_FAILED })
        return new Error("Reload")
    }
}

export const addUsersInGroup = (id, users) => {
    dispatch({
        type: ADD_USERS_TO_GROUP,
        payload: { id: id, users: users },
    })
}

export const searchInGroup = (search) => {
    let { groups, currentActiveWorkspace } = getState().workplaceReducer
    if (!groups) return

    if (search.trim().length === 0) {
        const activeWorkspace = groups.find(
            (grp) => grp.id === currentActiveWorkspace.id
        )
        dispatch({
            type: SEARCH_IN_GROUP,
            payload: {
                search,
                filterGroups: groups,
                currentActiveWorkspace: activeWorkspace,
            },
        })
        dispatch({
            type: SEARCH_IN_GROUP_BRANDS,
            payload: {
                filterGroups: groups,
                currentActiveWorkspace: activeWorkspace,
            },
        })
        return
    }

    let filterGroups = []
    groups.forEach((g) => {
        let brands = g.brands.filter((b) => b.name?.toLowerCase()?.includes(search))
        if (brands.length) {
            filterGroups.push({ ...g, brands: brands })
        }
    })

    let filterBrands = currentActiveWorkspace.brands?.filter((b) =>
        b.name?.toLowerCase()?.includes(search)
    )

    dispatch({
        type: SEARCH_IN_GROUP,
        payload: {
            search,
            filterGroups,
            currentActiveWorkspace: {
                ...currentActiveWorkspace,
                brands: filterBrands,
            },
        },
    })
    dispatch({
        type: SEARCH_IN_GROUP_BRANDS,
        payload: {
            filterGroups,
            currentActiveWorkspace: {
                ...currentActiveWorkspace,
                brands: filterBrands,
            },
        },
    })
}

export const addNewGroup = async ({ id }) => {
    let { groups } = getState().workplaceReducer
    if (!groups) return

    const headers = getHeaders()
    if (!headers) return
    headers.params = {
        id: id,
    }

    try {
        const res = await Axios.get(getCompanies, headers)
        if (res.data.model) {
            dispatch({
                type: UPDATE_GROUP,
                payload: { group: res.data.model, id },
            })
            dispatch({
                type: ADD_BRANDS,
                payload: { id, brands: res.data.model.brands },
            })
        } else {
            dispatch({ type: GROUP_FAILED })
            createMessage("danger", res.data.msg)
        }
    } catch (err) {
        if (err.response) {
            dispatch({ type: GROUP_FAILED })
            createMessage("danger", err.response.data)
        }
    }
}

export async function createCompanyManually(name, code) {
    const headers = getHeaders()
    if (!headers) return

    try {
        let data = { name, code, enable: 1 }
        const res = await Axios.post(getCompanies, data, headers)
        if (res.data.model) {
            reloadCompanyDetails()
        } else {
            dispatch({ type: GROUP_FAILED })
            createMessage("danger", res.data.msg)
        }
    } catch (err) {
        if (err.response) {
            dispatch({ type: GROUP_FAILED })
            createMessage("danger", err.response.data)
        }
    }
}

// {
//     "name": "awesomesocial",
//     "code": "AwesomeSocial",
//     "timezone": "Asia/Kolkata",
//     "emailId": "awesomesocial@pwc.com",
//     "address": "Jaipur, India",
//     "enable": 1
// }
export const createWorkspace = async (data) => {
    const headers = getHeaders()
    if (!headers) return
    try {
        const res = await Axios.post(getCompanies, data, headers)
        if (res.data.model) {
            saveWorkspaceForOnBoarding(res.data.model)
            addNewGroup({ id: res.data.model.id })
            dispatch({
                type: ADD_GROUP,
                payload: {
                    groups: [res.data.model],
                    currentCompanyId: res.data.model.id,
                },
            })
            return true
        } else {
            dispatch({ type: GROUP_FAILED })
            createMessage("danger", res.data.msg)
            return false
        }
    } catch (err) {
        if (err.response) {
            dispatch({ type: GROUP_FAILED })
            createMessage("danger", err.response.data)
        }
        return false
    }
}

export const updateGroupName = (data) => {
    dispatch({ type: UPDATE_GROUP_DATA, payload: data })
}

export const selectGroup = (select) => {
    dispatch({ type: SELECT_GROUP, payload: { select } })
}

export const changeView = (view) => {
    dispatch({ type: CHANGE_WORKPLACE_VIEW, payload: { view } })
}

export const deleteWorkspace = async (id) => {
    const headers = getHeaders()
    if (!headers) return
    headers.params = {
        id: id,
    }
    try {
        const res = await Axios.delete(getCompanies, headers)
        if (res.data.code === 200) {
            dispatch({ type: REMOVE_WORKSPACE, payload: { id: id } })
        } else {
            createMessage("danger", res.data.msg)
        }
    } catch (err) {
        if (err.response) {
            dispatch({ type: GROUP_FAILED })
            createMessage("danger", err.response.data)
        }
    }
}

export const fetchCompanyUsers = async (companyId) => {
    try {
        const res = await Axios.get(getWorkspaceUsers, {
            ...getHeaders(),
            params: {
                workspace_id: companyId,
            },
        })
        if (res.data.model) return res.data.model
        else {
            createMessage("danger", "Cant fetch users")
        }
    } catch (err) {
        createMessage("danger", err.response)
    }
}

export const deleteUserFromWorkSpace = async (workspaceId, userId) => {
    const headers = getHeaders()
    if (!headers) return
    try {
        const res = await Axios.delete(
            `${getUsersInBrand}?workspace_id=${workspaceId}&user_id=${userId}`,
            headers
        )
        if (res.data.code === 200) {
            dispatch({
                type: DELETE_USER_FROM_WORKSPACE,
                payload: { workspaceId, userId },
            })
        } else {
            createMessage("danger", "Unable to delete user")
        }
    } catch (err) {
        console.log(err) // TO conver to nebula log
        createMessage("danger", err)
    }
}

export const addWorkspaceUser = (user, id) => {
    const users = getState().userReducer.workplaceUsers[id]
    const userFound = users?.[user.id]
    if (userFound) return
    dispatch({
        type: UPDATE_WORKSPACE_USER,
        payload: { id, users: [user] },
    })
}
