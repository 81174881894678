import React, { useState, useEffect } from "react"
import UnsplashPicker from "../../MediaProviders/UnsplashPicker/UnsplashPicker"
import TenorGif from "../../MediaProviders/GIFPicker/GifPicker"
import FileUploaderTab from "../FileUploader"
import Nav from "../Nav"
import "./dialog.scss"
import { useTracking } from "react-tracking"
import { SOCIAL_POST } from "../../../../constants"

const tabs = {
    0: "FILE SELECT",
    1: "UNSPLASH",
    2: "GIF",
}

function TabSwitcher({ tab, tenor, companyId, setTenor, unsplash, setUnsplash, invokeByLinkedin, postType, ...props }) {
    switch (tab) {
        case 0:
            return <FileUploaderTab companyId={companyId} {...props} invokeByLinkedin={invokeByLinkedin} postType={postType} />
        case 1:
            return (
                <UnsplashPicker
                    open={true}
                    onClose={() => { }}
                    checkoutFile={unsplash}
                    setCheckoutFiles={setUnsplash}
                />
            )
        case 2:
            return (
                <TenorGif
                    open={true}
                    onClose={() => { }}
                    checkoutFile={tenor}
                    setCheckoutFiles={setTenor}
                />
            )
        default:
            return <FileUploaderTab companyId={companyId} {...props} invokeByLinkedin={invokeByLinkedin}
                postType={postType}
            />
    }
}

const Dialog = ({ files, onClose, companyId, invokeByLinkedin, postType, ...props }) => {
    const _C = "aui-media-selection-dialog"
    const [tab, selectTab] = useState(0)
    const [uploads, setUploads] = useState([])
    const [drive, setDrive] = useState([])
    const [dropbox, setDropbox] = useState([])
    const [tenor, setTenor] = useState([])
    const [unsplash, setUnsplash] = useState([])
    const { trackEvent: track } = useTracking()

    useEffect(() => { }, [tab])

    function CloseIt() {
        if (uploads.length > 0) props.fileHandler(uploads)
        if (tenor.length > 0) props.tenorHandler(tenor)
        if (unsplash.length > 0) props.unsplashHandler(unsplash)
        if (drive.length > 0) props.driveHandler(drive)
        if (dropbox.length > 0) props.dropboxHandler(dropbox)
        onClose()
    }

    return (
        <div className={_C + "-backdrop"}>
            <div className={_C}>
                <div className={_C + "-title"}>
                    <div>Add Media</div>
                    <div className={_C + "-cross"} onClick={CloseIt}>
                        +
                    </div>
                </div>
                <Nav
                    currentTab={tab}
                    selectTab={(e) => {
                        track({
                            type: "POST_MEDIA",
                            title: "post media select",
                            key: tabs[e],
                            category: SOCIAL_POST,
                        })
                        selectTab(e)

                    }}
                    invokeByLinkedin={invokeByLinkedin}
                    postType={postType}
                />
                <div className={_C + "-tab-content"}>
                    <TabSwitcher
                        tab={tab}
                        {...props}
                        companyId={companyId}
                        uploads={uploads}
                        setUploads={(file) => {
                            if (file.mediaType === "application/pdf") {
                                setUploads([file])
                            } else {
                                setUploads((files) => [...files, file])
                            }
                        }
                        }
                        drive={drive}
                        setDrive={setDrive}
                        dropbox={dropbox}
                        setDropbox={setDropbox}
                        onClose={onClose}
                        tenor={tenor}
                        setTenor={setTenor}
                        unsplash={unsplash}
                        setUnsplash={setUnsplash}
                        invokeByLinkedin={invokeByLinkedin}
                        postType={postType}
                    />

                    <div
                        className={`final-checkout-model ${uploads.length +
                            tenor.length +
                            unsplash.length +
                            drive.length +
                            dropbox.length
                            ? "final-checkout-model-show"
                            : ""
                            }`}
                        onClick={CloseIt}
                    >
                        <p>
                            {uploads.length +
                                tenor.length +
                                unsplash.length +
                                drive.length +
                                dropbox.length}{" "}
                            Items Selected
                        </p>
                        <div className="btn">
                            <span>Continue</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dialog
