import React, { useState, useRef } from "react"
import EmojiPicker from "../EmojiPicker"
import ToggleButton from "../ToggleButton"
import { Alert } from "@awesomesuite-frontend/awesome-nebula"
import { createMessage } from "../../../Actions/messageAction"
import Caption from "../../icons/caption.svg"
import "./postTextArea.scss"
import { useEffect } from "react"
import TooltipWrapper from "components/commonComps/Tooltip/TooltipWrapper"

/**
 *
 * @param {String} value : Value of Input
 * @param {Function} setValue : Function to update input value
 * @param {Boolean} emoji : Post text area will have an emoji input option
 * @param {minifyLink} Boolean : To MinifyLink
 * @param {Function} toggle : toggle the option to minify Link
 * @param {Function} MediaButton : Function to MediaButton
 * @param {Object|undefined} [style]
 */

const PostTextArea = ({
    title,
    value: rawValue,
    setValue,
    emoji,
    minifyLink,
    toggle,
    MediaButton,
    style = {},
    textBottomRight,
    limitText,
    description,
    icon,
}) => {
    const _C = "aui-post-text-area"
    const [focus, changeFocus] = useState(false)
    const textAreaRef = useRef(null)
    const value = rawValue ?? "";

    const testValue = (textContent) => {
        if (textContent.includes("@")) {
            let newString = textContent.replace("@", "")
            setValue(newString)
            createMessage(
                "danger",
                "Sorry! Currently we don't support mentions, we have removed the @ from your caption"
            )
        } else {
            setValue(textContent)
        }
    }

    return (
        <div className={_C} style={style}>
            {/* <div className="head"> */}
            <div id="post-management-title">
                <div className="icon">
                    <img
                        src={icon ?? Caption}
                        alt="posticon"
                        height="100%"
                        width="100%"
                    />
                </div>
                <div className="head">
                    <div>{title}</div>
                    <div>{description}</div>
                </div>
                {minifyLink !== null && (
                    <div className="link-minify">
                        Minify Link{" "}
                        <ToggleButton
                            click={toggle}
                            active={minifyLink}
                            width="2.5rem"
                            height="1.2rem"
                        />
                    </div>
                )}
            </div>
            {/* </div> */}
            <div
                className={`input ${focus ? "focusText" : ""}`}
                onFocus={() => changeFocus(!focus)}
                onBlur={() => changeFocus(!focus)}
            >
                <textarea
                    ref={textAreaRef}
                    type="text"
                    onChange={(e) => {
                        testValue(e.target.value)
                    }}
                    value={value}
                    placeholder="Hi There!"
                ></textarea>
                <div className="extras">
                    {emoji && (
                        <div className="extra">
                            <EmojiPicker
                                textareaRef={textAreaRef}
                                value={value}
                                setValue={setValue}
                            />
                        </div>
                    )}
                    {MediaButton && (
                        <div className="extra">
                            <TooltipWrapper text={"Add Media"} direction={"bottom"}>
                                <div className="media-button">{MediaButton}</div>
                            </TooltipWrapper>
                        </div>
                    )}
                    {textBottomRight}
                </div>
                {limitText && (
                    <div
                        className={`post-text-validation 
                                    ${280 - value.length <= 10 ? "text-remain" : ""}
                                    ${value.length > 280 ? "text-danger" : ""}`}
                        style={{
                            color:
                                280 - value.length > 10
                                    ? "#00ACEE"
                                    : 280 - value.length >= 0
                                        ? "#FFC107"
                                        : "#F2545C",
                        }}
                    >
                        {`${280 - value.length} Characters left`}
                    </div>
                )}
            </div>
        </div>
    )
}

export default PostTextArea
