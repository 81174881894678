import {
    CLEAR_MESSAGE_CHANNEL,
    PUSH_MESSAGE_TO_CHANNEL,
    START_MESSAGE_CHANNEL,
    UPDATE_PAGE,
    UPDATE_HASMORE,
    ADD_NEW_COMMENT,
    SET_LOADING,
    SET_INTERRUPT,
} from "../ActionTypes/userMessagingActionType"

const initState = {
    currentChannel: {
        channelType: null, // Can Have The Values "Post" or "Collaboration"
        brandId: -1,
    },
    page: 0,
    hasMore: true,
    loading: false,
    // for clear the settimeout tick
    pollerRef: null,
    messages: [],
    messageMap: {},
    interrupt: false,
}

const userMessageingReducer = (state = initState, action) => {
    const payload = action.payload

    switch (action.type) {
        case START_MESSAGE_CHANNEL:
            return startMessageChannel(state, payload)
        case CLEAR_MESSAGE_CHANNEL:
            return clearMessageChannel(state)
        case PUSH_MESSAGE_TO_CHANNEL:
            return pushMessageChannel(state, payload)
        case UPDATE_PAGE:
            return updatePage(state, payload)
        case UPDATE_HASMORE:
            return updateHasmore(state, payload)
        case ADD_NEW_COMMENT:
            return addNewComment(state, payload)
        case SET_LOADING:
            return setLoading(state, payload)
        case SET_INTERRUPT:
            return setInterrupt(state, payload)
        default:
            return state
    }
}

function setInterrupt(state, payload) {
    return {
        ...state,
        interrupt: payload,
    }
}

function clearMessageChannel(state) {
    clearInterval(state.pollerRef)
    return {
        ...state,
        currentChannel: {
            channelType: null,
            brandId: -1,
        },
        pollerRef: null,
        messages: [],
        messageMap: {},
        hasMore: true,
        loading: false,
        page: 0,
        interrupt: true,
    }
}

function startMessageChannel(state, payload) {
    let { message, channelType, tickPoller, brandId } = payload
    let messageMap = {}

    message &&
        message.forEach((msg) => {
            messageMap[msg.id] = msg
            // const id = getDayId(msg.date_created)
        })
    const stateMessages = Object.values(messageMap)
    stateMessages.sort((a, b) => {
        return new Date(a.time) < new Date(b.time)
    })

    return {
        ...state,
        currentChannel: {
            channelType: channelType,
            brandId: brandId,
        },
        pollerRef: tickPoller,
        messageMap: messageMap,
        messages: stateMessages,
        interrupt: false,
    }
}

function pushMessageChannel(state, payload) {
    let { message } = payload
    let messageMap = { ...state.messageMap }
    let isAnyNewMsg = false

    message &&
        message.forEach((msg) => {
            if (!messageMap[msg.id]) {
                isAnyNewMsg = true
                messageMap[msg.id] = msg
            }
        })

    if (!isAnyNewMsg) return state

    const stateMessages = Object.values(messageMap)
    stateMessages.sort((a, b) => {
        return new Date(a.time) < new Date(b.time)
    })

    return {
        ...state,
        messageMap: messageMap,
        messages: stateMessages,
    }
}

function addNewComment(state, payload) {
    const messageMapping = { ...state.messageMap }
    messageMapping[payload.id] = payload
    const stateMessages = Object.values(messageMapping)
    stateMessages.sort((a, b) => {
        return new Date(a.time) < new Date(b.time)
    })

    return {
        ...state,
        messageMap: messageMapping,
        messages: stateMessages,
    }
}

function updatePage(state, payload) {
    return {
        ...state,
        page: payload,
    }
}

function updateHasmore(state, payload) {
    return {
        ...state,
        hasMore: payload,
    }
}

function setLoading(state, payload) {
    return {
        ...state,
        loading: payload,
    }
}

export default userMessageingReducer
