import React from "react"
import "./SocialAnimation.scss"
import AnimatedIcon from "../../../assets/Awesome_Social.json"
import Lottie from "react-lottie"
import CatchFallback from "../../Pages/Error/CatchFallback"
import { useState } from "react"
import { useEffect } from "react"

const SocialAnimation = () => {
    const description = [
        "Getting everything ready ...",
        "Arranging your Instagram carousels...",
        "Designing your grid ...",
        "Please hold on as we reheat our coffee...",
        "Filling up your calendar with content...",
        "I like hashtags 'cause they look like waffles.",
        "One Mississippi, Two Mississippi...",
        "Never gonna give you up...",
        "Yabba Dabba Doo ...",
    ]
    const [index, setIndex] = useState(Math.floor(Math.random() * 10) % 9)

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex(Math.floor(Math.random() * 10) % 9);
        }, 4000)

        return () => {
            clearInterval(interval)
        }
    }, [])

    const lottieOptions = {
        loop: true,
        autoplay: true,
        animationData: AnimatedIcon,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    }

    return (
        <div className="social-first-loading">
            <div className="social-loading-wrapper">
                <CatchFallback>
                    {/* <Social /> */}
                    <Lottie options={lottieOptions} />
                </CatchFallback>
            </div>
            <div className="social-loading-tips">
                {/* <div className="social-loading-tips-heading">{headings[id]}</div> */}
                <div className="social-loading-tips-description">
                    {description[index]}
                </div>
            </div>
        </div>
    )
}

export default SocialAnimation
