import React from "react"
import HelpHeader from "../HelpHeader/HelpHeader"
import ResourceCenterContainer from "../ResourceCenterContainer/ResourceCenterContainer"
import HelpSupportContainer from "../HelpSupportContainer/HelpSupportContainer"
import "./HelpSideNavbar.scss"
import NewFaceBookGroup from "../NewFaceBookGroup/NewFaceBookGroup"

/**
 * Help SideNavBar for helping customers
 * component is is the root component for the help side bar.
 * and requires following components to work.
 *  - {@link ResourceCenterContainer} for the links to the help services
 *  - {@link HelpSupportContainer} for other optional links..
 *
 * @param {[{name:"", description:""}]} resources
 * @param {Boolean} helpOpen Help Content Bar is Open
 * @param {Function} toggleHelp To toggle help content bar
 * @param {[String]} supportContent Array of support contents
 * @param {Link?} [image] Background optional Image
 * @param {*?} [color] text color
 * @param {*?} [backgroundColor] Background Color
 */
const HelpSideNavbar = ({
    resources,
    helpOpen,
    toggleHelp,
    image,
    color,
    backgroundColor,
    supportContent,
}) => {
    return (
        helpOpen && (
            <div className="help-sidenavbar">
                <HelpHeader
                    setShowBar={() => toggleHelp(false)}
                    title="Help & getting started"
                    backgroundColor={backgroundColor || "#6A78D1"}
                    color={color || "white"}
                    style={{
                        background: "white"
                    }}
                />
                <div
                    className="background"
                    style={{ background: `url("${image}")` }}
                ></div>
                <ResourceCenterContainer
                    resources={resources}
                    color={"#33475b"}
                />
                <NewFaceBookGroup/>
                <HelpSupportContainer
                    supportContent={supportContent}
                    backgroundColor={"#33475b" || "#6A78D1"}
                    color={color || "white"}
                />
            </div>
        )
    )
}

export default HelpSideNavbar
