import React from "react"
import { hideTrialEnded } from "../../../Actions/messageAction"
import { logout } from "../../../Actions/userAction"
import { accountsLink } from "../../apis"
import "./style.scss"
import { Link } from "react-router-dom"
import { connect } from "react-redux"

const TrialEndedPopup = ({ active, isClosable = true }) => {
    const _C = "aui-trial-ended"
    if (!(active && !isClosable) || !active) return <></>
    return (
        <div className={_C}>
            <div className={_C + "-popup"}>
                <div
                    className={_C + "-image"}
                    style={{ backgroundColor: "#FFF0F4" }}
                >
                    <img
                        src={require("../../icons/hourglass.png")}
                        alt={"Hour Glass"}
                    />
                </div>
                <div className={_C + "-title"}>Your Free Trial Has Ended</div>
                <div className={_C + "-sub-title"}>
                    But it's not too late to take the next step. Upgrade to a paid
                    plan to enjoy features like Multiple Social channels and Team
                    Collaboration.
                </div>
                <div className={_C + "-button"}>
                    <a
                        className={_C + "-payment-button"}
                        href={`${accountsLink}/subscription/payment-methods`}
                    >
                        Add Payment Method
                    </a>
                </div>
                <div className={_C + "-bottom"}>
                    <a
                        className={_C + "-all-plans"}
                        href={`${accountsLink}/billing?service=social`}
                    >
                        View All Plans
                    </a>
                    <Link to={"/login?logout=true"}>
                        <span
                            className={_C + "-logout"}
                            onClick={() => {
                                logout()
                                window.location = "/login?logout=true"
                            }}
                        >
                            Logout
                        </span>
                    </Link>
                </div>
                {isClosable && (
                    <div className={_C + "-close"} onClick={hideTrialEnded}>
                        +
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStatesToProps = ({ messageReducer, userReducer }) => {
    return {
        active: messageReducer.viewTrialEnded,
        isClosable: userReducer.isSubscriptionActive,
    }
}

export default connect(mapStatesToProps)(TrialEndedPopup)
