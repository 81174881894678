import { UPDATE_BRAND_WORKFLOW, TOGGLE_FETCH } from "../ActionTypes/workflowActionTypes"

const initialState = {
    workflow: {},
    fetch: false,
}

const workflowReducer = (state = initialState, action) => {
    try {
        switch (action.type) {
            case UPDATE_BRAND_WORKFLOW:
                const { brandId, pipelines } = action.payload
                const updatedWorkflow = { ...state.workflow }
                updatedWorkflow[brandId] = pipelines
                return {
                    ...state,
                    workflow: updatedWorkflow,
                }
            case TOGGLE_FETCH:
                return {
                    ...state,
                    fetch: !state.fetch,
                }
            default:
                return state
        }
    } catch (err) {
    }
}

export default workflowReducer
