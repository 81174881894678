export const themeBackgroundData = [
    {
      title: "Flat",
      color: [
        "#00b894",
        "#00cec9",
        "#0984e3",
        "#6c5ce7",
        "#b2bec3",
        "#fdcb6e",
        "#e17055",
        "#d63031",
        "#e84393",
        "#2d3436",
        "#F97F51",
        "#B33771",
      ],
    },
    {
      title: "Gradients",
      color: [
        "linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%)",
        "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
        "linear-gradient(to top, #fdcbf1 0%, #fdcbf1 1%, #e6dee9 100%)",
        "linear-gradient(to top, #fbc2eb 0%, #a6c1ee 100%)",
        "linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)",
        "linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%)",
        "linear-gradient(120deg, #a6c0fe 0%, #f68084 100%)",
        "linear-gradient(120deg, #f093fb 0%, #f5576c 100%)",
        "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)",
        "linear-gradient(to right, #4facfe 0%, #00f2fe 100%)",
        "linear-gradient(to right, #43e97b 0%, #38f9d7 100%)",
        "linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",
        "linear-gradient(180deg, #2af598 0%, #009efd 100%)",
        "linear-gradient(to top, #c471f5 0%, #fa71cd 100%)",
        "linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%)",
        "linear-gradient(to top, #a7a6cb 0%, #8989ba 52%, #8989ba 100%)",
        "linear-gradient(-225deg, #FF057C 0%, #7C64D5 48%, #4CC3FF 100%)",
        "linear-gradient(-225deg, #3D4E81 0%, #5753C9 48%, #6E7FF3 100%)",
      ],
    },
    {
      title: "Neon",
      color: [
        "#F7EC09",
        "#08D9D6",
        "#FF2E63",
        "#30E3CA",
        "#A149FA",
        "#C70A80",
        "#FBCB0A",
        "#F806CC",
        "#00FFAB",
        "#FF5DA2",
        "#D62AD0",
        "#FB7AFC",
        "#FA26A0",
        "#05DFD7",
      ],
    },
    {
      title: "Vintage",
      color: [
        "#377D71",
        "#F0EBE3",
        "#E4DCCF",
        "#54BAB9",
        "#F7E2D6",
        "#9A86A4",
        "#B1BCE6",
        "#73777B",
        "#827397",
        "#B4CFB0",
        "#7897AB",
        "#D885A3",
        "#FDCEB9",
        "#E9DAC1",
        "#54BAB9",
      ],
    },
    {
      title: "Pastel",
      color: [
        "#CBF1F5",
        "#E6A4B4",
        "#F5EEE6",
        "#6E85B7",
        "#B2C8DF",
        "#C9BBCF",
        "#B7D3DF",
        "#7D9D9C",
        "#E4DCCF",
        "#FFE6E6",
        "#CDF0EA",
        "#F9CEEE",
        "#9CB4CC",
      ],
    },
  ];
  
  export const themeFontData = {
    IBARRA: {
      title: "Ibarra",
      family: "Ibarra Real Vova",
      group: "SANS_SERIF_FONTS"
    },
    MERRIWETHER: {
      title: "Merriweather",
      family: "Merriweather",
      group: "SANS_SERIF_FONTS"
    },
    TAVIRAJ: {
      title: "Taviraj",
      family: "Taviraj",
      group: "SANS_SERIF_FONTS"
    },
    UCHEN: {
      title: "Uchen",
      family: "Uchen",
      group: "SANS_SERIF_FONTS"
    },
    SQURARE_PEG: {
      title: "Square Peg",
      family: "Square Peg",
      group: "SANS_SERIF_FONTS"
    },
    POPPINS: {
      title: "Poppins",
      family: "Poppins",
      group: "SANS_SERIF_FONTS"
    },
    MONTSERRAT: {
      title: "Montserrat",
      family: "Montserrat",
      group: "SANS_SERIF_FONTS"
    },
    ROBOTO: {
      title: "Roboto",
      family: "Roboto",
      group: "SANS_SERIF_FONTS"
    },
    CAPRIOLA: {
      title: "Capriola",
      family: "Capriola",
      group: "SANS_SERIF_FONTS"
    },
    KARLA: {
      title: "Karla",
      family: "Karla",
      group: "SANS_SERIF_FONTS"
    },
    LATO: {
      title: "Lato",
      family: "Lato",
      group: "SANS_SERIF_FONTS"
    },
    OSWALD: {
      title: "Oswald",
      family: "Oswald",
      group: "SANS_SERIF_FONTS"
    },
    QUICKSAND: {
      title: "Quicksand",
      family: "Quicksand",
      group: "SANS_SERIF_FONTS"
    },
    WORK: {
      title: "Work",
      family: "Work Sans",
      group: "SANS_SERIF_FONTS"
    },
    NUNITO: {
      title: "Nunito",
      family: "Nunito",
      group: "SANS_SERIF_FONTS"
    },
    COURGETTE: {
      title: "Courgette",
      family: "Corgette",
      group: "SANS_SERIF_FONTS"
    },
    PACIFICO: {
      title: "Pacifico",
      family: "Pacifico",
      group: "SANS_SERIF_FONTS"
    },
    ARVO: {
      title: "Arvo",
      family: "Arvo",
      group: "SERIF_FONTS"
    },
    NOTO: {
      title: "Noto",
      family: "Noto Sans",
      group: "SERIF_FONTS"
    },
    PLAYFAIR: {
      title: "Playfair",
      family: "Playfair Display",
      group: "SERIF_FONTS"
    },
    CRIMSON: {
      title: "Crimson",
      family: "Crimson Text",
      group: "SERIF_FONTS"
    },
    
  };
  
  export const themeLinksData = {
    SHARP_1: {
      title: "Sharp 1",
      type: "border",
      linkRadius: "0",
      linkBorder: "1px solid black",
      linkShadow: "none",
    },
    SHARP_2: {
      title: "Sharp 2",
      type: "noBorder",
      linkRadius: "0",
      linkBorder: "none",
      linkShadow: "none",
    },
    SHARP_3: {
      title: "Sharp 3",
      type: "shadow",
      linkRadius: "0",
      linkBorder: "1px solid black",
      linkShadow: "6px 6px black",
    },
    ROUNDED_1: {
      title: "Rounded 1",
      type: "border",
      linkRadius: "30px",
      linkBorder: "1px solid black",
      linkShadow: "none",
    },
    ROUNDED_2: {
      title: "Rounded 2",
      type: "noBorder",
      linkRadius: "30px",
      linkBorder: "none",
      linkShadow: "none",
    },
    ROUNDED_3: {
      title: "Rounded 3",
      type: "shadow",
      linkRadius: "30px",
      linkBorder: "1px solid black",
      linkShadow: "6px 6px black",
    },
    LINK_1: {
      title: "Link 1",
      linkRadius: "0px",
      linkShadow: "0 0 0px 0px grey",
      shadowValue: "0 0 0px 0px",
      linkBorder: "1px solid black",
      type: "nofill",
    },
    LINK_2: {
      title: "Link 2",
      linkRadius: "12px",
      linkShadow: "0 0 0px 0px grey",
      shadowValue: "0 0 0px 0px",
      linkBorder: "1px solid black",
      type: "nofill",
    },
    LINK_3: {
      title: "Link 3",
      linkRadius: "0px",
      linkShadow: "0 0 0px 0px grey",
      shadowValue: "0 0 0px 0px",
      linkBorder: "none",
      type: "fill",
    },
    LINK_4: {
      title: "Link 4",
      linkRadius: "12px",
      linkShadow: "0 0 0px 0px grey",
      shadowValue: "0 0 0px 0px",
      type: "fill",
      linkBorder: "none",
    },
    LINK_5: {
      title: "Link 5",
      linkRadius: "0px",
      linkShadow: "10px 10px black",
      shadowValue: "10px 10px",
      background: "transparent",
      linkBorder: "none",
    },
    LINK_6: {
      title: "Link 6",
      linkRadius: "12px",
      linkShadow: "10px 10px black",
      shadowValue: "10px 10px",
      background: "transparent",
      linkBorder: "none",
    },
  };
  
  export const themeBannerData = {
    CURVED: {
      title: "Curved",
      bannerRadius: "16px",
      bannerButtonRadius: "21px",
      boxButtonBorder: null,
      boxImageBorderRadius: "0px",
      bannerPadding: "0",
      bannerBorder: null,
      bannerShadow: "2px 3px 6px #d6dfe8",
      bannerBG: null,
    },
    CLASSIC: {
      title: "Classic",
      bannerRadius: "0px",
      bannerButtonRadius: "0px",
      boxButtonBorder: true,
      boxImageBorderRadius: "0px",
      bannerPadding: "20px",
      bannerBorder: "1px solid black",
      bannerShadow: "none",
      bannerBG: null,
    },
    TRANSLUCENT: {
      title: "Translucent",
      bannerRadius: "16px",
      bannerButtonRadius: "0px",
      boxButtonBorder: null,
      boxImageBorderRadius: "0px",
      bannerPadding: "20px",
      bannerBorder: null,
      bannerShadow: "none",
      bannerBG: "rgba(0,0,0,0.33)"
    },
    BANNER_1: {
      title: "Banner 1",
      bannerRadius: "0px",
      bannerButtonRadius: "0px",
      boxImageBorderRadius: "0px",
    },
    BANNER_2: {
      title: "Banner 2",
      bannerRadius: "0px",
      bannerButtonRadius: "0px",
      boxImageBorderRadius: "12px",
    },
    BANNER_3: {
      title: "Banner 3",
      bannerRadius: "0px",
      bannerButtonRadius: "12px",
      boxImageBorderRadius: "0px",
    },
    BANNER_4: {
      title: "Banner 4",
      bannerRadius: "0px",
      bannerButtonRadius: "12px",
      boxImageBorderRadius: "12px",
    },
    BANNER_5: {
      title: "Banner 5",
      bannerRadius: "12px",
      bannerButtonRadius: "0px",
      boxImageBorderRadius: "0px",
    },
    BANNER_6: {
      title: "Banner 6",
      bannerRadius: "12px",
      bannerButtonRadius: "0px",
      boxImageBorderRadius: "12px",
    },
    BANNER_7: {
      title: "Banner 7",
      bannerRadius: "12px",
      bannerButtonRadius: "12px",
      boxImageBorderRadius: "0px",
    },
    BANNER_8: {
      title: "Banner 8",
      bannerRadius: "12px",
      bannerButtonRadius: "12px",
      boxImageBorderRadius: "12px",
    },
  };
  