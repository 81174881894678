import {
    CHANGE_SOCIAL,
    CHANGE_SOCIAL_VIEW,
    CHANGE_ACTIVE_SOCIAL_BRAND,
    CLEAR_EVERYTHING,
    SOCIAL_LOADING,
    SOCIAL_FAILED,
    UPDATE_COMPANY,
    UPDATE_URL,
    OPEN_SOCIAL_CONNECTOR,
    CLOSE_SOCIAL_CONNECTOR,
    OPEN_SOCIAL_MODEL,
    SOCIAL_PROFILE_DETAILS_FAILED,
    SOCIAL_PROFILE_DETAILS_SUCCESS,
    SOCIAL_PROFILE_DETAILS_LOADING,
    SOCIAL_CREATE_POST,
    SOCIAL_CREATE_POST_CLOSE,
    UPDATE_BRANDS_SOCIAL_CHANNELS,
    UPDATE_SINGLE_BRAND_SOCIAL_CHANNELS,
    ASSOCIATE_SOCIAL_PAGE_WITH_BRAND,
    CHANGE_POST_FILTER_STATUS,
    REMOVE_SOCIAL_PROFILE,
    DISABLE_SOCIAL_LOGIN_IN_ACTIVE_BRAND,
    RESET_SOCIAL_STATE,
    CHANGE_SOCIAL_SUBTYPE,
    TOGGLE_PINTEREST_CREATE_BOARD,
    SET_CAPTIONS,
    COMPANIES_LOADING,
    PROFILE_DETAILS_LOADING,
    SET_ENABLED_FEATURES,
    SET_GRID_VIEW,
    SET_PAGES,
    SET_ACTIVE_SETTINGS,
} from "../ActionTypes/socialActionType"
import { SocialSubTypeChecker } from "../components/Helper/Normalizers/SocialSubTypeChecker"
import { statusIconsObj } from "../components/Utilities/PostRelatedComponents/PostStatusIcons"

var initialState = {
    loading: false,
    socialView: 3,
    gridView: "Desktop",
    /**
     * to control the type of active social [facebook, linkedin etc]
     */
    social: null,
    fill: false,
    /**
     * for active dashboard type [publish, collaboration..etc]
     */
    activeSocialBrand: null,
    activeSocialCompany: null,
    // activeSocialPlatform:
    paramsUrl: null,
    socialConnectModel: false,
    socialConnector: null,
    /**
     * socialProfiles wil contain the social profile details
     * 
     * data structure will look like this:
     * @example {
     * brandId : {
     *      TWITTER : {
                profile: null,
                firstName: "",
                lastName: "",
                userName: "",
            }
     *    }  
     * }
     */
    socialProfiles: {},
    // TODO: See if we need this.
    socialProfileLoading: false,
    /**
     * handler post creation  and other
     *  @example{
     *      creator: auth user
     *      brandId: string
     *      companyId: string
     * }
     */
    createPost: null,
    brandSocialChannels: {},
    /**
     * All Social Pages of different brands
     */
    brandSocialPages: {},

    postStatusArray: {
        schedule: {
            statusName: "Scheduled",
            statusKey: "schedule",
            statusColor: "#00AEEF",
            statusFill: "#00A4BD",
            index: 0,
            Icon: statusIconsObj["schedule"],
        },
        publish: {
            statusName: "Published",
            statusKey: "publish",
            statusColor: "#25C16F",
            statusFill: "#25C16F",
            index: 1,
            Icon: statusIconsObj["publish"],
        },
        unpublish: {
            statusName: "Unpublished",
            statusKey: "unpublish",
            statusColor: "#FFCA28",
            statusFill: "rgba(254, 181, 26, 0.1)",
            index: 2,
            Icon: statusIconsObj["unpublish"],
        },
        draft: {
            statusName: "Drafted",
            statusKey: "draft",
            statusColor: "#99ACC2",
            statusFill: "rgba(153, 172, 194, 0.2)",
            index: 3,
            Icon: statusIconsObj["draft"],
        },
    },
    currentPostFilterStatus: {
        statusName: "Published",
        statusKey: "publish",
        statusColor: "#25C16F",
        statusFill: "#25C16F",
        index: 1,
        Icon: statusIconsObj["publish"],
    },
    activeItemSetting: 0,
    socialSubtype: "",
    pinterestBoardModal: false,
    captions: {},
    companyLoading: false,
    profilesLoading: false,
    enabledFeatures: {},
    pages: {},
}

var socialReducer = (state = initialState, action) => {
    try {
        switch (action.type) {
            case CLEAR_EVERYTHING:
                return getState()
            case SOCIAL_LOADING:
                return { ...state, loading: true }
            case SOCIAL_FAILED:
                return { ...state, loading: false }
            case COMPANIES_LOADING:
                return { ...state, companyLoading: action.payload }
            case CHANGE_ACTIVE_SOCIAL_BRAND:
                return {
                    ...state,
                    loading: false,
                    activeSocialBrand: action.payload.brand,
                    activeSocialCompany: action.payload.company,
                    fill:
                        action.payload.company && action.payload.company.brands
                            ? true
                            : false,
                }
            case CHANGE_SOCIAL:
                return {
                    ...state,
                    social: action.payload.social,
                }
            case CHANGE_SOCIAL_VIEW:
                return {
                    ...state,
                    socialView: action.payload.socialView,
                }
            case CHANGE_POST_FILTER_STATUS:
                return {
                    ...state,
                    currentPostFilterStatus: action.payload.status,
                }
            case UPDATE_COMPANY:
                return {
                    ...state,
                    fill: true,
                    loading: false,
                    activeSocialCompany: action.payload.company,
                }
            case UPDATE_URL:
                return {
                    ...state,
                    paramsUrl: action.payload.params,
                }
            case OPEN_SOCIAL_MODEL:
                return {
                    ...state,
                    socialConnectModel: true,
                    socialConnector: {
                        brandId: action.payload.brandId,
                        workspaceId: action.payload.workspaceId,
                        socialType: action.payload.socialType,
                        from: action.payload.from,
                    },
                }
            case OPEN_SOCIAL_CONNECTOR:
                return {
                    ...state,
                    socialConnector: {
                        brandId: action.payload.brandId,
                        workspaceId: action.payload.workspaceId,
                        from: action.payload.from,
                    },
                }
            case CLOSE_SOCIAL_CONNECTOR:
                return {
                    ...state,
                    socialConnectModel: false,
                    socialConnector: null,
                }
            case SOCIAL_PROFILE_DETAILS_LOADING:
                try {
                    var newUpdatedProfiles = { ...state.socialProfiles }
                    if (!newUpdatedProfiles[action.payload.brandId])
                        newUpdatedProfiles[action.payload.brandId] = {}
                    if (
                        !newUpdatedProfiles[action.payload.brandId][
                            action.payload.socialType
                        ]
                    )
                        newUpdatedProfiles[action.payload.brandId][
                            action.payload.socialType
                        ] = {}

                    if (SocialSubTypeChecker(action.payload.socialType)) {
                        newUpdatedProfiles[action.payload.brandId][
                            action.payload.socialType
                        ][action.payload.type?.toLowerCase()] = 0
                    } else {
                        newUpdatedProfiles[action.payload.brandId][
                            action.payload.socialType
                        ] = 0
                    }
                    return {
                        ...state,
                        socialProfiles: newUpdatedProfiles,
                    }
                } catch (err) {
                    return state
                }
            case SOCIAL_PROFILE_DETAILS_SUCCESS:
                try {
                    var newProfiles = { ...state.socialProfiles }
                    if (!newProfiles[action.payload.brandId])
                        newProfiles[action.payload.brandId] = {}
                    if (
                        !newProfiles[action.payload.brandId][
                            action.payload.socialType
                        ]
                    )
                        newProfiles[action.payload.brandId][
                            action.payload.socialType
                        ] = {}

                    if (SocialSubTypeChecker(action.payload.socialType)) {
                        newProfiles[action.payload.brandId][
                            action.payload.socialType
                        ][action.payload.type?.toLowerCase()] = action.payload.data
                    } else {
                        newProfiles[action.payload.brandId][
                            action.payload.socialType
                        ] = action.payload.data
                    }
                    return {
                        ...state,
                        socialProfiles: newProfiles,
                    }
                } catch (err) {
                    return state
                }
            case REMOVE_SOCIAL_PROFILE:
                return removeSocialProfile(state, action.payload)
            case DISABLE_SOCIAL_LOGIN_IN_ACTIVE_BRAND:
                let { social: channel, subType: channelType } = action.payload
                let { activeSocialBrand } = state
                activeSocialBrand.socialMedia.socials =
                    activeSocialBrand.socialMedia.socials.map((social) =>
                        social.name.toLowerCase() === channel.toLowerCase()
                            ? { ...social, enable: 0 }
                            : social
                    )
                return { ...state, activeSocialBrand }
            case SOCIAL_PROFILE_DETAILS_FAILED:
                return {
                    ...state,
                    socialProfileLoading: true,
                }
            case SOCIAL_CREATE_POST:
                return {
                    ...state,
                    createPost: action.payload,
                }
            case SOCIAL_CREATE_POST_CLOSE:
                return {
                    ...state,
                    createPost: null,
                }
            case UPDATE_BRANDS_SOCIAL_CHANNELS:
                return {
                    ...state,
                    brandSocialChannels: {
                        ...state.brandSocialChannels,
                        ...action.payload,
                    },
                }
            case UPDATE_SINGLE_BRAND_SOCIAL_CHANNELS:
                var updatedChannels = { ...state.brandSocialChannels }
                if (action.payload.id)
                    updatedChannels[action.payload.id] = action.payload.channels
                return {
                    ...state,
                    brandSocialChannels: updatedChannels,
                }
            case ASSOCIATE_SOCIAL_PAGE_WITH_BRAND:
                var { page, social, brandId, image, title, subText, type } =
                    action.payload
                var updatedSocialProfiles = { ...state.socialProfiles }

                if (!updatedSocialProfiles[brandId]) {
                    updatedSocialProfiles[brandId] = {}
                }
                if (!updatedSocialProfiles[brandId][social]) {
                    updatedSocialProfiles[brandId][social] = {}
                }

                if (SocialSubTypeChecker(social)) {
                    updatedSocialProfiles[brandId][social][type.toLowerCase()] = {
                        id: page.id,
                        page,
                        title,
                        subText,
                        social,
                        image,
                    }
                } else {
                    updatedSocialProfiles[brandId][social] = {
                        id: page.id,
                        page,
                        title,
                        subText,
                        social,
                        image,
                    }
                }
                return {
                    ...state,
                    socialProfiles: updatedSocialProfiles,
                }
            case RESET_SOCIAL_STATE:
                return {
                    ...state,
                    currentPostFilterStatus: {
                        statusName: "Scheduled",
                        statusKey: "schedule",
                        statusColor: "#00AEEF",
                        statusFill: "#00A4BD",
                        index: 0,
                        Icon: statusIconsObj["schedule"],
                    },
                    socialView: 3,
                }
            case CHANGE_SOCIAL_SUBTYPE:
                return {
                    ...state,
                    socialSubtype: action.payload.subType,
                }
            case TOGGLE_PINTEREST_CREATE_BOARD:
                return {
                    ...state,
                    pinterestBoardModal: !state.pinterestBoardModal,
                }
            case SET_CAPTIONS:
                return {
                    ...state,
                    captions: {
                        ...state.captions,
                        [action.payload.brandId]: action.payload.captions,
                    },
                }
            case PROFILE_DETAILS_LOADING:
                return {
                    ...state,
                    profilesLoading: action.payload,
                }
            case SET_ENABLED_FEATURES:
                return {
                    ...state,
                    enabledFeatures: action.payload,
                }
            case SET_ACTIVE_SETTINGS:
                return {
                    ...state,
                    activeItemSetting: action.payload,
                }
            case SET_GRID_VIEW:
                return setGridView(state, action.payload)
            case SET_PAGES:
                return setPages(state, action)

            default:
                return state
        }
    } catch (err) {}
}

export default socialReducer

function getState() {
    return {
        loading: false,
        socialView: 3,
        gridView: "Desktop",
        social: null,
        fill: false,
        activeSocialBrand: null,
        activeSocialCompany: null,
        paramsUrl: null,
        socialConnectModel: false,
        socialConnector: null,
        socialProfiles: {},
        socialProfileLoading: false,
        createPost: null,
        brandSocialChannels: {},
        brandSocialPages: {},
        postStatusArray: {
            schedule: {
                statusName: "Scheduled",
                statusKey: "schedule",
                statusColor: "#00AEEF",
                statusFill: "#00A4BD",
                index: 0,
                Icon: statusIconsObj["schedule"],
            },
            publish: {
                statusName: "Published",
                statusKey: "publish",
                statusColor: "#25C16F",
                statusFill: "#25C16F",
                index: 1,
                Icon: statusIconsObj["publish"],
            },
            unpublish: {
                statusName: "Unpublished",
                statusKey: "unpublish",
                statusColor: "#FFCA28",
                statusFill: "rgba(254, 181, 26, 0.1)",
                index: 2,
                Icon: statusIconsObj["unpublish"],
            },
            draft: {
                statusName: "Drafted",
                statusKey: "draft",
                statusColor: "#99ACC2",
                statusFill: "rgba(153, 172, 194, 0.2)",
                index: 3,
                Icon: statusIconsObj["draft"],
            },
        },
        currentPostFilterStatus: {
            statusName: "Scheduled",
            statusKey: "schedule",
            statusColor: "#00AEEF",
            statusFill: "#00A4BD",
            index: 0,
            Icon: statusIconsObj["schedule"],
        },
        socialSubtype: "",
        pinterestBoardModal: false,
        captions: {},
        companyLoading: false,
        profilesLoading: false,
        enabledFeatures: {},
        activeItemSetting: 0,
    }
}

function removeSocialProfile(state, payload) {
    try {
        let statePages = { ...state.pages }
        var newProfiles = { ...state.socialProfiles }
        var { brandId, social, type } = payload
        const subType = getType(type)
        if (social.toLowerCase() === "twitter") {
        }
        if (SocialSubTypeChecker(social)) {
            let findObj = Object.keys(newProfiles?.[brandId]?.[social] || {}).find(
                (i) => i.toLowerCase() == subType.toLowerCase()
            )
            newProfiles[brandId][social][findObj] = null
        } else {
            newProfiles[brandId][social] = null
        }
        return {
            ...state,
            socialProfiles: newProfiles,
            socialProfileLoading: false,
            pages: statePages,
        }
    } catch (err) {
        return state
    }
}

function getType(type) {
    return type[0]?.toUpperCase() + type?.slice(1)
}

const setGridView = (state, payload) => {
    return {
        ...state,
        gridView: payload,
    }
}

const setPages = (state, action) => {
    let statePages = { ...state.pages }
    const { brandId, social, subType, pages } = action.payload

    if (!statePages[brandId]) {
        statePages[brandId] = {}
    }
    if (!statePages[brandId][social]) {
        statePages[brandId][social] = {}
    }
    if (!statePages[brandId][social][subType]) {
        statePages[brandId][social][subType] = {}
    }
    statePages[brandId] = {
        ...statePages[brandId],
        [social]: {
            ...statePages[brandId][social],
            [subType]: pages,
        },
    }
    return {
        ...state,
        pages: statePages,
    }
}
