import React, { useState } from "react"
import GooglePicker from "@awesomesuite-frontend/awesome-nebula/dist/GooglePicker"
import DropboxChooser from "react-dropbox-chooser"
import Unsplash from "../components/Utilities/MediaProviders/Unsplash"
import TenorGif from "../components/Utilities/MediaProviders/TenorGif"
import mediaHandler from "../components/Helper/mediaHandler/mediaHandler"
import { mediaApi } from "../components/apis"
import Loader from "../components/commonComps/Loaders"
import {assignValue }from "../Actions/postAction"
import { useSelector } from "react-redux"



export const DrivePicker = ({ globalToken, getFileHandler,invokeByLinkedin = false,setLoading, ...props }) => {
    const mediaHandlerService = new mediaHandler(mediaApi)
    const token = globalToken
     const [loading2, setLoading2] = useState(false)

    async function handleFile(file) {
        return await mediaHandlerService.uploadMediaOther(
            file.name,
            file.mimeType,
            file.id,
            "drive",
            token
        )
    }
    async function fileUploader(data) {
        
        if (data.action !== "picked") return
        setLoading(true)
        let uploadedFiles = await Promise.all(data.docs.map(handleFile))
        setLoading(false)
        getFileHandler(uploadedFiles)
    }

    return (
        <GooglePicker
            clientId={process.env.REACT_APP_DRIVE_CLIENT_ID}
            developerKey={process.env.REACT_APP_DRIVE_APP_KEY}
            scope={["https://www.googleapis.com/auth/drive.readonly"]}
            onChange={fileUploader}
            onAuthFailed={(data) => {}}
            onAuthenticate={(data) => {
                assignValue(data)
            }}
            multiselect={invokeByLinkedin ? true :true}
            navHidden={true}
            authImmediate={false}
            mimeTypes={invokeByLinkedin? ["application/pdf"]:["image/png", "image/jpeg", "image/jpg"]}
        >
            <span className="icon">
                <img
                    src="https://fonts.gstatic.com/s/i/productlogos/drive_2020q4/v8/web-64dp/logo_drive_2020q4_color_2x_web_64dp.png"
                    alt="googleDrive"
                />
            </span>{" "}
            <span>Google Drive™</span>
            {loading2 && (
                <div style={{ position: "relative" }}>
                    <Loader
                        h="12px"
                        w="12px"
                        b="2px"
                        bk="#eaf0f6"
                        clr="#D6DFE8"
                        t="1s"
                    />
                </div>
            )}
        </GooglePicker>
    )
}

export const DropBoxPicker = ({ getFileHandler,invokeByLinkedin=false, setLoading, ...props }) => {
    return (
        <DropboxChooser
            appKey={process.env.REACT_APP_DROPBOX_KEY}
            success={(files) => {
                getFileHandler(files)
            }}
            linkType="direct"
            cancel={() => {}}
            multiselect={invokeByLinkedin? false:true}
            extensions={invokeByLinkedin ? [".pdf"]:[".mp4", ".jpg", ".png", ".jpeg"]}
        >
            <span className="icon">
                <svg
                    className="install-view-layout__logo"
                    xmlns="http://www.w3.org/2000/svg"
                    role="img"
                    width="26px"
                    height="26px"
                    viewBox="0 0 32 32"
                    style={{ fill: "rgb(0, 97, 255)" }}
                >
                    <path d="M8 2.4l8 5.1-8 5.1-8-5.1 8-5.1zm16 0l8 5.1-8 5.1-8-5.1 8-5.1zM0 17.7l8-5.1 8 5.1-8 5.1-8-5.1zm24-5.1l8 5.1-8 5.1-8-5.1 8-5.1zM8 24.5l8-5.1 8 5.1-8 5.1-8-5.1z"></path>
                </svg>
            </span>{" "}
            <span>Dropbox</span>
        </DropboxChooser>
    )
}

export const UnsplashPicker = ({ getFileHandler }) => {
    const [open, setOpen] = useState(false)
    function close() {
        setOpen(false)
    }
    return (
        <div>
            <Unsplash open={open} close={close} onFileSelect={getFileHandler} />
            <svg
                width="26px"
                height="26px"
                class="hic6U"
                viewBox="0 0 32 32"
                version="1.1"
                aria-labelledby="unsplash-home"
                aria-hidden="false"
                onClick={() => setOpen(true)}
            >
                <title id="unsplash-home">Unsplash</title>
                <path d="M10 9V0h12v9H10zm12 5h10v18H0V14h10v9h12v-9z"></path>
            </svg>
        </div>
    )
}

export const TenorGifPicker = ({ getFileHandler }) => {
    const [open, setOpen] = useState(false)
    function close() {
        setOpen(false)
    }
    return (
        <div>
            <TenorGif open={open} close={close} onFileSelect={getFileHandler} />
            <svg
                width="26px"
                height="26px"
                class="hic6U"
                viewBox="0 0 32 32"
                version="1.1"
                aria-labelledby="unsplash-home"
                aria-hidden="false"
                onClick={() => setOpen(true)}
            >
                <title id="unsplash-home">GiF</title>
                <path d="M10 9V0h12v9H10zm12 5h10v18H0V14h10v9h12v-9z"></path>
            </svg>
        </div>
    )
}
