import React, { useState, useRef, memo } from "react"
import { useEffect } from "react"
import useToggle from "../../../hooks/ToggleHook"
import "./style.scss"

/**
 * @description Custom Menu Component with search option
 * @param {String} value Selection Option
 * @param {Function} changeHandler On value change
 * @param {Boolean} round if the component has round corners
 */
const SearchableMenu = memo(
    ({
        options,
        value,
        changeHandler,
        variant = "custom",
        round,
        direction = "down",
    }) => {
        const menuRef = useRef()
        const [currentSelected, setCurrentSelected] = useState(null)
        const [dropDownOpen, setDropDownOpen] = useToggle(menuRef)
        const [searchedOptions, setSearchOptions] = useState(options)

        const newClass = direction === "up" ? "drop-down-bar" : ""

        const _C = "aui-searchable-menu"

        const checkCustom = variant === "custom"

        function menuSearchUtility(item, searchKey) {
            if (checkCustom)
                return item.value.toLowerCase().includes(searchKey?.toLowerCase())
            else return item.toLowerCase().includes(searchKey.toLowerCase())
        }

        function handleSearch(e) {
            let searchKey = e.target.value ?? ""
            let searchedItems = [...options].filter((item) =>
                menuSearchUtility(item, searchKey)
            )
            setSearchOptions(searchedItems)
        }

        function handleSelect(item) {
            setDropDownOpen(false)
            if (checkCustom) changeHandler(item.value)
            else changeHandler(item)
        }

        useEffect(() => {
            let currentVal = options.find((item) =>
                menuSearchUtility(item, value ?? "")
            )
            setCurrentSelected(currentVal)
        }, [value])

        return (
            <div>
                <div className={`${_C} ${round && _C + "-round"}`} ref={menuRef}>
                    <div
                        className={`${_C}-input`}
                        onClick={() => {
                            setDropDownOpen(true)
                        }}
                    >
                        <div className={_C + "-selected-value"}>
                            {currentSelected ? (
                                checkCustom ? (
                                    currentSelected.name
                                ) : (
                                    currentSelected
                                )
                            ) : (
                                <span>Select</span>
                            )}
                        </div>
                        <div
                            className={`${dropDownOpen ? "arrow-up" : "arrow-down"}`}
                            onClick={() => {
                                setDropDownOpen(true)
                            }}
                        ></div>
                    </div>
                    <div
                        className={`drop-down ${
                            dropDownOpen && "drop-down-open"
                        } ${newClass}`}
                    >
                        <div className={_C + "-search"}>
                            <input type="text" onChange={handleSearch} />
                        </div>
                        <div className="dropdown-wrapper">
                            {searchedOptions?.map((item, i) => (
                                <div
                                    key={i}
                                    value={item}
                                    className={`drop-menu`}
                                    onClick={() => handleSelect(item)}
                                >
                                    {checkCustom ? item.name : item.value}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
)

export default SearchableMenu
