export const LOAD_CUSTOMER_DETAILS = "LOAD_CUSTOMER_DETAILS"
export const LOAD_CUSTOMER_DETAILS_SUCCESS = "LOAD_CUSTOMER_DETAILS_SUCCESS"
export const LOAD_CUSTOMER_DETAILS_FAILED = "LOAD_CUSTOMER_DETAILS_FAILED"
export const LOAD_TRANSACTION_DETAILS = "Load_TRANSACTION_DETAILS"
export const LOAD_TRANSACTION_SUCCESS = "LOAD_TRANSACTION_SUCCESS"
export const LOAD_TRANSACTION_FAILED = "LOAD_TRANSACTION_FAILED"
export const LOAD_SUBSCRIPTION_DETAILS = "LOAD_SUBSCRIPTION_DETAILS"
export const LOAD_SUBSCRIPTION_DETAILS_SUCCESS = "LOAD_SUBSCRIPTION_DETAILS_SUCCESS"
export const LOAD_SUBSCRIPTION_DETAILS_FAILED = "LOAD_SUBSCRIPTION_DETAILS_FAILED"
export const LOAD_PLAN_DETAILS_LOADING = "LOAD_PLAN_DETAILS_LOADING"
export const LOAD_PLAN_DETAILS_SUCCESS = "LOAD_PLAN_DETAILS_SUCCESS"
export const LOAD_PLAN_DETAILS_FAILED = "LOAD_PLAN_DETAILS_FAILED"
export const SET_SUBSCRIPTION_PLANS = "SET_SUBSCRIPTION_PLANS"

export const UNCONSUME_PLAN = "UNCONSUME_PLAN";
export const CONSUME_PLAN = "CONSUME_PLAN";
export const CONSUME_ACCOUNT = "CONSUME_ACCOUNT";
export const CONSUME_EVENT = "CONSUME_EVENT";
export const CONSUME_USER = "CONSUME_USER";