import {
    SET_PERIOD_ANALYTICS,
    SET_CONSOLIDATED_PERIOD_ANALYTICS,
    SET_ACTIVE_NAV,
} from "ActionTypes/AnalyticsActionTypes"

const initialState = {
    subNavItems: [
        { name: "Overview" },
        { name: "Post Performance" },
        // { name: "Reel Performance" },
        // {name: "Story Performance"},
        // {name: "Demographic"},
        // {name: "Hey.bio"},
        // {name: "Report"},
    ],
    activeNav: 0,
    supportedMetrics: {
        facebook: {
            page_impressions: {
                title: "Impressions",
                id: "impressions",
                color: "rgba(90, 119, 169, 0.2)",
                border: "#29487D",
            },
            page_engaged_users: {
                title: "Reach",
                id: "reach",
                color: "rgba(103, 129, 188, 0.2)",
                border: "#3C5898",
            },
            page_views_total: {
                title: "Profile Views",
                id: "profile_views",
                color: "rgba(107, 169, 226, 0.2)",
                border: "#3D76BB",
            },
            page_fans: {
                title: "Page Fans",
                id: "page_fans",
                color: "rgba(118, 182, 238, 0.2)",
                border: "#5291C7",
            },
            page_consumptions: {
                title: "Page Consumptions",
                id: "page_consumptions",
                color: "rgba(132, 136, 255, 0.2)",
                border: "#4A8BFF",
            },
            // page_impressions_unique: {
            //     title: "Page Impressions Unique",
            //     id: "page_impressions_unique",
            //     color: "rgba(116, 225, 238, 0.2)",
            //     border: "#4CC9D7",
            // },
            page_impressions_paid: {
                title: "Page Impressions Unique",
                id: "page_impressions_paid",
                color: "rgba(194, 210, 230, 0.2)",
                border: "#9CB2CE",
            },
            page_post_engagements: {
                title: "Page Impressions Unique",
                id: "page_post_engagements",
                color: "rgba(180, 212, 228, 0.2)",
                border: "#83A9BC",
            },
        },
        instagram: {
            impressions: {
                title: "Impressions",
                id: "impressions",
                color: "rgba(164, 113, 174, 0.2)",
                border: "#925A9D",
            },
            reach: {
                title: "Reach",
                id: "reach",
                color: "rgba(218, 185, 246, 0.2)",
                border: "#C798EE",
            },
            profile_views: {
                title: "Profile Views",
                id: "profile_views",
                color: "rgba(225, 115, 177, 0.2)",
                border: "#C75596",
            },
            follower_count: {
                title: "Follower Count",
                id: "follower_count",
                color: "rgba(225, 115, 177, 0.2)",
                border: "#C75596",
            },
        },
        linkedin: {
            clickCount: {
                title: "Careers page banner promo clicks",
                id: "clickCount",
                color: "rgba(66, 168, 220, 0.2)",
                border: "#0077B5",
            },
            commentCount: {
                title: "Careers page banner promo clicks",
                id: "commentCount",
                color: "rgba(70, 178, 210, 0.2)",
                border: "#0077B5",
            },
            engagement: {
                title: "Careers page job clicks",
                id: "engagement",
                color: "rgba(113, 125, 138, 0.2)",
                border: "#3C4856",
            },
            impressionCount: {
                title: "Unique page views",
                id: "impressionCount",
                color: "rgba(113, 125, 138, 0.2)",
                border: "#3C4856",
            },
            allPageViews: {
                title: "Careers page promo links clicks",
                id: "allPageViews",
                color: "rgba(113, 125, 138, 0.2)",
                border: "#3C4856",
            },
            overviewPageViews: {
                title: "Careers page employees clicks",
                id: "overviewPageViews",
                color: "rgba(119, 159, 225, 0.2)",
                border: "#4773BC",
            },
            careersPageBannerPromoClicks: {
                title: "Page Views",
                id: "careersPageBannerPromoClicks",
                color: "rgba(113, 125, 138, 0.2)",
                border: "#3C4856",
            },
            careersPagePromoLinksClicks: {
                title: "Page Views",
                id: "careersPagePromoLinksClicks",
                color: "rgba(110, 120, 145, 0.2)",
                border: "#3C4856",
            },
        },
    },
    supportedPostMetrics: {
        instagram: [
            // "engagement",
            "impressions",
            "reach",
            "saved",
            // "video_views"
        ],
        facebook: [
            "post_impressions",
            "post_clicks",
            "post_reactions_by_type_total",
            "post_engaged_users",
            // "post_video_views",
        ],
        linkedin: [
            "uniqueImpressionsCount",
            "shareCount",
            "engagement",
            "clickCount",
            "likeCount",
            "impressionCount",
            "commentCount",
        ],
    },
    periodAnalytics: [],
    consolidatedPeriodAnalytics: [],
}

const analyticsReducer = (state = initialState, action) => {
    try {
        switch (action.type) {
            case SET_ACTIVE_NAV:
                return setActiveNav(state, action.payload)
            case SET_PERIOD_ANALYTICS:
                return setPeriodAnalytics(state, action.payload)
            case SET_CONSOLIDATED_PERIOD_ANALYTICS:
                return setConsolidatedPeriodAnalytics(state, action.payload)
            default:
                return state
        }
    } catch (err) {
        console.log(err)
    }
}

const setActiveNav = (state, payload) => {
    return {
        ...state,
        activeNav: payload,
    }
}
const setPeriodAnalytics = (state, payload) => {
    return {
        ...state,
        periodAnalytics: payload[0],
        consolidatedPeriodAnalytics: payload[1],
    }
}
const setConsolidatedPeriodAnalytics = (state, payload) => {
    return {
        ...state,
        periodAnalytics: null,
        consolidatedPeriodAnalytics: payload,
    }
}

export default analyticsReducer
