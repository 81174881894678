import Axios from "axios"
import store from "../store"
import { getPermission } from "../components/apis"
import { createMessage } from "./messageAction"

import {
    PERMISSION_FAILED,
    PERMISSION_LOADING,
    PERMISSION_SUCCESS,
    UPDATE_PERMISSIONS,
    PERMISSION_FAILED_INTERNAL_AUTH,
    USER_PERMISSIONS_SUCCESS,
    USER_PERMISSIONS_FAIL,
    LOAD_USER_PERMISSIONS,
} from "../ActionTypes/permissionActionType"

import { ADD_GROUP } from "../ActionTypes/workplaceType"
import { getHeaders } from "./userAction"
import { getRolesFromPermissions } from "./roleAction"

const { dispatch, getState } = store

export const getPermissions = async () => {
    const { permissions } = getState().permissionsReducer

    if (permissions && Object.keys(permissions).length > 0) {
        dispatch({ type: PERMISSION_SUCCESS })
        return
    }

    dispatch({ type: PERMISSION_LOADING })

    try {
        let headers = getHeaders()
        headers.params = {
            // product_id: 1,
        }

        let res = await Axios.get(getPermission, headers)
        if (res.data.code === 200) {
            let permissions = {}
            let roles = []

            res.data.model.roles.forEach(({ roleId, roleName, permission }) => {
                let name = roleName?.toUpperCase()
                roles.push({ id: roleId, rolename: name })
                permissions[roleId] = {}
                permission.forEach((per) => {
                    const { Enable, Id, RoleId } = per
                    permissions[roleId][per.ActionId.Action] = { Enable, Id, RoleId }
                })
            })
            getRolesFromPermissions(roles)
            dispatch({
                type: PERMISSION_SUCCESS,
                payload: {
                    permissions,
                },
            })
        } else {
            dispatch({ type: PERMISSION_FAILED_INTERNAL_AUTH })
            createMessage("danger", res.data.msg)
        }
    } catch (err) {
        if (err.response) {
            dispatch({ type: PERMISSION_FAILED })
            dispatch({
                type: ADD_GROUP,
                payload: {
                    groups: [],
                },
            })
            createMessage("danger", err.response.data)
        }
    }
}

export const updatePermission = async (id, company_id) => {
    try {
        const token = localStorage.getItem("auth-token")
        const result = await Axios.put(`${getPermission}?id=${id}`, null, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })

        if (result.data.code === 200) {
            dispatch({
                type: UPDATE_PERMISSIONS,
                payload: { company_id: company_id, data: result.data.model },
            })
        } else {
            createMessage("danger", result.data.msg)
        }
    } catch (error) {
        if (error.response) {
            createMessage("danger", error.response.data)
        }
        createMessage("danger", "Something went wrong.")
    }
}

export const getUserPermission = async (id) => {
    dispatch({ type: LOAD_USER_PERMISSIONS })

    try {
        const token = getState().userReducer.token
        const result = await Axios.get(`${getPermission}?id=${1}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })

        if (result.data.code === 200) {
            dispatch({
                type: USER_PERMISSIONS_SUCCESS,
                payload: { permissions: result.data.model },
            })
        } else {
            dispatch({ type: USER_PERMISSIONS_FAIL })
            createMessage("danger", result.data.msg)
        }
    } catch (error) {
        if (error.response) {
            createMessage("danger", error.response.data)
        }
        createMessage("danger", "Something went wrong.")
    }
}
