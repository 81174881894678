export const CHANGE_SOCIAL_VIEW = "CHANGE_SOCIAL_VIEW"
export const CHANGE_SOCIAL = "CHANGE_SOCIAL"
export const CHANGE_ACTIVE_SOCIAL_BRAND = "CHANGE_ACTIVE_SOCIAL_BRAND"
export const CHANGE_POST_FILTER_STATUS = "CHANGE_POST_FILTER_STATUS"
export const CLEAR_EVERYTHING = "CLEAR_EVERYTHING"
export const SOCIAL_LOADING = "SOCIAL_LOADING"
export const SOCIAL_FAILED = "SOCIAL_FAILED"
export const UPDATE_COMPANY = "UPDATE_COMPANY"
export const UPDATE_URL = "UPDATE_URL"
export const OPEN_SOCIAL_CONNECTOR = "OPEN_SOCIAL_CONNECTOR"
export const OPEN_SOCIAL_MODEL = "OPEN_SOCIAL_MODEL"
export const CLOSE_SOCIAL_CONNECTOR = "CLOSE_SOCIAL_CONNECTOR"

// FOR GET SOCIAL PROFILE DETAILS
export const SOCIAL_PROFILE_DETAILS_LOAD = "SOCIAL_PROFILE_DETAILS_LOAD"
export const SOCIAL_PROFILE_DETAILS_SUCCESS = "SOCIAL_PROFILE_DETAILS_SUCCESS"
export const SOCIAL_PROFILE_DETAILS_FAILED = "SOCIAL_PROFILE_DETAILS_FAILED"
export const REMOVE_SOCIAL_PROFILE = "REMOVE_SOCIAL_PROFILE"
export const SOCIAL_CREATE_POST = "SOCIAL_CREATE_POST"
export const SOCIAL_CREATE_POST_CLOSE = "SOCIAL_CREATE_POST_CLOSE"
export const UPDATE_BRANDS_SOCIAL_CHANNELS = "UPDATE_BRANDS_SOCIAL_CHANNELS"
export const UPDATE_SINGLE_BRAND_SOCIAL_CHANNELS =
    "UPDATE_SINGLE_BRAND_SOCIAL_CHANNELS"
export const ASSOCIATE_SOCIAL_PAGE_WITH_BRAND = "ASSOCIATE_SOCIAL_PAGE_WITH_BRAND"
export const DISABLE_SOCIAL_LOGIN_IN_ACTIVE_BRAND =
    "DISABLE_SOCIAL_LOGIN_IN_ACTIVE_BRAND"
export const RESET_SOCIAL_STATE = "RESET_SOCIAL_STATE"
export const SOCIAL_PROFILE_DETAILS_LOADING = "SOCIAL_PROFILE_DETAILS_LOADING"
export const CHANGE_SOCIAL_SUBTYPE = "CHANGE_SOCIAL_SUBTYPE"
export const TOGGLE_PINTEREST_CREATE_BOARD = "TOGGLE_PINTEREST_CREATE_BOARD"
export const SET_CAPTIONS = "SET_CAPTIONS"

export const COMPANIES_LOADING = "COMPANIES_LOADING"
export const PROFILE_DETAILS_LOADING = "PROFILE_DETAILS_LOADING"
export const SET_ENABLED_FEATURES = "SET_ENABLED_FEATURES"
export const SET_GRID_VIEW = "SET_GRID_VIEW"
export const SET_PAGES = "SET_PAGES"

export const SET_ACTIVE_SETTINGS ="SET_ACTIVE_SETTINGS"
