import React from "react"
import ResourceCenterCard from "../ResourceCenterCard/ResourceCenterCard"
import "./ResourceCenterContainer.scss"

/**
 * Resource Center Container Used In HelpSideNavbar Component
 * @param {[{name:"", image?: "", description:""}]} resources
 * @param {*?} [color] text color
 * @param {*?} [backgroundColor] Background Color
 *
 */

const ResourceCenterContainer = ({ resources, color }) => {
    return (
        <div className="resource-center-container">
            <p style={{ color: color }}>Resource Center</p>
            {resources.map((resource, index) => (
                <a href={resource.link} target="_blank" rel="noopener noreferrer">
                    <ResourceCenterCard key={index} {...resource} />
                </a>
            ))}
        </div>
    )
}

export default ResourceCenterContainer
