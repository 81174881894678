import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { createMessage } from "../../../../Actions/messageAction"
import Axios from "axios"
import { getAllActivityForGivenPost } from "../../../apis"
import Loader from "../../../commonComps/Loaders"
import LogStrip from "./LogStrip"
import "./ActivityLog.scss"
import NoNotificationContainer from "../../NotificationBar/NoNotificationContainer"

const ActivityLog = ({ post, token, userImageMapping }) => {
    const _C = "aui-activity-log"
    const [logs, setLogs] = useState(null)

    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    }

    useEffect(() => {
        loadActivitiesOnPost()
    }, [])

    async function loadActivitiesOnPost() {
        try {
            /**Todo: This is currently for brand */
            const res = await Axios.get(getAllActivityForGivenPost, {
                params: {
                    action: "post",
                    related_id: post.id,
                    product_id: 1,
                },
                headers,
            })

            if (res.data.model) {
                let newRes = []
                for (let i = 0; i < res.data.model.length; i++) {
                    const log = {
                        ...res.data.model[i],
                        image: userImageMapping[res.data.model[i].emailId]?.picture,
                    }
                    newRes.push(log)
                }
                setLogs(newRes)
            } else {
                createMessage("danger", res.data.msg)
            }
        } catch (err) {
            createMessage("danger", "Activities cannot be fetched")
        }
    }

    return (
        <div className={_C}>
            <div className={_C + "-head"}>Activity Log</div>
            <div className={_C + "-wrapper"}>
                {!logs ? (
                    <Loader
                        h="1.5rem"
                        w="1.5rem"
                        b="3px"
                        bk="#eaf0f6"
                        clr="#D6DFE8"
                        t="0.7s"
                    />
                ) : logs.length === 0 ? (
                    <NoNotificationContainer msg="No Activity for the post" />
                ) : (
                    logs.map((log, i) => <LogStrip key={i} {...log} />)
                )}
            </div>
        </div>
    )
}

const mapStateToProps = ({ userReducer, postReducer }) => {
    return {
        post: postReducer.post,
        token: userReducer.token,
        userImageMapping: userReducer.workplaceUsers[postReducer.companyId],
    }
}

export default connect(mapStateToProps)(ActivityLog)
