export default {
    accountsLink: "https://my.awesomesuite.com",
    heyBioLink: "https://hey.bio",
    baseURLAuthAndCompany:
        "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/workspace-service",
    baseURL:
        "https://g56270hogd.execute-api.ap-south-1.amazonaws.com/prod/social-service",
    newAuthService:
        "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/user-management-service",
    workflow:
        "https://g56270hogd.execute-api.ap-south-1.amazonaws.com/prod/social-service/workflow",
    minifyUrl:
        "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/urlshorter-service/create",
    notificationApi:
        "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/notification-service/notification",
    notificationSettingApi:
        "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/workspace-service/user/config",
    collaborationCommentUrl:
        "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/comment-service/comment",
    collaborationTaskUrl:
        "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/workflow-service/task",
    activityURL:
        "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/activity-service/activity",
    mediaApi:
        "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/media-service",
    bulkApi:
        "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/batching-service/request",
    bitly: "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/bitly",
    analyticsApi:
        "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/activity-service/activity",
    subscriptionApi:
        "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/subscription-service",
    plansApi:
        "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/subscription-service/v2/subscription",
    heyBioUrl : 
        "https://g56270hogd.execute-api.ap-south-1.amazonaws.com/prod/hey-bio/hey",
    clarityKey : process.env.REACT_APP_MS_CLARITY
}
