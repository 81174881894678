import React from "react"
import Tooltip from "../ToolTip"

import "./tagIcon.scss"

/**
 *
 * @param {String} color : color of tag
 * @param {boolean} border : Add border to TagIcon
 * @param {String} borderClr : BOrder color
 * @param {String} bgClr : Background color of tag
 * @param {Boolean} small : is tag small
 * @param {Function} click : Do something on tag click
 * @param {Boolean} toggle : is this tag lead to some toggle
 * @param {Boolean} dashed : is the border of tag dashed
 *
 */

const TagIcon = ({
    color,
    border,
    borderClr,
    bgClr,
    small,
    click,
    toggle,
    dashed,
    tooltip,
    background,
    zIndexVal,
}) => {
    const c = "aui-tag-icon"
    return (
        <div
            onClick={click}
            className={c}
            style={{
                color: color ?? "#99acc2",
                border: border ? `1px ${dashed ? "dashed" : "solid"} #D6DFE8` : "",
                borderColor: borderClr ?? "#D6DFE8",
                width: !small ? "2.25rem" : "1.75rem",
                height: !small ? "2.25rem" : "1.75rem",
                zIndex: zIndexVal ? zIndexVal : "unset",
                background,
            }}
        >
            <div
                className={c + "-dot"}
                style={{
                    height: "2px",
                    width: "2px",
                    borderRadius: "50%",
                    backgroundColor: "white",
                    position: "absolute",
                    top: "50%",
                    left: "30%",
                    transform: "translate(-50%,-50%)",
                    zIndex: "2",
                }}
            ></div>
            {!toggle ? (
                <div
                    style={{
                        position: "absolute",
                        width: !small ? "1.6rem" : "1.2rem",
                        height: !small ? "0.75rem" : "0.65rem",
                        clipPath:
                            "polygon(20% 0, 100% 0, 100% 100%, 20% 100%, 0 50%)",
                        transform: "translate(-50%, -50%)",
                        left: "50%",
                        top: "50%",
                        backgroundColor: bgClr ?? "#99acc2",
                    }}
                    className={dashed && "tagIcon"}
                ></div>
            ) : (
                <span style={{ fontSize: "1.5rem" }}>+</span>
            )}
        </div>
    )
}

export default TagIcon
