import React, { useState, useEffect, useRef } from "react"
import "./FileUploader.scss"
import { ReactComponent as UploadIcon } from "../../icons/UploadDocument.svg"
import mediaHandler from "../../Helper/mediaHandler/mediaHandler"
import FilePreviewBar from "../FilePreviewBar"
import { createMessage } from "Actions/messageAction"
import { connect } from "react-redux"

function validateFileSize(file) {
    if (file.size > 20971520) return false // 20mb
    return true
}

/**
 *
 * @param {Function} addFiles Add files
 * @param {Function} [onDelete] on delete files
 * @example onDelete(index, nameOfFile)
 * @param {String} mediaApi Media Api/URL
 * @param {Boolean} preview Show preview of uploaded files
 * @param {String} customClass
 * @param  {Ref} [triggerRef] passed ref
 */

const FileUploader = ({
    files,
    addFiles,
    onDelete,
    mediaApi,
    preview,
    triggerRef,
    currentCompanyId,
    setLoading,
    invokeByLinkedin,
    postType
}) => {
    const _C = "aui-file-uploader"
    const [fileOver, setFileOver] = useState(false)
    const inputRef = triggerRef ?? useRef(null)
    const mediaController = new mediaHandler(mediaApi, currentCompanyId)
    const [fileCache, doCache] = useState([])
    const [validatingParameters, setValidatingParameters] = useState([]);

    useEffect(() => {
        let index = -1
        if (!files) return
        for (let i = 0; i < files.length; i++) {
            for (let j = 0; j < fileCache.length; j++) {
                if (fileCache[j].id === files[i].id) {
                    index = j
                    break
                }
            }
            if (index !== -1) {
                const newCache = [...fileCache].splice(index + 1, 1)
                doCache([...newCache])
                break
            }
        }
    }, [files])

    useEffect(() => {
        if (postType === "reel") {
            setValidatingParameters(["video/mp4", "video/ogg", "video/webm"])
        } else {
            setValidatingParameters([
                "image/jpg",
                "image/jpeg",
                "image/png",
                "image/x-icon",
                "image/gif",
                "image/svg",
                "image/svg+xml",
                "image/webp",
                "video/mp4",
                "video/ogg",
                "video/webm",
            ])
        }
    }, [postType])

    useEffect(() => {
        if (invokeByLinkedin) {
            setValidatingParameters(["application/pdf"])
        }
    }, [invokeByLinkedin])

    function uploadClick() {
        inputRef.current.click()
    }

    function onFileEnter(event) {
        event.preventDefault()
    }
    function onFileDragOver(event) {
        event.preventDefault()
        if (!fileOver) setFileOver(true)
    }
    function onFileDrop(event) {
        event.preventDefault()
        handleFiles(event.dataTransfer.files)
        setFileOver(false)
    }

    function onFileLeave(event) {
        event.preventDefault()
        setFileOver(false)
    }

    function validateFile(file) {
        if (validatingParameters.includes(file.type)) return true
        return false
    }

    async function handleFiles(listFiles) {
        await new Promise(async (resolve) => {
            const validFiles = [...listFiles].filter((file) => {
                if (validateFile(file)) {
                    if (!validateFileSize(file)) {
                        createMessage("danger", "File size is very big.")
                        return false
                    }
                    return true
                } else {
                    createMessage("danger", "File Format is not valid.")
                    return false
                }
            })
            const allFiles = []
            if (postType !== "reel") {
                for (let i = 0; i < validFiles.length;) {
                    const file = validFiles[i]
                    const id = i + "_" + file.name
                    const curFile = {
                        id,
                        name: file.name,
                        file: file,
                        preview: URL.createObjectURL(file),
                        size: file.size,
                    }
                    allFiles.push(curFile)
                    i++

                    if (i === validFiles.length) doCache([...allFiles])
                }
            } else {
                if (validFiles.length > 1) {
                    createMessage("danger", "Only one video is allowed.")
                    return
                } else {
                    const file = validFiles[0]
                    const id = 0 + "_" + file.name
                    const curFile = {
                        id,
                        name: file.name,
                        file: file,
                        preview: URL.createObjectURL(file),
                        size: file.size,
                    }
                    allFiles.push(curFile)
                    doCache([...allFiles])
                }
            }
            setLoading(true)
            let resp = await Promise.all(
                allFiles.map(async ({ file }) => await mediaController.uploadMedia(file, true))
            )
            setLoading(false)
            resp && resp.length && resp.forEach((item, index) => {
                if (!item) return
                const file = validFiles[index]
                const id = index + "_" + file.name
                const curFile = {
                    ...item,
                    id,
                    name: file.name,
                    file: file,
                    preview: URL.createObjectURL(file),
                    size: file.size,
                    postType
                }
                addFiles(curFile)
            })
            resolve()
        })
    }

    return (
        <div
            className={`${_C}-wrapper scroll-modern ${fileOver ? _C + "-wrapper-hover" : ""
                } ${preview ? _C + "-wrapper-preview" : ""}`}
        >
            <div
                className={`${_C} ${fileOver ? _C + "-hover" : ""}`}
                onClick={uploadClick}
                onDragOver={onFileDragOver}
                onDragEnter={onFileEnter}
                onDrop={onFileDrop}
                onDragLeave={onFileLeave}
            >
                <div className="upload-icon">
                    <div>
                        <UploadIcon />
                    </div>
                    <div>Upload Media</div>
                </div>
                <input
                    type="file"
                    onChange={(e) => {
                        handleFiles(e.target.files)
                    }}
                    accept={validatingParameters.join(", ")}
                    hidden
                    multiple={invokeByLinkedin ? false : true}
                    ref={inputRef}
                />
            </div>
            {preview && (
                <FilePreviewBar
                    files={files}
                    fileCache={fileCache}
                    deleteFile={onDelete}
                />
            )}
        </div>
    )
}

const mapStatesToProps = ({ workplaceReducer }) => {
    return {
        currentCompanyId: workplaceReducer.currentCompanyId,

    }
}

export default connect(mapStatesToProps)(FileUploader)
