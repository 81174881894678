import React from "react"
import SideDrawer from "../SideDrawer"
import HelpSideNavbar from "../HelpSideNavbar"
import { connect } from "react-redux"
import { toggleHelp } from "Actions/messageAction"
import image from "../../icons/social help.svg"

/**
 *
 * @param {[{name:"", description:""}]} resources
 * @param {Boolean} helpOpen Help Content Bar is Open
 * @param {Function} toggleHelp To toggle help content bar
 * @param {Link?} [image] Background optional Image
 * @param {[String]} supportContent Array of support contents
 * @param {*?} [color] text colors
 * @param {*?} [backgroundColor] Background Colors
 */
const HelpContent = ({
    resources,
    helpOpen,
    supportContent,
}) => {
    const windowWidth = window.innerWidth;
    let color="white"
    let backgroundColor="#687FEB"
    return (
        <>
            {helpOpen && (
                <SideDrawer
                    vis={helpOpen}
                    position="right"
                    width={windowWidth <= 480 ? "300px" : "480px"}
                    toggle={() => toggleHelp(false)}
                >
                    <HelpSideNavbar
                        resources={resources}
                        helpOpen={helpOpen}
                        toggleHelp={toggleHelp}
                        image={image}
                        color={color}
                        backgroundColor={backgroundColor}
                        supportContent={supportContent}
                    />
                </SideDrawer>
            )}
        </>
    )
}

const mapStatesToProps = ({messageReducer, }) => {
    return {
        helpOpen: messageReducer.helpOpen,
        resources: messageReducer.resources,
        supportContent: messageReducer.supportContent,
    }
}

export default connect(mapStatesToProps)(HelpContent)
