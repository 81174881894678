import React from "react"
import { createUseStyles } from "react-jss"
// import { ReactComponent as Like } from "../../../../components/icons/twetterIcons/twitter-like.svg"
// import { ReactComponent as Comment } from "../../../../components/icons/twetterIcons/twitter-comment.svg"
// import { ReactComponent as ReTweet } from "../../../../components/icons/twetterIcons/twitter-retweet.svg"
import { ReactComponent as Bin } from "../../../../components/icons/bin.svg"
import { ReactComponent as Duplicate } from "../../../../components/icons/duplicate.svg"
import { ReactComponent as Star } from "./Star.svg"
import {
    openSocialPostManager,
    deletePost,
    clearPosts,
    resetPage,
} from "Actions/postAction"
import { useTracking } from "react-tracking"
import { CLICK, SOCIAL } from "../../../../constants"
import ThreeDotOptionDropdown from "components/Utilities/ThreeDotOptionsDropdown"
import { hashtagParser } from "../../../../hooks/msgParser"
import { consumePlan } from "Actions/transactionAction"
import { CONSUME_EVENT } from "ActionTypes/transactionActionTypes"
import { useState } from "react"

const useStyles = createUseStyles({
    pinterestPreviewPost: {
        maxWidth: "500px",
        minWidth: "150px",
        margin: "1rem auto",
        position: "relative",
        backgroundColor: "white",
        padding: "10px",
        cursor: "pointer",
        boxShadow: "0px 0px 4px rgba(200, 200, 200, 0.8)",
    },
    pinterestPreviewImage: {
        width: "100%",
        borderRadius: "20px",
        maxWidth: "500px",
        maxHeight: "800px",
        objectFit: "cover",
    },
    pinterestPostMiddle: {
        display: "flex",
        alignItems: "center",
        marginTop: "0.5rem",
    },
    pinTitle: {
        fontSize: "1rem",
        color: "#092f42",
        marginRight: "5px",
        lineHeight: "19px",
        fontWeight: "600",
        textAlign: "left",
    },
    icons: {
        marginLeft: "auto",
        marginBottom: "auto",
    },
    pinterestPostBottom: {
        display: "flex",
        alignItems: "center",
    },
    userDetails: {
        display: "flex",
        marginTop: "0.5rem",
        alignItems: "center",
    },
    icon: {
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        "& img": {
            borderRadius: "50%",
            height: "100%",
            width: "100%",
            objectFit: "cover",
        },
    },
    name: {
        fontSize: "1rem",
        color: "#092f42",
        margin: "0 5px",
        lineHeight: "19px",
        fontWeight: "400",
    },
    threeDot: {
        borderRadius: "50%",
        marginLeft: "auto",

        "& #dot-icon": {
            width: " 0.25rem",
        },
    },
    readMore: {
        color: "#99acc2",
        padding: "0 12px 12px 12px",
        textAlign: "left",
        fontWeight: "500",
    }
})

/**
 * @description Preview component for twitter post
 * @param {String} createdBy
 * @param {String} handleName Social handle / username
 * @param {Time} time
 * @param {String} text : Post text
 * @param {JSX} icon : Social icon of creator
 * @param {Link} profilePic : Platform profilePic
 * @param {Files} files
 * @param {Boolean} showStats to show like, share, etc icons
 */
const PinterestPost = ({
    createdBy,
    openPost,
    text: rawText,
    time,
    profilePic,
    files,
    stat,
    isOld,
    path,
    status,
    postStatus,
    statusColor,
    handleName,
    mediaUrl,
    icon,
    showStats,
    post,
    creator,
    companyId,
    brandId,
    editPost = false,
    ...props
}) => {
    const { trackEvent: track } = useTracking()
    const [readMore, setReadMore] = useState(false)
    const classes = useStyles()

    const getOptions = (post, creator, postStatus, social) => {
        let arr = [
            {
                name: "Duplicate",
                onClick: () => {
                    if (!companyId || (!post.brandId && !brandId)) return
                    if (!consumePlan(CONSUME_EVENT, 1, true)) return
                    openSocialPostManager(
                        "create",
                        0,
                        companyId,
                        brandId || post.brandId,
                        {
                            text: post.text || post.title,
                            postType: post.postType,
                            mediaContent: post.mediaContent.length ? post.mediaContent : [{ mediaUrl: post.mediaUrl }],
                        },
                        social,
                        creator
                    )
                },
                icon: <Duplicate />,
            },
        ]
        if (postStatus !== "publish")
            arr.push({
                name: "Delete",
                onClick: async () => {
                    const success = await deletePost(social, post.id, post.brandId)
                    if (success) {
                        clearPosts()
                        resetPage()
                    }
                },
                icon: <Bin />,
                style: {
                    color: "#F2545B",
                },
            })
        return arr
    }
    return (
        <div
            className={classes.pinterestPreviewPost}
            onClick={() => {
                openPost && openPost(props.id)
            }}
        >
            {files && files.length ? (
                <img
                    className={classes.pinterestPreviewImage}
                    src={files[0].mediaUrl ?? files[0]?.preview ?? files[0].src}
                    alt="Post"
                />
            ) : (
                <></>
            )}
            <div className={classes.pinterestPostMiddle}>
                <div>
                    <div
                        className={classes.pinTitle}
                        dangerouslySetInnerHTML={{
                            __html: hashtagParser(
                                readMore
                                    ? post?.title
                                    : post?.title?.substring(0, 150)
                            ),
                        }}
                    ></div>
                    {!readMore && post?.title?.length > 150 && (
                        <div
                            onClick={(e) => {
                                setReadMore(true)
                                e.stopPropagation()
                            }}
                            className={classes.readMore}
                        >
                            ...Read More
                        </div>
                    )}
                </div>
                <div className={classes.icons}>
                    <Star />
                </div>
            </div>
            <div className={classes.pinterestPostBottom}>
                <div className={classes.userDetails}>
                    <div className={classes.icon}>
                        <img
                            src={
                                profilePic && profilePic.length
                                    ? profilePic
                                    : require("../../../../components/icons/no_profile_pic/twitter_no_profile_pic.png")
                            }
                            alt={createdBy}
                        />
                    </div>
                    <div className={classes.name}>{createdBy}</div>
                </div>
                {editPost && (
                    <div className={classes.threeDot}>
                        <ThreeDotOptionDropdown
                            onClick={(e) => {
                                track({
                                    type: CLICK,
                                    title: "post drop down open",
                                    category: SOCIAL,
                                })
                                e.stopPropagation()
                            }}
                            options={getOptions(
                                post,
                                creator,
                                postStatus,
                                "pinterest"
                            )}
                            idName="postOption"
                            enableIcon
                        />
                    </div>
                )}
            </div>
            {icon}
        </div>
    )
}

export default React.memo(PinterestPost)
