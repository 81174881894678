//tracking types
export const PAGE_VIEW = "PAGE_VIEW"
export const CLICK = "CLICK"
export const NAV_CLICK = "NAV_CLICK"
export const PRODUCT_TRY = "PRODUCT_TRY"
export const APP_REDIRECT = "APP_REDIRECT"
export const LOGIN = "LOGIN"
export const BRAND = "BRAND"
export const SOCIAL = "social"
export const SETTINGS = "SETTINGS"
export const PUBLISH = "PUBLISH"
export const VIEW_CHANGE = "VIEW_CHANGE"
export const SOCIAL_CONNECT = "SOCIAL_CONNECT"
export const SOCIAL_POST = "SOCIAL_POST"

// Hey Bio
export const BACKGROUND = "BACKGROUND"
export const FONT_FAMILY = "FONT_FAMILY"
export const LINK = "LINK"
export const THEME = "THEME"
export const BANNER = "BANNER"
export const SOCIALS = "SOCIALS"
export const SANS_SERIF_FONTS = "SANS_SERIF_FONTS"
export const SERIF_FONTS = "SERIF_FONTS"
export const LINKS = "LINKS";
export const POSTS = "POSTS";
export const SOCIAL_LINK = "SOCIAL_LINK";
export const EMBEDDED_CONTENT="EMBEDDED_CONTENT"
export const PROPERTIES = "PROPERTIES"

export const OWNER = "owner";
export const ANY_ROLE = "ANY_ROLE";

// login challenges
export const REGISTER = "REGISTER";
export const EMAIL_OTP = "EMAIL_OTP";
export const PASSWORD = "PASSWORD";
export const REGISTER_ORG = "REGISTER_ORG";


export const CRITICAL = "critical"
export const WARNING = "warning"
export const SUCCESS = "success"

export const DATE = "DATE"
export const CREATOR = "CREATOR"
export const TAG = "TAG"
export const VIEW_POST = "VIEW POST"
export const COLLABORATE = "COLLABORATE"
export const APPROVAL = "APPROVAL"
export const PERFORMANCE = "PERFORMANCE"
export const DISCUSS = "DISCUSS"

export const USER = "USER"
export const CREATE_BRAND = "Create Brand"
//Engage
export const POP = "POP"
export const PUSH = "PUSH"
export const CLEAR = "CLEAR"

// Socials
export const TWITTER_USER = "twitter_user"
export const INSTAGRAM_PAGE = "instagram_page"
export const FACEBOOK_PAGE = "facebook_page"
export const FACEBOOK_GROUP = "facebook_group"
export const LINKEDIN_USER = "linkedin_user"
export const LINKEDIN_PAGE = "linkedin_page"
export const PINTEREST_USER = "pinterest_user"