import React from "react"
import "./nav.scss"
import {connect} from "react-redux"

const Nav = ({ currentTab, selectTab , invokeByLinkedin}) => {
    const _C = "aui-media-selection-dialog-nav"
    const tabs = []

    const valueToTabs=(arr)=>{
        if (invokeByLinkedin){
            arr.push("Uploads")
        }else {
            arr.push("Uploads", "Photos", "GIFs")
        }
    }
    valueToTabs(tabs)
    return (
        <div className={_C}>
            {tabs.map((tab, index) => (
                <div
                    onClick={() => selectTab(index)}
                    className={`${_C}-tab ${
                        index === currentTab ? _C + "-tab-active" : ""
                    }`}
                    key={index}
                >
                    {tab}
                </div>
            ))}
        </div>
    )
}
const mapStateToProps =({postReducer})=>{
    return {
        selectedChannelToView: postReducer.selectedChannelToView,
        channelsToPostOn: postReducer.channelsToPostOn,
    }
}

export default connect(mapStateToProps)(Nav)
