import React from "react"
import "./sliderDots.scss"

const SliderDots = ({ totalDots, currentDot }) => {
    const _C = "slider_dots"
    if (totalDots <= 1) return null
    return (
        <div className={_C}>
            {[...new Array(totalDots ?? 1)].map((_, i) => (
                <span
                    className={
                        _C + "-dot " + (currentDot === i ? _C + "-dot-active" : "")
                    }
                    key={i}
                ></span>
            ))}
        </div>
    )
}

export default SliderDots
