import React, { useState, useEffect } from "react"
import { createUseStyles } from "react-jss"
import "./PinDropDown.scss"
import { getPinterestBoards } from "../../Helper/SocialPostHandler/pinterest"
import { togglePinterestCreateBoard } from "Actions/socialAction"
import { ReactComponent as Right } from "../../icons/NotificationIcons/right-arrow.svg"
import { DataLoader } from "@awesomesuite-frontend/awesome-nebula"

const useStyles = createUseStyles({
    createBoard: (props) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "left",
        padding: "0 15px",
        height: "35px",

        "&:hover": {
            backgroundColor: "#e5f5f837",
        },
    }),
    boardInput: (props) => ({
        // border: "none",
        outline: "none",
        width: "100%",
        height: "2rem",
        paddingTop: "0.25rem",
    }),
    active: {
        backgroundColor: "#E5F5F8",

        "&:hover": {
            backgroundColor: "#E5F5F8 !important",
        },
    },
})

const dropdownContent = {
    dropdownContent: [
        {
            name: "Public",
            id: "PUBLIC",
        },
        {
            name: "Private",
            id: "PRIVATE",
        },
    ],
}

/**
 * Drop down component for the Pin boards
 */
const PinDropDown = ({ brandId, value, onChangeHandler, pinterestBoardModal }) => {
    const [drop, setDrop] = useState(false)
    const [listOfBoards, setlistOfBoards] = useState([])
    const [loading, setLoading] = useState(false)

    const classes = useStyles()

    useEffect(() => {
        async function init() {
            setLoading(true)
            const boards = await getPinterestBoards(brandId)
            if (boards) {
                onChangeHandler(value ?? boards[0]?.id)
                setlistOfBoards(boards)
            }
            setLoading(false)
        }
        if (!pinterestBoardModal) init()
    }, [brandId, pinterestBoardModal])

    /**
     * drop down click handler
     */
    function onCickHandler(id) {
        onChangeHandler(id)
        setDrop(false)
    }

    /**
     * handle cleck out side
     */
    function handleClick() {
        setDrop(false)
        document.removeEventListener("click", handleClick)
    }

    function filterSearch(value) {
        const val = value.target.value?.toLowerCase()
        setlistOfBoards((prev) => {
            let sorted = [...prev]
            if (sorted && val) {
                sorted = sorted.sort((a, b) => {
                    if (a.name?.toLowerCase().includes(val)) return -1
                    else return 1
                })
            }
            return sorted
        })
    }

    return (
        <div className="pin-drop-w">
            <div className="boardLabel">Pinning to:</div>
            <div
                className={`pin-drop ${drop ? "pin-is-drop" : ""}`}
                onMouseLeave={() => document.addEventListener("click", handleClick)}
            >
                {loading ? (
                    <div className="createBoard">
                        <DataLoader
                            size="1rem"
                            borderWidth="2px"
                            borderBackgroundColor="#ADD8E6"
                            borderColor="	#0096FF"
                            interval="750ms"
                            style={{ margin: "auto" }}
                        />
                    </div>
                ) : listOfBoards?.length > 0 ? (
                    <div
                        className="pin-drop-select"
                        onClick={() => {
                            setDrop((prev) => !prev)
                        }}
                    >
                        <div className="pin-drop-item">
                            {/* TODO: Add this later right now it does not support images
                                <div className="pin-drop-image"> */}
                            {/* <img src="" alt="alt text" width="100%" height="100%" /> */}
                            {/* </div> */}
                            <div className="pin-title">
                                {Array.isArray(listOfBoards)
                                    ? listOfBoards.find((item) => item.id === value)
                                          ?.name
                                    : "No Boards Found"}
                            </div>
                        </div>
                        <div
                            className={`drop-arrow ${drop ? "drop-arrow-down" : ""}`}
                        >
                            <Right />
                        </div>
                    </div>
                ) : (
                    <div
                        className="createBoard"
                        onClick={() => {
                            togglePinterestCreateBoard()
                        }}
                    >
                        + &nbsp;&nbsp; Create Board
                    </div>
                )}
                {drop && (
                    <div className="pin-droper scroll-modern">
                        <div className="pin-search">
                            <input
                                className="pin-in"
                                placeholder="Search your boards"
                                onChange={(e) => {
                                    filterSearch(e)
                                }}
                            />
                        </div>
                        <div className={classes.dropdownList}>
                            {Array.isArray(listOfBoards) ? (
                                <>
                                    <div
                                        className={`${classes.createBoard} createBoard`}
                                        onClick={() => {
                                            togglePinterestCreateBoard()
                                        }}
                                    >
                                        + &nbsp;&nbsp; Create Board
                                    </div>
                                    {listOfBoards?.map((item) => (
                                        <div
                                            className={`pin-drop-item pin-div ${
                                                item.id === value && classes.active
                                            } `}
                                            onClick={() => onCickHandler(item.id)}
                                        >
                                            <div className="pin-title">
                                                {item.name}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <div className="no-pin-drop">No Boards Found</div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default PinDropDown
