import React, { useRef, useEffect, useState, useCallback } from "react"
import { GetFile } from "../../GetFileIcon/index.js"
import { ReactComponent as ArrowLeft } from "../../../icons/simpleIcons/ArrowLeft.svg"
import { ReactComponent as ArrowRight } from "../../../icons/simpleIcons/ArrowRight.svg"
import "./linkedinSlider.scss"
import SliderDots from "./SliderDots/index.js"
import { openFilePreview } from "Actions/messageAction.js"
import usePdfHook from "components/Utilities/PdfRender/PdfHook.js"
import PdfCanvas from "components/Utilities/PdfRender/PdfCanvas.js"
import VideoPlayer from "components/Utilities/VideoPlayer/index.js"

/**
 * @description Linkedin style slider
 * @param {Files[]} files
 * @param {Number} [width]
 * @param {Number} [currentIndex]
 *
 */
const LinkedinSlider = ({ files, width = 500, currentIndex, showSliderDots }) => {
    const [transform, setTransform] = useState(0)
    const scroll = useRef([])
    const customWidth = files && files.length * width
    const [isPdf, setIsPdf] = useState(false)
    const [progressValue, setProgressValue] = useState(1)
    const [pageToRender, setPageToRender] = useState()
    const _C = "aui-linkedin-slider"
    //for pdf rendering
    function getUrl() {
        const pdfFile = files.find(
            (file) =>
                file.mediaType === "application/pdf" || file.mediaType === "DOCUMENT"
        )
        return pdfFile && pdfFile.mediaPath
            ? pdfFile.mediaPath
            : pdfFile && pdfFile.mediaUrl
            ? pdfFile.mediaUrl
            : null
    }
    function getName() {
        const pdfFile = files.find(
            (file) =>
                file.mediaType === "application/pdf" || file.mediaType === "DOCUMENT"
        )
        if (pdfFile?.source === "Drive") {
            const fileName = pdfFile.mediaName
                .split(`${pdfFile.mediaId}`)[1]
                .replace("pdf", "")
            return fileName
        } else if (pdfFile?.name) {
            let filename = pdfFile ? pdfFile.name : null
            let slicedString = filename.split(".").slice(0, -1).join(".")
            return slicedString
        } else return null
    }
    const documentUrl = files && getUrl()
    const name = getName()
    var pdfRefs = React.useRef([])
    const { pdfData, loadPdf, loadPdfLib, loadedLib, numberOfPages } = usePdfHook()

    const customWidthForPdf = documentUrl && numberOfPages * width

    useEffect(() => {
        async function getPdf() {
            if (loadedLib && documentUrl) {
                await loadPdf(documentUrl)
                setIsPdf(true)
            } else loadPdfLib()
            setTransform(0)
        }

        getPdf()
    }, [loadedLib, documentUrl])

    const scrollLeft = (e) => {
        if (!e) return
        if (transform < 0) {
            setTransform((prev) => prev + width)
        }

        e.stopPropagation()
    }

    const scrollRight = (e) => {
        if (!e) return
        if (
            (customWidth > width || customWidthForPdf > width) &&
            (transform - width > -customWidth ||
                transform - width > -customWidthForPdf)
        )
            setTransform((prev) => prev - width)

        e.stopPropagation()
    }

    const handleKeyPress = useCallback(
        (event) => {
            switch (event.key) {
                case "ArrowLeft":
                    scrollLeft()
                    break
                case "ArrowRight":
                    scrollRight()
                    break
                default:
            }
        },
        [transform, scrollLeft, scrollRight]
    )
    useEffect(() => {
        window.addEventListener("keydown", handleKeyPress)
        return () => window.removeEventListener("keydown", handleKeyPress)
    }, [handleKeyPress])

    useEffect(() => {
        if (currentIndex === undefined) setTransform(0)
        else setTransform(-width * currentIndex)
        setIsPdf(false)
    }, [files])

    useEffect(() => {
        setProgressValue(-transform / width)
        setPageToRender(-transform / width)
    }, [transform])

    const handleSliderChange = (event) => {
        const newValue = parseInt(event.target.value)
        setTransform(-newValue * width)
        setProgressValue(newValue)
        setPageToRender(newValue)
    }

    function openPreview(index) {
        openFilePreview(index, files)
    }

    return files && files.length > 0 ? (
        <div className={_C}>
            {isPdf && (
                <div className="fileName">
                    &nbsp;{name}&nbsp;&#8226;<span>{numberOfPages}&nbsp;pages</span>
                </div>
            )}
            {isPdf ? (
                <div
                    style={{
                        width: `${width}px`,
                        transform: `translateX(${transform}px)`,
                        display: "flex",
                        transition: "300ms ease-in-out",
                    }}
                >
                    {Array(numberOfPages)
                        .fill()
                        .map((item, index) => (
                            <div
                                style={{
                                    width: `${width}px`,
                                    maxWidth: `${width}px`,
                                    minWidth: `${width}px`,
                                }}
                            >
                                {pageToRender === index ? (
                                    <PdfCanvas
                                        pdfRefs={pdfRefs}
                                        pdf={pdfData}
                                        index={pageToRender}
                                        pageNumber={pageToRender + 1}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        ))}
                </div>
            ) : (
                <ul
                    ref={scroll}
                    style={{
                        width: `${customWidth}px`,
                        transform: `translateX(${transform}px)`,
                    }}
                >
                    {files.map((file, index) => (
                        <li key={index} style={{ flex: `0 0 ${width}px` }}>
                            <div
                                style={{
                                    width: `${width}px`,
                                    maxWidth: `${width}px`,
                                    minWidth: `${width}px`,
                                }}
                            >
                                {file.mediaType?.toUpperCase() !== "REEL" &&
                                file.postType?.toUpperCase() !== "REEL" &&
                                file.type?.toUpperCase() !== "VIDEO" ? (
                                    <GetFile
                                        openPreview={(e) => {
                                            e.stopPropagation()
                                            openPreview(index)
                                        }}
                                        file={file}
                                        style={{}}
                                    />
                                ) : (
                                    <VideoPlayer
                                        video={{
                                            id: file.mediaId,
                                            src:
                                                file.mediaUrl ||
                                                file.mediaPath ||
                                                file.src,
                                            thumbnail: file.preview,
                                        }}
                                        play={false}
                                        mute={true}
                                    />
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            )}
            {showSliderDots && files.length && (
                <SliderDots
                    totalDots={files.length}
                    currentDot={Math.abs(transform / width)}
                />
            )}
            <div className={_C + "-scroll-controls"}>
                {(files?.length > 1 || numberOfPages > 1) && (
                    <span
                        onClick={scrollLeft}
                        style={{
                            backgroundColor: `${
                                transform < 0 ? "#111111d7" : "#20202048"
                            }`,
                        }}
                    >
                        <ArrowLeft />
                    </span>
                )}
                {(files?.length > 1 || numberOfPages > 1) && (
                    <span
                        onClick={scrollRight}
                        style={{
                            backgroundColor: `${
                                transform - width > -customWidth ||
                                transform - width > -customWidthForPdf
                                    ? "#111111d7"
                                    : "#20202048"
                            }`,
                        }}
                    >
                        <ArrowRight />
                    </span>
                )}
            </div>
            {isPdf && (
                <div className="bottombar">
                    <div className="progressCount">
                        {progressValue + 1} / {numberOfPages}
                    </div>
                    <input
                        id="slider"
                        type="range"
                        min="0"
                        max={`${
                            numberOfPages > 1 ? numberOfPages - 1 : numberOfPages
                        }`}
                        step="1"
                        value={progressValue}
                        onChange={(event) => handleSliderChange(event)}
                    />
                </div>
            )}
        </div>
    ) : (
        <></>
    )
}

export default LinkedinSlider
