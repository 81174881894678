import React from "react"
import "./HelpSupportContainer.scss"

/**
 * User Help Supporting Items Container Used In HelpSideNavbar Component
 *
 * Help Suppoert Container
 * @param {*?} [color] text color
 * @param {*?} [backgroundColor] Background Color
 * @param {[String]} supportContent Array of Support contents
 */

const HelpSupportContainer = ({ color, backgroundColor, supportContent }) => {
    return (
        <div className="help-support-container">
            <div>
                <h6 style={{ color: backgroundColor }}>Support</h6>
                <div className="supports">
                    {supportContent.map((support, index) => (
                        <a href={support.link} target="_blank" rel="noopener noreferrer">
                            <div key={index}>
                                <div
                                    className="icon"
                                    style={{ color, background: support.background }}
                                >
                                    <img src={support.icon} alt={support.title} />
                                </div>
                                <p>{support.title}</p>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default HelpSupportContainer
