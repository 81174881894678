import React from "react"
import { createUseStyles } from "react-jss"
import { ReactComponent as Like } from "../../../../components/icons/twetterIcons/twitter-like.svg"
import { ReactComponent as Comment } from "../../../../components/icons/twetterIcons/twitter-comment.svg"
import { ReactComponent as ReTweet } from "../../../../components/icons/twetterIcons/twitter-retweet.svg"
import { ReactComponent as Bin } from "../../../../components/icons/bin.svg"
import { ReactComponent as Duplicate } from "../../../../components/icons/duplicate.svg"
import {
    openSocialPostManager,
    deletePost,
    clearPosts,
    resetPage,
} from "Actions/postAction"
import { useTracking } from "react-tracking"
import { CLICK, SOCIAL } from "../../../../constants"
import ThreeDotOptionDropdown from "components/Utilities/ThreeDotOptionsDropdown"
import { formatDateIntoWord } from "../../../Helper/HandleDateAndTime"
import TwitterGridView from "../../ImageViews/TwitterGridView"
import { useParseTextForLinks, hashtagParser } from "../../../../hooks/msgParser"
import LinkThumbnail from "../../LinkThumbnail"
import noProfilePic from "../../../../components/icons/no_profile_pic/twitter_no_profile_pic.png"
import Config from "Awesome-ui/Config/Config"
import "./twitter.scss"
import { consumePlan } from "Actions/transactionAction"
import { CONSUME_EVENT } from "ActionTypes/transactionActionTypes"
import { useState } from "react"

const useStyles = createUseStyles({
    threeDot: {
        borderRadius: "50%",
        marginLeft: "auto",
    },
    handleSpecs: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "left",
        marginLeft: "0.7rem",
        marginBottom: "0.6rem",
    },
    name: {
        fontSize: "1rem",
        color: "#092f42",
        marginRight: "5px",
        lineHeight: "19px",
        fontWeight: "600",
    },
    time: {
        fontSize: "0.8rem",
        color: "rgb(101, 119, 134)",
        lineHeight: "19px",
        textAlign: "left",
        marginTop: "0.6rem",
    },
    twitterComment: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
    },
    icon: {
        width: "35px",
        height: "100%",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        "& img": {
            borderRadius: "50%",
            height: "35px",
            width: "auto",
        },
    },
    contentContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "left",
    },
    commentContent: {
        display: "inline-block",
        backgroundColor: "#F2F3F5",
        borderRadius: "5px",
        borderTopLeftRadius: "0",
        padding: "0.5rem",
        margin: "0.5rem 0.5rem 0.1rem 0.5rem",
        minWidth: "3rem",
    },
    commentUser: {
        fontWeight: "600",
        textAlign: "left",
        marginRight: "auto",
        width: "100%",
        display: "flex",
    },
    commentText: {
        fontSize: "0.9rem",
        textAlign: "left",
        fontSize: "0.8rem",
    },
    header: {
        display: "flex",
    },
    timeTag: {
        marginLeft: "5px",
    },
    commentName: {
        color: "#092f42",
        margin: "0 6px",
        fontWeight: "600",
    },
    handleName: {
        marginRight: "5px",
    },
    replyHeader: {
        textAlign: "left",
        marginLeft: "5px",
    },
    blue: {
        color: "rgb(29, 155, 240);",
    },
    comment: {
        textAlign: "left",
        marginLeft: "5px",
    },
    stats: {
        width: "100%",
        margin: "0.1rem",
        marginTop: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    stat: {
        display: "flex",
        cursor: "pointer",
        alignItems: "center",

        "& svg": {
            height: "16px",
            width: "16px",
            fill: "rgb(35, 38, 41)",

            "& .t-c": {
                fill: "rgb(35, 38, 41)",
            },
            "&: .t-l": {
                fill: "rgb(35, 38, 41)",
            },
        },
    },
})

/**
 * @description Preview component for twitter post
 * @param {String} createdBy
 * @param {String} handleName Social handle / username
 * @param {Time} time
 * @param {String} text : Post text
 * @param {JSX} icon : Social icon of creator
 * @param {Link} profilePic : Platform profilePic
 * @param {Files} files
 * @param {Boolean} showStats to show like, share, etc icons
 */
const TwitterPost = ({
    createdBy,
    openPost,
    text: rawText,
    time,
    publishDate,
    profilePic,
    files,
    stat,
    isOld,
    path,
    status,
    postStatus,
    statusColor,
    handleName,
    mediaUrl,
    icon,
    showStats,
    post,
    creator,
    companyId,
    brandId,
    editPost = false,
    firstComment,
    ...props
}) => {
    const [text, links] = useParseTextForLinks(rawText ?? "")
    const [readMore, setReadMore] = useState(false)
    const { trackEvent: track } = useTracking()
    const classes = useStyles()

    const getOptions = (post, creator, postStatus, social) => {
        let arr = [
            {
                name: "Duplicate",
                onClick: () => {
                    if (!companyId || (!post.brandId && !brandId)) return
                    if (!consumePlan(CONSUME_EVENT, 1, true)) return
                    openSocialPostManager(
                        "create",
                        0,
                        companyId,
                        brandId || post.brandId,
                        {
                            text: post.text || post.title,
                            postType: post.postType,
                            mediaContent: post.mediaContent.length ? post.mediaContent : [{ mediaUrl: post.mediaUrl }],
                        },
                        social,
                        creator
                    )
                },
                icon: <Duplicate />,
            },
        ]
        if (postStatus !== "publish") {
            arr.push({
                name: "Delete",
                onClick: async () => {
                    const success = await deletePost(social, post.id, post.brandId)
                    if (success) {
                        clearPosts()
                        resetPage()
                    }
                },
                icon: <Bin />,
                style: {
                    color: "#F2545B",
                },
            })
        }
        return arr
    }

    return (
        <div
            className="twitterPreviewPost "
            // TODO: This as well
            onClick={() => {
                openPost && openPost(props.id)
            }}
        >
            {/* TODO: No idea about this function.  */}
            {statusColor && (
                <div
                    className="status"
                    style={{
                        border: `1px solid ${statusColor}`,
                        borderRadius: "50%",
                    }}
                >
                    <div
                        style={{
                            width: "16px",
                            height: "16px",
                            backgroundColor: statusColor && statusColor,
                            borderRadius: "50%",
                        }}
                    ></div>
                </div>
            )}
            <div className="twitter-right">
                <div className="title">
                    <div className="icon">
                        <img
                            src={
                                profilePic && profilePic.length
                                    ? profilePic
                                    : noProfilePic
                            }
                            alt={createdBy}
                        />
                    </div>
                    <div className={classes.handleSpecs}>
                        <div className={classes.name}>{createdBy}</div>
                        <div>@{handleName}</div>{" "}
                    </div>
                    {editPost && (
                        <div className={classes.threeDot}>
                            <ThreeDotOptionDropdown
                                onClick={(e) => {
                                    track({
                                        type: CLICK,
                                        title: "post drop down open",
                                        category: SOCIAL,
                                    })
                                    e.stopPropagation()
                                }}
                                options={getOptions(
                                    post,
                                    creator,
                                    postStatus,
                                    "twitter"
                                )}
                                idName="postOption"
                                enableIcon
                            />
                        </div>
                    )}
                </div>

                <div
                    className="text postText"
                    dangerouslySetInnerHTML={{
                        __html: hashtagParser(
                            readMore ? text : text?.substring(0, 150)
                        ),
                    }}
                ></div>
                {!readMore && text?.length > 150 && (
                    <span
                        onClick={(e) => {
                            setReadMore(true)
                            e.stopPropagation()
                        }}
                        className="readMore"
                    >
                        ...Read More
                    </span>
                )}
                {links?.[0] && <LinkThumbnail url={links[0]} column isSocialMedia />}
                <TwitterGridView
                    files={
                        files && files.length
                            ? files
                            : path || mediaUrl
                                ? [{ preview: path || mediaUrl }]
                                : []
                    }
                />
                <div className={classes.time}>
                    {formatDateIntoWord(
                        time || publishDate,
                        true,
                        true,
                        !(time || publishDate)
                    )}
                </div>
                {showStats && (
                    <div className="stats">
                        <div className="comment">
                            <Comment /> {stat ? stat.comment : ""}
                        </div>
                        <div className="hearts">
                            <Like /> {stat ? stat.like : ""}
                        </div>
                        <div className="retweet">
                            <ReTweet />
                            &nbsp; {stat ? stat.reTweet : ""}
                        </div>
                    </div>
                )}
                {firstComment && (
                    <Config features={["FIRST_LEVEL_POST"]} social={"twitter"}>
                        <div className={classes.twitterComment}>
                            <div className={classes.icon}>
                                <img
                                    src={
                                        profilePic && profilePic.length > 0
                                            ? profilePic
                                            : noProfilePic
                                    }
                                    alt="user"
                                />
                            </div>
                            <div className={classes.contentContainer}>
                                <div className={classes.header}>
                                    <div className={classes.commentName}>
                                        {createdBy}
                                    </div>
                                    <div className={classes.handleName}>
                                        @{handleName}
                                    </div>{" "}
                                    <span>.</span>
                                    <div className={classes.timeTag}>1s</div>
                                </div>
                                <div className={classes.replyHeader}>
                                    Replying to{" "}
                                    <span className={classes.blue}>
                                        @{handleName}
                                    </span>
                                </div>
                                <div className={classes.comment}>{firstComment}</div>
                            </div>
                        </div>
                        <div className={classes.stats}>
                            <div className={classes.stat}>
                                <Comment /> {stat ? stat.comment : ""}
                            </div>
                            <div className={classes.stat}>
                                <Like /> {stat ? stat.like : ""}
                            </div>
                            <div className={classes.stat}>
                                <ReTweet />
                                &nbsp; {stat ? stat.reTweet : ""}
                            </div>
                        </div>
                    </Config>
                )}
            </div>
            {icon}
        </div>
    )
}

export default React.memo(TwitterPost)
