import { combineReducers } from "redux"
import { userReducer } from "./userReducer"
import permissionsReducer from "./permissionsReducer"
import messageReducer from "./messageReducer"
import workplaceReducer from "./workplaceReducer"
import roleReducer from "./roleReducer"
import brandsReducer from "./brandsReducer"
import socialReducer from "./socialReducer"
import settingReducer from "./settingReducer"
import transactionReducer from "./transactionReducer"
import collaborationReducer from "./collaborationReducer"
import workflowReducer from "./workflowReducer"
import postReducer from "./postReducer"
import publishReducer from "./publishReducer"
import userMessageingReducer from "./userMessagingReducer"
import HeyBioReducer from "./HeyBioReducer"
import engageReducer from "./engageReducer"
import analyticsReducer from "./analyticsReducer"
import storage from "redux-persist/lib/storage"
import { persistReducer } from "redux-persist"

const permissionsPersist = {
    key: "permissions",
    storage,
    whitelist: ["permissions", "fill"],
}

const rolePersist = {
    key: "roles",
    storage,
    whitelist: ["role"],
}

const featuresPersist = {
    key: "enabledFeatures",
    storage,
    whitelist: ["enabledFeatures", "socialView", "currentPostFilterStatus"],
}

const postsPersist = {
    key: "posts",
    storage,
    whitelist: "rowLimit",
}

const workspacePersist = {
    key: "workspace",
    storage,
    whitelist: [
        "currentCompanyId",
        "fill",
        "groups",
        "loading",
        "currentActiveWorkspace",
    ],
}

export default combineReducers({
    userReducer,
    permissionsReducer: persistReducer(permissionsPersist, permissionsReducer),
    roleReducer: persistReducer(rolePersist, roleReducer),
    messageReducer,
    // workplaceReducer: persistReducer(workspacePersist, workplaceReducer),
    workplaceReducer,
    brandsReducer,
    socialReducer: persistReducer(featuresPersist, socialReducer),
    settingReducer,
    transactionReducer,
    collaborationReducer,
    workflowReducer,
    postReducer: persistReducer(postsPersist, postReducer),
    publishReducer,
    userMessageingReducer,
    HeyBioReducer,
    engageReducer,
    analyticsReducer,
})
