import React from "react"
import "./icon.scss"
import { ReactComponent as TwitterSVG } from "../../icons/socialIcons/twitter.svg"

const TwitterIcon = ({ active, style }) => {
    return (
        <div
            className={active ? "social-icon-active" : "social-icon-inactive"}
            style={style}
        >
            <TwitterSVG />
        </div>
    )
}

export default TwitterIcon
