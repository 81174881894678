import { useState, useEffect, useCallback, useRef } from "react"
import Axios from "axios"
import { createMessage } from "../Actions/messageAction"
import usePrevious from "./usePrevious"

/**
 *
 * @param {String} url
 * @param {Object} headers
 * @param {Function} parser
 * @param {Number} brandId
 * @returns {loading, list, setList, lastElementRef}
 */

function useFetch(url, headers, parser, brandId) {
    const [page, setPage] = useState(0)
    const [loading, setLoading] = useState(false)
    let [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const previousBrand = usePrevious(brandId)

    const observer = useRef()

    const lastElementRef = useCallback(
        (node) => {
            if (loading) return
            if (observer.current) observer.current.disconnect()
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    setPage((prev) => prev + 1)
                }
            })
            if (node) observer.current.observe(node)
        },
        [loading, hasMore, brandId]
    )

    const pagination = async (isBrandChanged = false) => {
        if (isBrandChanged && brandId !== -1) {
        } else if (brandId === -1 || loading || !hasMore) return
        try {
            setLoading(true)
            if (isBrandChanged) setPage(0)

            const res = await Axios.get(url, {
                params: {
                    related_id: brandId,
                    page: isBrandChanged ? 0 : page,
                    limit: 15,
                },
                headers,
            })
            if (res.data.model) {
                let allThread = []
                const comments = res.data.model.comments
                for (let i = 0; i < comments.length; i++) {
                    let newThread = comments[i]
                    if (parser) {
                        newThread = parser(comments[i])
                    }
                    allThread = [newThread, ...allThread]
                }

                if (allThread.length > 0) setHasMore(true)
                else setHasMore(false)

                if (isBrandChanged) setList(allThread)
                else setList((list) => [...allThread, ...list])
            } else {
                createMessage("danger", "Cant fetch comments")
            }
            setLoading(false)
        } catch (err) {
            createMessage("danger", "Cant fetch comments")
        }
    }

    useEffect(() => {
        if (brandId !== previousBrand) {
            setList([])
            pagination(true)
        } else {
            pagination()
        }
    }, [brandId, page])

    return { loading, list, setList, lastElementRef, page }
}

export default useFetch