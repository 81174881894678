import React from "react"
import { connect } from "react-redux"
import OptionSideNav from "../OptionSideNav"
import CreatorTitleBar from "../CreatorTitleBar"
import "./ManagementSection.scss"
import PostEditSection from "../PostEditSection"
import PostCollaboration from "../PostCollaborationContainer"
import ActivityLog from "../ActivityLog"
import PostEngagement from "../PostEngagementSection"

const ManagementSection = ({
    post,
    token,
    state,
    option,
    user,
    brandId,
    creator,
    setActiveDuplication,
    activeDuplication,
    showCaptions,
    setShowCaptions,
    showQueue,
    setShowQueue,
    social,
    resetQueue
}) => {

    const _C = "aui-post-manager-section"
    function GetComponent(state, option) {
        const EditSection = (
            <div className={_C + "-edit-section"}>
                <PostEditSection
                    postState={state}
                    brandId={brandId}
                    activeDuplication={activeDuplication}
                    setActiveDuplication={setActiveDuplication}
                    showCaptions={showCaptions}
                    setShowCaptions={setShowCaptions}
                    showQueue={showQueue}
                    setShowQueue={setShowQueue}
                    resetQueue={resetQueue}
                />
            </div>
        )

        const CollaborationSection = <PostCollaboration post={post} token={token} />

        if (state === "create") return EditSection
        else if (state === "view") {
            switch (option) {
                case 0:
                    return <PostEngagement post={post} brandId={brandId} social={social} />
                case 1:
                    return EditSection
                case 2:
                    return CollaborationSection
                case 3:
                    return <ActivityLog />
                default:
                    return <PostEngagement post={post} brandId={brandId} social={social} />
            }
        } else {
            switch (option) {
                case 0:
                    return EditSection
                case 1:
                    return CollaborationSection
                case 2:
                    return <PostEngagement post={post} brandId={brandId} social={social} />
                case 3:
                    return <ActivityLog />
                default:
                    return <PostEngagement post={post} brandId={brandId} social={social} />
            }
        }
    }
    return (
        <div className={_C} >
            <div className="post-manager-title-bar ">
                <CreatorTitleBar creator={creator ?? user} post={post} />
            </div>
            <div className={_C + "-wrapper"}>
                {GetComponent(state, option)}
                <OptionSideNav social={social} />
            </div>
        </div>
    )
}

const mapStateToProps = ({ postReducer, userReducer }) => {
    const { creator, post, option, state } = postReducer
    return {
        post: post,
        option: option,
        state: state,
        token: userReducer.token,
        user: userReducer.user,
        creator,
    }
}

export default connect(mapStateToProps)(ManagementSection)
