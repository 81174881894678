import React from "react";
import ReactDom from "react-dom";
import GifPicker from "./GIFPicker/GifPicker";

const TenorGif = (props) => {
  if(!props.open) return null
  ReactDom.unmountComponentAtNode(document.getElementById("external-libs"))
  return ReactDom.createPortal(
    <GifPicker {...props} />,
    document.getElementById("external-libs")
  );
};

export default TenorGif;
