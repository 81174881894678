import React from "react"
import { BrowserRouter } from "react-router-dom"
import MainPage from "components/Pages/MainPage/MainPage"
import { refreshToken } from "./Actions/userAction"

import { connect } from "react-redux"
import { ThemeContext } from "@awesomesuite-frontend/awesome-nebula"
import { themeObject } from "./NebulaStyle"
import "./App.scss"
import "react-big-calendar/lib/css/react-big-calendar.css"
import GuardContext from "Awesome-ui/GuardContext"
import ConfigContext from "Awesome-ui/ConfigContext/ConfigContext"
import "react-big-calendar/lib/addons/dragAndDrop/styles.scss"
import { clarityKey } from "components/apis"
import Routes from "routes"
import { devLog } from "components/Helper/UtilityHelpers/Logger"

class App extends React.Component {
    async componentDidMount() {
        if (
            !window.location.pathname.includes("login") &&
            !window.location.pathname.includes("getting-started")
        ) {
            await refreshToken()
        }
        if (clarityKey) {
            ;(function (c, l, a, r, i, t, y) {
                c[a] =
                    c[a] ||
                    function () {
                        ;(c[a].q = c[a].q || []).push(arguments)
                    }
                t = l.createElement(r)
                t.async = 1
                t.src = "https://www.clarity.ms/tag/" + i
                y = l.getElementsByTagName(r)[0]
                y.parentNode.insertBefore(t, y)
            })(window, document, "clarity", "script", clarityKey)
        }
    }

    render() {
        let { permissions, roleId, enabledFeatures } = this.props

        devLog("This is Development Logger for" , process.env.REACT_APP_ENV , "Environment");

        return (
            <div className="App">
                <ConfigContext.Provider value={{ enabledFeatures: enabledFeatures }}>
                    <GuardContext.Provider
                        value={{ role: roleId, permissions: permissions }}
                    >
                        <BrowserRouter>
                            <ThemeContext theme={themeObject}>
                                <Routes />
                                <MainPage />
                            </ThemeContext>
                        </BrowserRouter>
                    </GuardContext.Provider>
                </ConfigContext.Provider>
            </div>
        )
    }
}

const mapStateToProps = ({
    permissionsReducer,
    workplaceReducer,
    socialReducer,
}) => {
    return {
        permissions: permissionsReducer.permissions,
        roleId: workplaceReducer.currentActiveWorkspace?.roleId,
        enabledFeatures: socialReducer.enabledFeatures,
    }
}

export default connect(mapStateToProps)(App)
