import React, { useState, useRef } from "react"
import useStyles from "./PostEditSectionStyles"
import { connect } from "react-redux"
import { socialObj } from "../../PostRelatedComponents/SocialIconPreviewMapping"
import { ReactComponent as BookLinkedin } from "../../../icons/LinkedinBook.svg"
import TagList from "../../TagList"
import {
    selectChannelToView,
    selectKeyToView,
    selectChannelToPostOn,
    changePost,
    addFiles,
    deleteFile,
    toggleMinifyLink,
    createPost,
    updatePostScheduledDate,
    updatePostScheduledTime,
    editPost,
    shuffleFile,
    changePostMultiSocial,
    duplicateFiles,
    getFirstComment,
} from "../../../../Actions/postAction"
import PostTextArea from "../../PostTextArea"
import "./postEditSection.scss"
import PostBottomBarNew from "../../PostRelatedComponents/PostBottomBarNew"
import MediaSelectionDialog from "../../MediaSelectionDialog"
import FilePreviewBar from "../../FilePreviewBar"
import {
    fileHandler,
    driveUploadHandler,
    handleDropBox,
    handleUnsplashPicker,
    handleGifPicker,
} from "../../PostRelatedComponents/fileUploadHandlerOnThirdParty"
import PinDropDown from "../../PinDropDown/PinDropDown"
import {
    INSTAGRAM,
    PINTEREST,
    TWITTER,
    FACEBOOK,
    LINKED_IN,
} from "../../../../Data/SocialMedialTypes"
import { SocialSubTypeChecker } from "../../../Helper/Normalizers/SocialSubTypeChecker"
import { useTracking } from "react-tracking"
import { SOCIAL_POST } from "../../../../constants"
import { getBrandWorkflow } from "../../../../Actions/workflowAction"
import { useEffect } from "react"
import { getColorFromList } from "../../../Helper/getRandomColor"
import { addLabel } from "../../../Helper/SocialPostHandler/LabelHandler"
import { updateLabels } from "../../../../Actions/brandsAction"
import AddMedia from "../../../icons/Add-Media.svg"
import Description from "../../../icons/Description.svg"
import SelectProfiles from "../../../icons/Select-Profiles.svg"
import Grid from "@awesomesuite-frontend/awesome-nebula/dist/Grid"
import SocialIconsMapping from "components/Utilities/SocialIcons/SocialIconsMapping"
import { createUseStyles } from "react-jss"
import Config from "Awesome-ui/Config/Config"
import TooltipWrapper from "components/commonComps/Tooltip/TooltipWrapper"
import { createMessage } from "Actions/messageAction"
import { ReactComponent as Post } from "assets/SocialIcons/grey-icons/post.svg"
import { ReactComponent as Reel } from "assets/SocialIcons/grey-icons/reel.svg"

const PostEditSection = ({
    brandId,
    socialChannels,
    post,
    selectedChannelToView,
    selectedKeyToView,
    files,
    minifyLink,
    channelsToPostOn,
    state,
    date,
    time,
    socialProfiles,
    timeZone,
    brandWorkflow,
    user,
    labels,
    postLabels,
    postState,
    socialAccounts,
    pinterestBoardModal,
    errors,
    paramsData,
    activeDuplication,
    setActiveDuplication,
    showCaptions,
    setShowCaptions,
    showQueue,
    setShowQueue,
    resetQueue
}) => {
    const { trackEvent: track } = useTracking()
    const _C = "aui-post-edit-section"
    const dialogRemoteOpen = useRef()
    const [invokeByLinkedin, setInvokeByLinkedin] = useState(false)
    const [disable, setDisable] = useState(false)
    const [postType, setPostType] = useState("post")

    const [labelList, setLabelList] = useState([])
    const [selectedLabelList, setSelectedLabelList] = useState([])

    const [firstComment, setFirstComment] = useState(false)
    const [isApprovalRequired, setIsApprovalRequired] = useState(1)
    const [containsMention, setContainsMention] = useState(false)
    const [previewChannel, setPreviewChannel] = useState(selectedChannelToView)
    const [selectedAccounts, setSelectedAccounts] = useState(socialAccounts)

    const postTypeList = [
        {
            name: "Post",
            id: "post",
            icon: <Post />
        },
        // {
        //     name: "Reels",
        //     id: "reel",
        //     icon: <Reel />
        // },
    ]

    useEffect(() => {
        if (!errors || errors.length <= 0) return
        const accountNames =
            errors && errors?.length > 0 ? errors.map((err) => err.platform) : []
        const errorAccounts = socialAccounts.filter((account) => {
            const key = `${account.name.toLowerCase()}_${account.key.toLowerCase()}`
            if (!accountNames.includes(key)) {
                if (channelsToPostOn.includes(key)) selectChannelToPostOn(key)
                return false
            }
            return true
        })
        setSelectedAccounts(errorAccounts)
        selectChannelToView(errorAccounts[0].name)
        selectKeyToView(errorAccounts[0].key)
    }, [errors])

    const classes = useStyles()

    useEffect(() => {
        // Set Post Text
        changePost("text", { ...post.text })

        // Set current channel for Preview
        if ((state === "create" || state === "edit") && paramsData) {
            selectChannelToPostOn(
                `${paramsData?.socialPlatform}_${paramsData?.subType}`
            )
        }

        // Set Duplication
        if (activeDuplication === "Similar") {
            duplicateAcrossSocials(post?.text[`${previewChannel}_text`])
            duplicateFiles(previewChannel)
        }

        // Set Labels
        let newList = []
        const splitTags = post?.label?.split("||")?.map((lab) => lab.trim())
        const newTagList =
            labels[brandId] &&
            labels[brandId].map((tagName) => {
                const newTag = {
                    name: tagName,
                    id: Math.floor(Math.random() * 1000),
                    color: getColorFromList(),
                }
                if (splitTags?.includes(tagName)) newList = [...newList, newTag]
                return newTag
            })
        setLabelList(newTagList ? newTagList : [])
        changePost("labelList", newList)
        if (newList?.length > 0) setSelectedLabelList(newList)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        async function getComment() {
            if (!post.commentId) {
                const comment = await getFirstComment(
                    previewChannel,
                    brandId,
                    post.id
                )
                if (comment) setFirstComment(true)
            }
        }
        changePost("firstComment", {
            twitter: "",
            facebook: "",
            linkedin: "",
            instagram: "",
        })
        getComment()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let approvalValue
        getBrandWorkflow(brandId)
        let currentUserWorkflow
        if (!brandWorkflow) approvalValue = 0
        else approvalValue = 1
        brandWorkflow &&
            brandWorkflow.levels.map((level) => {
                level.teams.forEach((levelUser) => {
                    if (levelUser === user.user_name) {
                        currentUserWorkflow = level
                        if (currentUserWorkflow.step === brandWorkflow.levels.length)
                            approvalValue = 0
                    }
                })
            })
        setIsApprovalRequired(approvalValue)
        changePost("isApprovalRequired", approvalValue)
        // eslint-disable-next-line
    }, [brandWorkflow])

    useEffect(() => {
        if (
            !channelsToPostOn.includes(
                `${selectedChannelToView}_${selectedKeyToView}`
            )
        ) {
            const otherChannels = channelsToPostOn.filter(
                (channel) =>
                    `${selectedChannelToView}_${selectedKeyToView}` !== channel
            )
            if (otherChannels.length > 0) {
                selectChannelToView(otherChannels[0].split("_")[0])
                selectKeyToView(otherChannels[0].split("_")[1])
            }
        }
        // eslint-disable-next-line
    }, [channelsToPostOn])

    useEffect(() => {
        if (selectedChannelToView) setPreviewChannel(selectedChannelToView)
    }, [selectedChannelToView])

    const duplicateAcrossSocials = (value) => {
        changePost("text", {
            twitter_text: value,
            instagram_text: value,
            facebook_text: value,
            linkedin_text: value,
        })
    }

    const MediaButton = (
        <MediaSelectionDialog
            ref={dialogRemoteOpen}
            fileHandler={(data) =>
                fileHandler(data, addFiles, previewChannel, activeDuplication, setDisable)
            }
            driveHandler={(data) =>
                driveUploadHandler(data, addFiles, previewChannel, activeDuplication, setDisable)
            }
            dropboxHandler={(data) =>
                handleDropBox(data, addFiles, previewChannel, activeDuplication, setDisable)
            }
            tenorHandler={(data) =>
                handleGifPicker(data, addFiles, previewChannel, activeDuplication)
            }
            unsplashHandler={(data) =>
                handleUnsplashPicker(
                    data,
                    addFiles,
                    previewChannel,
                    activeDuplication
                )
            }
            invokeByLinkedin={invokeByLinkedin}
            postType={postType}
        />
    )
    function containsOnlyLinkedin(arr, allowedValues) {
        return arr.every((element) => allowedValues.includes(element));
    }
    function shift(channel2) {
        return files && files[channel2] && files[channel2][0]?.mediaType === "application/pdf" ? true : false
    }
    return (
        <>
            {errors?.map((item) => (
                <Grid
                    container
                    border="2px solid rgb(255 129 129)"
                    align="center"
                    radius="4px"
                    padding="8px 4px"
                    style={{ cursor: "pointer", margin: "0.125rem 0" }}
                    onClick={() => {
                        selectChannelToView(item.platform.split("_")[0])
                        selectKeyToView(item.platform.split("_")[1])
                    }}
                >
                    <Grid item xs={1}>
                        <SocialIconsMapping
                            name={item.platform}
                            active
                            style={{ height: 40 }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={11}
                        style={{ textAlign: "start", paddingLeft: "0.25rem" }}
                    >
                        {item.failureReason || "Could not create post!"}
                    </Grid>
                </Grid>
            ))}
            <div className={_C}>
                {state === "create" && (
                    <div className="postTo">
                        <div className="post-editor-head">
                            <div id="post-management-title">
                                <div className="icon">
                                    <img
                                        src={SelectProfiles}
                                        alt="posticon"
                                        style={{ width: "100%", maxHeight: "unset" }}
                                    />
                                </div>
                                <div className="head">
                                    <div>Select Profiles</div>
                                    <div>
                                        Choose Social Profiles you'd like to publish
                                        your post on
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="platformWrapper scroll-modern">
                                {selectedAccounts.map((account, i) => {
                                    if (!account.enable || !account.id) return <></>

                                    let channel = account.name.toLowerCase()
                                    let key = account.key.toLowerCase()

                                    let isPres = channelsToPostOn.includes(
                                        `${channel}_${key}`
                                    )
                                    let socialProfilePres = socialProfiles?.[channel]

                                    if (!socialProfilePres === undefined)
                                        return <></>
                                    if (SocialSubTypeChecker(channel)) {
                                        socialProfilePres =
                                            socialProfilePres?.[key.toLowerCase()]
                                    }

                                    return (
                                        <Config
                                            key={i}
                                            features={["CHANNEL"]}
                                            social={channel}
                                        >
                                            <div className="channelWrapper">
                                                <div
                                                    className={`channel ${isPres
                                                        ? "channel-active"
                                                        : "channel-inactive"
                                                        } ${previewChannel === channel &&
                                                            selectedKeyToView === key &&
                                                            isPres
                                                            ? "channel-select"
                                                            : ""
                                                        }`}
                                                    style={{
                                                        border:
                                                            previewChannel ===
                                                                channel &&
                                                                selectedKeyToView ===
                                                                key &&
                                                                isPres
                                                                ? `3px solid ${activeDuplication ===
                                                                    "Similar"
                                                                    ? "#00acee"
                                                                    : "#F39449"
                                                                }`
                                                                : "unset",
                                                    }}
                                                    key={i}
                                                >
                                                    <span
                                                        onClick={() => {
                                                            if (isPres) {
                                                                track({
                                                                    type: SOCIAL_POST,
                                                                    title: "change post preview",
                                                                    key: channel,
                                                                    catagory:
                                                                        SOCIAL_POST,
                                                                })
                                                                selectChannelToView(
                                                                    channel
                                                                )
                                                                selectKeyToView(key)
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            socialObj[channel + key]
                                                                .Icon
                                                        }
                                                    </span>
                                                    <div
                                                        className="overlay"
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            if ((shift(previewChannel)) && !(channel === "linkedin" || activeDuplication === "Custom")) {
                                                                createMessage("danger", "Document cannot be uploaded on other social media")
                                                            } else {
                                                                track({
                                                                    type: SOCIAL_POST,
                                                                    title: "social platform selection",
                                                                    key: channel,
                                                                })
                                                                selectChannelToPostOn(
                                                                    `${channel}_${key}`
                                                                )
                                                            }
                                                        }}
                                                    >
                                                        +
                                                    </div>
                                                </div>
                                                {previewChannel ===
                                                    channel.toLowerCase() &&
                                                    selectedKeyToView === key && (
                                                        <div
                                                            className="bottomStatusBar"
                                                            style={{
                                                                backgroundColor:
                                                                    activeDuplication ===
                                                                        "Similar"
                                                                        ? "#00ACEE"
                                                                        : "#F39449",
                                                            }}
                                                        ></div>
                                                    )}
                                            </div>
                                        </Config>
                                    )
                                })}
                            </div>
                            <div className="postDuplication">
                                <div
                                    className={`postSimilarPost ${activeDuplication === "Similar"
                                        ? "activeDuplication"
                                        : ""
                                        }`}
                                    onClick={() => {
                                        if (shift(previewChannel)) {
                                            createMessage("danger", "Cannot post document on other social platfrom")
                                        }
                                        else {
                                            setActiveDuplication("Similar")
                                            duplicateAcrossSocials(
                                                post?.text[`${previewChannel}_text`]
                                            )
                                            duplicateFiles(previewChannel)
                                            setDisable(false)
                                        }

                                    }}
                                    style={{
                                        borderTopRightRadius:
                                            activeDuplication === "Similar"
                                                ? "4px"
                                                : "unset",
                                        border:
                                            activeDuplication === "Similar"
                                                ? "1px solid #00ACEE"
                                                : "1px solid #ccdff2",
                                        borderBottom:
                                            activeDuplication === "Custom" && "none",
                                    }}
                                >
                                    Similar Post
                                </div>
                                <div
                                    className={`postCustomizedPost ${activeDuplication === "Custom"
                                        ? "activeDuplicationCustom"
                                        : ""
                                        }`}
                                    onClick={() => {
                                        if (shift(previewChannel)) {
                                            createMessage("danger", "Custom post can only be active if all files are removed")
                                        } else {
                                            setActiveDuplication("Custom")
                                        }
                                    }}
                                    style={{
                                        borderBottomRightRadius:
                                            activeDuplication === "Custom"
                                                ? "4px"
                                                : "unset",
                                        border:
                                            activeDuplication === "Custom"
                                                ? "1px solid #F39449"
                                                : "1px solid #ccdff2",
                                        borderTop:
                                            activeDuplication === "Similar" &&
                                            "none",
                                    }}
                                >
                                    Customized Post
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {post.failureReason ? (
                    <div className="post-failure-msg">
                        <span role="img" aria-label="img">
                            🛠️
                        </span>{" "}
                        Post Failed due to ${post.failureReason}
                    </div>
                ) : (
                    <></>
                )}

                <ul className="post-type">
                    {
                        postTypeList.map((item, i) => (
                            <li
                                key={i}
                                className={`post-type--item ${postType === item.id ? "active" : ""}`}
                                onClick={() => setPostType(item.id)}
                            >
                                {/* <input type="radio" className="post-type--radio" name="post-type" id={item.id}
                                    checked={postType === item.id}
                                    onChange={() => setPostType(item.id)}
                                /> */}
                                <span
                                    className={`post-type--radio ${postType === item.id ? "active" : ""}`}
                                ></span>
                                {item.icon}
                                {item.name}
                            </li>
                        ))
                    }
                </ul>

                {previewChannel !== PINTEREST && (
                    <PostTextArea
                        value={
                            previewChannel === TWITTER
                                ? post?.text?.twitter_text
                                : previewChannel === INSTAGRAM
                                    ? post?.text?.instagram_text
                                    : previewChannel === FACEBOOK
                                        ? post?.text?.facebook_text
                                        : previewChannel === LINKED_IN
                                            ? post?.text?.linkedin_text
                                            : post?.text?.twitter_text
                        }
                        setValue={(value) => {
                            previewChannel === TWITTER
                                ? changePostMultiSocial("text.twitter_text", value)
                                : previewChannel === INSTAGRAM
                                    ? changePostMultiSocial("text.instagram_text", value)
                                    : previewChannel === FACEBOOK
                                        ? changePostMultiSocial("text.facebook_text", value)
                                        : previewChannel === LINKED_IN
                                            ? changePostMultiSocial("text.linkedin_text", value)
                                            : changePostMultiSocial("text.twitter_text", value)

                            if (activeDuplication === "Similar") {
                                duplicateAcrossSocials(value)
                            }
                        }}
                        emoji
                        title={"Description"}
                        description={"Add Caption and description to your post"}
                        minifyLink={minifyLink}
                        toggle={toggleMinifyLink}
                        icon={Description}
                        MediaButton={MediaButton}
                        style={{ marginBottom: "1rem" }}
                        limitText={selectedChannelToView === TWITTER}
                        containsMention={containsMention}
                        setContainsMention={setContainsMention}
                        textBottomRight={
                            <Config
                                features={["FIRST_LEVEL_POST"]}
                                social={previewChannel}
                            >
                                <div
                                    className={classes.commentButton}
                                    onClick={() => {
                                        setFirstComment((prev) => !prev)
                                    }}
                                >
                                    <TooltipWrapper
                                        direction={"top"}
                                        text={`Schedule first comment on ${previewChannel[0].toUpperCase() +
                                            previewChannel.substring(1)
                                            }`}
                                        style={{ maxWidth: "12rem" }}
                                    >
                                        <div className={classes.commentWrapper}>
                                            <div className={classes.plus}>
                                                {firstComment ? "-" : "+"}
                                            </div>
                                            <div className="text">{`${previewChannel[0].toUpperCase() +
                                                previewChannel.substring(1)
                                                } first comment`}</div>
                                        </div>
                                    </TooltipWrapper>
                                </div>
                            </Config>
                        }
                    />
                )}
                {firstComment && (
                    <Config features={["FIRST_LEVEL_POST"]} social={previewChannel}>
                        <PostTextArea
                            value={post?.firstComment?.[previewChannel]}
                            setValue={(value) => {
                                if (activeDuplication === "Similar") {
                                    changePost("firstComment", {
                                        twitter: value,
                                        facebook: value,
                                        linkedin: value,
                                        instagram: value,
                                        pinterest: value,
                                    })
                                } else {
                                    changePostMultiSocial(
                                        `firstComment.${selectedChannelToView}`,
                                        value
                                    )
                                }
                            }}
                            emoji
                            title={"First Comment"}
                            description={
                                "Enter a First Comment to go along with your Post"
                            }
                            minifyLink={null}
                            // toggle={toggleMinifyLink}
                            // MediaButton={MediaButton}
                            style={{ marginBottom: "1rem" }}
                            containsMention={containsMention}
                            setContainsMention={setContainsMention}
                        />
                    </Config>
                )}
                {selectedChannelToView === PINTEREST &&
                    channelsToPostOn.includes("pinterest_user") && (
                        <PinterestForm
                            brandId={brandId}
                            post={post}
                            pinterestBoardModal={pinterestBoardModal}
                            MediaButton={MediaButton}
                        />
                    )}

                {selectedChannelToView !== PINTEREST && (
                    <div className={classes.postUtilities}>
                        <div
                            className={classes.captions}
                            onClick={() => {
                                setShowCaptions(!showCaptions)
                            }}
                        >
                            <span aria-label="save-icon" role="img">
                                💾
                            </span>{" "}
                            Saved Captions
                        </div>
                    </div>
                )}
                <div className="files-wrapper">
                    <div id="post-management-title">
                        <div className="icon">
                            <img
                                src={AddMedia}
                                alt="posticon"
                                height="100%"
                                width="100%"
                                style={{ maxHeight: "unset" }}
                            />
                        </div>
                        <div className="head">
                            <div> Add Media</div>
                            <div>
                                Upload high-performing and engaging media to your
                                post.
                            </div>
                        </div>
                    </div>
                    <div className="post-files">
                        {/* {!files.length && ( */}
                        {(!shift(previewChannel) || (activeDuplication === "Custom" && previewChannel !== "linkedin")) && (<div
                            onClick={() => {
                                setInvokeByLinkedin(false)
                                dialogRemoteOpen && dialogRemoteOpen.current()
                            }
                            }
                            id="add-image"
                        >
                            +
                        </div>)}
                        {(channelsToPostOn.length > 0 && previewChannel === "linkedin" && !files.linkedin.length > 0 &&
                            (containsOnlyLinkedin(channelsToPostOn, ["linkedin_user", "linkedin_page"]) || activeDuplication === "Custom")) &&
                            <div
                                onClick={() => {
                                    setInvokeByLinkedin(true)
                                    dialogRemoteOpen && dialogRemoteOpen.current()
                                }

                                }
                                id="add-image"
                            >
                                <TooltipWrapper direction={"top"} text="Upload a document" >
                                    <BookLinkedin />
                                </TooltipWrapper>
                            </div>

                        }
                        {/* )} */}
                        <FilePreviewBar
                            files={files[previewChannel]}
                            previewChannel={previewChannel}
                            deleteFile={deleteFile}
                            canShuffle
                            onShuffle={shuffleFile}
                            activeDuplication={activeDuplication}
                            setDisable={setDisable}
                        />
                    </div>
                </div>
                <TagList
                    tagIcon
                    list={labelList}
                    addTagToList={(tag) => {
                        addLabel(tag.name, brandId)
                        updateLabels(brandId)
                        setLabelList([...labelList, tag])
                    }}
                    // addToSelected={() => {}}
                    // deleteTagFromSelected={() => {}}
                    addToSelected={(tag) => {
                        setSelectedLabelList(
                            selectedLabelList.length < 2
                                ? [...selectedLabelList, tag]
                                : selectedLabelList
                        )
                        !postLabels
                            ? changePost("labelList", [tag])
                            : postLabels?.length < 2
                                ? changePost("labelList", [...postLabels, tag])
                                : changePost("labelList", [...postLabels])
                    }}
                    deleteTagFromSelected={(tag) => {
                        const newList = selectedLabelList.filter(
                            (listTag) => listTag.name !== tag.name
                        )
                        setSelectedLabelList(newList)
                        changePost("labelList", newList)
                    }}
                    selectedLabelList={selectedLabelList}
                />
            </div>
            {post.status !== "publish" && (
                <PostBottomBarNew
                    state={state}
                    date={date}
                    time={time}
                    createPost={state === "edit" ? editPost : createPost}
                    updateScheduledDate={updatePostScheduledDate}
                    updateScheduledTime={updatePostScheduledTime}
                    timezoneValue={timeZone}
                    isApprovalRequired={isApprovalRequired}
                    brandWorkflow={brandWorkflow}
                    postState={postState}
                    brandId={brandId}
                    blockSubmit={
                        paramsData?.socialPlatform === "default" ||
                        channelsToPostOn?.length <= 0
                    }
                    showQueue={showQueue}
                    setShowQueue={setShowQueue}
                    resetQueue={resetQueue}
                />
            )}
        </>
    )
}

const mapStateToProps = ({
    postReducer,
    socialReducer,
    workflowReducer,
    userReducer,
    brandsReducer,
}) => {
    return {
        files: postReducer.files,
        post: postReducer.post,
        errors: postReducer.errors,
        state: postReducer.state,
        minifyLink: postReducer.minifyLink,
        selectedChannelToView: postReducer.selectedChannelToView,
        selectedKeyToView: postReducer.selectedKeyToView,
        socialChannels: socialReducer.brandSocialChannels[postReducer.brandId],
        channelsToPostOn: postReducer.channelsToPostOn,
        date: postReducer.scheduleDate,
        time: postReducer.scheduleTime,
        timeZone: postReducer.timeZone,
        socialProfiles: socialReducer.socialProfiles[postReducer.brandId],
        brandWorkflow: workflowReducer.workflow[postReducer.brandId],
        user: userReducer.user,
        labels: brandsReducer.labels,
        postLabels: postReducer.post.labelList,
        socialAccounts:
            brandsReducer.brandDetails[postReducer.brandId]?.socialMedia.socials,
        pinterestBoardModal: socialReducer.pinterestBoardModal,
        paramsData: socialReducer.paramsUrl,
    }
}

export default connect(mapStateToProps)(PostEditSection)

const pinterestStyles = createUseStyles({
    outerWrapper: {
        marginBottom: "0 !important",
    },
    titleWrapper: {
        width: "100%",
        marginBottom: "1rem",
    },
    titleField: {
        border: "1px solid #D6DFE8",
        outline: "none",
        padding: "5px 10px",
        height: "3rem",
        width: "100%",
        borderRadius: "5px",
        margin: "3px 0",
    },
    bodyWrapper: {
        display: "flex",
        justifyContent: "space-between",

        "@media (max-width: 1250px)": {
            flexDirection: "column",
        },
    },
    linkWrapper: {
        width: "50%",
        marginBottom: "1rem",

        "@media (max-width: 1250px)": {
            width: "100%",
        },
    },
    linkField: {
        border: "1px solid #D6DFE8",
        outline: "none",
        padding: "5px 10px",
        height: "3rem",
        width: "100%",
        borderRadius: "5px",
        margin: "3px 0",
    },
})

/**
 * When user have the pinterest connected we
 * will show this ui so that he can add the details for penterest
 */
const PinterestForm = ({ brandId, post, pinterestBoardModal, MediaButton }) => {
    const classes = pinterestStyles()
    return (
        <div className={classes.outerWrapper}>
            <div style={{ display: "none" }}>{MediaButton}</div>
            <div className={classes.titleWrapper}>
                <div
                    style={{
                        textAlign: "left",
                        fontSize: "1rem",
                        fontWeight: "600",
                    }}
                >
                    Title
                </div>
                <input
                    className={classes.titleField}
                    type="text"
                    value={post.title}
                    onChange={(e) => {
                        changePost("title", e.target.value)
                    }}
                    placeholder={"Enter Pin Title"}
                />
            </div>
            <div className={classes.bodyWrapper}>
                <PinDropDown
                    brandId={brandId}
                    value={post.boardId}
                    onChangeHandler={(key) => changePost("boardId", key)}
                    pinterestBoardModal={pinterestBoardModal}
                />
                <div className={classes.linkWrapper}>
                    <div
                        style={{
                            textAlign: "left",
                            fontSize: "1rem",
                            fontWeight: "600",
                        }}
                    >
                        Website
                    </div>
                    <input
                        className={classes.linkField}
                        type="text"
                        value={post.destinationLink}
                        onChange={(e) => {
                            changePost("destinationLink", e.target.value)
                        }}
                        placeholder={"Add URL this pin links to"}
                    />
                </div>
            </div>
        </div>
    )
}
