import React from "react"
import { createUseStyles } from "react-jss"
import { ReactComponent as Like } from "../../../../components/icons/instaIcons/insta-like.svg"
import { ReactComponent as Comment } from "../../../../components/icons/instaIcons/insta-comment.svg"
import { ReactComponent as Share } from "../../../../components/icons/instaIcons/insta-send.svg"
import { ReactComponent as Save } from "../../../../components/icons/instaIcons/insta-save.svg"
import { ReactComponent as Bin } from "../../../../components/icons/bin.svg"
import { ReactComponent as Duplicate } from "../../../../components/icons/duplicate.svg"
import {
    openSocialPostManager,
    deletePost,
    clearPosts,
    resetPage,
} from "Actions/postAction"
import { useTracking } from "react-tracking"
import { CLICK, SOCIAL } from "../../../../constants"
import ThreeDotOptionDropdown from "components/Utilities/ThreeDotOptionsDropdown"
import { useParseTextForLinks } from "../../../../hooks/msgParser"
import { formatDateIntoWord } from "../../../Helper/HandleDateAndTime"
import LinkThumbnail from "../../LinkThumbnail"
import "./instagram.scss"
import LinkedinSlider from "../../ImageViews/LinkedinSlider"
import { useRef } from "react"
import { hashtagParser } from "../../../../hooks/msgParser"
import Config from "Awesome-ui/Config/Config"
import { consumePlan } from "Actions/transactionAction"
import { CONSUME_EVENT } from "ActionTypes/transactionActionTypes"
import { useState } from "react"

const useStyles = createUseStyles({
    threeDot: {
        borderRadius: "50%",
        marginLeft: "auto",
    },
})

/**
 * @description Preview component for Instagram post
 * @param {String} createdBy
 * @param {String} handleName Social handle / username
 * @param {Time} time
 * @param {String} text : Post text
 * @param {JSX} icon : Social icon of creator
 * @param {Link} profilePic : Platform profilePic
 * @param {Files} files
 * @param {Boolean} showStats to show like, share, etc icons
 */
const InstagramPost = ({
    createdBy,
    text: rawText,
    files,
    time,
    icon,
    stat,
    profilePic,
    path,
    mediaUrl,
    showStats,
    openPost,
    publishDate,
    firstComment,
    status,
    postStatus,
    post,
    creator,
    companyId,
    brandId,
    editPost = false,
    ...props
}) => {
    const [text, links] = useParseTextForLinks(rawText ?? "")
    const [readMore, setReadMore] = useState(false)
    const cardRef = useRef()
    const { trackEvent: track } = useTracking()
    const classes = useStyles()

    const getOptions = (post, creator, postStatus, social) => {
        let arr = [
            {
                name: "Duplicate",
                onClick: () => {
                    if (!companyId || (!post.brandId && !brandId)) return
                    if (!consumePlan(CONSUME_EVENT, 1, true)) return
                    openSocialPostManager(
                        "create",
                        0,
                        companyId,
                        brandId || post.brandId,
                        {
                            text: post.text || post.title,
                            postType: post.postType,
                            mediaContent: post.mediaContent.length ? post.mediaContent : [{ mediaUrl: post.mediaUrl }],
                        },
                        social,
                        creator
                    )
                },
                icon: <Duplicate />,
            },
        ]
        if (postStatus !== "publish")
            arr.push({
                name: "Delete",
                onClick: async () => {
                    const success = await deletePost(social, post.id, post.brandId)
                    if (success) {
                        clearPosts()
                        resetPage()
                    }
                },
                icon: <Bin />,
                style: {
                    color: "#F2545B",
                },
            })
        return arr
    }

    return (
        <div
            className="instaPost"
            onClick={() => {
                openPost && openPost(props.id)
            }}
            ref={cardRef}
        >
            <div className="postTop">
                <div className="icon">
                    <img
                        src={
                            profilePic && profilePic.length > 0
                                ? profilePic
                                : require(`../../../icons/no_profile_pic/instagram_no_profile_pic.png`)
                        }
                        alt="user"
                    />
                </div>
                <div className="title">
                    <div className="name">{createdBy}</div>
                    <div className="time">
                        {formatDateIntoWord(
                            time || publishDate,
                            true,
                            true,
                            !(time || publishDate)
                        )}
                    </div>
                </div>
                {editPost && (
                    <div className={classes.threeDot}>
                        <ThreeDotOptionDropdown
                            onClick={(e) => {
                                track({
                                    type: CLICK,
                                    title: "post drop down open",
                                    category: SOCIAL,
                                })
                                e.stopPropagation()
                            }}
                            options={getOptions(
                                post,
                                creator,
                                postStatus,
                                "instagram"
                            )}
                            idName="postOption"
                            enableIcon
                        />
                    </div>
                )}
            </div>
            {links?.[0] && <LinkThumbnail url={links[0]} column isSocialMedia />}
            <LinkedinSlider
                files={
                    files && files.length
                        ? files
                        : path || mediaUrl
                            ? [{ preview: path || mediaUrl }]
                            : []
                }
                width={cardRef?.current?.clientWidth}
                style={{}}
                showSliderDots={post?.mediaUrl?.length > 1}
            />
            {showStats && (
                <div className="stats">
                    <div className="stats-1">
                        <div className="comment">
                            <Comment /> {stat ? stat.comment : ""}
                        </div>
                        <div className="heart">
                            <Like /> {stat ? stat.like : ""}
                        </div>
                        <div className="share">
                            <Share />
                            {stat ? stat.share : ""}
                        </div>
                    </div>
                    <div className="stats-2">
                        <div className="save">
                            <Save />
                            {stat ? stat.share : ""}
                        </div>
                    </div>
                </div>
            )}
            <div className="instagramCaption">
                <div className="text creator postText">
                    <span>{createdBy}</span>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: hashtagParser(
                                readMore ? text : text?.substring(0, 150)
                            ),
                        }}
                    ></div>
                    {!readMore && text?.length > 150 && (
                        <span
                            className="readMore"
                            onClick={(e) => {
                                setReadMore(true)
                                e.stopPropagation()
                            }}
                        >
                            ...Read More
                        </span>
                    )}
                </div>
                {icon}
            </div>
            <Config features={["FIRST_LEVEL_POST"]} social={"instagram"}>
                {firstComment && (
                    <div>
                        <div className="commentLine">
                            View all 1 comments <br />
                        </div>
                        <div className="firstComment">
                            <div className="text creator">
                                <span>{createdBy}</span>
                                {firstComment}
                            </div>
                        </div>
                    </div>
                )}
            </Config>
        </div>
    )
}

export default InstagramPost
