export const FILTER_GROUP = "FILTER_GROUP"
export const ADD_GROUP = "ADD_GROUP"
export const ADD_USERS_TO_GROUP = "ADD_USERS_TO_GROUP"
export const UPDATE_GROUP = "UPDATE_GROUP"
export const GROUP_LOADING = "GROUP_LOADING"
export const GROUP_FAILED = "GROUP_FAILED"
export const CHANGE_WORKPLACE_VIEW = "CHANGE_WORKPLACE_VIEW"
export const ADD_NEW_BRAND_TO_GROUP = "ADD_NEW_BRAND_TO_GROUP"
export const SEARCH_IN_GROUP = "SEARCH_IN_GROUP"
export const SELECT_GROUP = "SELECT_GROUP"
export const CLEAR_EVERYTHING = "W_CLEAR_EVERYTHING"
export const TOGGLE_BRAND_SETTING = "TOGGLE_BRAND_SETTING"
export const UPDATE_GROUP_DATA = "UPDATE_GROUP_DATA"
export const REMOVE_BRAND_FORM_WORKSPACE = "REMOVE_BRAND_FORM_WORKSPACE"
export const REMOVE_WORKSPACE = "REMOVE_WORKSPACE"
export const DELETE_USER_FROM_WORKSPACE = "DELETE_USER_FROM_WORKSPACE"
export const REMOVE_BRAND_COMPANY = "REMOVE_BRAND_COMPANY"
export const WORKPLACE_COMPANIES_LOADING = "WORKPLACE_COMPANIES_LOADING"