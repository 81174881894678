export const SET_COMMENTS = "SET_COMMENTS"
export const SET_CURRENT_COMMENT_ID = "SET_CURRENT_COMMENT_ID"
export const ALTER_STACK = "ALTER_STACK"
export const SET_ACTIVE_OPTION = "SET_ACTIVE_OPTION"
export const SET_SELECTED_ITEM = "SET_SELECTED_ITEM"
export const SET_SELECTED_ITEM_ID = "SET_SELECTED_ITEM_ID"
export const COMMENTS_LOADING = "COMMENTS_LOADING"
export const REPLY_LOADING = "REPLY_LOADING"
export const COMMENTS_POSTING = "COMMENTS_POSTING"
export const CLEAR_COMMENT = "CLEAR_COMMENT"
export const COMMENT_TO = "COMMENT_TO"
export const DELETE_COMMENT_TO = "DELETE_COMMENT_TO"
export const SET_DMS = "SET_DMS"
export const SET_DMS_MESSAGES = "SET_DMS_MESSAGES"
export const START_DM_MESSAGE_CHANNEL = "START_DM_MESSAGE_CHANNEL"
export const SET_DM_INTERRUPT = "SET_DM_INTERRUPT"
export const CLEAR_DM_MESSAGE_CHANNEL = "CLEAR_DM_MESSAGE_CHANNEL"
export const SET_CHATS_LOADING = "SET_CHATS_LOADING"
export const SET_DMS_LOADING = "SET_DMS_LOADING"