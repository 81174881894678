import React from 'react'
import { createUseStyles } from 'react-jss'
import ThreeDotOptionDropdown from 'components/Utilities/ThreeDotOptionsDropdown'
import { ReactComponent as Bin } from "../../../icons/bin.svg"
import { ReactComponent as PenLogo } from "../../../icons/pen.svg"

const useStyles = createUseStyles({
    captionWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        borderRadius: "8px",
        border: "1px solid #D6DFE8",
        width: "100%",
        marginBottom: "1rem",
        cursor: "pointer",
    },
    active: {
        backgroundColor: "#E2F6FF",
        border: "1.5px solid #0FB1EF",
    },
    captionHeading: {
        width: "100%",
        padding: "0.5rem 0.5rem 0.25rem 0.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        color: "#2E3F50"
    },
    headingText: {
        width: "85%",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
    },
    captionText: {
        width: "100%",
        padding: "0.25rem 0.5rem 0.5rem 0.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
        color: "#A5B6C9",
        whiteSpace: "pre-wrap",
        textAlign: "left"
    },
})

function Caption({
    active, 
    heading, 
    text, 
    id,
    setCaptionForm, 
    setCaptionName, 
    setCaptionText, 
    setEditCaptionId,
    deleteCaption,
    brandId,
    clickFunction,
}) {
    const classes = useStyles()

    const getOptions = (e) => {
        let arr = [
            {
                name: "Edit",
                onClick: () => {
                    setCaptionName(heading)
                    setCaptionText(text)
                    setEditCaptionId(id)
                    setCaptionForm(true)
                },
                icon: <PenLogo/>,
            },
            {
                name: "Delete",
                onClick: () => {
                    deleteCaption(id, brandId)
                },
                icon: <Bin />,
                style: {
                    color: "#F2545B",
                },
            },
        ]
        return arr
    }
  return (
    <div 
        className={`${classes.captionWrapper} ${active ? classes.active : ""}`}
        onClick={()=>{
            clickFunction()
        }}
    >
        <div className={classes.captionHeading}>
            <div className={classes.headingText} >{heading}</div>
            <ThreeDotOptionDropdown
                onClick={(e) => {
                    e.stopPropagation()
                }}
                options={getOptions()}
                idName="postOption"
                enableIcon
            />
        </div>
        <div className={classes.captionText}>
            {text}
        </div>
    </div>
  )
}

export default Caption
