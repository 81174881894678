import React from "react"
import Times from "../../icons/x-lg.svg"
import "./HelpHeader.scss"

/**
 * Side Bar Header
 *
 * Contained Notification Title & Close Icon
 *
 * @param {String} title
 * @param {String} color text color
 * @param {String} backgroundColor background color
 * @param {Function} setShowBar
 * @param {Object} style
 *
 */
const HelpHeader = ({ title, backgroundColor, color, setShowBar, style }) => {
    const _C = "aui-help-header"
    return (
        <div className={_C} style={{ backgroundColor, color, ...style }}>
            <p>{title}</p>
            <div onClick={() => setShowBar(false)}>
                <img src={Times} alt="close" />
            </div>
        </div>
    )
}

export default HelpHeader
