import React, { useRef } from "react"
import { ReactComponent as ThreeDots } from "../../icons/3-vertical-dots.svg"
import useToggle from "../../../hooks/ToggleHook"
import "./style.scss"
import Guard from "Awesome-ui/Guard";

/**
 * @description Three dot options dropdown component
 * @params
 * options[] : [{
 *      name: String,
 *      icon: HTMLComponent / ReactComponent
 *      onClick: Function (What happens onClick)
 *      style: Object (Custom style)
 * }]
 * onClick: Function (On Dropdown open)
 * enableIcon: show icons in dropdown
 */
const ThreeDotOptionDropdown = ({
    options = [],
    onClick = () => {},
    enableIcon,
    style = {},
    idName,
    direction="bottom"
}) => {
    const _C = "three-dot-dropdown"

    const brandsDrop = useRef()
    const [visible, toggle] = useToggle(brandsDrop)

    function handleToggle(e) {
        toggle(!visible)
        onClick(e)
    }

    return (
        <div className={_C} onClick={handleToggle} id={idName} style={style}>
            <span id="dot-icon">
                <ThreeDots />
            </span>
            <li
                className={
                    `${_C + "-dropdown " + _C + "-" + (visible ? "open" : "close")} ${"dropdownDirection"+direction}`
                }
            >
                {options.map(({ name, icon, onClick, style = {} }, i) => {
                    let component = (
                        <ul
                            key={i}
                            onClick={() => {
                                toggle(false)
                                onClick()
                            }}
                            style={style}
                        >
                            {enableIcon && (
                                <span
                                    style={{
                                        display: "inline-flex",
                                        maxWidth: "1rem",
                                        maxHeight: "1rem",
                                    }}
                                >
                                    {icon}
                                </span>
                            )}
                            <span>{name}</span>
                        </ul>
                    )

                    if (name === "Delete" && idName === "postOption")
                        return <Guard scope="social_delete">{component}</Guard>

                    if (name === "Delete" && idName === "addIcons")
                        return <Guard scope="user_delete">{component}</Guard>

                    if (name === "Edit" && idName === "addIcons")
                        return <Guard scope="user_update">{component}</Guard>

                    return component
                })}
            </li>
        </div>
    )
}

export default ThreeDotOptionDropdown
