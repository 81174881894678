import React, { useState, useEffect } from "react"
import Avatar from "../Avatar/Avatar"
import { formatDateIntoWord } from "../../Helper/HandleDateAndTime"
import { parseCollaborationMsg } from "../../Helper/formattedMsg"
import "./CollaborationCommentStrip.scss"

/**
 * @description Comment Strip Component for Collaboration
 * @param {String} name Name of User
 * @param {String} comment Comment By User
 * @param {Time} time Time of Comment
 */

const CollaborationCommentStrip = ({ name, comment, time, image, curRef }) => {
    const _C = "aui-post-collaboration-comment-strip"
    const [splittedMsg, split] = useState({})

    useEffect(() => {
        const msgArray = parseCollaborationMsg(comment)
        const status = msgArray?.[0]
        const reason = msgArray?.[1]
        const msg = msgArray?.[2]

        split({ status, reason, msg })
    }, [])

    const statusClass =
        _C +
        (splittedMsg.status
            ? splittedMsg.status === "Approved"
                ? "-approved"
                : "-rejected"
            : "")

    return (
        <div className={_C + " " + statusClass} ref={curRef}>
            <Avatar
                firstName={name}
                lastName={name}
                width="32px"
                height="32px"
                round
                bgClr="#CB3333"
                src={image}
                icon={image}
                border="1px solid lightgray"
            />
            <div className={_C + "-content"}>
                <div className={_C + "-top"}>
                    <div className={_C + "-name"}>{name}</div>
                    {splittedMsg.status && (
                        <div
                            className={
                                _C +
                                "-status" +
                                (splittedMsg.status === "Approved"
                                    ? "-approved"
                                    : "-rejected")
                            }
                        >
                            {splittedMsg.status === "Approved" ? (
                                <>
                                    <span></span>
                                    <span>Approved this post</span>
                                </>
                            ) : (
                                <>
                                    <span>Post Rejected </span>
                                    <span></span>
                                </>
                            )}
                        </div>
                    )}
                </div>
                <div className={_C + "-comment"}>{splittedMsg.msg}</div>
                <div className={_C + "-bottom"}>
                    {formatDateIntoWord(time, true , true , true)}
                </div>
            </div>
        </div>
    )
}

export default CollaborationCommentStrip
