import {
    ADD_BRANDS,
    ADD_BRANDS_FAILED,
    BRANDS_LOADING,
    FILTER_BRANDS,
    UPDATE_BRANDS_DETAILS,
    UPDATE_BRAND_DETAILS,
    UPDATE_BRAND_DETAILS_IN_COMPANY,
    ADD_NEW_BRAND,
    SEARCH_IN_GROUP_BRANDS,
    BRAND_SETTING_TOGGLE,
    CLEAR_EVERYTHING,
    REMOVE_BRAND_DETAILS,
    ADD_USER_TO_BRAND,
    UPDATE_USER_ROLE_IN_BRAND,
    GET_ALL_USERS_IN_BRAND,
    DELETE_USER_FROM_BRAND,
    END_LOADING,
    DISABLE_SOCIAL_LOGIN_IN_BRANDS,
    UPDATE_TEMPORARY_BRAND,
    ADD_TEMPORARY_BRAND,
    RESET_TEMPORARY_BRAND,
    UPDATE_TEMPORARY_BRAND_KEY,
    UPDATE_LABELS,
} from "../ActionTypes/brandsActionTypes"

const initialState = {
    loading: false,
    fill: false,
    filteredBrandsFill: false,
    brands: {},
    brandDetails: {},
    filteredBrands: {},
    brandSettingOpen: false,
    team: {},
    temporaryBrand: {},
    labels: {},
}

const brandsReducer = (state = initialState, action) => {
    try {
        switch (action.type) {
            case CLEAR_EVERYTHING:
                return {
                    loading: false,
                    fill: false,
                    filteredBrandsFill: false,
                    brands: {},
                    brandDetails: {},
                    filteredBrands: {},
                    brandSettingOpen: false,
                    team: {},
                    temporaryBrand: {},
                    labels: {},
                }
            case END_LOADING:
                return { ...state, loading: false }
            case BRANDS_LOADING:
                return {
                    ...state,
                    loading: true,
                }
            case ADD_BRANDS_FAILED:
                return {
                    ...state,
                    loading: false,
                    fill: false,
                }
            case ADD_BRANDS:
                const newBrands = { ...state.brands }
                newBrands[action.payload.id] = action.payload.brands
                return {
                    ...state,
                    loading: false,
                    fill: true,
                    brands: newBrands,
                    filteredBrands: newBrands,
                }
            case UPDATE_BRANDS_DETAILS:
                return {
                    ...state,
                    brandDetails: {
                        ...state.brandDetails,
                        ...action.payload.brandsObj,
                    },
                }
            case UPDATE_BRAND_DETAILS:
                const oldBrandsDetails = { ...state.brandDetails }
                oldBrandsDetails[action.payload.id] = { ...action.payload }
                return { ...state, brandDetails: oldBrandsDetails }
            case DISABLE_SOCIAL_LOGIN_IN_BRANDS:
                var { brandId, social: channel } = action.payload
                var curBrand = state.brandDetails[brandId]
                var allSocials = curBrand.socialMedia.socials.map((social) =>
                    social.name.toLowerCase() === channel.toLowerCase()
                        ? { ...social, enable: 0, id: null }
                        : social
                )
                curBrand.socialMedia.socials = allSocials
                return {
                    ...state,
                    brandDetails: { ...state.brandDetails, [brandId]: curBrand },
                }
            case UPDATE_BRAND_DETAILS_IN_COMPANY:
                const updateNameinBrand = { ...state.brands }
                const updateNameinfilterBrands = { ...state.filteredBrands }
                const indexToUpdate = updateNameinBrand[
                    action.payload.companyId
                ].findIndex((item) => item.brandId === action.payload.id)
                const indexToUpdateinFilterBrands = updateNameinfilterBrands[
                    action.payload.companyId
                ].findIndex((item) => item.brandId === action.payload.id)
                updateNameinBrand[action.payload.companyId][indexToUpdate] = {
                    ...updateNameinBrand[action.payload.companyId][indexToUpdate],
                    name: action.payload.name,
                    icon: action.payload.icon,
                }
                updateNameinfilterBrands[action.payload.companyId][
                    indexToUpdateinFilterBrands
                ] = {
                    ...updateNameinfilterBrands[action.payload.companyId][
                        indexToUpdateinFilterBrands
                    ],
                    name: action.payload.name,
                    icon: action.payload.icon,
                }

                return {
                    ...state,
                    filteredBrands: updateNameinfilterBrands,
                    brands: updateNameinBrand,
                }

            case FILTER_BRANDS:
                const filteredBrands = { ...state.filteredBrands }
                filteredBrands[action.payload.id] = action.payload.filteredBrands
                return {
                    ...state,
                    filteredBrands,
                    filteredBrandsFill: true,
                }
            case ADD_NEW_BRAND:
                const { id, brand } = action.payload
                brand.brandId = brand.id
                brand.companyId = id
                let addingBrand = { ...state.brands }
                if (addingBrand[id]) {
                    addingBrand[id] = [brand, ...addingBrand[id]]
                } else {
                    addingBrand[id] = [brand]
                }

                let addingBrandDetails = { ...state.brandDetails }
                addingBrandDetails[brand.id] = brand
                return {
                    ...state,
                    filteredBrands: addingBrand,
                    brands: addingBrand,
                    brandDetails: addingBrandDetails,
                }
            case SEARCH_IN_GROUP_BRANDS:
                const { filterGroups } = action.payload
                let newFilterBrands = {}
                filterGroups.map((g) => (newFilterBrands[g.id] = g.brands))
                return {
                    ...state,
                    filteredBrands: newFilterBrands,
                }
            case BRAND_SETTING_TOGGLE:
                return { ...state, brandSettingOpen: action.payload.isOpen }
            case REMOVE_BRAND_DETAILS:
                const newBrandDetails = state.brandDetails
                if (newBrandDetails.hasOwnProperty(action.payload.brandId)) {
                    delete newBrandDetails[action.payload.brandId]
                }
                const newBrand = state.brands
                newBrand[action.payload.workspaceId] = newBrand[
                    action.payload.workspaceId
                ].filter((brand) => brand.brandId !== action.payload.brandId)
                const newFilteredBrand = state.brands
                newFilteredBrand[action.payload.workspaceId] = newFilteredBrand[
                    action.payload.workspaceId
                ].filter((brand) => brand.brandId !== action.payload.brandId)

                return {
                    ...state,
                    brandDetails: newBrandDetails,
                    filteredBrands: newFilteredBrand,
                    brands: newBrand,
                }
            case ADD_USER_TO_BRAND:
                let team = state.team[action.payload.brandId]
                if (team) team = [...team, action.payload.member]
                else team = [action.payload.member]
                return {
                    ...state,
                    loading: false,
                    team: {
                        ...state.team,
                        [action.payload.brandId]: team,
                    },
                }
            case DELETE_USER_FROM_BRAND:
                let newTeam = state.team[action.payload.brandId]
                newTeam = [...newTeam].filter(
                    (member) => member.id !== action.payload.userId
                )
                return {
                    ...state,
                    loading: false,
                    team: {
                        ...state.team,
                        [action.payload.brandId]: newTeam,
                    },
                }
            case UPDATE_USER_ROLE_IN_BRAND:
                const updatedMembers = state.team
                updatedMembers[action.payload.brandId] = [
                    ...updatedMembers[action.payload.brandId],
                ].map((user) =>
                    user.id === action.payload.userId
                        ? { ...user, role: action.payload.member.RoleId.roleName }
                        : user
                )
                return {
                    ...state,
                    loading: false,
                    team: updatedMembers,
                }
            case GET_ALL_USERS_IN_BRAND:
                return {
                    ...state,
                    team: {
                        ...state.team,
                        [action.payload.brandId]: action.payload.team,
                    },
                }
            case UPDATE_TEMPORARY_BRAND:
                return {
                    ...state,
                    temporaryBrand: { ...state.temporaryBrand, ...action.payload },
                }
            case UPDATE_TEMPORARY_BRAND_KEY:
                return {
                    ...state,
                    temporaryBrand: {
                        ...state.temporaryBrand,
                        [action.payload.key]: action.payload.value,
                    },
                }
            case ADD_TEMPORARY_BRAND:
                return { ...state, temporaryBrand: { ...action.payload } }
            case RESET_TEMPORARY_BRAND:
                return { ...state, temporaryBrand: {} }
            case UPDATE_LABELS:
                return {
                    ...state,
                    labels: {
                        ...state.labels,
                        [action.payload.brandId]: action.payload.labels,
                    },
                }
            default:
                return state
        }
    } catch (err) {
        console.log(err) // to convert to nebula log
    }
}

export default brandsReducer
