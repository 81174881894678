import React from "react"
import CommentStrip from "../CollaborationCommentStrip"
import Loader from "../../commonComps/Loaders"
import CommentInput from "./CommentInput"

const ChatBox = ({
    comments,
    onComment,
    doApproval,
    forApproval,
    loading,
    boxRef,
    lastElementRef,
    containerBottomRef,
    user,
}) => {
    const _C = "aui-post-collaboration-chat-box"

    return (
        <div className={_C} ref={boxRef}>
            <div className={_C + "-wrapper  scroll-modern"}>
                {loading && (
                    <div id="loader-container">
                        <Loader
                            h="2rem"
                            w="2rem"
                            b="4px"
                            bk="#eaf0f6"
                            clr="#3c4c5e"
                            t="0.7s"
                        />
                    </div>
                )}

                {comments?.map((comment, i) => (
                    <CommentStrip
                        curRef={i === 0 && !loading ? lastElementRef : null}
                        className={`${_C} ${
                            comment.email_id === user.email_id
                                ? _C + "-me"
                                : _C + "-not-me"
                        }`}
                        key={comment.id}
                        {...comment}
                    />
                ))}
                <div
                    className="chat-container-bottom"
                    ref={containerBottomRef}
                ></div>
            </div>
            <CommentInput
                onComment={onComment}
                doApproval={doApproval}
                forApproval={forApproval}
            />
        </div>
    )
}

export default ChatBox
